export const COLORS = {
  primaryLight: '#33ab9f',
  primary: '#009688',
  primary02: 'rgba(0, 150, 136, 0.2)',
  primaryDark: '#00695f',
  secondaryLight: '#607d8b',
  secondary: '#455a64',
  secondaryDark: '#263238',
  textPrimary: 'rgba(0, 0, 0, 0.87)',
  textSecondary: 'rgba(0, 0, 0, 0.54)',
  inputBorder: 'rgba(0, 0, 0, 0.23)',
  inputDisabledBorder: 'rgba(0, 0, 0, 0.1)',
  divider: '#b4b4b4',
  defaultBackground: '#f5f5f5',
  white: '#eee',
  success: '#43a047',
  warning: '#fb8c00',
  error: '#f44336',
  errorDark: '#d32f2f',
}

export const THEME = {
  UNIT: 8,
  EASE_IN_OUT: 'cubic-bezier(0.4, 0, 0.2, 1)',
  SHORTER: 200,
}

export default {
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      light: COLORS.primaryLight,
      main: COLORS.primary,
      dark: COLORS.primaryDark,
    },
    secondary: {
      light: COLORS.secondaryLight,
      main: COLORS.secondary,
      dark: COLORS.secondaryDark,
    },
    divider: COLORS.divider,
    background: {
      default: COLORS.white,
    },
    text: {
      secondary: COLORS.textSecondary,
    },
    error: {
      main: COLORS.error,
    },
  },
  spacing: {
    unit: THEME.UNIT,
  },
  transitions: {
    easing: {
      easeInOut: THEME.EASE_IN_OUT,
    },
    duration: {
      shorter: THEME.SHORTER,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontWeight: 'normal',
      },
      label: {
        whiteSpace: 'nowrap',
      },
      contained: {
        backgroundColor: 'white',
      },
    },
    MuiTableCell: {
      root: {
        paddingRight: THEME.UNIT * 3,
      },
      head: {
        fontWeight: 600,
      },
    },
    MuiDivider: {
      root: {
        margin: '10px 0',
      },
    },
    MuiTablePagination: {
      spacer: {
        display: 'none',
      },
      select: {
        fontWeight: 'normal',
      },
    },
    MuiAppBar: {
      root: {
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
      },
    },
    MuiNotchedOutline: {
      disabled: {
        borderColor: COLORS.inputDisabledBorder,
      },
    },
    MuiTableRow: {
      hover: {
        cursor: 'pointer',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
      },
    },
    MuiInputBase: {
      root: {
        '&$disabled': {
          color: COLORS.textPrimary,
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        fontWeight: 'normal',
      },
      disabled: {
        '& fieldset': {
          borderColor: `${COLORS.inputDisabledBorder} !important`,
        },
      },
    },
    MuiSelect: {
      disabled: {
        '& ~ svg': {
          display: 'none',
        },
      },
    },
    MuiSnackbarContent: {
      root: {
        flexWrap: 'nowrap',
      },
      message: {
        width: '100%',
      },
    },
    MuiChip: {
      label: {
        padding: '5px 12px',
        whiteSpace: 'normal',
        lineHeight: 1.3,
      },
    },
    MuiStepLabel: {
      label: {
        flexBasis: '100%',
      },
    },
    MuiStep: {
      root: {
        flexBasis: '100%',
      },
    },
    MuiPrivateTabIndicator: {
      colorSecondary: {
        backgroundColor: COLORS.textPrimary,
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: 16,
      },
    },
    MuiFormHelperText: {
      contained: {
        marginTop: THEME.UNIT,
        marginLeft: 0,
      },
    },
    MuiPickersToolbarButton: {
      toolbarBtn: {
        fontSize: '1.125rem',
      },
    },
  },
}
