import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import createBrowserHistory from 'history/createBrowserHistory'
import moment from 'moment'
import {Router} from 'react-router-dom'
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles'
import {MuiPickersUtilsProvider} from 'material-ui-pickers'
import MomentUtils from '@date-io/moment'
import CssBaseline from '@material-ui/core/CssBaseline'

import '../AppContent/style.scss'
import {fetchUser, userRoleSelector} from '../../redux/user'
import {fetchBackInfo, fetchCheck} from '../../redux/auth'
import ScrollToTop from '../_common/ScrollToTop'
import Loader from '../_common/Loader'
import AppContent from '../AppContent'
import Empty from '../Empty'
import muiTheme from '../../styles/muiTheme'
import {BASE_URL} from '../../utils/urls'
import {kc} from '../../index'

const INTERVAL = 5 * 60 * 1000

const history = createBrowserHistory()
window.routerHistory = history
const theme = createMuiTheme(muiTheme)

const logOut = () => kc.logout(process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : BASE_URL)

class App extends Component {
  componentDidMount() {
    const {fetchUser, fetchBackInfo} = this.props
    fetchUser()
    fetchBackInfo()
  }

  static propTypes = {
    user: PropTypes.object,
    roles: PropTypes.array,
    role: PropTypes.string.isRequired,
    isUserFetching: PropTypes.bool,
    userError: PropTypes.object,
    backInfo: PropTypes.object,
    fetchUser: PropTypes.func.isRequired,
    fetchBackInfo: PropTypes.func.isRequired,
    fetchCheck: PropTypes.func.isRequired,
  }

  handleTimeoutCheck = () => {
    this.props.fetchCheck()
    setTimeout(this.handleTimeoutCheck, INTERVAL)
  }

  render() {
    const {user, roles, role, isUserFetching, userError, backInfo} = this.props
    return (
      <Router history={history}>
        <ScrollToTop>
          <MuiThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale="ru" moment={moment}>
              <CssBaseline />
              {isUserFetching ? (
                <Loader isApp isFetch={isUserFetching} />
              ) : (
                <div className="loaded loaded_long">
                  {user && role ? (
                    <AppContent user={user} roles={roles} role={role} backInfo={backInfo} logOut={logOut} />
                  ) : (
                    <Empty userError={userError} logOut={logOut} />
                  )}
                </div>
              )}
            </MuiPickersUtilsProvider>
          </MuiThemeProvider>
        </ScrollToTop>
      </Router>
    )
  }
}

const mapStateToProps = ({auth, user}) => ({
  roles: user.user && user.user.roles,
  role: userRoleSelector(user.user),
  user: user.user,
  isUserFetching: user.isUserFetching,
  userError: user.userError,
  backInfo: auth.backInfo,
})

const mapDispatchToProps = {
  fetchUser,
  fetchBackInfo,
  fetchCheck,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
