import axios from 'axios'
import moment from 'moment'
import fileDownload from 'js-file-download'

import {handleWithSnackbarError, paramsSerializer} from '../utils/helpres'

const module = 'reports'

const FETCH_SELL_IN = `${module}/FETCH_SELL_IN`
const FETCH_SELL_IN_SUCCESS = `${module}/FETCH_SELL_IN_SUCCESS`
const FETCH_SELL_IN_FAIL = `${module}/FETCH_SELL_IN_FAIL`
const SET_SELL_IN_SIZE = `${module}/SET_SELL_IN_SIZE`
const SET_SELL_IN_PAGE = `${module}/SET_SELL_IN_PAGE`
const SET_SELL_IN_SORT = `${module}/SET_SELL_IN_SORT`
const SET_SELL_IN_SEARCH = `${module}/SET_SELL_IN_SEARCH`

const FETCH_SELL_OUT = `${module}/FETCH_SELL_OUT`
const FETCH_SELL_OUT_SUCCESS = `${module}/FETCH_SELL_OUT_SUCCESS`
const FETCH_SELL_OUT_FAIL = `${module}/FETCH_SELL_OUT_FAIL`
const SET_SELL_OUT_SIZE = `${module}/SET_SELL_OUT_SIZE`
const SET_SELL_OUT_PAGE = `${module}/SET_SELL_OUT_PAGE`
const SET_SELL_OUT_SORT = `${module}/SET_SELL_OUT_SORT`
const SET_SELL_OUT_SEARCH = `${module}/SET_SELL_OUT_SEARCH`

const FETCH_ADMIN_SELL_OUT = `${module}/FETCH_ADMIN_SELL_OUT`
const FETCH_ADMIN_SELL_OUT_SUCCESS = `${module}/FETCH_ADMIN_SELL_OUT_SUCCESS`
const FETCH_ADMIN_SELL_OUT_FAIL = `${module}/FETCH_ADMIN_SELL_OUT_FAIL`
const SET_ADMIN_SELL_OUT_SIZE = `${module}/SET_ADMIN_SELL_OUT_SIZE`
const SET_ADMIN_SELL_OUT_PAGE = `${module}/SET_ADMIN_SELL_OUT_PAGE`
const SET_ADMIN_SELL_OUT_SORT = `${module}/SET_ADMIN_SELL_OUT_SORT`
const SET_ADMIN_SELL_OUT_SEARCH = `${module}/SET_ADMIN_SELL_OUT_SEARCH`

const FETCH_PARTNER_SELL_OUT = `${module}/FETCH_PARTNER_SELL_OUT`
const FETCH_PARTNER_SELL_OUT_SUCCESS = `${module}/FETCH_PARTNER_SELL_OUT_SUCCESS`
const FETCH_PARTNER_SELL_OUT_FAIL = `${module}/FETCH_PARTNER_SELL_OUT_FAIL`
const SET_PARTNER_SELL_OUT_SIZE = `${module}/SET_PARTNER_SELL_OUT_SIZE`
const SET_PARTNER_SELL_OUT_PAGE = `${module}/SET_PARTNER_SELL_OUT_PAGE`
const SET_PARTNER_SELL_OUT_SORT = `${module}/SET_PARTNER_SELL_OUT_SORT`
const RESET_PARTNER_SELL_OUT = `${module}/RESET_PARTNER_SELL_OUT`

const APPROVE_SELL_OUT = `${module}/APPROVE_SELL_OUT`
const APPROVE_SELL_OUT_SUCCESS = `${module}/APPROVE_SELL_OUT_SUCCESS`
const APPROVE_SELL_OUT_FAIL = `${module}/APPROVE_SELL_OUT_FAIL`

const DOWNLOAD_REPORT = `${module}/FETCH_DOWNLOAD_REPORT`
const DOWNLOAD_REPORT_SUCCESS = `${module}/FETCH_DOWNLOAD_REPORT_SUCCESS`
const DOWNLOAD_REPORT_FAIL = `${module}/FETCH_DOWNLOAD_REPORT_FAIL`

const FETCH_CHECK_STATUS = `${module}/FETCH_CHECK_STATUS`
const FETCH_CHECK_STATUS_SUCCESS = `${module}/FETCH_CHECK_STATUS_SUCCESS`
const FETCH_CHECK_STATUS_FAIL = `${module}/FETCH_CHECK_STATUS_FAIL`

const TOGGLE_STATUS_MODAL = `${module}/TOGGLE_STATUS_MODAL`
const CLOSE_STATUS_MODAL = `${module}/CLOSE_STATUS_MODAL`

const UPDATE_REPORTS_FORM_VALUES = `${module}/UPDATE_REPORTS_FORM_VALUES`

const FETCH_REPORTS = `${module}/FETCH_REPORTS`
const FETCH_REPORTS_FAIL = `${module}/FETCH_REPORTS_FAIL`
const FETCH_CHECK_REPORTS_STATUS_SUCCESS = `${module}/FETCH_CHECK_REPORTS_STATUS_SUCCESS`
const FETCH_CHECK_REPORTS_STATUS_FAIL = `${module}/FETCH_CHECK_REPORTS_STATUS_FAIL`
const SET_REPORTS_SIZE = `${module}/SET_REPORTS_SIZE`
const SET_REPORTS_PAGE = `${module}/SET_REPORTS_PAGE`
const SET_REPORTS_SORT = `${module}/SET_REPORTS_SORT`

const DOWNLOAD_SELL_IN_FILE = `${module}/DOWNLOAD_SELL_IN_FILE`
const DOWNLOAD_SELL_IN_FILE_SUCCESS = `${module}/DOWNLOAD_SELL_IN_FILE_SUCCESS`
const DOWNLOAD_SELL_IN_FILE_FAIL = `${module}/DOWNLOAD_SELL_IN_FILE_FAIL`

const DOWNLOAD_SELL_OUT_FILE = `${module}/DOWNLOAD_SELL_OUT_FILE`
const DOWNLOAD_SELL_OUT_FILE_SUCCESS = `${module}/DOWNLOAD_SELL_OUT_FILE_SUCCESS`
const DOWNLOAD_SELL_OUT_FILE_FAIL = `${module}/DOWNLOAD_SELL_OUT_FILE_FAIL`

const RESET_DOWNLOAD = `${module}/RESET_DOWNLOAD`

const initialState = {
  sellIn: null,
  isSellInFetching: false,
  sellInSize: 10,
  sellInPage: 0,
  sellInSort: null,
  sellInSearch: '',

  sellOut: null,
  isSellOutFetching: false,
  sellOutSize: 10,
  sellOutPage: 0,
  sellOutSort: null,
  sellOutSearch: '',

  adminSellOut: null,
  isAdminSellOutFetching: false,
  adminSellOutSize: 10,
  adminSellOutPage: 0,
  adminSellOutSort: null,
  adminSellOutSearch: '',

  partnerSellOut: null,
  isPartnerSellOutFetching: false,
  partnerSellOutSize: 10,
  partnerSellOutPage: 0,
  partnerSellOutSort: null,

  isSellOutApprove: false,

  report: null,
  isReportDownload: false,

  status: null,
  statusError: null,
  isStatusModalOpen: false,

  reportsFormValues: {
    date_from: moment().set({month: 0, date: 1}),
    date_to: moment(),
    is_vaillant: true,
    is_proterm: true,
    is_zch: true,
  },

  reports: null,
  isReportsFetching: false,
  reportsStatus: false,
  reportsSize: 10,
  reportsPage: 0,
  reportsSort: null,

  isSellInFileDownload: false,

  isSellOutFileDownload: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SELL_IN:
      return {...state, isSellInFetching: true}
    case FETCH_SELL_IN_SUCCESS:
      return {...state, isSellInFetching: false, sellIn: action.sellIn}
    case FETCH_SELL_IN_FAIL:
      return {...state, isSellInFetching: false}
    case SET_SELL_IN_SIZE:
      return {...state, sellInSize: action.size}
    case SET_SELL_IN_PAGE:
      return {...state, sellInPage: action.page}
    case SET_SELL_IN_SORT:
      return {...state, sellInSort: action.sort}
    case SET_SELL_IN_SEARCH:
      return {...state, sellInSearch: action.search}

    case FETCH_SELL_OUT:
      return {...state, isSellOutFetching: true}
    case FETCH_SELL_OUT_SUCCESS:
      return {...state, isSellOutFetching: false, sellOut: action.sellOut}
    case FETCH_SELL_OUT_FAIL:
      return {...state, isSellOutFetching: false}
    case SET_SELL_OUT_SIZE:
      return {...state, sellOutSize: action.size}
    case SET_SELL_OUT_PAGE:
      return {...state, sellOutPage: action.page}
    case SET_SELL_OUT_SORT:
      return {...state, sellOutSort: action.sort}
    case SET_SELL_OUT_SEARCH:
      return {...state, sellOutSearch: action.search}

    case FETCH_ADMIN_SELL_OUT:
      return {...state, isAdminSellOutFetching: true}
    case FETCH_ADMIN_SELL_OUT_SUCCESS:
      return {...state, isAdminSellOutFetching: false, adminSellOut: action.adminSellOut}
    case FETCH_ADMIN_SELL_OUT_FAIL:
      return {...state, isAdminSellOutFetching: false}
    case SET_ADMIN_SELL_OUT_SIZE:
      return {...state, adminSellOutSize: action.size}
    case SET_ADMIN_SELL_OUT_PAGE:
      return {...state, adminSellOutPage: action.page}
    case SET_ADMIN_SELL_OUT_SORT:
      return {...state, adminSellOutSort: action.sort}
    case SET_ADMIN_SELL_OUT_SEARCH:
      return {...state, adminSellOutSearch: action.search}

    case FETCH_PARTNER_SELL_OUT:
      return {...state, isPartnerSellOutFetching: true}
    case FETCH_PARTNER_SELL_OUT_SUCCESS:
      return {...state, isPartnerSellOutFetching: false, partnerSellOut: action.partnerSellOut}
    case FETCH_PARTNER_SELL_OUT_FAIL:
      return {...state, isPartnerSellOutFetching: false}
    case SET_PARTNER_SELL_OUT_SIZE:
      return {...state, partnerSellOutSize: action.size}
    case SET_PARTNER_SELL_OUT_PAGE:
      return {...state, partnerSellOutPage: action.page}
    case SET_PARTNER_SELL_OUT_SORT:
      return {...state, partnerSellOutSort: action.sort}
    case RESET_PARTNER_SELL_OUT:
      return {
        ...state,
        partnerSellOut: null,
        isPartnerSellOutFetching: false,
        partnerSellOutSize: initialState.partnerSellOutSize,
        partnerSellOutPage: initialState.partnerSellOutPage,
        partnerSellOutSort: initialState.partnerSellOutSort,
      }

    case APPROVE_SELL_OUT:
      return {...state, isSellOutApprove: true}
    case APPROVE_SELL_OUT_SUCCESS:
    case APPROVE_SELL_OUT_FAIL:
      return {...state, isSellOutApprove: false}

    case DOWNLOAD_REPORT:
      return {...state, isReportDownload: true}
    case DOWNLOAD_REPORT_SUCCESS:
      return {...state, report: action.report}
    case DOWNLOAD_REPORT_FAIL:
      return {...state, isReportDownload: false}

    case FETCH_CHECK_STATUS:
      return {...state, statusError: null}
    case FETCH_CHECK_STATUS_SUCCESS:
      return {...state, isReportDownload: false, status: action.status}
    case FETCH_CHECK_STATUS_FAIL:
      return {...state, isReportDownload: false, statusError: action.error}

    case TOGGLE_STATUS_MODAL:
      return {...state, isStatusModalOpen: !state.isStatusModalOpen}
    case CLOSE_STATUS_MODAL:
      return {...state, isStatusModalOpen: false}

    case UPDATE_REPORTS_FORM_VALUES:
      return {
        ...state,
        reportsFormValues: action.reportsFormValues,
      }

    case FETCH_REPORTS:
      return {...state, isReportsFetching: true}
    case FETCH_REPORTS_FAIL:
      return {...state, isReportsFetching: false}
    case FETCH_CHECK_REPORTS_STATUS_SUCCESS:
      return {...state, isReportsFetching: false, reports: action.reports}
    case FETCH_CHECK_REPORTS_STATUS_FAIL:
      return {...state, isReportsFetching: false}
    case SET_REPORTS_SIZE:
      return {...state, reportsSize: action.size}
    case SET_REPORTS_PAGE:
      return {...state, reportsPage: action.page}
    case SET_REPORTS_SORT:
      return {...state, reportsSort: action.sort}

    case DOWNLOAD_SELL_IN_FILE:
      return {...state, isSellInFileDownload: true}
    case DOWNLOAD_SELL_IN_FILE_SUCCESS:
    case DOWNLOAD_SELL_IN_FILE_FAIL:
      return {...state, isSellInFileDownload: false}

    case DOWNLOAD_SELL_OUT_FILE:
      return {...state, isSellOutFileDownload: true}
    case DOWNLOAD_SELL_OUT_FILE_SUCCESS:
    case DOWNLOAD_SELL_OUT_FILE_FAIL:
      return {...state, isSellOutFileDownload: false}

    case RESET_DOWNLOAD:
      return {
        ...state,
        report: null,
        isReportDownload: false,
        status: null,
        statusError: null,
        isStatusModalOpen: false,
      }

    default:
      return state
  }
}

export const fetchSellIn = () => (dispatch, getState) => {
  dispatch({type: FETCH_SELL_IN})
  const state = getState().reports
  const params = {
    size: state.sellInSize,
    page: state.sellInPage,
  }
  if (state.sellInSort) {
    params.sort = `${state.sellInSort[0]},${state.sellInSort[1]}`
  }
  if (state.sellInSearch) {
    params.search = state.sellInSearch
  }
  axios
    .get('/sale_in/history', {params})
    .then(({data}) => dispatch({type: FETCH_SELL_IN_SUCCESS, sellIn: data}))
    .catch(error => handleWithSnackbarError(dispatch, FETCH_SELL_IN_FAIL, error))
}

export const setSellInSize = size => ({type: SET_SELL_IN_SIZE, size})
export const setSellInPage = page => ({type: SET_SELL_IN_PAGE, page})
export const setSellInSort = sort => ({type: SET_SELL_IN_SORT, sort})
export const setSellInSearch = search => ({type: SET_SELL_IN_SEARCH, search})

export const fetchSellOut = () => (dispatch, getState) => {
  dispatch({type: FETCH_SELL_OUT})
  const state = getState().reports
  const params = {
    size: state.sellOutSize,
    page: state.sellOutPage,
  }
  if (state.sellOutSort) {
    if (state.sellOutSort[0].includes(',')) {
      const sortNameParts = state.sellOutSort[0].split(',')
      const sortOrder = state.sellOutSort[1]
      params.sort = [`${sortNameParts[0]},${sortOrder}`, `${sortNameParts[1]},${sortOrder}`]
    } else {
      params.sort = `${state.sellOutSort[0]},${state.sellOutSort[1]}`
    }
  }
  if (state.sellOutSearch) {
    params.search = state.sellOutSearch
  }
  axios
    .get('/sale_out/history', {
      params,
      paramsSerializer,
    })
    .then(({data}) => dispatch({type: FETCH_SELL_OUT_SUCCESS, sellOut: data}))
    .catch(error => handleWithSnackbarError(dispatch, FETCH_SELL_OUT_FAIL, error))
}

export const setSellOutSize = size => ({type: SET_SELL_OUT_SIZE, size})
export const setSellOutPage = page => ({type: SET_SELL_OUT_PAGE, page})
export const setSellOutSort = sort => ({type: SET_SELL_OUT_SORT, sort})
export const setSellOutSearch = search => ({type: SET_SELL_OUT_SEARCH, search})

export const fetchAdminSellOut = () => (dispatch, getState) => {
  dispatch({type: FETCH_ADMIN_SELL_OUT})
  const state = getState().reports
  const params = {
    size: state.adminSellOutSize,
    page: state.adminSellOutPage,
  }
  if (state.adminSellOutSort) {
    params.sort = `${state.adminSellOutSort[0]},${state.adminSellOutSort[1]}`
  }
  if (state.sellOutSearch) {
    params.search = state.sellOutSearch
  }
  axios
    .get('/sale_out/partner', {
      params,
      paramsSerializer,
    })
    .then(({data}) =>
      dispatch({
        type: FETCH_ADMIN_SELL_OUT_SUCCESS,
        adminSellOut: data,
      })
    )
    .catch(error => handleWithSnackbarError(dispatch, FETCH_ADMIN_SELL_OUT_FAIL, error))
}

export const setAdminSellOutSize = size => ({type: SET_ADMIN_SELL_OUT_SIZE, size})
export const setAdminSellOutPage = page => ({type: SET_ADMIN_SELL_OUT_PAGE, page})
export const setAdminSellOutSort = sort => ({type: SET_ADMIN_SELL_OUT_SORT, sort})
export const setAdminSellOutSearch = search => ({type: SET_ADMIN_SELL_OUT_SEARCH, search})

export const fetchPartnerSellOut = orgNumber => (dispatch, getState) => {
  dispatch({type: FETCH_PARTNER_SELL_OUT})
  const state = getState().reports
  const params = {
    size: state.partnerSellOutSize,
    page: state.partnerSellOutPage,
  }
  if (state.partnerSellOutSort) {
    params.sort = `${state.partnerSellOutSort[0]},${state.partnerSellOutSort[1]}`
  }
  axios
    .get(`/sale_out/partner/${orgNumber}`, {
      params,
      paramsSerializer,
    })
    .then(({data}) => dispatch({type: FETCH_PARTNER_SELL_OUT_SUCCESS, partnerSellOut: data}))
    .catch(error => handleWithSnackbarError(dispatch, FETCH_PARTNER_SELL_OUT_FAIL, error))
}
export const setPartnerSellOutSize = size => ({type: SET_PARTNER_SELL_OUT_SIZE, size})
export const setPartnerSellOutPage = page => ({type: SET_PARTNER_SELL_OUT_PAGE, page})
export const setPartnerSellOutSort = sort => ({type: SET_PARTNER_SELL_OUT_SORT, sort})
export const resetPartnerSellOut = () => ({type: RESET_PARTNER_SELL_OUT})

export const approveSellOut = (id, orgNumber) => dispatch => {
  dispatch({type: APPROVE_SELL_OUT})
  axios
    .put('sale_out', null, {
      params: {guid: id},
    })
    .then(() => {
      dispatch({type: APPROVE_SELL_OUT_SUCCESS})
      dispatch(fetchPartnerSellOut(orgNumber))
    })
    .catch(error => handleWithSnackbarError(dispatch, APPROVE_SELL_OUT_FAIL, error))
}

export const downloadReport = (data, closeModal) => dispatch => {
  const fd = new FormData()
  fd.append('file', data.file)
  dispatch({type: DOWNLOAD_REPORT})
  const {type} = data
  const params = {
    type,
  }
  if (type === 'SALE_OUT') {
    params.report_month_year = data.report_month_year
  } else {
    params.report_date = data.report_date
  }
  axios
    .post('/sale_file', fd, {
      params,
      contentType: 'multipart/form-data',
    })
    .then(({data}) => {
      dispatch({type: DOWNLOAD_REPORT_SUCCESS, report: data})
      dispatch(fetchCheckStatus(data.statusLink.slice(7)))
      closeModal()
    })
    .catch(error => handleWithSnackbarError(dispatch, DOWNLOAD_REPORT_FAIL, error))
}

const fetchCheckStatus = statusUrl => dispatch => {
  dispatch({type: FETCH_CHECK_STATUS})
  axios
    .get(statusUrl)
    .then(({data, status}) => {
      if (status === 200) {
        dispatch({type: FETCH_CHECK_STATUS_SUCCESS, status: data})
        statusUrl.includes('SALE_IN') ? dispatch(fetchSellIn()) : dispatch(fetchSellOut())
        dispatch(toggleStatusModal())
        return
      }
      setTimeout(() => dispatch(fetchCheckStatus(statusUrl)), 2000)
    })
    .catch(error => {
      dispatch({type: FETCH_CHECK_STATUS_FAIL, error})
      dispatch(toggleStatusModal())
    })
}

export const toggleStatusModal = () => ({type: TOGGLE_STATUS_MODAL})

export const updateReportsFormValues = reportsFormValues => ({type: UPDATE_REPORTS_FORM_VALUES, reportsFormValues})

export const fetchReports = () => (dispatch, getState) => {
  dispatch({type: FETCH_REPORTS})
  const state = getState().reports
  const {date_from, date_to, is_proterm, is_vaillant, is_zch} = state.reportsFormValues
  const params = {
    is_proterm,
    is_vaillant,
    is_zch,
    date_from: moment(date_from).format('YYYY-MM-DD'),
    date_to: moment(date_to).format('YYYY-MM-DD'),
    size: state.reportsSize,
    page: state.reportsPage,
  }
  if (state.reportsSort) {
    params.sort = `${state.reportsSort[0]},${state.reportsSort[1]}`
  }
  axios
    .get('/sale_in', {params})
    .then(({data}) => {
      dispatch(fetchCheckReportsStatus(data.slice(7)))
    })
    .catch(error => handleWithSnackbarError(dispatch, FETCH_REPORTS_FAIL, error))
}

const fetchCheckReportsStatus = statusUrl => dispatch => {
  axios
    .get(statusUrl, {params: {is_file: false}})
    .then(({data, status}) => {
      if (status === 200 && data.guid) {
        dispatch({type: FETCH_CHECK_REPORTS_STATUS_SUCCESS, reports: data.saleInEntities})
        return
      }
      setTimeout(() => dispatch(fetchCheckReportsStatus(statusUrl)), 2000)
    })
    .catch(error => {
      dispatch({type: FETCH_CHECK_REPORTS_STATUS_FAIL, error})
    })
}

export const setReportsSize = size => ({type: SET_REPORTS_SIZE, size})
export const setReportsPage = page => ({type: SET_REPORTS_PAGE, page})
export const setReportsSort = sort => ({type: SET_REPORTS_SORT, sort})

export const downloadSellInFile = () => (dispatch, getState) => {
  dispatch({type: DOWNLOAD_SELL_IN_FILE})
  const state = getState().reports
  const {date_from, date_to, is_proterm, is_vaillant, is_zch} = state.reportsFormValues
  const params = {
    is_proterm,
    is_vaillant,
    is_zch,
    date_from: moment(date_from).format('YYYY-MM-DD'),
    date_to: moment(date_to).format('YYYY-MM-DD'),
  }
  axios
    .get('/sale_in_export', {params})
    .then(({data}) => dispatch(fetchCheckSellInFileStatus(data.slice(7))))
    .catch(error => handleWithSnackbarError(dispatch, DOWNLOAD_SELL_IN_FILE_FAIL, error))
}

const fetchCheckSellInFileStatus = statusUrl => dispatch => {
  axios
    .get(statusUrl)
    .then(({data}) => {
      if (data.fileIsReady) {
        console.log(data)
        axios
          .get(`/sale_in/${data.guid}/file`, {
            responseType: 'blob',
          })
          .then(({data, headers}) => {
            dispatch({type: DOWNLOAD_SELL_IN_FILE_SUCCESS})
            fileDownload(data, decodeURI(headers['content-disposition'].split('filename=')[1]))
          })
          .catch(error => handleWithSnackbarError(dispatch, DOWNLOAD_SELL_IN_FILE_FAIL, error))
        return
      }
      setTimeout(() => dispatch(fetchCheckSellInFileStatus(statusUrl)), 2000)
    })
    .catch(error => {
      dispatch({type: DOWNLOAD_SELL_IN_FILE_FAIL, error})
    })
}

export const downloadSellOutFile = guid => dispatch => {
  dispatch({type: DOWNLOAD_SELL_OUT_FILE})
  const params = {
    guid,
  }
  axios
    .get('/sale_out', {
      params,
      responseType: 'blob',
    })
    .then(({data, headers}) => {
      dispatch({type: DOWNLOAD_SELL_OUT_FILE_SUCCESS})
      fileDownload(data, decodeURI(headers['content-disposition'].split('filename=')[1]))
    })
    .catch(error => handleWithSnackbarError(dispatch, DOWNLOAD_SELL_OUT_FILE_FAIL, error))
}

export const resetDownload = () => ({type: RESET_DOWNLOAD})
