import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import withStyles from '@material-ui/core/styles/withStyles'
import CommentIcon from '@material-ui/icons/CommentTwoTone'
import TrendingFlatIcon from '@material-ui/icons/TrendingFlatTwoTone'

import './style.scss'

const StyledCommentIcon = withStyles({
  root: {
    fontSize: 20,
    marginLeft: 8,
  },
})(CommentIcon)

const StyledTrendingFlatIcon = withStyles({
  root: {
    fontSize: 20,
  },
})(TrendingFlatIcon)

const getShortName = user => {
  const middleName = user.middleName && user.middleName !== 'null' ? ` ${user.middleName.slice(0, 1)}.` : ''
  return `${user.lastName} ${user.firstName.slice(0, 1)}.${middleName}`
}

const getCurator = (curators, comment) => {
  const currentCurator = curators
    ? curators.find(curator => curator.curator && curator.curator.id === comment.forCuratorId)
    : null
  return currentCurator ? getShortName(currentCurator.curator) : 'Куратор'
}

AgreementComment.propTypes = {
  curators: PropTypes.array.isRequired,
  comment: PropTypes.object.isRequired,
}

export default function AgreementComment({curators, comment}) {
  const moscowDate = moment
    .utc(comment.createdDate)
    .add(3, 'hours')
    .format('DD.MM.YYYY, HH:mm')
  return (
    <li className="agreement-comment">
      <p className="agreement-comment__header">
        <span className="agreement-comment__name">{getShortName(comment.user)}</span>
        {comment.isAdditional && (
          <>
            &nbsp;
            <StyledTrendingFlatIcon />
            &nbsp;{getCurator(curators, comment)}
          </>
        )}
        &nbsp;
        <span className="grey">{moscowDate}</span>
        {comment.isAdditional && <StyledCommentIcon />}
      </p>
      <p>{comment.comment}</p>
    </li>
  )
}
