import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import {NavLink} from 'react-router-dom'
import {withStyles} from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import DashboardIcon from '@material-ui/icons/DashboardTwoTone'
import PersonIcon from '@material-ui/icons/PersonTwoTone'
import CheckCircleIcon from '@material-ui/icons/CheckCircleTwoTone'
import ArchiveIcon from '@material-ui/icons/ArchiveTwoTone'
import ListAltIcon from '@material-ui/icons/ListAltTwoTone'
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternateTwoTone'
import DescriptionTwoToneIcon from '@material-ui/icons/DescriptionTwoTone'
import WorkIcon from '@material-ui/icons/WorkTwoTone'

import './style.scss'
import logImg from '../../assets/img/logo.png'
import {getTitle} from '../../utils/helpres'
import URLS from '../../utils/urls'
import ROLES from '../../utils/constants'
import {COLORS, THEME} from '../../styles/muiTheme'
import {version} from '../../../package.json'

const styles = {
  button: {
    textAlign: 'left',
    textTransform: 'none',
    padding: '14px 16px',
    justifyContent: 'flex-start',
    borderRadius: 3,
  },
  buttonActive: {
    color: 'white',
    backgroundColor: COLORS.primary,
    boxShadow: `0 12px 20px -10px ${COLORS.primary02}, 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px ${
      COLORS.primary02
    }`,
    '&:hover': {
      backgroundColor: COLORS.primary,
    },
  },
  icon: {
    marginRight: THEME.UNIT * 2,
  },
  divider: {
    marginBottom: THEME.UNIT * 2,
  },
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  backInfo: PropTypes.object,
  onClose: PropTypes.func,
}

const renderNavLinks = (classes, navLinks, onClose) =>
  navLinks.map(link => (
    <li key={link.to} className="sidebar__link">
      <Button
        fullWidth
        className={classes.button}
        activeClassName={classes.buttonActive}
        exact={link.to === URLS.DASHBOARD}
        size="large"
        to={link.to}
        component={NavLink}
        onClick={onClose || null}
      >
        {link.icon}
        <span className="cropped">{link.text}</span>
      </Button>
    </li>
  ))

function Sidebar({classes, role, backInfo, onClose}) {
  const navLinks = []
  const reportsLink = {
    text: getTitle(URLS.REPORTS, role),
    to: URLS.REPORTS,
    icon: <DescriptionTwoToneIcon className={classes.icon} />,
  }
  if (role !== ROLES.P && role !== ROLES.GD && role !== ROLES.U) {
    navLinks.push({
      text: getTitle(URLS.DASHBOARD, role),
      to: URLS.DASHBOARD,
      icon: <DashboardIcon className={classes.icon} />,
    })
  }
  if (role !== ROLES.U) {
    navLinks.push({
      text: getTitle(URLS.PARTNERS, role),
      to: URLS.PARTNERS,
      icon: <PersonIcon className={classes.icon} />,
    })
  }
  if (role !== ROLES.P) {
    navLinks.push({
      text: getTitle(URLS.AGREEMENT, role),
      to: URLS.AGREEMENT,
      icon: <CheckCircleIcon className={classes.icon} />,
    })
  }
  if (role === ROLES.ADMIN) {
    navLinks.push(
      {
        text: getTitle(URLS.SCANS, role),
        to: URLS.SCANS,
        icon: <AddPhotoAlternateIcon className={classes.icon} />,
      },
      {
        text: getTitle(URLS.ACTS, role),
        to: URLS.ACTS,
        icon: <WorkIcon className={classes.icon} />,
      },
      {
        text: getTitle(URLS.SELL_IN, role),
        to: URLS.SELL_IN,
        icon: <DescriptionTwoToneIcon className={classes.icon} />,
      },
      {
        text: getTitle(URLS.SELL_OUT, role),
        to: URLS.SELL_OUT,
        icon: <DescriptionTwoToneIcon className={classes.icon} />,
      },
      reportsLink,
      {
        text: getTitle(URLS.REFERENCES, role),
        to: URLS.REFERENCES,
        icon: <ListAltIcon className={classes.icon} />,
      },
      {
        text: getTitle(URLS.HISTORY, role),
        to: URLS.HISTORY,
        icon: <ArchiveIcon className={classes.icon} />,
      }
    )
  }
  if (role === ROLES.P) {
    navLinks.push({
      text: getTitle(URLS.SELL_OUT, role),
      to: URLS.SELL_OUT,
      icon: <DescriptionTwoToneIcon className={classes.icon} />,
    })
  }
  if (role === ROLES.RP || role === ROLES.RM || role === ROLES.DP || role === ROLES.GD) {
    navLinks.push(reportsLink)
  }
  const backVersion = backInfo && backInfo.git ? backInfo.git.branch : '...'
  return (
    <aside className={cn('sidebar', {sidebar_compact: Boolean(onClose)}, 'f fd-c jc-sb')}>
      <nav className="sidebar__nav">
        <NavLink to={URLS.DASHBOARD}>
          <img className="sidebar__logo" src={logImg} alt="Vaillant Group" />
        </NavLink>
        <Divider className={classes.divider} />
        <ul>{renderNavLinks(classes, navLinks, onClose)}</ul>
      </nav>
      <div className="mt-s">
        <p className="sidebar__version cropped">
          Версии: front <strong>{version}</strong> | back <strong>{backVersion}</strong>
        </p>
      </div>
    </aside>
  )
}

export default withStyles(styles)(Sidebar)
