import React, {Component} from 'react'
//import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {fetchAllPartners} from '../../redux/partners'
import {fetchPartnerTypes} from '../../redux/references'
import GridItem from './Grid/GridItem.jsx'
import GridContainer from './Grid/GridContainer.jsx'
import Card from './Card'
import SimplePieChart from './SimplePieChart'
import SimpleStatistics from './SimpleStatistics'
import SimpleBar from './SimpleBar'
import DoubleBar from './DoubleBar'
import PartnersView from './PartnersView'

//import {STATUSES} from '../../utils/constants'

class Dashboard extends Component {
  /* componentDidMount() {
     const {partnerTypes, fetchAllPartners, fetchPartnerTypes} = this.props
    if (!partnerTypes) fetchPartnerTypes()
    fetchAllPartners() 
  } */

  render() {
    const {
      simpleStatistics,
      ratesByYear,
      theoryPlan,
      salesByRegions,
      brand,
      salesByChannels,
      partners,
      products,
    } = this.props

    return (
      <div className="dashboard container loaded">
        <GridContainer>
          {simpleStatistics.map((item, index) => (
            <GridItem xs={6} lg={3} key={index}>
              <Card cardName={item.label}>
                <SimpleStatistics value={item.value} />
              </Card>
            </GridItem>
          ))}
        </GridContainer>
        <GridContainer>
          <GridItem xs={6} lg={3}>
            <Card cardName={ratesByYear.label}>
              <DoubleBar data={ratesByYear.data} />
            </Card>
          </GridItem>
          <GridItem xs={6} lg={3}>
            <Card cardName={theoryPlan.label}>
              <DoubleBar data={theoryPlan.data} />
            </Card>
          </GridItem>
          <GridItem xs={12} lg={6}>
            <Card cardName={salesByRegions.label}>
              <SimpleBar data={salesByRegions.data} />
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={6} lg={4}>
            <Card cardName={brand.label}>
              <SimplePieChart data={brand.data} />
            </Card>
          </GridItem>
          <GridItem xs={6} lg={4}>
            <Card cardName={salesByChannels.label}>
              <SimplePieChart data={salesByChannels.data} />
            </Card>
          </GridItem>
          <GridItem xs={6} lg={4}>
            <Card cardName={partners.label}>
              <PartnersView data={partners.data} />
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} lg={6}>
            <Card cardName={products.label}>
              <SimpleBar data={products.data} />
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    )
  }
}

const mapStateToProps = ({dashboard}) => ({
  simpleStatistics: dashboard.simpleStatistics,
  ratesByYear: dashboard.ratesByYear,
  theoryPlan: dashboard.theoryPlan,
  salesByRegions: dashboard.salesByRegions,
  brand: dashboard.brand,
  salesByChannels: dashboard.salesByChannels,
  partners: dashboard.partners,
  products: dashboard.products,
})

const mapDispatchToProps = {
  fetchAllPartners,
  fetchPartnerTypes,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)
