import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import moment from 'moment'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'

import Loader from '../_common/Loader'
import PartnerSellOutActions from './PartnerSellOutActions'
import URLS from '../../utils/urls'

import {
  fetchPartnerSellOut,
  setPartnerSellOutSize,
  setPartnerSellOutPage,
  setPartnerSellOutSort,
  resetPartnerSellOut,
  approveSellOut,
  downloadSellOutFile,
} from '../../redux/reports'
import ExtendedTable from '../_common/ExtendedTable'

const headerCells = [
  {title: 'Название файла', sortName: 'fileName'},
  {title: 'Месяц отчета', sortName: 'month,year'},
  {title: 'Дата загрузки', sortName: 'createdDate'},
  {title: 'Подтвержден', sortName: 'approved'},
  {title: 'Действие'},
]

class PartnerSellOut extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    partnerSellOut: PropTypes.object,
    isPartnerSellOutFetching: PropTypes.bool.isRequired,
    partnerSellOutSize: PropTypes.number.isRequired,
    partnerSellOutPage: PropTypes.number.isRequired,
    partnerSellOutSort: PropTypes.array,
    isSellOutApprove: PropTypes.bool.isRequired,
    isSellOutFileDownload: PropTypes.bool.isRequired,

    fetchPartnerSellOut: PropTypes.func.isRequired,
    setPartnerSellOutSize: PropTypes.func.isRequired,
    setPartnerSellOutPage: PropTypes.func.isRequired,
    setPartnerSellOutSort: PropTypes.func.isRequired,
    resetPartnerSellOut: PropTypes.func.isRequired,
    approveSellOut: PropTypes.func.isRequired,
    downloadSellOutFile: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const {
      match: {params},
      fetchPartnerSellOut,
    } = this.props
    fetchPartnerSellOut(params.id)
  }

  componentWillReceiveProps(nextProps) {
    const {
      match: {params},
      partnerSellOutSize,
      partnerSellOutPage,
      partnerSellOutSort,
      fetchPartnerSellOut,
    } = this.props
    if (
      nextProps.partnerSellOutSize !== partnerSellOutSize ||
      nextProps.partnerSellOutPage !== partnerSellOutPage ||
      nextProps.partnerSellOutSort !== partnerSellOutSort
    ) {
      fetchPartnerSellOut(params.id)
    }
  }

  componentWillUnmount() {
    this.props.resetPartnerSellOut()
  }

  handleGoToSellOut = () => this.props.history.push(URLS.SELL_OUT)

  getTableRows() {
    const {
      match: {params},
      partnerSellOut,
      approveSellOut,
      downloadSellOutFile,
    } = this.props
    return (
      partnerSellOut &&
      partnerSellOut.content.map(report => ({
        id: report.guid,
        data: [
          report.fileName,
          `${String(report.month).length === 1 ? '0' : ''}${report.month}.${report.year}`,
          moment(report.createdDate).format('DD.MM.YYYY'),
          report.approved ? 'Да' : 'Нет',
          <PartnerSellOutActions
            id={report.guid}
            orgNumber={params.id}
            isApproved={report.approved}
            approveSellOut={approveSellOut}
            downloadSellOutFile={downloadSellOutFile}
          />,
        ],
      }))
    )
  }

  render() {
    const {
      partnerSellOut,
      isPartnerSellOutFetching,
      partnerSellOutSize,
      partnerSellOutPage,
      partnerSellOutSort,
      isSellOutApprove,
      isSellOutFileDownload,

      setPartnerSellOutSize,
      setPartnerSellOutPage,
      setPartnerSellOutSort,
    } = this.props
    const total = partnerSellOut ? partnerSellOut.totalElements : 0
    return (
      <section className="container loaded">
        <Loader isFetch={isSellOutApprove || isSellOutFileDownload} />
        <Paper>
          <div className="block">
            <div className="mb">
              <Button variant="outlined" onClick={this.handleGoToSellOut}>
                Назад
              </Button>
            </div>
            <ExtendedTable
              isInternal
              headerCells={headerCells}
              tableRows={this.getTableRows()}
              isFetching={isPartnerSellOutFetching}
              total={total}
              rowsPerPage={partnerSellOutSize}
              page={partnerSellOutPage}
              sort={partnerSellOutSort}
              setPage={setPartnerSellOutPage}
              setSize={setPartnerSellOutSize}
              setSort={setPartnerSellOutSort}
            />
          </div>
        </Paper>
      </section>
    )
  }
}

const mapStateToProps = ({reports}) => ({
  partnerSellOut: reports.partnerSellOut,
  isPartnerSellOutFetching: reports.isPartnerSellOutFetching,
  partnerSellOutSize: reports.partnerSellOutSize,
  partnerSellOutPage: reports.partnerSellOutPage,
  partnerSellOutSort: reports.partnerSellOutSort,
  isSellOutApprove: reports.isSellOutApprove,
  isSellOutFileDownload: reports.isSellOutFileDownload,
})

const mapDispatchToProps = {
  fetchPartnerSellOut,
  setPartnerSellOutSize,
  setPartnerSellOutPage,
  setPartnerSellOutSort,
  resetPartnerSellOut,
  approveSellOut,
  downloadSellOutFile,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerSellOut)
