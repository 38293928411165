import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {Form, Field} from 'react-final-form'
import Grid from '@material-ui/core/Grid'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

import GridContainer from '../../../_common/GridContainer'
import InputField from '../../../_common/InputField'
import {validateAgreement} from '../../../../utils/validator'
import {focusOnError} from '../../../../utils/helpres'
import ROLES, {ROLE_PREFIX, AGREEMENT_EVENTS} from '../../../../utils/constants'

const styles = {
  dialogContent: {
    paddingTop: 0,
  },
}

export default class AgreementForm extends PureComponent {
  static propTypes = {
    approvalSheet: PropTypes.object,
    isAgreeSheetFetching: PropTypes.bool.isRequired,
    isSend: PropTypes.bool,
    isModify: PropTypes.bool,
    isReject: PropTypes.bool,
    isConclusion: PropTypes.bool,
    isConfirm: PropTypes.bool,
    isModalOpen: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    agreeApprovalSheet: PropTypes.func.isRequired,
  }

  handleFormSubmit = values => {
    const {
      approvalSheet,
      isSend,
      isModify,
      isReject,
      isConclusion,
      isConfirm,
      agreeApprovalSheet,
      onModalClose,
    } = this.props
    const currentCurator = approvalSheet.curators.find(curator => !curator.isSignature)
    const comment = values.comment ? {title: 'Заголовок комментария', comment: values.comment} : {}
    const contractEvent = isSend
      ? AGREEMENT_EVENTS.SEND
      : isModify
      ? AGREEMENT_EVENTS.MODIFY
      : isReject
      ? AGREEMENT_EVENTS.REJECT
      : isConclusion
      ? AGREEMENT_EVENTS.CONCLUDE
      : isConfirm
      ? AGREEMENT_EVENTS.CONFIRM
      : AGREEMENT_EVENTS.APPROVE
    agreeApprovalSheet({
      contractEvent,
      comment,
      curatorId: currentCurator.id,
      approvalSheetId: approvalSheet.id,
      documentNumber: approvalSheet.document.docNumber,
      closeModal: onModalClose,
    })
  }

  render() {
    const {
      approvalSheet,
      isAgreeSheetFetching,
      isSend,
      isModify,
      isReject,
      isConclusion,
      isConfirm,
      isModalOpen,
      onModalClose,
    } = this.props
    const submitText = isSend
      ? 'Отправить на согласование'
      : isModify
      ? 'Доработать'
      : isReject
      ? 'Заблокировать партнера'
      : isConclusion
      ? 'Добавить'
      : 'Согласовать'
    const isDPCurator =
      !isSend &&
      !isModify &&
      !isReject &&
      approvalSheet &&
      approvalSheet.curators.find(curator => !curator.isSignature).role.replace(ROLE_PREFIX, '') === ROLES.DP
    return (
      <Form
        validate={isConfirm || isDPCurator ? null : validateAgreement}
        decorators={[focusOnError]}
        onSubmit={this.handleFormSubmit}
        render={({handleSubmit}) => (
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <DialogContent style={styles.dialogContent}>
              <GridContainer>
                <Grid item xs={12}>
                  <Field autoFocus multiline name="comment" label="Комментарий" rows={6} component={InputField} />
                </Grid>
              </GridContainer>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={isModalOpen ? onModalClose : null}>
                Отмена
              </Button>
              <Button type="submit" color="primary" disabled={!isModalOpen || isAgreeSheetFetching}>
                {submitText}
              </Button>
            </DialogActions>
          </form>
        )}
      />
    )
  }
}
