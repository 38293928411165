import axios from 'axios'
import 'formdata-polyfill'

import {userRoleSelector} from './user'
import {resetCuratorsEditType} from './curators'
import {openSuccessSnackbar} from './ui'
import {isRPRM, isDPRPRM, handleWithSnackbarError} from '../utils/helpres'
import ROLES from '../utils/constants'
import URLS from '../utils/urls'

const module = 'partners'

const FETCH_PARTNERS = `${module}/FETCH_PARTNERS`
const FETCH_PARTNERS_SUCCESS = `${module}/FETCH_PARTNERS_SUCCESS`
const FETCH_PARTNERS_FAIL = `${module}/FETCH_PARTNERS_FAIL`

const FETCH_ALL_PARTNERS = `${module}/FETCH_ALL_PARTNERS`
const FETCH_ALL_PARTNERS_SUCCESS = `${module}/FETCH_ALL_PARTNERS_SUCCESS`
const FETCH_ALL_PARTNERS_FAIL = `${module}/FETCH_ALL_PARTNERS_FAIL`

const SET_PARTNERS_SIZE = `${module}/SET_PARTNERS_SIZE`
const SET_PARTNERS_PAGE = `${module}/SET_PARTNERS_PAGE`
const SET_PARTNERS_SORT = `${module}/SET_PARTNERS_SORT`
const SET_PARTNERS_SEARCH = `${module}/SET_PARTNERS_SEARCH`

const FETCH_PARTNER = `${module}/FETCH_PARTNER`
const FETCH_PARTNER_SUCCESS = `${module}/FETCH_PARTNER_SUCCESS`
const FETCH_PARTNER_FAIL = `${module}/FETCH_PARTNER_FAIL`

const UPDATE_PARTNER = `${module}/UPDATE_PARTNER`
const UPDATE_PARTNER_SUCCESS = `${module}/UPDATE_PARTNER_SUCCESS`
const UPDATE_PARTNER_FAIL = `${module}/UPDATE_PARTNER_FAIL`

const SAVE_PARTNER = `${module}/SAVE_PARTNER`
const SAVE_PARTNER_SUCCESS = `${module}/SAVE_PARTNER_SUCCESS`
const SAVE_PARTNER_FAIL = `${module}/SAVE_PARTNER_FAIL`

const RESET_PARTNER = `${module}/RESET_PARTNER`

const FETCH_DOWNLOAD_EXEL = `${module}/FETCH_DOWNLOAD_EXEL`
const FETCH_DOWNLOAD_EXEL_SUCCESS = `${module}/FETCH_DOWNLOAD_EXEL_SUCCESS`
const FETCH_DOWNLOAD_EXEL_FAIL = `${module}/FETCH_DOWNLOAD_EXEL_FAIL`

const FETCH_CHECK_STATUS = `${module}/FETCH_CHECK_STATUS`
const FETCH_CHECK_STATUS_SUCCESS = `${module}/FETCH_CHECK_STATUS_SUCCESS`
const FETCH_CHECK_STATUS_FAIL = `${module}/FETCH_CHECK_STATUS_FAIL`

const TOGGLE_STATUS_MODAL = `${module}/TOGGLE_STATUS_MODAL`
const CLOSE_STATUS_MODAL = `${module}/CLOSE_STATUS_MODAL`

const RESET_DOWNLOAD = `${module}/RESET_DOWNLOAD`

const initialState = {
  partners: null,
  isPartnersFetching: false,
  partnersError: null,

  allPartners: null,
  isAllPartnersFetching: false,
  allPartnersError: null,

  partnersSize: 10,
  partnersPage: 0,
  partnersSort: null,
  partnersSearch: '',

  partner: null,
  isPartnerFetching: false,
  partnerError: null,

  isPartnerUpdateFetching: false,
  partnerUpdateError: null,

  isPartnerSaveFetching: false,
  partnerSaveError: null,

  exel: null,
  isExelFetching: false,

  status: null,
  statusError: null,

  isStatusModalOpen: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PARTNERS:
      return {...state, isPartnersFetching: true, partnersError: null}
    case FETCH_PARTNERS_SUCCESS:
      return {...state, isPartnersFetching: false, partners: action.partners}
    case FETCH_PARTNERS_FAIL:
      return {...state, isPartnersFetching: false, partnersError: action.error}

    case FETCH_ALL_PARTNERS:
      return {...state, isAllPartnersFetching: true, allPartnersError: null}
    case FETCH_ALL_PARTNERS_SUCCESS:
      return {...state, isAllPartnersFetching: false, allPartners: action.allPartners}
    case FETCH_ALL_PARTNERS_FAIL:
      return {...state, isAllPartnersFetching: false, allPartnersError: action.error}

    case SET_PARTNERS_SIZE:
      return {...state, partnersSize: action.size}
    case SET_PARTNERS_PAGE:
      return {...state, partnersPage: action.page}
    case SET_PARTNERS_SORT:
      return {...state, partnersSort: action.sort}
    case SET_PARTNERS_SEARCH:
      return {...state, partnersSearch: action.search}

    case FETCH_PARTNER:
      return {...state, isPartnerFetching: true, partnerError: null}
    case FETCH_PARTNER_SUCCESS:
      return {...state, isPartnerFetching: false, partner: action.partner}
    case FETCH_PARTNER_FAIL:
      return {...state, isPartnerFetching: false, partnerError: action.error}

    case UPDATE_PARTNER:
      return {...state, isPartnerUpdateFetching: true, partnerUpdateError: null}
    case UPDATE_PARTNER_SUCCESS:
      return {...state, isPartnerUpdateFetching: false}
    case UPDATE_PARTNER_FAIL:
      return {...state, isPartnerUpdateFetching: false, partnerUpdateError: action.error}

    case SAVE_PARTNER:
      return {...state, isPartnerSaveFetching: true, partnerSaveError: null}
    case SAVE_PARTNER_SUCCESS:
      return {...state, isPartnerSaveFetching: false}
    case SAVE_PARTNER_FAIL:
      return {...state, isPartnerSaveFetching: false, partnerSaveError: action.error}

    case RESET_PARTNER:
      return {
        ...state,
        partner: null,
        isPartnerFetching: false,
        partnerError: null,
      }

    case FETCH_DOWNLOAD_EXEL:
      return {...state, isExelFetching: true}
    case FETCH_DOWNLOAD_EXEL_SUCCESS:
      return {...state, exel: action.exel}
    case FETCH_DOWNLOAD_EXEL_FAIL:
      return {...state, isExelFetching: false}

    case FETCH_CHECK_STATUS:
      return {...state, statusError: null}
    case FETCH_CHECK_STATUS_SUCCESS:
      return {...state, isExelFetching: false, status: action.status}
    case FETCH_CHECK_STATUS_FAIL:
      return {...state, isExelFetching: false, statusError: action.error}

    case TOGGLE_STATUS_MODAL:
      return {...state, isStatusModalOpen: !state.isStatusModalOpen}
    case CLOSE_STATUS_MODAL:
      return {...state, isStatusModalOpen: false}

    case RESET_DOWNLOAD:
      return {
        ...state,
        exel: null,
        isExelFetching: false,
        scanDocument: null,
        isScanDocumentFetching: false,
        scanDocumentError: null,
        status: null,
        statusError: null,
        isStatusModalOpen: false,
      }

    default:
      return state
  }
}

export const fetchPartners = () => (dispatch, getState) => {
  dispatch({type: FETCH_PARTNERS})
  const user = getState().user.user
  const role = userRoleSelector(user)
  const state = getState().partners
  const params = {
    size: state.partnersSize,
    page: state.partnersPage,
  }
  if (state.partnersSort) {
    params.sort = `${state.partnersSort[0]},${state.partnersSort[1]}`
  }
  if (
    !isRPRM(user.roles) &&
    !isDPRPRM(user.roles) &&
    (role === ROLES.ADMIN || role === ROLES.RM || role === ROLES.DP || role === ROLES.GD) &&
    state.partnersSearch
  ) {
    params.search = state.partnersSearch
  }
  axios
    .get('/partner/cardDefinition', {params})
    .then(({data}) => dispatch({type: FETCH_PARTNERS_SUCCESS, partners: data}))
    .catch(error => handleWithSnackbarError(dispatch, FETCH_PARTNERS_FAIL, error))
}

export const fetchAllPartners = () => dispatch => {
  dispatch({type: FETCH_ALL_PARTNERS})
  const params = {
    size: 2000,
  }
  axios
    .get('/partner/cardDefinition', {params})
    .then(({data}) => dispatch({type: FETCH_ALL_PARTNERS_SUCCESS, allPartners: data}))
    .catch(error => dispatch({type: FETCH_ALL_PARTNERS_FAIL, error}))
}

export const setPartnersSize = size => ({type: SET_PARTNERS_SIZE, size})
export const setPartnersPage = page => ({type: SET_PARTNERS_PAGE, page})
export const setPartnersSort = sort => ({type: SET_PARTNERS_SORT, sort})
export const setPartnersSearch = search => ({type: SET_PARTNERS_SEARCH, search})

export const fetchPartner = (orgNumber, isWithRedirect) => (dispatch, getState) => {
  dispatch({type: FETCH_PARTNER})
  const {curatorsEditType} = getState().curators
  axios
    .get(`/partner/${orgNumber}/cardDefinition`)
    .then(({data}) => {
      dispatch({type: FETCH_PARTNER_SUCCESS, partner: data})
      if (curatorsEditType) dispatch(resetCuratorsEditType())
      if (isWithRedirect) window.routerHistory.push(`${URLS.PARTNERS}/${orgNumber}`)
    })
    .catch(error => handleWithSnackbarError(dispatch, FETCH_PARTNER_FAIL, error))
}

export const updatePartner = partner => dispatch => {
  dispatch({type: UPDATE_PARTNER})
  axios
    .put('/partner', partner)
    .then(() => {
      dispatch({type: UPDATE_PARTNER_SUCCESS})
      dispatch(fetchPartner(partner.orgNumber))
      dispatch(openSuccessSnackbar(`Данные партнера "${partner.orgNumber}" сохранены`))
    })
    .catch(error => handleWithSnackbarError(dispatch, UPDATE_PARTNER_FAIL, error))
}

export const savePartner = (partner, editToggle) => dispatch => {
  dispatch({type: SAVE_PARTNER})
  axios
    .post('/partner', partner)
    .then(() => {
      dispatch({type: SAVE_PARTNER_SUCCESS})
      dispatch(fetchPartner(partner.orgNumber))
      editToggle()
      dispatch(openSuccessSnackbar(`Карточка партнера "${partner.orgNumber}" подтверждена`))
    })
    .catch(error => handleWithSnackbarError(dispatch, SAVE_PARTNER_FAIL, error))
}

export const resetPartner = () => ({type: RESET_PARTNER})

export const fetchDownloadExel = files => dispatch => {
  if (!files.length) return
  const fd = new FormData()
  fd.append('file', files[0])
  dispatch({type: FETCH_DOWNLOAD_EXEL})
  axios
    .post('/file', fd, {
      contentType: 'multipart/form-data',
    })
    .then(({data}) => {
      dispatch({type: FETCH_DOWNLOAD_EXEL_SUCCESS, exel: data})
      dispatch(fetchCheckStatus(data.statusLink.slice(7)))
    })
    .catch(error => handleWithSnackbarError(dispatch, FETCH_DOWNLOAD_EXEL_FAIL, error))
}

const fetchCheckStatus = statusUrl => dispatch => {
  dispatch({type: FETCH_CHECK_STATUS})
  axios
    .get(statusUrl)
    .then(({data, status}) => {
      if (status === 200) {
        dispatch({type: FETCH_CHECK_STATUS_SUCCESS, status: data})
        dispatch(fetchPartners())
        dispatch(toggleStatusModal())
        return
      }
      setTimeout(() => dispatch(fetchCheckStatus(statusUrl)), 2000)
    })
    .catch(error => {
      dispatch({type: FETCH_CHECK_STATUS_FAIL, error})
      dispatch(toggleStatusModal())
    })
}

export const toggleStatusModal = () => ({type: TOGGLE_STATUS_MODAL})

export const resetDownload = () => ({type: RESET_DOWNLOAD})
