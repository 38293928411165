import React, {PureComponent, Fragment} from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import {withStyles} from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import DoneIcon from '@material-ui/icons/CheckCircleTwoTone'
import ErrorIcon from '@material-ui/icons/ErrorTwoTone'
import WarningIcon from '@material-ui/icons/WarningTwoTone'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import './style.scss'
import Modal from '../../_common/Modal'
import {SERVER_ERROR_MSG} from '../../../utils/constants'
import {THEME, COLORS} from '../../../styles/muiTheme'

const styles = {
  expansionPanel: {
    marginBottom: THEME.UNIT * 2,
    boxShadow: 'none',
    border: `1px solid ${COLORS.error}`,
    borderRadius: 4,
    '&:first-child': {
      borderRadius: 4,
    },
  },
  warningExpansionPanel: {
    borderColor: COLORS.warning,
  },
  expansionPanelDetails: {
    maxHeight: 400,
    overflowY: 'auto',
    color: COLORS.error,
  },
  warningExpansionPanelDetails: {
    color: COLORS.warning,
  },
  icon: {
    marginRight: THEME.UNIT,
  },
}

const getNumber = n => +n.match(/\d+/g)[0]

const renderMessages = messages => {
  return messages
    .sort((a, b) => getNumber(a) - getNumber(b))
    .map((message, index) => (
      <li key={index} className="status-modal__item">
        {message}
      </li>
    ))
}

class StatusModal extends PureComponent {
  numberProp = this.props.report ? 'persisted' : 'numberOfPartners'

  static propTypes = {
    classes: PropTypes.object.isRequired,
    report: PropTypes.string,
    file: PropTypes.object,
    status: PropTypes.object,
    statusError: PropTypes.object,
    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    onExited: PropTypes.func,
  }

  renderStatusDetails(status) {
    const {report} = this.props
    const isReport = Boolean(report)
    return (
      <Fragment>
        {report !== 'sellOut' && (
          <p className={cn({warning: isReport})}>
            {report ? 'Строк с ошибкой' : 'Отбраковано'}: <strong>{status.hasError || 0}</strong>
          </p>
        )}
        <p className={cn({success: isReport})}>
          Загружено всего: <strong>{status[this.numberProp] || 0}</strong>
        </p>
        {!report && (
          <Fragment>
            <p>
              Новых записей: <strong>{status.persisted || 0}</strong>
            </p>
            <p>
              Обновленных записей: <strong>{status.updated || 0}</strong>
            </p>
          </Fragment>
        )}
      </Fragment>
    )
  }

  render() {
    const {report, classes, file, status, statusError, isOpen, closeModal, onExited} = this.props
    const isReport = Boolean(report)
    const fileName = file && file.fileName
    let title = ''
    let message = ''
    let details = null
    if (status) {
      title = status.title
      if (status.criticalErrorMessage.length) message = [...status.criticalErrorMessage]
      if (status.statusMessage.length) message = [...message, ...status.statusMessage]
    } else if (statusError) {
      title = statusError.response ? statusError.response.data.title : SERVER_ERROR_MSG
      message = statusError.response
        ? isReport && statusError.response.data.criticalErrorMessage.length
          ? statusError.response.data.criticalErrorMessage[0].split('frontendMessage=')[1].split(/, \w+=/g)[0]
          : statusError.response.data.statusMessage
        : ''
    }
    if (status) {
      details = this.renderStatusDetails(status)
    } else if (statusError && statusError.response) {
      details = this.renderStatusDetails(statusError.response.data)
    }
    const isZeroPartners = status && !status[this.numberProp]
    let isError = Boolean(statusError)
    if (!isReport) isError = Boolean(statusError) || (status && status.hasError) || isZeroPartners
    let isWarning = isReport && ((status && status.hasError) || isZeroPartners)
    const titleBlock = (
      <div className={`status-modal__result ${isError ? 'error' : isWarning ? 'warning' : 'success'} f ai-c`}>
        {isError ? (
          <ErrorIcon className={classes.icon} />
        ) : isWarning ? (
          <WarningIcon className={classes.icon} />
        ) : (
          <DoneIcon className={classes.icon} />
        )}
        {isZeroPartners ? message : title}
      </div>
    )
    let date
    if (fileName && isReport) {
      date = file.salesDateParam.replace(/-/g, '.')
      if (file.salesDateParam.length > 7) {
        date = date
          .split('.')
          .reverse()
          .join('.')
      }
    }
    return (
      <Modal open={isOpen} title="Отчет о загрузке" onClose={closeModal} onExited={onExited}>
        <div className="status-modal">
          {`${message}` ? (
            status && !status[this.numberProp] ? (
              <Fragment>
                {titleBlock}
                <Divider />
              </Fragment>
            ) : (
              <ExpansionPanel className={cn(classes.expansionPanel, {[classes.warningExpansionPanel]: isWarning})}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>{titleBlock}</ExpansionPanelSummary>
                <ExpansionPanelDetails
                  className={cn(classes.expansionPanelDetails, {[classes.warningExpansionPanelDetails]: isWarning})}
                >
                  {typeof message === 'object' && message.length > 1 ? (
                    <ul className="status-modal__list">{renderMessages(message)}</ul>
                  ) : (
                    message
                  )}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )
          ) : (
            <Fragment>
              {titleBlock}
              {fileName && <Divider />}
            </Fragment>
          )}
          {fileName && (
            <div>
              {isReport ? (
                <Fragment>
                  Файл <strong>{fileName}</strong>
                  {!isError && (
                    <span>
                      за <strong>{date}</strong> обработан
                    </span>
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  Файл: <strong>{fileName}</strong>
                </Fragment>
              )}
            </div>
          )}
          {details}
        </div>
      </Modal>
    )
  }
}

export default withStyles(styles)(StatusModal)
