import React, {PureComponent, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Form, Field} from 'react-final-form'
import {withStyles} from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import AddIcon from '@material-ui/icons/Add'
import Grid from '@material-ui/core/Grid'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'

import withModal from '../../_decorators/withModal'
import GridContainer from '../../_common/GridContainer'
import Modal from '../../_common/Modal'
import InputField from '../../_common/InputField'
import ReportDownloadForm from './ReportDownloadForm'
import {THEME} from '../../../styles/muiTheme'

const StyledFab = withStyles({
  root: {
    marginRight: THEME.UNIT,
    flexShrink: 0,
  },
})(Fab)

class ReportsSearchForm extends PureComponent {
  static propTypes = {
    isSellOut: PropTypes.bool,
    placeholder: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    isFetching: PropTypes.bool.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    setSearch: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,
    downloadReport: PropTypes.func.isRequired,
    onModalToggle: PropTypes.func.isRequired,
  }

  handleFormSubmit = ({search}) => {
    const {setSearch, fetchData} = this.props
    setSearch(search)
    fetchData()
  }

  handleChange = value => !value && this.props.setSearch('')

  render() {
    const {isSellOut, isModalOpen, placeholder, search, isFetching, downloadReport, onModalToggle} = this.props
    const initialValues = {
      search,
    }
    return (
      <Fragment>
        <Form
          initialValues={initialValues}
          onSubmit={this.handleFormSubmit}
          render={({handleSubmit, values}) => (
            <form noValidate className="mb" autoComplete="off" onSubmit={handleSubmit}>
              <GridContainer>
                <Grid item xs={8}>
                  <div className="f ai-c">
                    <Field
                      name="search"
                      variant="standard"
                      placeholder={placeholder}
                      component={InputField}
                      onChange={this.handleChange}
                    />
                    <Tooltip disableFocusListener title="Найти" enterDelay={500}>
                      <div>
                        <StyledFab type="submit" size="small" color="secondary" disabled={!values.search || isFetching}>
                          <SearchIcon />
                        </StyledFab>
                      </div>
                    </Tooltip>
                    <Tooltip disableFocusListener title="Загрузить отчет" enterDelay={500}>
                      <StyledFab size="small" color="primary" onClick={onModalToggle}>
                        <AddIcon />
                      </StyledFab>
                    </Tooltip>
                  </div>
                </Grid>
              </GridContainer>
            </form>
          )}
        />
        <Modal
          isForm
          open={isModalOpen}
          maxWidth="md"
          title={`Загрузка отчета Sell-${isSellOut ? 'Out' : 'In'}`}
          onClose={onModalToggle}
        >
          <ReportDownloadForm
            isSellOut={isSellOut}
            isModalOpen={isModalOpen}
            downloadReport={downloadReport}
            onModalClose={onModalToggle}
          />
        </Modal>
      </Fragment>
    )
  }
}

export default withModal(ReportsSearchForm)
