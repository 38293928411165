import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ReactFileReader from 'react-file-reader'
import moment from 'moment'
import {Form, Field} from 'react-final-form'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import NoteAddIcon from '@material-ui/icons/NoteAdd'

import GridContainer from '../../GridContainer'
import InputField from '../../InputField'
import {focusOnError} from '../../../../utils/helpres'
import {validateSellInDownload, validateSellOutDownload} from '../../../../utils/validator'

const fileTypes = ['.xls', '.xlsx', '.xlsb']

const InputProps = {
  readOnly: true,
}

const InputLabelProps = {
  shrink: true,
}

const StyledDialogContent = withStyles({
  root: {
    '&:first-child': {
      paddingTop: 0,
    },
  },
})(DialogContent)

const sellInInitialValues = {
  report_month_year: moment(),
}
const sellOutInitialValues = {
  report_date: moment(),
}

const mutators = {
  setFileValue: (args, state, {changeValue}) => {
    const file = args[0][0]
    changeValue(state, 'fileName', () => file.name)
    changeValue(state, 'file', () => file)
  },
}

export default class ReportDownloadForm extends Component {
  static propTypes = {
    isSellOut: PropTypes.bool,
    documentFile: PropTypes.object,
    isModalOpen: PropTypes.bool.isRequired,
    downloadReport: PropTypes.func.isRequired,
    onModalClose: PropTypes.func.isRequired,
  }

  handleFormSubmit = ({fileName, ...values}) => {
    const {isSellOut, downloadReport, onModalClose} = this.props
    const data = {
      file: values.file,
      type: `SALE_${isSellOut ? 'OUT' : 'IN'}`,
    }
    if (isSellOut) {
      data.report_month_year = moment(values.report_month_year).format('MM-YYYY')
    } else {
      data.report_date = moment(values.report_date).format('YYYY-MM-DD')
    }
    downloadReport(data, onModalClose)
  }

  handleFiles = setFileValue => file => {
    setFileValue(file)
  }

  renderForm = ({handleSubmit, form}) => {
    const {isSellOut, isModalOpen, onModalClose} = this.props
    return (
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <StyledDialogContent>
          <GridContainer>
            <Grid item xs={4}>
              {isSellOut ? (
                <Field name="report_month_year" label="Месяц отчета" type="monthPicker" component={InputField} />
              ) : (
                <Field name="report_date" label="Дата отчета" type="datePicker" component={InputField} />
              )}
            </Grid>
            <Grid item xs={8}>
              <div className="f ai-c">
                <Field
                  name="fileName"
                  label="Файл отчета"
                  placeholder="Выберите файл"
                  InputProps={InputProps}
                  InputLabelProps={InputLabelProps}
                  component={InputField}
                />
                <div className="ml">
                  <ReactFileReader fileTypes={fileTypes} handleFiles={this.handleFiles(form.mutators.setFileValue)}>
                    <Tooltip disableFocusListener title="Выбрать файл" enterDelay={500}>
                      <Fab size="medium" color="primary">
                        <NoteAddIcon />
                      </Fab>
                    </Tooltip>
                  </ReactFileReader>
                </div>
              </div>
            </Grid>
          </GridContainer>
        </StyledDialogContent>
        <DialogActions>
          <Button color="secondary" onClick={isModalOpen ? onModalClose : null}>
            Закрыть
          </Button>
          <Button type="submit" color="primary" disabled={!isModalOpen}>
            Загрузить
          </Button>
        </DialogActions>
      </form>
    )
  }

  render() {
    const {isSellOut} = this.props
    const initialValues = isSellOut ? sellInInitialValues : sellOutInitialValues
    return (
      <Form
        initialValues={initialValues}
        validate={isSellOut ? validateSellOutDownload : validateSellInDownload}
        mutators={mutators}
        decorators={[focusOnError]}
        onSubmit={this.handleFormSubmit}
        render={this.renderForm}
      />
    )
  }
}
