import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Form, Field} from 'react-final-form'
import createCalculateDecorator from 'final-form-calculate'
import {withRouter} from 'react-router-dom'
import moment from 'moment'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import GridContainer from '../GridContainer/index'
import InputField from '../InputField/index'
import Loader from '../Loader/index'
import BonusesContent from '../BonusesContent/index'
import {flatArray, checkDecimal, focusOnError} from '../../../utils/helpres'
import {
  validatePartnerDocumentWithoutBonus,
  validatePartnerDocumentVaillantOrProtherm,
  validatePartnerDocumentVaillantAndProtherm,
  validatePartnerDocumentICGoldQuarter,
  validatePartnerDocumentICGoldYear,
  validatePartnerDocumentICSilverQuarter,
  validatePartnerDocumentICSilverYear,
  validatePartnerDocumentINP,
  validatePartnerDocumentWarehouse,
} from '../../../utils/validator'
import URLS from '../../../utils/urls'
import {THEME} from '../../../styles/muiTheme'

const countPercent = value => value && +(value * 0.8).toFixed(10)

const getNumOrderFromString = str => {
  switch (str) {
    case 'FIRST':
      return 1
    case 'SECOND':
      return 2
    case 'THIRD':
      return 3
    default:
      return 4
  }
}

const VAILLANT = 'VAILLANT'
const PROTHERM = 'PROTHERM'
const VAILLANT_AND_PROTHERM = 'VAILLANT_AND_PROTHERM'

const quarterBonusTemplate = [
  {quarter: 'FIRST', scenario: 'FIRST', quarterNum: 1, scenarioNum: 1},
  {quarter: 'FIRST', scenario: 'SECOND', quarterNum: 1, scenarioNum: 2},
  {quarter: 'SECOND', scenario: 'FIRST', quarterNum: 2, scenarioNum: 1},
  {quarter: 'SECOND', scenario: 'SECOND', quarterNum: 2, scenarioNum: 2},
  {quarter: 'THIRD', scenario: 'FIRST', quarterNum: 3, scenarioNum: 1},
  {quarter: 'THIRD', scenario: 'SECOND', quarterNum: 3, scenarioNum: 2},
  {quarter: 'FOURTH', scenario: 'FIRST', quarterNum: 4, scenarioNum: 1},
  {quarter: 'FOURTH', scenario: 'SECOND', quarterNum: 4, scenarioNum: 2},
]

const iNPBonusTemplate = [
  {scenario: 'FIRST', scenarioNum: 1},
  {scenario: 'SECOND', scenarioNum: 2},
  {scenario: 'THIRD', scenarioNum: 3},
]

const warehouseBonusTemplate = [
  {companyType: VAILLANT, scenario: 'FIRST', scenarioNum: 1},
  {companyType: VAILLANT, scenario: 'SECOND', scenarioNum: 2},
  {companyType: PROTHERM, scenario: 'FIRST', scenarioNum: 1},
  {companyType: PROTHERM, scenario: 'SECOND', scenarioNum: 2},
]

const vaillantOrProthermInitialValues = {
  prize11: 5.5,
  prize21: 5.5,
  prize31: 5.5,
  prize41: 5.5,
  prize12: 3.5,
  prize22: 3.5,
  prize32: 3.5,
  prize42: 3.5,
  groBonus: 2,
}

const vaillantAndProthermInitialValues = {
  ...vaillantOrProthermInitialValues,
  prizeSecond11: 5.5,
  prizeSecond21: 5.5,
  prizeSecond31: 5.5,
  prizeSecond41: 5.5,
  prizeSecond12: 3.5,
  prizeSecond22: 3.5,
  prizeSecond32: 3.5,
  prizeSecond42: 3.5,
  groBonusSecond: 2,
}

const icQuarterIntialValues = {
  prize11: 5,
  prize21: 5,
  prize31: 5,
  prize41: 5,
  prize12: 3,
  prize22: 3,
  prize32: 3,
  prize42: 3,
}

const icYearInitialValues = {
  year: moment().year(),
  prize: 5,
}

const icGoldFieldsIntialValues = {
  negotiableBonus: 5,
  boilersNegotiableBonus: 5,
  prepaymentBonus: 2,
  additionalBonus: 3,
}

const icSilverFieldsIntialValues = {
  negotiableBonus: 3,
  boilersNegotiableBonus: 3,
  prepaymentBonus: 2,
}

const icGoldQuarterInitialValues = {
  ...icQuarterIntialValues,
  ...icGoldFieldsIntialValues,
}

const icGoldYearInitialValues = {
  ...icYearInitialValues,
  ...icGoldFieldsIntialValues,
}

const icSilverQuarterInitialValues = {
  ...icQuarterIntialValues,
  ...icSilverFieldsIntialValues,
}

const icSilverYearInitialValues = {
  ...icYearInitialValues,
  ...icSilverFieldsIntialValues,
}

const getQuarterBonusScenario = (values, isSecond) =>
  [...quarterBonusTemplate].map(bonus => {
    const second = isSecond ? 'Second' : ''
    return {
      quarterlyPurchaseNoVAT: checkDecimal(
        values[`quarterlyPurchaseNoVAT${second}${bonus.quarterNum}${bonus.scenarioNum}`]
      ),
      prize: checkDecimal(values[`prize${second}${bonus.quarterNum}${bonus.scenarioNum}`]),
      scenario: bonus.scenario,
      quarter: bonus.quarter,
    }
  })

const getQuarterBonusScenarioValues = (bonusScenario, isSecond) => {
  const second = isSecond ? 'Second' : ''
  const values = {}
  bonusScenario.forEach(bonus => {
    values[`prize${second}${getNumOrderFromString(bonus.quarter)}${getNumOrderFromString(bonus.scenario)}`] =
      bonus.prize
    values[
      `quarterlyPurchaseNoVAT${second}${getNumOrderFromString(bonus.quarter)}${getNumOrderFromString(bonus.scenario)}`
    ] = bonus.quarterlyPurchaseNoVAT
  })
  return values
}

const getYearBonusScenario = values => [
  {
    year: values.year,
    quarterlyPurchaseNoVAT: checkDecimal(values.quarterlyPurchaseNoVAT),
    prize: checkDecimal(values.prize),
  },
]

const getICFieldValues = values => ({
  negotiableBonus: checkDecimal(values.negotiableBonus),
  boilersNegotiableBonus: checkDecimal(values.boilersNegotiableBonus),
  prepaymentBonus: checkDecimal(values.prepaymentBonus),
})

const getICQuarterValues = bonus => ({
  ...getQuarterBonusScenarioValues(bonus.quarterBonusScenario),
  negotiableBonus: bonus.negotiableBonus,
  boilersNegotiableBonus: bonus.boilersNegotiableBonus,
  prepaymentBonus: bonus.prepaymentBonus,
})

const getICYearValues = bonus => ({
  year: bonus.yearlyBonusScenario[0].year,
  quarterlyPurchaseNoVAT: bonus.yearlyBonusScenario[0].quarterlyPurchaseNoVAT,
  prize: bonus.yearlyBonusScenario[0].prize,
  negotiableBonus: bonus.negotiableBonus,
  boilersNegotiableBonus: bonus.boilersNegotiableBonus,
  prepaymentBonus: bonus.prepaymentBonus,
})

const getINPBonusScenario = values =>
  [...iNPBonusTemplate].map(bonus => ({
    quarterlyPurchaseNoVAT: checkDecimal(values[`quarterlyPurchaseNoVAT${bonus.scenarioNum}`]),
    prize: checkDecimal(values[`prize${bonus.scenarioNum}`]),
    scenario: bonus.scenario,
  }))

const getWarehouseBonusScenario = values =>
  [...warehouseBonusTemplate].map(bonus => ({
    quarterlyPurchaseNoVAT: checkDecimal(values[`quarterlyPurchaseNoVAT${bonus.companyType}${bonus.scenarioNum}`]),
    prize: checkDecimal(values[`prize${bonus.companyType}${bonus.scenarioNum}`]),
    scenario: bonus.scenario,
    companyType: bonus.companyType,
  }))

const calculator = createCalculateDecorator(
  {field: /quarterlyPurchaseNoVAT11/, updates: {quarterlyPurchaseNoVAT12: countPercent}},
  {field: /quarterlyPurchaseNoVAT21/, updates: {quarterlyPurchaseNoVAT22: countPercent}},
  {field: /quarterlyPurchaseNoVAT31/, updates: {quarterlyPurchaseNoVAT32: countPercent}},
  {field: /quarterlyPurchaseNoVAT41/, updates: {quarterlyPurchaseNoVAT42: countPercent}},
  {field: /quarterlyPurchaseNoVATSecond11/, updates: {quarterlyPurchaseNoVATSecond12: countPercent}},
  {field: /quarterlyPurchaseNoVATSecond21/, updates: {quarterlyPurchaseNoVATSecond22: countPercent}},
  {field: /quarterlyPurchaseNoVATSecond31/, updates: {quarterlyPurchaseNoVATSecond32: countPercent}},
  {field: /quarterlyPurchaseNoVATSecond41/, updates: {quarterlyPurchaseNoVATSecond42: countPercent}}
)

const styles = {
  paper: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  buttonFirst: {
    marginRight: THEME.UNIT,
  },
}

class DocumentForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      initialValues: {
        ...this.getInitialValues(),
      },
    }
    this.isContractConfirmed =
      props.isRecreate && !(props.document && Object.values(props.document.contractFlowDTO.contract[0].contractType)[0])
    this.confirmedBonus =
      props.isRecreate &&
      props.document &&
      props.document.bonus.length > 1 &&
      props.document.bonus.find(
        item => item.contractState !== 'INITIALIZE_CONTRACT' && item.contractState !== 'RECREATE_CONTRACT'
      )
  }

  static propTypes = {
    history: PropTypes.object.isRequired,
    isRecreate: PropTypes.bool,
    isPartner: PropTypes.bool,
    isPartnerTypes: PropTypes.bool,
    document: PropTypes.object,
    documentNumber: PropTypes.string,
    isDocumentNumberFetching: PropTypes.bool,
    documentReferences: PropTypes.object,
    isDocumentReferencesFetching: PropTypes.bool.isRequired,
    documentBonus: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isDocumentBonusFetching: PropTypes.bool.isRequired,
    isDocumentSaveFetching: PropTypes.bool.isRequired,
    fetchDocumentReferences: PropTypes.func.isRequired,
    fetchDocumentBonus: PropTypes.func.isRequired,
    resetDocumentBonus: PropTypes.func.isRequired,
    fetchDocumentNumber: PropTypes.func.isRequired,
    saveDocument: PropTypes.func.isRequired,
    saveBonus: PropTypes.func,
    resetDocumentNumber: PropTypes.func.isRequired,
    onNewContractToggle: PropTypes.func,
  }

  componentDidMount() {
    const {isRecreate, fetchDocumentReferences, fetchDocumentNumber} = this.props
    fetchDocumentReferences()
    !isRecreate && fetchDocumentNumber()
  }

  componentWillReceiveProps(nextProps) {
    const {documentNumber} = this.props
    if (nextProps.documentNumber !== documentNumber) {
      this.setState(state => ({
        initialValues: {
          ...state.initialValues,
          documentNumber: nextProps.documentNumber,
        },
      }))
    }
  }

  componentWillUnmount() {
    const {resetDocumentNumber, resetDocumentBonus} = this.props
    resetDocumentNumber()
    resetDocumentBonus()
  }

  handleFormSubmit = values => {
    const {
      isRecreate,
      document,
      documentNumber,
      documentBonus,
      saveDocument,
      saveBonus,
      onNewContractToggle,
    } = this.props
    let newDocument
    switch (values.bonusType) {
      case '1':
      case '2':
        newDocument = [
          {
            '@class': documentBonus['@class'],
            bonusType: values.bonusType === '1' ? VAILLANT : PROTHERM,
            quarterBonusScenario: getQuarterBonusScenario(values),
            groBonus: checkDecimal(values.groBonus),
          },
        ]
        break
      case '3':
        newDocument = [
          {
            '@class': documentBonus['@class'],
            bonusType: VAILLANT,
            quarterBonusScenario: getQuarterBonusScenario(values),
            groBonus: checkDecimal(values.groBonus),
          },
          {
            '@class': documentBonus['@class'],
            bonusType: PROTHERM,
            quarterBonusScenario: getQuarterBonusScenario(values, true),
            groBonus: checkDecimal(values.groBonusSecond),
          },
        ]
        break
      case '4':
        newDocument = [
          {
            ...getICFieldValues(values),
            '@class': documentBonus['@class'],
            yearlyBonusScenario: getYearBonusScenario(values),
            additionalBonus: checkDecimal(values.additionalBonus),
          },
        ]
        break
      case '5':
        newDocument = [
          {
            ...getICFieldValues(values),
            '@class': documentBonus['@class'],
            quarterBonusScenario: getQuarterBonusScenario(values),
            additionalBonus: checkDecimal(values.additionalBonus),
          },
        ]
        break
      case '6':
        newDocument = [
          {
            ...getICFieldValues(values),
            '@class': documentBonus['@class'],
            quarterBonusScenario: getQuarterBonusScenario(values),
          },
        ]
        break
      case '7':
        newDocument = [
          {
            ...getICFieldValues(values),
            '@class': documentBonus['@class'],
            yearlyBonusScenario: getYearBonusScenario(values),
          },
        ]
        break
      case '8':
        newDocument = [
          {
            '@class': documentBonus['@class'],
            investmentContractBonusScenario: getINPBonusScenario(values),
          },
        ]
        break
      case '9':
        newDocument = [
          {
            '@class': documentBonus['@class'],
            warehouseBonusScenario: getWarehouseBonusScenario(values),
          },
        ]
        break
      default:
        newDocument = null
    }
    if (isRecreate) {
      if (this.isContractConfirmed) {
        let newConfirmedDocument = newDocument
        if (this.confirmedBonus) {
          newConfirmedDocument = newConfirmedDocument.filter(
            document => document.bonusType !== this.confirmedBonus.bonusType
          )
        }
        const params = {
          document_number: document.oldDocNumber,
          bonus_type_id: values.bonusType,
        }
        saveBonus(newConfirmedDocument, values.contractType, params)
      } else {
        const params = {
          new_document_number: values.documentNumber,
          old_document_number: document.oldDocNumber,
          bonus_type_id: values.bonusType,
          valid_date_from: values.validDateFrom.format('YYYY-MM-DD'),
          valid_date_to: values.validDateTo.format('YYYY-MM-DD'),
        }
        saveDocument(newDocument, values.contractType, params)
      }
    } else {
      const params = {
        document_number: documentNumber,
        bonus_type_id: values.bonusType,
        valid_date_from: values.validDateFrom.format('YYYY-MM-DD'),
        valid_date_to: values.validDateTo.format('YYYY-MM-DD'),
      }
      saveDocument(newDocument, values.contractType, params, onNewContractToggle)
    }
  }

  handleContractTypeChange = (values, form) => contractType => {
    const {documentReferences, fetchDocumentBonus} = this.props
    if (values.contractType === contractType) return
    const currentContract = documentReferences.contract.find(contract => contract.contractType[contractType])
    this.setState(
      {
        initialValues: {
          contractType,
          bonusType: Object.keys(currentContract.bonusType).length < 2 ? '10' : '11',
          documentNumber: values.documentNumber,
          validDateFrom: values.validDateFrom,
          validDateTo: values.validDateTo,
        },
      },
      form.reset
    )
    fetchDocumentBonus(currentContract.contractScope)
  }

  handleBonusTypeChange = (values, form) => bonusType => {
    if (values.bonusType === bonusType) return
    const baseValues = {
      bonusType,
      contractType: values.contractType,
      documentNumber: values.documentNumber,
      validDateFrom: values.validDateFrom,
      validDateTo: values.validDateTo,
    }
    let bonusValues
    switch (bonusType) {
      case '1':
      case '2':
        bonusValues = vaillantOrProthermInitialValues
        break
      case '3':
        bonusValues = vaillantAndProthermInitialValues
        break
      case '4':
        bonusValues = icGoldYearInitialValues
        break
      case '5':
        bonusValues = icGoldQuarterInitialValues
        break
      case '6':
        bonusValues = icSilverQuarterInitialValues
        break
      case '7':
        bonusValues = icSilverYearInitialValues
        break
      default:
        bonusValues = null
    }
    this.setState(
      {
        initialValues: {
          ...baseValues,
          ...bonusValues,
        },
      },
      form.reset
    )
  }

  handleValidDateToChange = values => validDateTo => {
    const {fetchDocumentNumber} = this.props
    const prevValidDateTo = this.state.initialValues.validDateTo
    const prevYear = moment(prevValidDateTo, 'DD-MM-YYYY', true).isValid() && prevValidDateTo.format('YYYY')
    const year = moment(validDateTo, 'DD-MM-YYYY', true).isValid() && validDateTo.format('YYYY')
    if (year && prevYear !== year) {
      fetchDocumentNumber(year)
    }
    this.setState({
      initialValues: {
        ...values,
        validDateTo: validDateTo,
      },
    })
  }

  handleGoToAgreement = () => this.props.history.push(URLS.AGREEMENT)

  getInitialValues = () => {
    const {isRecreate} = this.props
    if (isRecreate) {
      const {document} = this.props
      const {metaData, bonus} = document
      let contractType = null
      let bonusType = this.isContractConfirmed
        ? null
        : Object.keys(document.contractFlowDTO.contract[0].bonusType).length < 2
        ? '10'
        : '11'
      let bonusValues
      Object.keys(metaData.contractTypeMeta).forEach(key => {
        if (document.oldContractType === metaData.contractTypeMeta[key]) {
          contractType = key
        }
      })
      Object.keys(metaData.bonusTypeMeta).forEach(key => {
        if (bonus.length > 1) {
          if (metaData.bonusTypeMeta[key] === VAILLANT_AND_PROTHERM) bonusType = key
        } else if (bonus[0] && bonus[0].bonusType === metaData.bonusTypeMeta[key]) {
          bonusType = key
        }
      })
      switch (bonusType) {
        case '1':
        case '2':
          bonusValues = {
            ...getQuarterBonusScenarioValues(bonus[0].quarterBonusScenario),
            groBonus: bonus[0].groBonus,
          }
          break
        case '3':
          const vaillantBonus = bonus.find(item => item.bonusType === VAILLANT)
          const prothermBonus = bonus.find(item => item.bonusType === PROTHERM)
          bonusValues = {
            ...getQuarterBonusScenarioValues(vaillantBonus.quarterBonusScenario),
            ...getQuarterBonusScenarioValues(prothermBonus.quarterBonusScenario, true),
            groBonus: vaillantBonus.groBonus,
            groBonusSecond: prothermBonus.groBonus,
          }
          break
        case '4':
          bonusValues = {...getICYearValues(bonus[0]), additionalBonus: bonus[0].additionalBonus}
          break
        case '5':
          bonusValues = {...getICQuarterValues(bonus[0]), additionalBonus: bonus[0].additionalBonus}
          break
        case '6':
          bonusValues = getICQuarterValues(bonus[0])
          break
        case '7':
          bonusValues = getICYearValues(bonus[0])
          break
        default:
          bonusValues = null
      }
      return {
        ...bonusValues,
        contractType,
        bonusType,
        documentNumber: document.newDocNumber,
        validDateFrom: moment(document.validDateFrom),
        validDateTo: moment(document.validDateTo),
      }
    } else {
      const today = moment()
      const dateTo = moment().set({year: today.year() + 1, month: 0, date: 0})
      return {
        validDateFrom: today,
        validDateTo: dateTo,
      }
    }
  }

  getContractTypes() {
    const {documentReferences} = this.props
    const contractTypes = []
    if (!documentReferences) return contractTypes
    documentReferences.contract.forEach(contract => {
      contractTypes.push(
        Object.keys(contract.contractType).map(key => ({
          value: key,
          label: contract.contractType[key],
        }))
      )
    })
    return flatArray(contractTypes)
  }

  getBonusTypes(currentBonusTypes, currentReference) {
    return [...currentBonusTypes]
      .sort((a, b) => b - a)
      .map(bonusType => ({
        value: bonusType,
        label: currentReference.bonusType[bonusType],
      }))
  }

  renderBonusTypesFields(currentReference, values, form) {
    if (!currentReference) return
    const {documentReferences} = this.props
    const {contractType} = values
    if (!contractType || !documentReferences) return null
    const isBonusConfirmed = Boolean(this.confirmedBonus)
    let currentBonusTypes = Object.keys(currentReference.bonusType)
    if (this.isContractConfirmed) {
      currentBonusTypes = currentBonusTypes.filter(bonusType => bonusType !== '11')
    }
    if (isBonusConfirmed) {
      currentBonusTypes = currentBonusTypes.filter(bonusType => bonusType !== '1' && bonusType !== '2')
    }
    return (
      <GridContainer>
        <Grid item xs={6}>
          <Field
            name="bonusType"
            label="Тип приложения"
            component={InputField}
            options={this.getBonusTypes(currentBonusTypes, currentReference)}
            onChange={this.handleBonusTypeChange(values, form)}
            disabled={isBonusConfirmed}
          />
        </Grid>
      </GridContainer>
    )
  }

  renderForm = ({handleSubmit, form, values}) => {
    const {
      isRecreate,
      isPartner,
      isPartnerTypes,
      documentReferences,
      isDocumentBonusFetching,
      isDocumentNumberFetching,
      isDocumentSaveFetching,
      isDocumentReferencesFetching,
      onNewContractToggle,
    } = this.props
    const currentReference =
      documentReferences && documentReferences.contract.find(reference => reference.contractType[values.contractType])
    let bonusesContent
    if (values.bonusType === '10' || values.bonusType === '11') {
      bonusesContent = null
    } else if (values.bonusType === '3') {
      bonusesContent = (
        <Fragment>
          <BonusesContent
            type="3"
            values={values}
            currentReference={currentReference}
            isConfirmed={Boolean(this.confirmedBonus && this.confirmedBonus.bonusType === VAILLANT)}
          />
          <BonusesContent
            isSecond
            type="3"
            values={values}
            currentReference={currentReference}
            isConfirmed={Boolean(this.confirmedBonus && this.confirmedBonus.bonusType === PROTHERM)}
          />
        </Fragment>
      )
    } else if (values.bonusType) {
      bonusesContent = <BonusesContent type={values.bonusType} values={values} currentReference={currentReference} />
    }
    return (
      <form noValidate className="block loaded" autoComplete="off" onSubmit={handleSubmit}>
        <Loader isFetch={isDocumentSaveFetching} />
        <div className="p-r">
          <Loader isBlock isFetch={isDocumentBonusFetching || isDocumentReferencesFetching} />
          <GridContainer>
            <Grid item xs={3}>
              <Field
                name="contractType"
                label="Тип документа"
                options={this.getContractTypes()}
                component={InputField}
                disabled={this.isContractConfirmed}
                onChange={this.handleContractTypeChange(values, form)}
              />
            </Grid>
            <Grid item xs={3}>
              <Field disabled name="documentNumber" label="Номер договора" component={InputField} />
            </Grid>
            <Grid item xs={3}>
              <Field
                name="validDateFrom"
                label="Срок действия с"
                type="datePicker"
                component={InputField}
                disabled={this.isContractConfirmed}
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                name="validDateTo"
                label="Срок действия по"
                type="datePicker"
                component={InputField}
                onChange={this.handleValidDateToChange(values)}
                disabled={(isRecreate && (!isPartner || !isPartnerTypes)) || this.isContractConfirmed}
              />
            </Grid>
          </GridContainer>
          {this.renderBonusTypesFields(currentReference, values, form)}
        </div>
        <div className="mt mb">
          {!isDocumentBonusFetching && !isDocumentReferencesFetching && bonusesContent && (
            <div className="loaded">{bonusesContent}</div>
          )}
        </div>
        <div className="f jc-fe">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={styles.buttonFirst}
            disabled={isDocumentBonusFetching || isDocumentNumberFetching || isDocumentSaveFetching}
          >
            Сформировать документ
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={isRecreate ? this.handleGoToAgreement : onNewContractToggle}
          >
            Закрыть
          </Button>
        </div>
      </form>
    )
  }

  render() {
    const {isRecreate} = this.props
    const {initialValues} = this.state
    let validate
    switch (initialValues.bonusType) {
      case '1':
      case '2':
        validate = validatePartnerDocumentVaillantOrProtherm
        break
      case '3':
        validate = validatePartnerDocumentVaillantAndProtherm
        break
      case '4':
        validate = validatePartnerDocumentICGoldYear
        break
      case '5':
        validate = validatePartnerDocumentICGoldQuarter
        break
      case '6':
        validate = validatePartnerDocumentICSilverQuarter
        break
      case '7':
        validate = validatePartnerDocumentICSilverYear
        break
      case '8':
        validate = validatePartnerDocumentINP
        break
      case '9':
        validate = validatePartnerDocumentWarehouse
        break
      default:
        validate = validatePartnerDocumentWithoutBonus
    }
    return (
      <Paper style={isRecreate ? null : styles.paper}>
        <Form
          initialValues={initialValues}
          validate={validate}
          decorators={[focusOnError, calculator]}
          onSubmit={this.handleFormSubmit}
          render={this.renderForm}
        />
      </Paper>
    )
  }
}

export default withRouter(DocumentForm)
