import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

import Modal from '../Modal'
import Loader from '../../_common/Loader'

const StyledDialogContent = withStyles({
  root: {
    fontSize: 16,
  },
})(DialogContent)

Confirmation.propTypes = {
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,

  confirmAction: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default function Confirmation({title, isOpen, isFetching, confirmAction, onClose}) {
  return (
    <>
      <Loader isFetch={isFetching} />
      <Modal isForm maxWidth="xs" open={isOpen} title={title} onClose={onClose}>
        <StyledDialogContent>Вы уверены?</StyledDialogContent>
        <DialogActions>
          <Button color="secondary" onClick={isOpen ? onClose : null}>
            Нет
          </Button>
          <Button color="primary" disabled={!isOpen || isFetching} onClick={confirmAction}>
            Да
          </Button>
        </DialogActions>
      </Modal>
    </>
  )
}
