import React, {PureComponent, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Form, Field} from 'react-final-form'
import {withStyles} from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import Grid from '@material-ui/core/Grid'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'

import GridContainer from '../../_common/GridContainer'
import InputField from '../../_common/InputField'
import {THEME} from '../../../styles/muiTheme'

const StyledFab = withStyles({
  root: {
    marginRight: THEME.UNIT,
    flexShrink: 0,
  },
})(Fab)

class AdminSellOutSearchForm extends PureComponent {
  static propTypes = {
    isSellOut: PropTypes.bool,
    placeholder: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    isFetching: PropTypes.bool.isRequired,

    setSearch: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,
  }

  handleFormSubmit = ({search}) => {
    const {setSearch, fetchData} = this.props
    setSearch(search)
    fetchData()
  }

  handleChange = value => !value && this.props.setSearch('')

  render() {
    const {placeholder, search, isFetching} = this.props
    const initialValues = {
      search,
    }
    return (
      <Fragment>
        <Form
          initialValues={initialValues}
          onSubmit={this.handleFormSubmit}
          render={({handleSubmit, values}) => (
            <form noValidate className="mb" autoComplete="off" onSubmit={handleSubmit}>
              <GridContainer>
                <Grid item xs={5}>
                  <div className="f ai-c">
                    <Field
                      name="search"
                      variant="standard"
                      placeholder={placeholder}
                      component={InputField}
                      onChange={this.handleChange}
                    />
                    <Tooltip disableFocusListener title="Найти" enterDelay={500}>
                      <div>
                        <StyledFab type="submit" size="small" color="secondary" disabled={!values.search || isFetching}>
                          <SearchIcon />
                        </StyledFab>
                      </div>
                    </Tooltip>
                  </div>
                </Grid>
              </GridContainer>
            </form>
          )}
        />
      </Fragment>
    )
  }
}

export default AdminSellOutSearchForm
