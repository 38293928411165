import moment from 'moment'

export const regExps = {
  // eslint-disable-next-line
  email: /^\s*(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/,
  phone: /^\+7 \(\d{3}\) \d{7}$/,
}

const isValue = value => value && value.trim()
const isErrorSelectValue = selectValue => !selectValue && selectValue !== 0

export const validateWorkerAdd = values => {
  const errors = {}
  if (!isValue(values.firstName)) {
    errors.firstName = 'required'
  }
  if (!isValue(values.lastName)) {
    errors.lastName = 'required'
  }
  if (!regExps.phone.test(values.phoneNumber)) {
    errors.phoneNumber = 'required'
  }
  if (!isValue(values.email)) {
    errors.email = 'required'
  } else if (!regExps.email.test(values.email)) errors.email = 'Неверный формат email'
  return errors
}

export const validateContactSubscriber = values => {
  const errors = {}
  if (!isValue(values.surnameAndInitials)) {
    errors.surnameAndInitials = 'required'
  }
  if (!isValue(values.fullNameInGenitiveCase)) {
    errors.fullNameInGenitiveCase = 'required'
  }
  if (!isValue(values.position)) {
    errors.position = 'required'
  }
  if (!isValue(values.positionInGenitiveCase)) {
    errors.positionInGenitiveCase = 'required'
  }
  if (!isValue(values.signatureBaseInGenitiveCase)) {
    errors.signatureBaseInGenitiveCase = 'required'
  }
  return errors
}

export const validatePartner = values => {
  const errors = {}
  if (isErrorSelectValue(values.regionManagerId)) {
    errors.regionManagerId = 'required'
  }
  if (isErrorSelectValue(values.currentFederalDistrictId)) {
    errors.currentFederalDistrictId = 'required'
  }
  if (isErrorSelectValue(values.currentRegionId)) {
    errors.currentRegionId = 'required'
  }
  if (isErrorSelectValue(values.partnerTypeId)) {
    errors.partnerTypeId = 'required'
  }
  if (isErrorSelectValue(values.vaillantCuratorId)) {
    errors.vaillantCuratorId = 'required'
  }
  if (isErrorSelectValue(values.prothermCuratorId)) {
    errors.prothermCuratorId = 'required'
  }
  if (values.partnerTypeId !== 1 && values.partnerTypeId !== 2 && isErrorSelectValue(values.serviceCoordinatorId)) {
    errors.serviceCoordinatorId = 'required'
  }
  if (!isValue(values.bankName)) {
    errors.bankName = 'required'
  }
  if (!isValue(values.cAcc)) {
    errors.cAcc = 'required'
  }
  if (!isValue(values.acc)) {
    errors.acc = 'required'
  }
  return errors
}

const validatePartnerDocument = (values, errors) => {
  if (!values.contractType) errors.contractType = 'required'
  if (!values.bonusType) errors.bonusType = 'required'
  if (!values.validDateFrom) errors.validDateFrom = 'required'
  if (!moment(values.validDateFrom, 'DD-MM-YYYY', true).isValid()) errors.validDateFrom = 'required'
  if (!moment(values.validDateTo, 'DD-MM-YYYY', true).isValid()) errors.validDateTo = 'required'
}

const validatePartnerDocumentQuarter = (values, errors) => {
  if (!values.prize11) errors.prize11 = 'required'
  if (!values.quarterlyPurchaseNoVAT11) errors.quarterlyPurchaseNoVAT11 = 'required'
  if (!values.prize12) errors.prize12 = 'required'
  if (!values.quarterlyPurchaseNoVAT12) errors.quarterlyPurchaseNoVAT12 = 'required'
  if (!values.prize21) errors.prize21 = 'required'
  if (!values.quarterlyPurchaseNoVAT21) errors.quarterlyPurchaseNoVAT21 = 'required'
  if (!values.prize22) errors.prize22 = 'required'
  if (!values.quarterlyPurchaseNoVAT22) errors.quarterlyPurchaseNoVAT22 = 'required'
  if (!values.prize31) errors.prize31 = 'required'
  if (!values.quarterlyPurchaseNoVAT31) errors.quarterlyPurchaseNoVAT31 = 'required'
  if (!values.prize32) errors.prize32 = 'required'
  if (!values.quarterlyPurchaseNoVAT32) errors.quarterlyPurchaseNoVAT32 = 'required'
  if (!values.prize41) errors.prize41 = 'required'
  if (!values.quarterlyPurchaseNoVAT41) errors.quarterlyPurchaseNoVAT41 = 'required'
  if (!values.prize42) errors.prize42 = 'required'
  if (!values.quarterlyPurchaseNoVAT42) errors.quarterlyPurchaseNoVAT42 = 'required'
}

export const validatePartnerDocumentWithoutBonus = values => {
  const errors = {}
  validatePartnerDocument(values, errors)
  return errors
}

export const validatePartnerDocumentVaillantOrProtherm = values => {
  const errors = {}
  validatePartnerDocument(values, errors)
  validatePartnerDocumentQuarter(values, errors)
  if (!values.groBonus) errors.groBonus = 'required'
  return errors
}

export const validatePartnerDocumentVaillantAndProtherm = values => {
  const errors = {}
  validatePartnerDocument(values, errors)
  validatePartnerDocumentQuarter(values, errors)
  if (!values.prizeSecond11) errors.prizeSecond11 = 'required'
  if (!values.quarterlyPurchaseNoVATSecond11) errors.quarterlyPurchaseNoVATSecond11 = 'required'
  if (!values.prizeSecond12) errors.prizeSecond12 = 'required'
  if (!values.quarterlyPurchaseNoVATSecond12) errors.quarterlyPurchaseNoVATSecond12 = 'required'
  if (!values.prizeSecond21) errors.prizeSecond21 = 'required'
  if (!values.quarterlyPurchaseNoVATSecond21) errors.quarterlyPurchaseNoVATSecond21 = 'required'
  if (!values.prizeSecond22) errors.prizeSecond22 = 'required'
  if (!values.quarterlyPurchaseNoVATSecond22) errors.quarterlyPurchaseNoVATSecond22 = 'required'
  if (!values.prizeSecond31) errors.prizeSecond31 = 'required'
  if (!values.quarterlyPurchaseNoVATSecond31) errors.quarterlyPurchaseNoVATSecond31 = 'required'
  if (!values.prizeSecond32) errors.prizeSecond32 = 'required'
  if (!values.quarterlyPurchaseNoVATSecond32) errors.quarterlyPurchaseNoVATSecond32 = 'required'
  if (!values.prizeSecond41) errors.prizeSecond41 = 'required'
  if (!values.quarterlyPurchaseNoVATSecond41) errors.quarterlyPurchaseNoVATSecond41 = 'required'
  if (!values.prizeSecond42) errors.prizeSecond42 = 'required'
  if (!values.quarterlyPurchaseNoVATSecond42) errors.quarterlyPurchaseNoVATSecond42 = 'required'

  if (!values.groBonus) errors.groBonus = 'required'
  if (!values.groBonusSecond) errors.groBonusSecond = 'required'
  return errors
}

const validatePartnerDocumentYear = (values, errors) => {
  if (!values.year) errors.year = 'required'
  if (!values.quarterlyPurchaseNoVAT) errors.quarterlyPurchaseNoVAT = 'required'
  if (!values.prize) errors.prize = 'required'
}

const validatePartnerDocumentIC = (values, errors) => {
  if (!values.negotiableBonus) errors.negotiableBonus = 'required'
  if (!values.boilersNegotiableBonus) errors.boilersNegotiableBonus = 'required'
  if (!values.prepaymentBonus) errors.prepaymentBonus = 'required'
}

export const validatePartnerDocumentICGoldQuarter = values => {
  const errors = {}
  validatePartnerDocument(values, errors)
  validatePartnerDocumentIC(values, errors)
  validatePartnerDocumentQuarter(values, errors)
  if (!values.additionalBonus) errors.additionalBonus = 'required'
  return errors
}

export const validatePartnerDocumentICGoldYear = values => {
  const errors = {}
  validatePartnerDocument(values, errors)
  validatePartnerDocumentIC(values, errors)
  validatePartnerDocumentYear(values, errors)
  if (!values.additionalBonus) errors.additionalBonus = 'required'
  return errors
}

export const validatePartnerDocumentICSilverQuarter = values => {
  const errors = {}
  validatePartnerDocument(values, errors)
  validatePartnerDocumentIC(values, errors)
  validatePartnerDocumentQuarter(values, errors)
  return errors
}

export const validatePartnerDocumentICSilverYear = values => {
  const errors = {}
  validatePartnerDocument(values, errors)
  validatePartnerDocumentIC(values, errors)
  validatePartnerDocumentYear(values, errors)
  return errors
}

export const validatePartnerDocumentINP = values => {
  const errors = {}
  if (!values.quarterlyPurchaseNoVAT1) errors.quarterlyPurchaseNoVAT1 = 'required'
  if (!values.prize1) errors.prize1 = 'required'
  if (!values.quarterlyPurchaseNoVAT2) errors.quarterlyPurchaseNoVAT2 = 'required'
  if (!values.prize2) errors.prize2 = 'required'
  if (!values.quarterlyPurchaseNoVAT3) errors.quarterlyPurchaseNoVAT3 = 'required'
  if (!values.prize3) errors.prize3 = 'required'
  return errors
}

export const validatePartnerDocumentWarehouse = values => {
  const errors = {}
  if (!values.quarterlyPurchaseNoVATVaillant1) errors.quarterlyPurchaseNoVATVaillant1 = 'required'
  if (!values.prizeVaillant1) errors.prizeVaillant1 = 'required'
  if (!values.quarterlyPurchaseNoVATVaillant2) errors.quarterlyPurchaseNoVATVaillant2 = 'required'
  if (!values.prizeVaillant2) errors.prizeVaillant2 = 'required'
  if (!values.quarterlyPurchaseNoVATProtherm1) errors.quarterlyPurchaseNoVATProtherm1 = 'required'
  if (!values.prizeProtherm1) errors.prizeProtherm1 = 'required'
  if (!values.quarterlyPurchaseNoVATProtherm2) errors.quarterlyPurchaseNoVATProtherm2 = 'required'
  if (!values.prizeProtherm2) errors.prizeProtherm2 = 'required'
  return errors
}

export const validateAgreement = values => {
  const errors = {}
  if (!isValue(values.comment)) {
    errors.comment = 'required'
  }
  return errors
}

export const validateMessage = values => {
  const errors = {}
  if (!values.forCuratorId) errors.forCuratorId = 'required'
  if (!isValue(values.comment)) {
    errors.comment = 'required'
  }
  return errors
}

export const validateSellInDownload = values => {
  const errors = {}
  if (!moment(values.report_date, 'DD-MM-YYYY', true).isValid()) errors.report_date = 'required'
  if (!values.fileName) errors.fileName = 'required'
  return errors
}

export const validateSellOutDownload = values => {
  const errors = {}
  if (!moment(values.report_month_year, 'MM / YYYY', true).isValid()) errors.report_month_year = 'required'
  if (!values.fileName) errors.fileName = 'required'
  return errors
}

export const validateReports = values => {
  const errors = {}
  if (!moment(values.date_from, 'DD-MM-YYYY', true).isValid()) errors.date_from = 'required'
  if (!moment(values.date_to, 'DD-MM-YYYY', true).isValid()) errors.date_to = 'required'
  return errors
}
