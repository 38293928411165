import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import ErrorIcon from '@material-ui/icons/ErrorTwoTone'
import WarningIcon from '@material-ui/icons/WarningTwoTone'

import './style.scss'
import logoImg from '../../assets/img/logo.png'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import {SERVER_ERROR_MSG} from '../../utils/constants'
import {THEME} from '../../styles/muiTheme'

const StyledPaper = withStyles({
  root: {
    padding: THEME.UNIT * 5,
    maxWidth: 500,
    margin: 'auto',
  },
})(Paper)

Empty.propTypes = {
  userError: PropTypes.object,
  logOut: PropTypes.func.isRequired,
}

export default function Empty({userError, logOut}) {
  return (
    <div className="empty">
      <img className="empty__img" width={260} height={24} src={logoImg} alt="SDS" />
      <StyledPaper>
        <p className="mb">
          {userError ? (
            <Fragment>
              <span className="empty__icon">
                <ErrorIcon color="error" />
              </span>
              <span className="error">
                {userError && userError.response && userError.response.data.message
                  ? userError.response.data.message
                  : SERVER_ERROR_MSG}
                .
              </span>
            </Fragment>
          ) : (
            <Fragment>
              <span className="empty__icon">
                <WarningIcon />
              </span>
              У вас отсутствует роль в данном приложении. Заявка на добавление роли отправлена администратору.
            </Fragment>
          )}
        </p>
        <Button fullWidth variant="contained" color="primary" onClick={logOut}>
          Назад
        </Button>
      </StyledPaper>
    </div>
  )
}
