import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

import Modal from '../Modal'

const StyledDialogContent = withStyles({
  root: {
    fontSize: 16,
  },
})(DialogContent)

Alert.propTypes = {
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,

  onClose: PropTypes.func.isRequired,
}

export default function Alert({title, message, isOpen, onClose}) {
  return (
    <Modal isForm open={isOpen} title={title} onClose={onClose}>
      <StyledDialogContent>{message}</StyledDialogContent>
      <DialogActions>
        <Button color="primary" disabled={!isOpen} onClick={onClose}>
          Ок
        </Button>
      </DialogActions>
    </Modal>
  )
}
