import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'

import './style.scss'

Card.propTypes = {
  cardName: PropTypes.string,
  children: PropTypes.any,
  smallCard: PropTypes.bool,
}

Card.defaultProps = {
  smallCard: false,
}

function Card({cardName, children, smallCard}) {
  return (
    <Paper className={`card-container ${smallCard ? 'small-card' : ''}`}>
      <label>{cardName}</label>
      <div className="card-container__content">{children}</div>
    </Paper>
  )
}

export default Card
