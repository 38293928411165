import React from 'react'
import PropTypes from 'prop-types'

import './style.scss'

SimpleStatistics.propTypes = {
  value: PropTypes.string.isRequired,
  small: PropTypes.bool,
}

SimpleStatistics.defaultProps = {
  small: false,
}

function SimpleStatistics({value, small}) {
  return (
    <div className={`statistics-container ${small ? 'statistics-container_small' : ''}`}>
      <span>{value.split(/(?=(?:\d{3})+$)/).join(' ')}</span>
    </div>
  )
}

export default SimpleStatistics
