import React, {Fragment} from 'react'
import qs from 'qs'
import createFocusDecorator from 'final-form-focus'
import CheckIcon from '@material-ui/icons/Check'
import RemoveIcon from '@material-ui/icons/Remove'

import {openErrorSnackbar} from '../redux/ui'
import ROLES, {ROLE_PREFIX, SERVER_ERROR_MSG} from './constants'
import URLS from './urls'
import {kc} from '../index'

export const isRPRM = roles =>
  roles.length === 2 && roles.includes(`${ROLE_PREFIX}${ROLES.RP}`) && roles.includes(`${ROLE_PREFIX}${ROLES.RM}`)

export const isDPRPRM = roles =>
  roles.length === 3 &&
  roles.includes(`${ROLE_PREFIX}${ROLES.DP}`) &&
  roles.includes(`${ROLE_PREFIX}${ROLES.RP}`) &&
  roles.includes(`${ROLE_PREFIX}${ROLES.RM}`)

export const paramsSerializer = params => qs.stringify(params, {arrayFormat: 'repeat'})

export const flatArray = arr => [].concat(...arr)

export const removeDuplicates = (arr, prop) =>
  arr.filter((obj, index, arr) => arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === index)

export const cloneObject = obj => JSON.parse(JSON.stringify(obj))

export const scrollTop = () => window.scrollTo(0, 0)

export const checkDecimal = value => (value && !String(value).includes('.') ? `${value}.0` : value)

export const handleWithSnackbarError = (dispatch, type, error) => {
  dispatch({type: type, error})
  const isErrorResponse = error && error.response
  if (isErrorResponse && error.response.status === 401) {
    kc.logout()
    return
  }
  dispatch(
    openErrorSnackbar(
      isErrorResponse
        ? error.response.data.frontendMessage ||
            error.response.data.message ||
            error.response.data.title ||
            error.response.statusText ||
            SERVER_ERROR_MSG
        : SERVER_ERROR_MSG
    )
  )
}

export const focusOnError = createFocusDecorator()

export const isPartnerFederalOrRegionalDistributor = partnerTypeId => partnerTypeId === 1 || partnerTypeId === 2

export const isPartnerEngineeringCentre = partnerTypeId => partnerTypeId === 3

export const isCuratorsTableFetching = (type, isRead, curatorsEditType, isFetching) =>
  (curatorsEditType === type && isFetching) || (isRead && !curatorsEditType && isFetching)

export const isAddCommentButton = (sheet, user) =>
  Boolean(sheet.approvalSheetComments.length) &&
  !sheet.approvalSheetComments.every(comment => comment.user.id === user.id)

export const isDocumentHasScan = (document, item) =>
  item.scan &&
  ((!document.actId && !document.bonusId && !item.act && !item.annex) ||
    (document.actId && !item.annex && item.act && document.actId === item.actId) ||
    (document.bonusId && !item.act && item.annex && document.bonusId === item.bonusId))

export const getPartnerFullName = (partner, partnerTypes) => {
  const currentPartnerType =
    partnerTypes && partnerTypes.find(type => type.id === partner.partnerTypeId || type.value === partner.partnerTypeId)
  return (
    <Fragment>
      {partner.orgName}{' '}
      {currentPartnerType && (
        <span className="grey">
          {currentPartnerType.name || currentPartnerType.label}
          {partner.contractAnnex ? ` ${partner.contractAnnex}` : ''}
        </span>
      )}
    </Fragment>
  )
}

export const getSexValue = sex => (sex === 'MALE' ? 'М' : 'Ж')

export const getCheckValue = isCheck => (isCheck ? <CheckIcon /> : <RemoveIcon />)

export const getTitle = (url, role, partner, partnerTypes) => {
  if (url === URLS.DASHBOARD) return 'Главная'
  if (url === URLS.PARTNERS) return role === ROLES.P ? 'Личная карточка' : 'Партнеры'
  if (url.includes(`${URLS.PARTNERS}/`))
    return partner && partnerTypes
      ? getPartnerFullName(partner, partnerTypes)
      : role === ROLES.P
      ? 'Личная карточка'
      : 'Карточка партнера'
  if (url.includes(`${URLS.SELL_OUT}/`)) return 'Sell-Out партнера'
  if (url === URLS.AGREEMENT) return 'Согласование'
  if (url === URLS.HISTORY) return 'История загрузок'
  if (url === URLS.AGREEMENT) return 'Согласование документов'
  if (url === URLS.AGREEMENT_RECREATE) return 'Переформирование договора'
  if (url === URLS.SCANS) return 'Загрузка сканкопий'
  if (url === URLS.SELL_IN) return 'Отчеты Sell-In'
  if (url === URLS.SELL_OUT) return 'Отчеты Sell-Out'
  if (url === URLS.ACTS) return 'Акты'
  if (url.includes(`${URLS.ACTS}/`)) return 'Акты партнера'
  if (url === URLS.REPORTS) return 'Отчеты'
  if (url === URLS.REFERENCES) return 'Справочники'
}

export const getPartnerTypeMnemonic = (partnerTypes, partnerTypeId) =>
  partnerTypes.find(type => type.id === partnerTypeId).mnemonic

export const getDocumentFile = document =>
  document.actId
    ? document.documentFile.find(item => !item.scan && !item.annex && item.act && document.actId === item.actId)
    : document.bonusId
    ? document.documentFile.find(item => !item.scan && !item.act && item.annex && document.bonusId === item.bonusId)
    : document.documentFile.find(item => !item.scan && !item.act && !item.annex && document.id === item.document_id)

export const getCuratorsRows = (partner, type) => {
  return partner && partner[type]
    ? partner[type].map(curator => ({
        id: curator.id,
        data: [curator.fullName, `+${curator.phoneNumber}`, curator.email],
      }))
    : []
}

export const sortOptions = options => options.sort((a, b) => (a.label > b.label ? 1 : a.label < b.label ? -1 : 0))

export const getInitialPartnerValues = partner =>
  partner
    ? {
        orgNumber: partner.orgNumber,
        orgName: partner.orgName,
        currentFederalDistrictId: partner.currentFederalDistrictId,
        currentRegionId: partner.currentRegionId,
        partnerTypeId: partner.partnerTypeId,
        contractAnnex: partner.contractAnnex,
        regionManagerId: partner.regionManagerId,
        vaillantCuratorId: partner.vaillantCuratorId,
        prothermCuratorId: partner.prothermCuratorId,
        serviceCoordinatorId: partner.serviceCoordinatorId,
        postcode: partner.postcode,
        city: partner.city,
        street: partner.street,
        building: partner.building,
        apartment: partner.apartment,
        phoneNumber: partner.phoneNumber,
        email: partner.email,
        federalDistrict: partner.federalDistrict,
        region: partner.region,
        BIK: partner.BIK,
        bankName: partner.bankName,
        INN: partner.INN,
        KPP: partner.KPP,
        cAcc: partner.cAcc,
        acc: partner.acc,
      }
    : null
