import axios from 'axios'
import fileDownload from 'js-file-download'

import {fetchDocuments} from './documents'
import {openSuccessSnackbar} from './ui'
import {handleWithSnackbarError} from '../utils/helpres'

const module = 'acts'

const FETCH_ACTS = `${module}/FETCH_ACTS`
const FETCH_ACTS_SUCCESS = `${module}/FETCH_ACTS_SUCCESS`
const FETCH_ACTS_FAIL = `${module}/FETCH_ACTS_FAIL`
const SET_ACTS_SIZE = `${module}/SET_ACTS_SIZE`
const SET_ACTS_PAGE = `${module}/SET_ACTS_PAGE`
const SET_ACTS_SORT = `${module}/SET_ACTS_SORT`
const SET_ACTS_SEARCH = `${module}/SET_ACTS_SEARCH`

const INIT_ACTS = `${module}/INIT_ACTS`
const INIT_ACTS_SUCCESS = `${module}/INIT_ACTS_SUCCESS`
const INIT_ACTS_FAIL = `${module}/INIT_ACTS_FAIL`

const DOWNLOAD_ACTS_REPORT = `${module}/DOWNLOAD_ACTS_REPORT`
const DOWNLOAD_ACTS_REPORT_SUCCESS = `${module}/DOWNLOAD_ACTS_REPORT_SUCCESS`
const DOWNLOAD_ACTS_REPORT_FAIL = `${module}/DOWNLOAD_ACTS_REPORT_FAIL`

const FETCH_PARTNER_ACTS = `${module}/FETCH_PARTNER_ACTS`
const FETCH_PARTNER_ACTS_SUCCESS = `${module}/FETCH_PARTNER_ACTS_SUCCESS`
const FETCH_PARTNER_ACTS_FAIL = `${module}/FETCH_PARTNER_ACTS_FAIL`
const SET_PARTNER_ACTS_SIZE = `${module}/SET_PARTNER_ACTS_SIZE`
const SET_PARTNER_ACTS_PAGE = `${module}/SET_PARTNER_ACTS_PAGE`
const SET_PARTNER_ACTS_SORT = `${module}/SET_PARTNER_ACTS_SORT`
const RESET_PARTNER_ACTS = `${module}/RESET_PARTNER_ACTS`

const SELECT_ACT = `${module}/SELECT_ACT`
const FETCH_ACT_PRIZE = `${module}/FETCH_ACT_PRIZE`
const FETCH_ACT_PRIZE_SUCCESS = `${module}/FETCH_ACT_PRIZE_SUCCESS`
const FETCH_ACT_PRIZE_FAIL = `${module}/FETCH_ACT_PRIZE_FAIL`
const RESET_ACT = `${module}/RESET_ACT`

const RECALCULATE_ACT = `${module}/RECALCULATE_ACT`
const RECALCULATE_ACT_SUCCESS = `${module}/RECALCULATE_ACT_SUCCESS`
const RECALCULATE_ACT_FAIL = `${module}/RECALCULATE_ACT_FAIL`

const CHANGE_ACT_STATUS = `${module}/CHANGE_ACT_STATUS`
const CHANGE_ACT_STATUS_SUCCESS = `${module}/CHANGE_ACT_STATUS_SUCCESS`
const CHANGE_ACT_STATUS_FAIL = `${module}/CHANGE_ACT_STATUS_FAIL`

const DOWNLOAD_ACT = `${module}/DOWNLOAD_ACT`
const DOWNLOAD_ACT_SUCCESS = `${module}/DOWNLOAD_ACT_SUCCESS`
const DOWNLOAD_ACT_FAIL = `${module}/DOWNLOAD_ACT_FAIL`

const initialState = {
  acts: null,
  isActsFetching: false,
  actsSize: 10,
  actsPage: 0,
  actsSort: null,
  actsSearch: '',

  isActsInit: false,

  isActsReportDownload: false,

  partnerActs: null,
  isPartnerActsFetching: false,
  partnerActsSize: 10,
  partnerActsPage: 0,
  partnerActsSort: null,

  selected: null,

  actPrize: null,
  isActPrizeFetching: false,

  isActStatusChange: false,

  isActRecalculate: false,

  isActDownload: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACTS:
      return {...state, isActsFetching: true}
    case FETCH_ACTS_SUCCESS:
      return {...state, isActsFetching: false, acts: action.acts}
    case FETCH_ACTS_FAIL:
      return {...state, isActsFetching: false}
    case SET_ACTS_SIZE:
      return {...state, actsSize: action.size}
    case SET_ACTS_PAGE:
      return {...state, actsPage: action.page}
    case SET_ACTS_SORT:
      return {...state, actsSort: action.sort}
    case SET_ACTS_SEARCH:
      return {...state, actsSearch: action.search}

    case INIT_ACTS:
      return {...state, isActsInit: true}
    case INIT_ACTS_SUCCESS:
    case INIT_ACTS_FAIL:
      return {...state, isActsInit: false}

    case DOWNLOAD_ACTS_REPORT:
      return {...state, isActsReportDownload: true}
    case DOWNLOAD_ACTS_REPORT_SUCCESS:
    case DOWNLOAD_ACTS_REPORT_FAIL:
      return {...state, isActsReportDownload: false}

    case FETCH_PARTNER_ACTS:
      return {...state, isPartnerActsFetching: true}
    case FETCH_PARTNER_ACTS_SUCCESS:
      return {...state, isPartnerActsFetching: false, partnerActs: action.partnerActs}
    case FETCH_PARTNER_ACTS_FAIL:
      return {...state, isPartnerActsFetching: false}
    case SET_PARTNER_ACTS_SIZE:
      return {...state, partnerActsSize: action.size}
    case SET_PARTNER_ACTS_PAGE:
      return {...state, partnerActsPage: action.page}
    case SET_PARTNER_ACTS_SORT:
      return {...state, partnerActsSort: action.sort}
    case RESET_PARTNER_ACTS:
      return {
        ...state,
        partnerActs: null,
        isPartnerActsFetching: false,
        partnerActsSize: initialState.partnerActsSize,
        partnerActsPage: initialState.partnerActsPage,
        partnerActsSort: initialState.partnerActsSort,
      }

    case SELECT_ACT:
      return {
        ...state,
        selected: action.selected,
      }
    case FETCH_ACT_PRIZE:
      return {...state, isActPrizeFetching: true}
    case FETCH_ACT_PRIZE_SUCCESS:
      return {...state, isActPrizeFetching: false, actPrize: action.actPrize}
    case FETCH_ACT_PRIZE_FAIL:
      return {...state, isActPrizeFetching: false}
    case RESET_ACT:
      return {
        ...state,
        selected: null,
        actPrize: null,
      }

    case CHANGE_ACT_STATUS:
      return {...state, isActStatusChange: true}
    case CHANGE_ACT_STATUS_SUCCESS:
    case CHANGE_ACT_STATUS_FAIL:
      return {...state, isActStatusChange: false}

    case RECALCULATE_ACT:
      return {...state, isActRecalculate: true}
    case RECALCULATE_ACT_SUCCESS:
    case RECALCULATE_ACT_FAIL:
      return {...state, isActRecalculate: false}

    case DOWNLOAD_ACT:
      return {...state, isActDownload: true}
    case DOWNLOAD_ACT_SUCCESS:
    case DOWNLOAD_ACT_FAIL:
      return {...state, isActDownload: false}

    default:
      return state
  }
}

export const fetchActs = () => (dispatch, getState) => {
  dispatch({type: FETCH_ACTS})
  const state = getState().acts
  const params = {
    size: state.actsSize,
    page: state.actsPage,
  }
  if (state.actsSort) {
    params.sort = `${state.actsSort[0]},${state.actsSort[1]}`
  }
  if (state.actsSearch) {
    params.search = state.actsSearch
  }
  axios
    .get('/act', {params})
    .then(({data}) => dispatch({type: FETCH_ACTS_SUCCESS, acts: data}))
    .catch(error => handleWithSnackbarError(dispatch, FETCH_ACTS_FAIL, error))
}
export const setActsSize = size => ({type: SET_ACTS_SIZE, size})
export const setActsPage = page => ({type: SET_ACTS_PAGE, page})
export const setActsSort = sort => ({type: SET_ACTS_SORT, sort})
export const setActsSearch = search => ({type: SET_ACTS_SEARCH, search})

export const initActs = params => dispatch => {
  dispatch({type: INIT_ACTS})
  axios
    .get('/init_acts', {params})
    .then(() => {
      dispatch({type: INIT_ACTS_SUCCESS})
      dispatch(fetchActs())
      dispatch(
        openSuccessSnackbar(
          'Процесс переформирования актов запущен, этот процесс займет некоторое время. Перезагрузите страницу позже'
        )
      )
    })
    .catch(error => handleWithSnackbarError(dispatch, INIT_ACTS_FAIL, error))
}

export const downloadActsReport = (params, closeModal) => dispatch => {
  dispatch({type: DOWNLOAD_ACTS_REPORT})
  axios
    .get('/act/report', {
      params,
      responseType: 'blob',
    })
    .then(({data, headers}) => {
      dispatch({type: DOWNLOAD_ACTS_REPORT_SUCCESS})
      fileDownload(data, decodeURI(headers['content-disposition'].split('filename=')[1]))
      closeModal()
    })
    .catch(error => handleWithSnackbarError(dispatch, DOWNLOAD_ACTS_REPORT_FAIL, error))
}

export const fetchPartnerActs = orgNumber => (dispatch, getState) => {
  dispatch({type: FETCH_PARTNER_ACTS})
  const state = getState().acts
  const params = {
    size: state.partnerActsSize,
    page: state.partnerActsPage,
  }
  if (state.partnerActsSort) {
    params.sort = `${state.partnerActsSort[0]},${state.partnerActsSort[1]}`
  }
  Promise.all([axios.get(`/act/${orgNumber}`, {params}), axios.get(`/act/${orgNumber}/count`)])
    .then(([acts, count]) =>
      dispatch({type: FETCH_PARTNER_ACTS_SUCCESS, partnerActs: {content: acts.data, total: count.data}})
    )
    .catch(error => handleWithSnackbarError(dispatch, FETCH_PARTNER_ACTS_FAIL, error))
}
export const setPartnerActsSize = size => ({type: SET_PARTNER_ACTS_SIZE, size})
export const setPartnerActsPage = page => ({type: SET_PARTNER_ACTS_PAGE, page})
export const setPartnerActsSort = sort => ({type: SET_PARTNER_ACTS_SORT, sort})
export const resetPartnerActs = () => ({type: RESET_PARTNER_ACTS})

export const selectAct = selected => ({type: SELECT_ACT, selected})
export const fetchActPrize = () => (dispatch, getState) => {
  dispatch({type: FETCH_ACT_PRIZE})
  axios
    .get(`/act/${getState().acts.selected.id}/prize`)
    .then(({data}) => dispatch({type: FETCH_ACT_PRIZE_SUCCESS, actPrize: data}))
    .catch(error => handleWithSnackbarError(dispatch, FETCH_ACT_PRIZE_FAIL, error))
}
export const resetAct = () => ({type: RESET_ACT})

export const changeActStatus = (id, status, orgNumber, onModalClose) => dispatch => {
  dispatch({type: CHANGE_ACT_STATUS})
  axios
    .put(`/act/${id}/${status}`)
    .then(() => {
      dispatch({type: CHANGE_ACT_STATUS_SUCCESS})
      orgNumber ? dispatch(fetchPartnerActs(orgNumber)) : dispatch(fetchDocuments())
      if (status === 'approveA' || status === 'approveP') dispatch(openSuccessSnackbar('Акт подтвержден'))
      onModalClose && onModalClose()
    })
    .catch(error => handleWithSnackbarError(dispatch, CHANGE_ACT_STATUS_FAIL, error))
}

export const recalculateAct = (id, orgNumber) => dispatch => {
  dispatch({type: RECALCULATE_ACT})
  axios
    .get('/act/recalculate', {params: {act_id: id}})
    .then(() => {
      dispatch({type: RECALCULATE_ACT_SUCCESS})
      dispatch(fetchPartnerActs(orgNumber))
      dispatch(openSuccessSnackbar('Акт переформирован'))
    })
    .catch(error => handleWithSnackbarError(dispatch, RECALCULATE_ACT_FAIL, error))
}

export const downloadAct = id => dispatch => {
  dispatch({type: DOWNLOAD_ACT})
  axios
    .get('/act/file', {
      params: {
        act_id: id,
      },
      responseType: 'blob',
    })
    .then(({data, headers}) => {
      dispatch({type: DOWNLOAD_ACT_SUCCESS})
      fileDownload(data, decodeURI(headers['content-disposition'].split('filename=')[1]))
    })
    .catch(error => handleWithSnackbarError(dispatch, DOWNLOAD_ACT_FAIL, error))
}
