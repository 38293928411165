import React from 'react'
import PropTypes from 'prop-types'
import {BarChart, Bar, XAxis, LabelList} from 'recharts'

import PercentageDifferenceBar from '../PercentageDifferenceBar'
import './style.scss'

DoubleBar.propTypes = {
  data: PropTypes.array,
}

DoubleBar.defaultProps = {
  data: [],
}

const renderCustomizedLabel = props => {
  const {x, y, width, height, value} = props
  return (
    <g>
      <text
        x={x + width / 2}
        y={y + height / 2}
        fill="#000000"
        fontSize="18px"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {value}
      </text>
    </g>
  )
}

function DoubleBar({data}) {
  const renderDifferenceBar = () => {
    if (data && data.length) {
      const a = data[0]['pv']
      const b = data[1]['pv']
      const difference = Math.round(((b - a) * 100) / a)

      return <PercentageDifferenceBar value={difference} />
    }

    return null
  }

  return (
    <div className="double-bar-wrapper">
      {<div className="difference-bar">{renderDifferenceBar()}</div>}
      <BarChart width={220} height={250} data={data} margin={{top: 40, right: 30, left: 20, bottom: 5}}>
        <XAxis dataKey="name" />
        <Bar dataKey="pv" fill="#5B9BD5" barSize={60}>
          <LabelList dataKey="pv" content={renderCustomizedLabel} />
        </Bar>
      </BarChart>
    </div>
  )
}

export default DoubleBar
