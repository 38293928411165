import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import CheckCircleIcon from '@material-ui/icons/CheckCircleTwoTone'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFileTwoTone'

export default class PartnerSellOutActions extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    orgNumber: PropTypes.string.isRequired,
    isApproved: PropTypes.bool,

    approveSellOut: PropTypes.func.isRequired,
    downloadSellOutFile: PropTypes.func.isRequired,
  }

  handleReportApprove = () => {
    const {id, orgNumber, approveSellOut} = this.props
    approveSellOut(id, orgNumber)
  }

  handleReportDownload = () => {
    const {id, downloadSellOutFile} = this.props
    downloadSellOutFile(id)
  }

  render() {
    const {isApproved} = this.props
    return (
      <div className="table-actions f ws-nw">
        <Tooltip disableFocusListener title="Подтвердить" enterDelay={500}>
          <div>
            <IconButton onClick={this.handleReportApprove} disabled={isApproved}>
              <CheckCircleIcon />
            </IconButton>
          </div>
        </Tooltip>
        <Tooltip disableFocusListener title="Скачать" enterDelay={500}>
          <IconButton onClick={this.handleReportDownload}>
            <InsertDriveFileIcon />
          </IconButton>
        </Tooltip>
      </div>
    )
  }
}
