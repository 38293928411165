import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'

import PartnerCurators from './PartnerCurators'
import PartnerSignatures from './PartnerSignatures'

export default class PartnerPersons extends PureComponent {
  static propTypes = {
    isRead: PropTypes.bool,
    isFederalOrRegionalDistributor: PropTypes.bool.isRequired,
    isSignaturesLimit: PropTypes.bool,
  }

  render() {
    const {isRead, isFederalOrRegionalDistributor, isSignaturesLimit} = this.props
    return (
      <div className={isRead ? null : 'loaded'}>
        <PartnerCurators isRead={isRead} />
        <PartnerSignatures
          isRead={isRead}
          isFederalOrRegionalDistributor={isFederalOrRegionalDistributor}
          isSignaturesLimit={isSignaturesLimit}
        />
      </div>
    )
  }
}
