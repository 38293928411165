import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'
import moment from 'moment'

import ExtendedTable from '../../_common/ExtendedTable'
import URLS from '../../../utils/urls'

const headerCells = [
  {title: 'Номер'},
  {title: 'Дата загрузки'},
  {title: 'Название'},
  {title: 'ИНН'},
  {title: 'ФО'},
  {title: 'Регион'},
]

class ConfirmedPartnerTable extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    partner: PropTypes.object.isRequired,
  }

  handleGoToPartner = orgNumber => () => this.props.history.push(`${URLS.PARTNERS}/${orgNumber}`)

  render() {
    const {partner} = this.props
    const tableRows = [
      {
        id: partner.orgNumber,
        data: [
          partner.orgNumber,
          <span className="ws-nw">{moment(partner.importCreatedDate).format('DD.MM.YYYY HH:mm')}</span>,
          partner.orgName,
          partner.INN,
          partner.federalDistrict,
          partner.region,
        ],
      },
    ]
    return (
      <ExtendedTable isInternal headerCells={headerCells} tableRows={tableRows} onRowClick={this.handleGoToPartner} />
    )
  }
}

export default withRouter(ConfirmedPartnerTable)
