import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import moment from 'moment'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFileTwoTone'

import {
  fetchSellOut,
  setSellOutSize,
  setSellOutPage,
  setSellOutSort,
  setSellOutSearch,
  downloadReport,
  toggleStatusModal,
  downloadSellOutFile,
  resetDownload,
} from '../../redux/reports'
import Loader from '../_common/Loader'
import StatusModal from '../_common/StatusModal'
import ExtendedTable from '../_common/ExtendedTable'
import ReportsSearchForm from '../_common/ReportsSearchForm'

const headerCells = [
  {title: 'Дата отчета', sortName: 'year,month'},
  {title: 'Название файла', sortName: 'fileName'},
  {title: 'Пользователь', sortName: 'uploadedUser'},
  {title: 'Записей всего', sortName: 'numberOfRows'},
  {title: 'Записей с ошибками', sortName: 'hasError'},
  {title: ''},
]

class SellOut extends Component {
  static propTypes = {
    sellOut: PropTypes.object,
    isSellOutFetching: PropTypes.bool.isRequired,
    sellOutSize: PropTypes.number.isRequired,
    sellOutPage: PropTypes.number.isRequired,
    sellOutSort: PropTypes.array,
    sellOutSearch: PropTypes.string.isRequired,
    report: PropTypes.object,
    isReportDownload: PropTypes.bool.isRequired,
    status: PropTypes.object,
    statusError: PropTypes.object,
    isStatusModalOpen: PropTypes.bool.isRequired,
    isSellOutFileDownload: PropTypes.bool.isRequired,
    fetchSellOut: PropTypes.func.isRequired,
    setSellOutSize: PropTypes.func.isRequired,
    setSellOutPage: PropTypes.func.isRequired,
    setSellOutSort: PropTypes.func.isRequired,
    setSellOutSearch: PropTypes.func.isRequired,
    downloadReport: PropTypes.func.isRequired,
    toggleStatusModal: PropTypes.func.isRequired,
    downloadSellOutFile: PropTypes.func.isRequired,
    resetDownload: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.props.fetchSellOut()
  }

  componentWillReceiveProps(nextProps) {
    const {sellOutSize, sellOutPage, sellOutSort, sellOutSearch, fetchSellOut} = this.props
    if (
      (!nextProps.sellOutSearch && nextProps.sellOutSearch !== sellOutSearch) ||
      nextProps.sellOutSize !== sellOutSize ||
      nextProps.sellOutPage !== sellOutPage ||
      nextProps.sellOutSort !== sellOutSort
    ) {
      fetchSellOut()
    }
  }

  handleFileDownload = id => () => this.props.downloadSellOutFile(id)

  getTableRows() {
    const {sellOut} = this.props
    return (
      sellOut &&
      sellOut.content.map(item => ({
        id: item.guid,
        data: [
          <span className="ws-nw">{moment(`${item.month} ${item.year}`, 'M YYYY', true).format('MM.YYYY')}</span>,
          item.fileName,
          item.uploadedUser,
          item.numberOfRows,
          item.hasError,
          <div className="f ai-c">
            <Tooltip disableFocusListener title="Сохранить отчет" enterDelay={500}>
              <div>
                <IconButton onClick={this.handleFileDownload(item.guid)}>
                  <InsertDriveFileIcon />
                </IconButton>
              </div>
            </Tooltip>
          </div>,
        ],
      }))
    )
  }

  render() {
    const {
      sellOut,
      sellOutSize,
      sellOutPage,
      sellOutSort,
      sellOutSearch,
      isSellOutFetching,
      report,
      isReportDownload,
      status,
      statusError,
      isStatusModalOpen,
      isSellOutFileDownload,
      setSellOutSize,
      setSellOutPage,
      setSellOutSort,
      setSellOutSearch,
      fetchSellOut,
      downloadReport,
      toggleStatusModal,
      resetDownload,
    } = this.props
    const total = sellOut ? sellOut.totalElements : 0
    return (
      <section className="container loaded">
        <Loader isFetch={isReportDownload || isSellOutFileDownload} />
        <ReportsSearchForm
          isSellOut
          placeholder="Введите дату отчета или название файла"
          search={sellOutSearch}
          isFetching={isSellOutFetching}
          setSearch={setSellOutSearch}
          fetchData={fetchSellOut}
          downloadReport={downloadReport}
        />
        <ExtendedTable
          headerCells={headerCells}
          tableRows={this.getTableRows()}
          isFetching={isSellOutFetching}
          total={total}
          rowsPerPage={sellOutSize}
          page={sellOutPage}
          sort={sellOutSort}
          setPage={setSellOutPage}
          setSize={setSellOutSize}
          setSort={setSellOutSort}
        />
        <StatusModal
          report="sellOut"
          file={report}
          status={status}
          statusError={statusError}
          isOpen={isStatusModalOpen}
          closeModal={toggleStatusModal}
          onExited={resetDownload}
        />
      </section>
    )
  }
}

const mapStateToProps = ({reports}) => ({
  sellOut: reports.sellOut,
  isSellOutFetching: reports.isSellOutFetching,
  sellOutSize: reports.sellOutSize,
  sellOutPage: reports.sellOutPage,
  sellOutSort: reports.sellOutSort,
  sellOutSearch: reports.sellOutSearch,
  report: reports.report,
  isReportDownload: reports.isReportDownload,
  status: reports.status,
  statusError: reports.statusError,
  isStatusModalOpen: reports.isStatusModalOpen,
  isSellOutFileDownload: reports.isSellOutFileDownload,
})

const mapDispatchToProps = {
  fetchSellOut,
  setSellOutSize,
  setSellOutPage,
  setSellOutSort,
  setSellOutSearch,
  downloadReport,
  toggleStatusModal,
  downloadSellOutFile,
  resetDownload,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SellOut)
