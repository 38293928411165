import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import moment from 'moment'

import ReportsSearchForm from '../_common/ReportsSearchForm'
import StatusModal from '../_common/StatusModal'

import {
  fetchSellIn,
  setSellInSize,
  setSellInPage,
  setSellInSort,
  setSellInSearch,
  downloadReport,
  toggleStatusModal,
  resetDownload,
} from '../../redux/reports'
import Loader from '../_common/Loader/'
import ExtendedTable from '../_common/ExtendedTable'

const headerCells = [
  {title: 'Дата отчета', sortName: 'uploadedDate'},
  {title: 'Название файла', sortName: 'fileName'},
  {title: 'Пользователь', sortName: 'uploadedUser'},
  {title: 'Записей всего', sortName: 'numberOfRows'},
  {title: 'Записей с ошибками', sortName: 'hasError'},
]

class SellIn extends Component {
  static propTypes = {
    sellIn: PropTypes.object,
    isSellInFetching: PropTypes.bool.isRequired,
    sellInSize: PropTypes.number.isRequired,
    sellInPage: PropTypes.number.isRequired,
    sellInSort: PropTypes.array,
    sellInSearch: PropTypes.string.isRequired,
    report: PropTypes.object,
    isReportDownload: PropTypes.bool.isRequired,
    status: PropTypes.object,
    statusError: PropTypes.object,
    isStatusModalOpen: PropTypes.bool.isRequired,
    fetchSellIn: PropTypes.func.isRequired,
    setSellInSize: PropTypes.func.isRequired,
    setSellInPage: PropTypes.func.isRequired,
    setSellInSort: PropTypes.func.isRequired,
    setSellInSearch: PropTypes.func.isRequired,
    downloadReport: PropTypes.func.isRequired,
    toggleStatusModal: PropTypes.func.isRequired,
    resetDownload: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.props.fetchSellIn()
  }

  componentWillReceiveProps(nextProps) {
    const {sellInSize, sellInPage, sellInSort, sellInSearch, fetchSellIn} = this.props
    if (
      nextProps.sellInSize !== sellInSize ||
      nextProps.sellInPage !== sellInPage ||
      nextProps.sellInSort !== sellInSort
    ) {
      fetchSellIn()
    }
    if (!nextProps.sellInSearch && nextProps.sellInSearch !== sellInSearch) {
      fetchSellIn()
    }
  }

  getTableRows() {
    const {sellIn} = this.props
    return (
      sellIn &&
      sellIn.content.map(item => ({
        id: item.guid,
        data: [
          <span className="ws-nw">{moment(item.uploadedDate).format('DD.MM.YYYY')}</span>,
          item.fileName,
          item.uploadedUser,
          item.numberOfRows,
          item.hasError,
        ],
      }))
    )
  }

  render() {
    const {
      sellIn,
      sellInSize,
      sellInPage,
      sellInSort,
      sellInSearch,
      isSellInFetching,
      report,
      isReportDownload,
      status,
      statusError,
      isStatusModalOpen,
      setSellInSize,
      setSellInPage,
      setSellInSort,
      setSellInSearch,
      fetchSellIn,
      downloadReport,
      toggleStatusModal,
      resetDownload,
    } = this.props
    const total = sellIn ? sellIn.totalElements : 0
    return (
      <section className="container loaded">
        <Loader isFetch={isReportDownload} />
        <ReportsSearchForm
          placeholder="Введите дату отчета, название файла или пользователя"
          search={sellInSearch}
          isFetching={isSellInFetching}
          setSearch={setSellInSearch}
          fetchData={fetchSellIn}
          downloadReport={downloadReport}
        />
        <ExtendedTable
          headerCells={headerCells}
          tableRows={this.getTableRows()}
          isFetching={isSellInFetching}
          total={total}
          rowsPerPage={sellInSize}
          page={sellInPage}
          sort={sellInSort}
          setPage={setSellInPage}
          setSize={setSellInSize}
          setSort={setSellInSort}
        />
        <StatusModal
          report="sellIn"
          file={report}
          status={status}
          statusError={statusError}
          isOpen={isStatusModalOpen}
          closeModal={toggleStatusModal}
          onExited={resetDownload}
        />
      </section>
    )
  }
}

const mapStateToProps = ({reports}) => ({
  sellIn: reports.sellIn,
  isSellInFetching: reports.isSellInFetching,
  sellInSize: reports.sellInSize,
  sellInPage: reports.sellInPage,
  sellInSort: reports.sellInSort,
  sellInSearch: reports.sellInSearch,
  report: reports.report,
  isReportDownload: reports.isReportDownload,
  status: reports.status,
  statusError: reports.statusError,
  isStatusModalOpen: reports.isStatusModalOpen,
})

const mapDispatchToProps = {
  fetchSellIn,
  setSellInSize,
  setSellInPage,
  setSellInSort,
  setSellInSearch,
  downloadReport,
  toggleStatusModal,
  resetDownload,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SellIn)
