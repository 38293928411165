import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import ExtendedTable from '../../_common/ExtendedTable'
import PartnerDocumentActions from '../../_common/PartnerDocumentActions'
import {isDocumentHasScan, getDocumentFile} from '../../../utils/helpres'

const headerCells = [
  {title: 'Дата согласования', sortName: 'createdDate'},
  {title: 'Дата создания', sortName: 'lastModifiedDate'},
  {title: 'Тип документа(ов)', sortName: 'contractTypeName'},
  {title: 'Номер документа', sortName: 'docNumber'},
  {title: 'Партнер', sortName: 'partnerCard.orgName'},
  {title: 'Действие'},
]

export default class ConfirmedDocumentsTable extends PureComponent {
  static propTypes = {
    confirmedDocuments: PropTypes.object,
    isConfirmedDocumentsFetching: PropTypes.bool.isRequired,
    confirmedDocumentsSize: PropTypes.number.isRequired,
    confirmedDocumentsPage: PropTypes.number.isRequired,
    confirmedDocumentsSort: PropTypes.array,
    selectedConfirmedDocument: PropTypes.string,
    setConfirmedDocumentsSize: PropTypes.func.isRequired,
    setConfirmedDocumentsPage: PropTypes.func.isRequired,
    setConfirmedDocumentsSort: PropTypes.func.isRequired,
    selectConfirmedDocument: PropTypes.func.isRequired,
    downloadFile: PropTypes.func.isRequired,
  }

  handleRowSelect = selectedRow => () => {
    const {selectedConfirmedDocument, selectConfirmedDocument} = this.props
    selectedConfirmedDocument !== selectedRow ? selectConfirmedDocument(selectedRow) : selectConfirmedDocument(null)
  }

  getTableRows() {
    const {confirmedDocuments, downloadFile} = this.props
    if (!confirmedDocuments) return
    return confirmedDocuments.content.map(document => {
      const documentFile = getDocumentFile(document)
      const isAct = Boolean(document.actId)
      let scan
      document.documentFile.forEach(item => {
        if (isDocumentHasScan(document, item)) {
          scan = item
        }
      })
      return {
        id: document.bonusId
          ? `${document.id}-${document.bonusId}`
          : document.actId
          ? `${document.id}--${document.actId}`
          : String(document.id),
        data: [
          isAct ? '-' : <span className="ws-nw">{moment(document.createdDate).format('DD.MM.YYYY HH:mm')}</span>,
          <span className="ws-nw">{moment(document.lastModifiedDate).format('DD.MM.YYYY HH:mm')}</span>,
          isAct ? `Акт ${document.actPeriod}` : documentFile.bonusTypeName || documentFile.contractTypeName,
          document.actNumber || document.docNumber,
          document.partnerCard.orgName,
          <PartnerDocumentActions
            documentId={documentFile.id}
            fileName={documentFile.fileName}
            scanId={scan && scan.id}
            scanFileName={scan && scan.fileName}
            downloadFile={downloadFile}
          />,
        ],
      }
    })
  }

  render() {
    const {
      confirmedDocuments,
      isConfirmedDocumentsFetching,
      confirmedDocumentsSize,
      confirmedDocumentsPage,
      confirmedDocumentsSort,
      selectedConfirmedDocument,
      setConfirmedDocumentsSize,
      setConfirmedDocumentsPage,
      setConfirmedDocumentsSort,
    } = this.props
    const total = confirmedDocuments ? confirmedDocuments.totalElements : 0
    return (
      <ExtendedTable
        isInternal
        headerCells={headerCells}
        tableRows={this.getTableRows()}
        isFetching={isConfirmedDocumentsFetching}
        selected={selectedConfirmedDocument}
        total={total}
        rowsPerPage={confirmedDocumentsSize}
        page={confirmedDocumentsPage}
        sort={confirmedDocumentsSort}
        setPage={setConfirmedDocumentsPage}
        setSize={setConfirmedDocumentsSize}
        setSort={setConfirmedDocumentsSort}
        onRowClick={this.handleRowSelect}
      />
    )
  }
}
