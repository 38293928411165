import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom'

import {userRoleSelector} from '../../redux/user'
import {
  fetchPartners,
  fetchPartner,
  setPartnersSize,
  setPartnersPage,
  setPartnersSort,
  setPartnersSearch,
  fetchDownloadExel,
  toggleStatusModal,
  resetDownload,
} from '../../redux/partners'
import Loader from '../_common/Loader'
import SearchForm from './SearchForm'
import PartnersTable from './PartnersTable'
import StatusModal from '../_common/StatusModal'
import {isRPRM, isDPRPRM} from '../../utils/helpres'
import ROLES from '../../utils/constants'
import URLS from '../../utils/urls'

class Partners extends Component {
  static propTypes = {
    roles: PropTypes.array.isRequired,
    role: PropTypes.string.isRequired,
    partners: PropTypes.object,
    isPartnersFetching: PropTypes.bool.isRequired,
    partnersSize: PropTypes.number.isRequired,
    partnersPage: PropTypes.number.isRequired,
    partnersSort: PropTypes.array,
    partnersSearch: PropTypes.string.isRequired,
    isPartnerFetching: PropTypes.bool.isRequired,
    exel: PropTypes.object,
    isExelFetching: PropTypes.bool.isRequired,
    status: PropTypes.object,
    statusError: PropTypes.object,
    isStatusModalOpen: PropTypes.bool.isRequired,
    fetchPartners: PropTypes.func.isRequired,
    fetchPartner: PropTypes.func.isRequired,
    setPartnersSize: PropTypes.func.isRequired,
    setPartnersPage: PropTypes.func.isRequired,
    setPartnersSort: PropTypes.func.isRequired,
    setPartnersSearch: PropTypes.func.isRequired,
    fetchDownloadExel: PropTypes.func.isRequired,
    toggleStatusModal: PropTypes.func.isRequired,
    resetDownload: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.props.fetchPartners()
  }

  componentWillReceiveProps(nextProps) {
    const {roles, role, partnersSize, partnersPage, partnersSort, partnersSearch, fetchPartners} = this.props
    if (
      nextProps.partnersSize !== partnersSize ||
      nextProps.partnersPage !== partnersPage ||
      nextProps.partnersSort !== partnersSort
    ) {
      fetchPartners()
    }
    if (
      role !== ROLES.RP &&
      role !== ROLES.DP &&
      role !== ROLES.SC &&
      !isRPRM(roles) &&
      !isDPRPRM(roles) &&
      !nextProps.partnersSearch &&
      nextProps.partnersSearch !== partnersSearch
    ) {
      fetchPartners()
    }
  }

  render() {
    const {
      roles,
      role,
      partners,
      isPartnersFetching,
      partnersSize,
      partnersPage,
      partnersSort,
      partnersSearch,
      isPartnerFetching,
      exel,
      status,
      statusError,
      isStatusModalOpen,
      isExelFetching,
      fetchPartners,
      fetchPartner,
      setPartnersSize,
      setPartnersPage,
      setPartnersSort,
      setPartnersSearch,
      fetchDownloadExel,
      toggleStatusModal,
      resetDownload,
    } = this.props
    const searchForm = (
      <SearchForm
        roles={roles}
        role={role}
        partnersSearch={partnersSearch}
        isPartnersFetching={isPartnersFetching}
        isPartnerFetching={isPartnerFetching}
        isExelFetching={isExelFetching}
        setPartnersSearch={setPartnersSearch}
        fetchPartners={fetchPartners}
        fetchPartner={fetchPartner}
        fetchDownloadExel={fetchDownloadExel}
      />
    )
    const partnersTable = (
      <PartnersTable
        partners={partners}
        partnersSize={partnersSize}
        partnersPage={partnersPage}
        partnersSort={partnersSort}
        isPartnersFetching={isPartnersFetching}
        setPartnersSize={setPartnersSize}
        setPartnersPage={setPartnersPage}
        setPartnersSort={setPartnersSort}
      />
    )
    let partnersContent = (
      <Fragment>
        {searchForm}
        {partnersTable}
      </Fragment>
    )
    if (role === ROLES.ADMIN) {
      partnersContent = (
        <Fragment>
          <Loader isFetch={isExelFetching} />
          {searchForm}
          {partnersTable}
          <StatusModal
            file={exel}
            status={status}
            statusError={statusError}
            isOpen={isStatusModalOpen}
            closeModal={toggleStatusModal}
            onExited={resetDownload}
          />
        </Fragment>
      )
    } else if (role === ROLES.P) {
      if (isPartnersFetching) {
        partnersContent = <Loader isBlock isFetch={isPartnersFetching} />
      } else if (partners && partners.content[0]) {
        partnersContent = <Redirect to={`${URLS.PARTNERS}/${partners.content[0].orgNumber}`} />
      } else {
        partnersContent = null
      }
    }
    return <section className="container loaded">{partnersContent}</section>
  }
}

const mapStateToProps = ({user, partners}) => ({
  roles: user.user.roles,
  role: userRoleSelector(user.user),
  partners: partners.partners,
  isPartnersFetching: partners.isPartnersFetching,
  partnersSize: partners.partnersSize,
  partnersPage: partners.partnersPage,
  partnersSort: partners.partnersSort,
  partnersSearch: partners.partnersSearch,
  isPartnerFetching: partners.isPartnerFetching,
  exel: partners.exel,
  isExelFetching: partners.isExelFetching,
  status: partners.status,
  statusError: partners.statusError,
  isStatusModalOpen: partners.isStatusModalOpen,
})

const mapDispatchToProps = {
  fetchPartners,
  fetchPartner,
  setPartnersSize,
  setPartnersPage,
  setPartnersSort,
  setPartnersSearch,
  fetchDownloadExel,
  toggleStatusModal,
  resetDownload,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Partners)
