import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {Form, Field} from 'react-final-form'
import Grid from '@material-ui/core/Grid'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

import GridContainer from '../GridContainer'
import InputField from '../InputField'
import {validateMessage} from '../../../utils/validator'
import {removeDuplicates, focusOnError} from '../../../utils/helpres'

const styles = {
  dialogContent: {
    paddingTop: 0,
  },
}

export default class AgreementForm extends PureComponent {
  constructor(props) {
    super(props)
    const {user, approvalSheet} = props
    const currentCurators = removeDuplicates(
      approvalSheet.approvalSheetComments.map(comment => comment.user),
      'id'
    ).filter(curator => curator.id !== user.id)
    this.curators = currentCurators.map(curator => ({
      value: curator.id,
      label: `${curator.lastName} ${curator.firstName}`,
    }))
  }

  static propTypes = {
    user: PropTypes.object.isRequired,
    approvalSheet: PropTypes.object,
    isFetch: PropTypes.bool.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    sendMessage: PropTypes.func.isRequired,
    onModalClose: PropTypes.func.isRequired,
  }

  handleFormSubmit = values => {
    const {user, approvalSheet, sendMessage, onModalClose} = this.props
    const message = {
      user,
      title: 'Заголовок коментария',
      comment: values.comment,
      isAdditional: true,
      forCuratorId: values.forCuratorId,
    }
    sendMessage({
      message,
      approvalSheet,
      closeModal: onModalClose,
    })
  }

  render() {
    const {isFetch, isModalOpen, onModalClose} = this.props
    return (
      <Form
        validate={validateMessage}
        decorators={[focusOnError]}
        onSubmit={this.handleFormSubmit}
        render={({handleSubmit}) => (
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <DialogContent style={styles.dialogContent}>
              <GridContainer>
                <Grid item xs={12}>
                  <Field name="forCuratorId" label="Кому" rows={6} options={this.curators} component={InputField} />
                </Grid>
              </GridContainer>
              <GridContainer>
                <Grid item xs={12}>
                  <Field autoFocus multiline name="comment" label="Комментарий" rows={6} component={InputField} />
                </Grid>
              </GridContainer>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={isModalOpen ? onModalClose : null}>
                Отмена
              </Button>
              <Button type="submit" color="primary" disabled={!isModalOpen || isFetch}>
                Добавить
              </Button>
            </DialogActions>
          </form>
        )}
      />
    )
  }
}
