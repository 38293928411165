import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'
import moment from 'moment'

import ExtendedTable from '../../_common/ExtendedTable'
import {STATUSES} from '../../../utils/constants'
import URLS from '../../../utils/urls'

const headerCells = [
  {title: 'Идентификатор', sortName: 'orgNumber'},
  {title: 'Дата', sortName: 'importCreatedDate'},
  {title: 'Название', sortName: 'orgName'},
  {title: 'ИНН', sortName: 'INN'},
  {title: 'ФО', sortName: 'federalDistrict'},
  {title: 'Регион', sortName: 'region'},
  {title: 'Статус', sortName: 'status'},
]

class PartnersTable extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    partners: PropTypes.object,
    partnersSize: PropTypes.number.isRequired,
    partnersPage: PropTypes.number.isRequired,
    partnersSort: PropTypes.array,
    isPartnersFetching: PropTypes.bool.isRequired,
    setPartnersSize: PropTypes.func.isRequired,
    setPartnersPage: PropTypes.func.isRequired,
    setPartnersSort: PropTypes.func.isRequired,
  }

  handleGoToPartner = id => () => this.props.history.push(`${URLS.PARTNERS}/${id}`)

  getTableRows() {
    const {partners} = this.props
    return (
      partners &&
      partners.content.map(partner => ({
        id: partner.orgNumber,
        data: [
          partner.orgNumber,
          <span className="ws-nw">{moment(partner.importCreatedDate).format('DD.MM.YYYY HH:mm')}</span>,
          partner.orgName,
          partner.INN,
          partner.federalDistrict,
          partner.region,
          partner.status === STATUSES.DRAFT_CONFIRMED
            ? 'Черновик (подтвержден)'
            : partner.status === STATUSES.DRAFT
            ? 'Черновик'
            : partner.status === STATUSES.CREATED
            ? 'Создан договор'
            : partner.status === STATUSES.AGREEMENT_CONTRACT
            ? 'Согласование'
            : partner.status === STATUSES.CONFIRMED_CONTRACT
            ? 'Согласован'
            : partner.status === STATUSES.SIGNED_CONTRACT
            ? 'Договор подписан'
            : partner.status === STATUSES.REJECT
            ? 'Заблокирован'
            : 'Загружен',
        ],
      }))
    )
  }

  render() {
    const {
      partners,
      partnersSize,
      partnersPage,
      partnersSort,
      isPartnersFetching,
      setPartnersSize,
      setPartnersPage,
      setPartnersSort,
    } = this.props
    const total = partners ? partners.totalElements : 0
    return (
      <ExtendedTable
        headerCells={headerCells}
        tableRows={this.getTableRows()}
        isFetching={isPartnersFetching}
        total={total}
        rowsPerPage={partnersSize}
        page={partnersPage}
        sort={partnersSort}
        setPage={setPartnersPage}
        setSize={setPartnersSize}
        setSort={setPartnersSort}
        onRowClick={this.handleGoToPartner}
      />
    )
  }
}

export default withRouter(PartnersTable)
