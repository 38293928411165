import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import Grid from '@material-ui/core/Grid'

import {createVaillantCurator, deleteVaillantCurator} from '../../../redux/curators'
import withModal from '../../_decorators/withModal'
import withRowSelect from '../../_decorators/withRowSelect'
import GridContainer from '../../_common/GridContainer'
import ExtendedTable from '../../_common/ExtendedTable'
import TableActions from '../../_common/TableActions'
import Modal from '../../_common/Modal'
import Loader from '../../_common/Loader'
import WorkerAddForm from '../WorkerAddForm'
import {isCuratorsTableFetching, getCuratorsRows} from '../../../utils/helpres'

const headerCells = [{title: 'ФИО'}, {title: 'Телефон'}, {title: 'E-mail'}]

class PartnerWorkers extends PureComponent {
  static propTypes = {
    isRead: PropTypes.bool,
    partner: PropTypes.object,
    isPartnerFetching: PropTypes.bool.isRequired,
    curatorsEditType: PropTypes.string,
    isVaillantCuratorCreateFetching: PropTypes.bool.isRequired,
    isVaillantCuratorDeleteFetching: PropTypes.bool.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    selectedRow: PropTypes.number,
    createVaillantCurator: PropTypes.func.isRequired,
    deleteVaillantCurator: PropTypes.func.isRequired,
    onModalToggle: PropTypes.func.isRequired,
    onRowSelect: PropTypes.func.isRequired,
    onSelectedRowReset: PropTypes.func.isRequired,
  }

  handleVaillantCuratorDelete = () => {
    const {selectedRow, deleteVaillantCurator, onSelectedRowReset} = this.props
    deleteVaillantCurator(selectedRow, onSelectedRowReset)
  }

  render() {
    const {
      isRead,
      partner,
      curatorsEditType,
      isPartnerFetching,
      isVaillantCuratorCreateFetching,
      isVaillantCuratorDeleteFetching,
      isModalOpen,
      selectedRow,
      createVaillantCurator,
      onModalToggle,
      onRowSelect,
    } = this.props
    const vaillantCuratorsRows = getCuratorsRows(partner, 'vgrCurator')
    return (
      <div className={isRead ? null : 'loaded'}>
        <Loader isFetch={isVaillantCuratorDeleteFetching} />
        <GridContainer>
          <Grid item xs={isRead ? 12 : 11}>
            <ExtendedTable
              isInternal
              headerCells={headerCells}
              tableRows={vaillantCuratorsRows}
              selected={selectedRow}
              isFetching={isCuratorsTableFetching('vgr', isRead, curatorsEditType, isPartnerFetching)}
              onRowClick={!isRead ? onRowSelect : null}
            />
          </Grid>
          {!isRead && (
            <Grid item xs={1}>
              <TableActions
                isFetching={isPartnerFetching}
                onAdd={vaillantCuratorsRows.length >= 2 ? null : onModalToggle}
                onDelete={selectedRow ? this.handleVaillantCuratorDelete : null}
              />
              <Modal isForm open={isModalOpen} title="Добавление контактного лица VGR" onClose={onModalToggle}>
                <WorkerAddForm
                  isModalOpen={isModalOpen}
                  isFetching={isVaillantCuratorCreateFetching}
                  createCurator={createVaillantCurator}
                  onModalToggle={onModalToggle}
                />
              </Modal>
            </Grid>
          )}
        </GridContainer>
      </div>
    )
  }
}

const mapStateToProps = ({partners, curators}) => ({
  partner: partners.partner,
  isPartnerFetching: partners.isPartnerFetching,
  curatorsEditType: curators.curatorsEditType,
  isVaillantCuratorCreateFetching: curators.isVaillantCuratorCreateFetching,
  isVaillantCuratorDeleteFetching: curators.isVaillantCuratorDeleteFetching,
})

const mapDispatchToProps = {
  createVaillantCurator,
  deleteVaillantCurator,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withModal(withRowSelect(PartnerWorkers)))
