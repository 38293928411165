import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withStyles} from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Tooltip from '@material-ui/core/Tooltip'

import {userRoleSelector} from '../../redux/user'
import {fetchPartner, updatePartner, savePartner, resetPartner} from '../../redux/partners'
import {
  regionalManagersSelector,
  fetchRegionalManagers,
  fetchRegionalRepresentatives,
  fetchServiceCoordinators,
  fetchFederalDistricts,
  fetchPartnerTypes,
  fetchContractBonusTypes,
  fetchAllCurators,
} from '../../redux/references'
import {
  fetchDocuments,
  fetchDocumentReferences,
  fetchDocumentBonus,
  resetDocumentBonus,
  saveDocument,
  downloadFile,
  resetDocuments,
  fetchDocumentNumber,
  resetDocumentNumber,
  deleteDocument,
} from '../../redux/documents'
import {changeActStatus, downloadAct} from '../../redux/acts'
import {toggleCurrentPanelExpanded, resetCurrentPanelsExpanded} from '../../redux/ui'
import PartnerForm from './PartnerForm'
import PartnerFormRead from './PartnerFormRead'
import PartnerDocuments from './PartnerDocuments'
import DocumentForm from '../_common/DocumentForm'
import {scrollTop} from '../../utils/helpres'
import {STATUSES} from '../../utils/constants'

const tabs = ['Личный кабинет', 'Документы']

const StyledTab = withStyles({
  root: {
    height: '100%',
  },
})(Tab)

const DisabledTab = ({tab, fullWidth, indicator, textColor, ...props}) => (
  <div className="f" {...props}>
    <StyledTab disabled label={tab} />
  </div>
)

class Partner extends Component {
  state = {
    activeTab: 0,
    isEdit: false,
    isNewContract: true,
  }

  static propTypes = {
    match: PropTypes.object.isRequired,
    roles: PropTypes.array.isRequired,
    role: PropTypes.string.isRequired,
    partner: PropTypes.object,
    isPartnerFetching: PropTypes.bool.isRequired,
    isPartnerUpdateFetching: PropTypes.bool.isRequired,
    isPartnerSaveFetching: PropTypes.bool.isRequired,
    curatorsEditType: PropTypes.string,
    regionalManagers: PropTypes.array.isRequired,
    partnerTypes: PropTypes.array,
    documents: PropTypes.array,
    isDocumentsFetching: PropTypes.bool.isRequired,
    contractBonusTypes: PropTypes.array,
    isContractBonusTypesFetching: PropTypes.bool.isRequired,
    documentReferences: PropTypes.object,
    isDocumentReferencesFetching: PropTypes.bool.isRequired,
    documentBonus: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isDocumentBonusFetching: PropTypes.bool.isRequired,
    documentNumber: PropTypes.string,
    isDocumentNumberFetching: PropTypes.bool.isRequired,
    isDocumentSaveFetching: PropTypes.bool.isRequired,
    isFileDownloadFetching: PropTypes.bool.isRequired,
    isDocumentDelete: PropTypes.bool.isRequired,
    isRegionalManagers: PropTypes.bool.isRequired,
    isRegionalRepresentatives: PropTypes.bool.isRequired,
    isServiceCoordinators: PropTypes.bool.isRequired,
    isFederalDistricts: PropTypes.bool.isRequired,
    isPartnerTypes: PropTypes.bool.isRequired,
    allCurators: PropTypes.object,
    isPartnerPanelExpand1: PropTypes.bool.isRequired,
    isPartnerPanelExpand2: PropTypes.bool.isRequired,
    isPartnerPanelExpand3: PropTypes.bool.isRequired,
    isPartnerPanelExpand4: PropTypes.bool.isRequired,
    isPartnerPanelExpand5: PropTypes.bool.isRequired,
    isActStatusChange: PropTypes.bool.isRequired,
    isActDownload: PropTypes.bool.isRequired,

    fetchPartner: PropTypes.func.isRequired,
    updatePartner: PropTypes.func.isRequired,
    savePartner: PropTypes.func.isRequired,
    resetPartner: PropTypes.func.isRequired,
    fetchRegionalManagers: PropTypes.func.isRequired,
    fetchRegionalRepresentatives: PropTypes.func.isRequired,
    fetchServiceCoordinators: PropTypes.func.isRequired,
    fetchFederalDistricts: PropTypes.func.isRequired,
    fetchPartnerTypes: PropTypes.func.isRequired,
    fetchContractBonusTypes: PropTypes.func.isRequired,
    fetchAllCurators: PropTypes.func.isRequired,
    toggleCurrentPanelExpanded: PropTypes.func.isRequired,
    resetCurrentPanelsExpanded: PropTypes.func.isRequired,
    fetchDocuments: PropTypes.func.isRequired,
    fetchDocumentReferences: PropTypes.func.isRequired,
    fetchDocumentBonus: PropTypes.func.isRequired,
    resetDocumentBonus: PropTypes.func.isRequired,
    fetchDocumentNumber: PropTypes.func.isRequired,
    saveDocument: PropTypes.func.isRequired,
    downloadFile: PropTypes.func.isRequired,
    resetDocuments: PropTypes.func.isRequired,
    resetDocumentNumber: PropTypes.func.isRequired,
    deleteDocument: PropTypes.func.isRequired,
    changeActStatus: PropTypes.func.isRequired,
    downloadAct: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const {
      match: {params},
      isRegionalManagers,
      isRegionalRepresentatives,
      isServiceCoordinators,
      isFederalDistricts,
      isPartnerTypes,
      allCurators,
      fetchPartner,
      fetchRegionalManagers,
      fetchRegionalRepresentatives,
      fetchServiceCoordinators,
      fetchFederalDistricts,
      fetchPartnerTypes,
      fetchAllCurators,
    } = this.props
    if (!isFederalDistricts) fetchFederalDistricts()
    if (!isPartnerTypes) fetchPartnerTypes()
    if (!isRegionalManagers) fetchRegionalManagers()
    if (!isRegionalRepresentatives) fetchRegionalRepresentatives()
    if (!isServiceCoordinators) fetchServiceCoordinators()
    if (!allCurators) fetchAllCurators()
    fetchPartner(params.id)
  }

  componentWillUnmount() {
    const {resetPartner, resetDocuments} = this.props
    resetPartner()
    resetDocuments()
  }

  handleActiveTabChange = (e, value) => this.setState({activeTab: value, isNewContract: false})

  handleEditToggle = () => this.setState(state => ({isEdit: !state.isEdit}), scrollTop)

  handleNewContractToggle = () => this.setState(state => ({isNewContract: !state.isNewContract}), scrollTop)

  getPartnerTypeOptions() {
    const {partnerTypes} = this.props
    return partnerTypes
      ? partnerTypes.map(type => ({
          value: type.id,
          label: type.name,
          secondLabel: type.mnemonic,
        }))
      : []
  }

  renderTabs() {
    const {partner, isPartnerFetching} = this.props
    return tabs.map(tab =>
      tab === 'Документы' &&
      !isPartnerFetching &&
      (!partner || partner.status === STATUSES.DOWNLOADED || partner.status === STATUSES.DRAFT) ? (
        <Tooltip
          disableFocusListener
          key={tab}
          title="Вкладка будет активна после внесения всех данных. Карточка партнера содержит незаполненные поля."
        >
          <DisabledTab tab={tab} />
        </Tooltip>
      ) : (
        <Tab disabled={isPartnerFetching} key={tab} label={tab} />
      )
    )
  }

  renderTabContent() {
    const {
      roles,
      role,
      partner,
      isPartnerFetching,
      isPartnerUpdateFetching,
      isPartnerSaveFetching,
      curatorsEditType,
      regionalManagers,
      documents,
      isDocumentsFetching,
      contractBonusTypes,
      isContractBonusTypesFetching,
      documentNumber,
      isDocumentNumberFetching,
      documentReferences,
      isDocumentReferencesFetching,
      documentBonus,
      allCurators,
      isDocumentBonusFetching,
      isDocumentSaveFetching,
      isFileDownloadFetching,
      isDocumentDelete,
      isPartnerPanelExpand1,
      isPartnerPanelExpand2,
      isPartnerPanelExpand3,
      isPartnerPanelExpand4,
      isPartnerPanelExpand5,
      isActStatusChange,
      isActDownload,

      updatePartner,
      savePartner,
      toggleCurrentPanelExpanded,
      resetCurrentPanelsExpanded,
      fetchDocuments,
      fetchDocumentReferences,
      fetchDocumentBonus,
      resetDocumentBonus,
      fetchDocumentNumber,
      resetDocumentNumber,
      saveDocument,
      downloadFile,
      deleteDocument,
      changeActStatus,
      downloadAct,
    } = this.props
    const {activeTab, isEdit, isNewContract} = this.state
    const partnerTypesOptions = this.getPartnerTypeOptions()
    const form = isEdit ? (
      <PartnerForm
        partner={partner}
        isPartnerUpdateFetching={isPartnerUpdateFetching}
        isPartnerSaveFetching={isPartnerSaveFetching}
        partnerTypes={partnerTypesOptions}
        updatePartner={updatePartner}
        savePartner={savePartner}
        onEditToggle={this.handleEditToggle}
      />
    ) : (
      <PartnerFormRead
        roles={roles}
        role={role}
        partner={partner}
        isPartnerFetching={isPartnerFetching}
        curatorsEditType={curatorsEditType}
        regionalManagers={regionalManagers}
        partnerTypes={partnerTypesOptions}
        allCurators={allCurators}
        isPartnerPanelExpand1={isPartnerPanelExpand1}
        isPartnerPanelExpand2={isPartnerPanelExpand2}
        isPartnerPanelExpand3={isPartnerPanelExpand3}
        isPartnerPanelExpand4={isPartnerPanelExpand4}
        isPartnerPanelExpand5={isPartnerPanelExpand5}
        toggleCurrentPanelExpanded={toggleCurrentPanelExpanded}
        resetCurrentPanelsExpanded={resetCurrentPanelsExpanded}
        onEditToggle={this.handleEditToggle}
      />
    )
    switch (activeTab) {
      case 0:
        return form
      default:
        return isNewContract ? (
          <DocumentForm
            documentNumber={documentNumber}
            isDocumentNumberFetching={isDocumentNumberFetching}
            documentReferences={documentReferences}
            isDocumentReferencesFetching={isDocumentReferencesFetching}
            documentBonus={documentBonus}
            isDocumentBonusFetching={isDocumentBonusFetching}
            isDocumentSaveFetching={isDocumentSaveFetching}
            fetchDocumentReferences={fetchDocumentReferences}
            fetchDocumentBonus={fetchDocumentBonus}
            resetDocumentBonus={resetDocumentBonus}
            fetchDocumentNumber={fetchDocumentNumber}
            saveDocument={saveDocument}
            resetDocumentNumber={resetDocumentNumber}
            onNewContractToggle={this.handleNewContractToggle}
          />
        ) : (
          <PartnerDocuments
            roles={roles}
            role={role}
            isActivePartner={Boolean(partner && partner.status !== STATUSES.REJECT)}
            documents={documents}
            isDocumentsFetching={isDocumentsFetching}
            contractBonusTypes={contractBonusTypes}
            isContractBonusTypesFetching={isContractBonusTypesFetching}
            isFileDownloadFetching={isFileDownloadFetching}
            isDocumentDelete={isDocumentDelete}
            isActStatusChange={isActStatusChange}
            isActDownload={isActDownload}
            fetchDocuments={fetchDocuments}
            downloadFile={downloadFile}
            deleteDocument={deleteDocument}
            changeActStatus={changeActStatus}
            downloadAct={downloadAct}
            onNewContractToggle={this.handleNewContractToggle}
          />
        )
    }
  }

  render() {
    const {activeTab} = this.state
    return (
      <section className="container loaded">
        <AppBar position="static">
          <Tabs value={activeTab} onChange={this.handleActiveTabChange}>
            {this.renderTabs()}
          </Tabs>
        </AppBar>
        {this.renderTabContent()}
      </section>
    )
  }
}

const mapStateToProps = ({user, partners, references, curators, documents, acts, ui}) => ({
  roles: user.user.roles,
  role: userRoleSelector(user.user),
  regionalManagers: regionalManagersSelector(references.regionalManagers),
  partnerTypes: references.partnerTypes,
  partner: partners.partner,
  isPartnerFetching: partners.isPartnerFetching,
  isPartnerUpdateFetching: partners.isPartnerUpdateFetching,
  isPartnerSaveFetching: partners.isPartnerSaveFetching,
  documents: documents.documents,
  isDocumentsFetching: documents.isDocumentsFetching,
  documentReferences: documents.documentReferences,
  isDocumentReferencesFetching: documents.isDocumentReferencesFetching,
  documentBonus: documents.documentBonus,
  isDocumentBonusFetching: documents.isDocumentBonusFetching,
  contractBonusTypes: references.contractBonusTypes,
  isContractBonusTypesFetching: references.isContractBonusTypesFetching,
  documentNumber: documents.documentNumber,
  isDocumentNumberFetching: documents.isDocumentNumberFetching,
  curatorsEditType: curators.curatorsEditType,
  isRegionalManagers: Boolean(references.regionalManagers),
  isRegionalRepresentatives: Boolean(references.regionalRepresentatives),
  isServiceCoordinators: Boolean(references.serviceCoordinators),
  isFederalDistricts: Boolean(references.federalDistricts),
  isPartnerTypes: Boolean(references.partnerTypes),
  allCurators: references.allCurators,
  isDocumentSaveFetching: documents.isDocumentSaveFetching,
  isFileDownloadFetching: documents.isFileDownloadFetching,
  isDocumentDelete: documents.isDocumentDelete,
  isPartnerPanelExpand1: ui.isPartnerPanelExpand1,
  isPartnerPanelExpand2: ui.isPartnerPanelExpand2,
  isPartnerPanelExpand3: ui.isPartnerPanelExpand3,
  isPartnerPanelExpand4: ui.isPartnerPanelExpand4,
  isPartnerPanelExpand5: ui.isPartnerPanelExpand5,
  isActStatusChange: acts.isActStatusChange,
  isActDownload: acts.isActDownload,
})

const mapDispatchToProps = {
  fetchPartner,
  updatePartner,
  savePartner,
  resetPartner,
  fetchRegionalManagers,
  fetchRegionalRepresentatives,
  fetchServiceCoordinators,
  fetchFederalDistricts,
  fetchPartnerTypes,
  fetchContractBonusTypes,
  fetchAllCurators,
  toggleCurrentPanelExpanded,
  resetCurrentPanelsExpanded,
  fetchDocuments,
  fetchDocumentReferences,
  fetchDocumentBonus,
  resetDocumentBonus,
  saveDocument,
  downloadFile,
  resetDocuments,
  fetchDocumentNumber,
  resetDocumentNumber,
  deleteDocument,
  changeActStatus,
  downloadAct,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Partner)
