import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Paper from '@material-ui/core/Paper'

import {userRoleSelector} from '../../redux/user'
import {
  fetchApprovalSheets,
  fetchApprovalSheet,
  setApprovalSheetsSize,
  setApprovalSheetsPage,
  setApprovalSheetsSort,
  selectApprovalSheet,
  resetApprovalSheet,
  fetchHistoricSheets,
  setHistoricSheetsSize,
  setHistoricSheetsPage,
  setHistoricSheetsSort,
  agreeApprovalSheet,
  sendMessage,
} from '../../redux/agreement'
import {downloadFile, fetchDocument} from '../../redux/documents'
import Loader from '../_common/Loader'
import AgreementContent from './AgreementContent'
import AgreementHistory from './AgreementHistory'

const tabs = ['Согласование', 'История согласований']

const renderTabs = () => tabs.map(tab => <Tab key={tab} label={tab} />)

const styles = {
  paper: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
}

class Agreement extends Component {
  state = {
    activeTab: 0,
  }

  static propTypes = {
    roles: PropTypes.array.isRequired,
    role: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    approvalSheets: PropTypes.object,
    isApprovalSheetsFetching: PropTypes.bool.isRequired,
    approvalSheet: PropTypes.object,
    isApprovalSheetFetching: PropTypes.bool.isRequired,
    selectedApprovalSheet: PropTypes.number,
    approvalSheetsSize: PropTypes.number.isRequired,
    approvalSheetsPage: PropTypes.number.isRequired,
    approvalSheetsSort: PropTypes.array,
    historicSheets: PropTypes.object,
    isHistoricSheetsFetching: PropTypes.bool.isRequired,
    historicSheetsSize: PropTypes.number.isRequired,
    historicSheetsPage: PropTypes.number.isRequired,
    historicSheetsSort: PropTypes.array,
    isAgreeSheetFetching: PropTypes.bool.isRequired,
    isDocumentFetching: PropTypes.bool.isRequired,
    isFileDownloadFetching: PropTypes.bool.isRequired,
    fetchApprovalSheets: PropTypes.func.isRequired,
    fetchApprovalSheet: PropTypes.func.isRequired,
    setApprovalSheetsSize: PropTypes.func.isRequired,
    setApprovalSheetsPage: PropTypes.func.isRequired,
    setApprovalSheetsSort: PropTypes.func.isRequired,
    selectApprovalSheet: PropTypes.func.isRequired,
    resetApprovalSheet: PropTypes.func.isRequired,
    fetchHistoricSheets: PropTypes.func.isRequired,
    setHistoricSheetsSize: PropTypes.func.isRequired,
    setHistoricSheetsPage: PropTypes.func.isRequired,
    setHistoricSheetsSort: PropTypes.func.isRequired,
    agreeApprovalSheet: PropTypes.func.isRequired,
    sendMessage: PropTypes.func.isRequired,
    downloadFile: PropTypes.func.isRequired,
    fetchDocument: PropTypes.func.isRequired,
  }

  handleActiveTabChange = (e, value) => this.setState({activeTab: value})

  render() {
    const {
      roles,
      role,
      user,
      approvalSheets,
      isApprovalSheetsFetching,
      approvalSheet,
      isApprovalSheetFetching,
      selectedApprovalSheet,
      approvalSheetsSize,
      approvalSheetsPage,
      approvalSheetsSort,
      historicSheets,
      isHistoricSheetsFetching,
      historicSheetsSize,
      historicSheetsPage,
      historicSheetsSort,
      isAgreeSheetFetching,
      isSendMessageFetching,
      isDocumentFetching,
      isFileDownloadFetching,
      fetchApprovalSheets,
      fetchApprovalSheet,
      setApprovalSheetsSize,
      setApprovalSheetsPage,
      setApprovalSheetsSort,
      selectApprovalSheet,
      fetchHistoricSheets,
      setHistoricSheetsSize,
      setHistoricSheetsPage,
      setHistoricSheetsSort,
      agreeApprovalSheet,
      sendMessage,
      resetApprovalSheet,
      downloadFile,
      fetchDocument,
    } = this.props
    const {activeTab} = this.state
    return (
      <section className="container loaded">
        <Loader
          isFetch={isAgreeSheetFetching || isSendMessageFetching || isDocumentFetching || isFileDownloadFetching}
        />
        <AppBar position="static">
          <Tabs value={activeTab} onChange={this.handleActiveTabChange}>
            {renderTabs()}
          </Tabs>
        </AppBar>
        <Paper style={styles.paper}>
          <div className="block">
            {activeTab === 0 ? (
              <AgreementContent
                roles={roles}
                role={role}
                user={user}
                approvalSheet={approvalSheet}
                isApprovalSheetsFetching={isApprovalSheetsFetching}
                approvalSheets={approvalSheets}
                isApprovalSheetFetching={isApprovalSheetFetching}
                approvalSheetsSize={approvalSheetsSize}
                approvalSheetsPage={approvalSheetsPage}
                approvalSheetsSort={approvalSheetsSort}
                selectedApprovalSheet={selectedApprovalSheet}
                isAgreeSheetFetching={isAgreeSheetFetching}
                isSendMessageFetching={isSendMessageFetching}
                fetchApprovalSheets={fetchApprovalSheets}
                fetchApprovalSheet={fetchApprovalSheet}
                setApprovalSheetsSize={setApprovalSheetsSize}
                setApprovalSheetsPage={setApprovalSheetsPage}
                setApprovalSheetsSort={setApprovalSheetsSort}
                selectApprovalSheet={selectApprovalSheet}
                agreeApprovalSheet={agreeApprovalSheet}
                sendMessage={sendMessage}
                resetApprovalSheet={resetApprovalSheet}
                downloadFile={downloadFile}
                fetchDocument={fetchDocument}
              />
            ) : (
              <AgreementHistory
                user={user}
                historicSheets={historicSheets}
                isHistoricSheetsFetching={isHistoricSheetsFetching}
                historicSheetsSize={historicSheetsSize}
                historicSheetsPage={historicSheetsPage}
                historicSheetsSort={historicSheetsSort}
                fetchHistoricSheets={fetchHistoricSheets}
                setHistoricSheetsSize={setHistoricSheetsSize}
                setHistoricSheetsPage={setHistoricSheetsPage}
                setHistoricSheetsSort={setHistoricSheetsSort}
                isSendMessageFetching={isSendMessageFetching}
                sendMessage={sendMessage}
              />
            )}
          </div>
        </Paper>
      </section>
    )
  }
}

const mapStateToProps = ({user, agreement, documents}) => ({
  roles: user.user.roles,
  role: userRoleSelector(user.user),
  user: user.user,
  approvalSheets: agreement.approvalSheets,
  isApprovalSheetsFetching: agreement.isApprovalSheetsFetching,
  approvalSheet: agreement.approvalSheet,
  isApprovalSheetFetching: agreement.isApprovalSheetFetching,
  approvalSheetsSize: agreement.approvalSheetsSize,
  approvalSheetsPage: agreement.approvalSheetsPage,
  approvalSheetsSort: agreement.approvalSheetsSort,
  selectedApprovalSheet: agreement.selectedApprovalSheet,
  historicSheets: agreement.historicSheets,
  isHistoricSheetsFetching: agreement.isHistoricSheetsFetching,
  historicSheetsSize: agreement.historicSheetsSize,
  historicSheetsPage: agreement.historicSheetsPage,
  historicSheetsSort: agreement.historicSheetsSort,
  isAgreeSheetFetching: agreement.isAgreeSheetFetching,
  isSendMessageFetching: agreement.isSendMessageFetching,
  isDocumentFetching: documents.isDocumentFetching,
  isFileDownloadFetching: documents.isFileDownloadFetching,
})

const mapDispatchToProps = {
  fetchApprovalSheets,
  fetchApprovalSheet,
  setApprovalSheetsSize,
  setApprovalSheetsPage,
  setApprovalSheetsSort,
  selectApprovalSheet,
  resetApprovalSheet,
  fetchHistoricSheets,
  setHistoricSheetsSize,
  setHistoricSheetsPage,
  setHistoricSheetsSort,
  agreeApprovalSheet,
  downloadFile,
  fetchDocument,
  sendMessage,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Agreement)
