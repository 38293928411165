import axios from 'axios'

import {ROLE_PREFIX} from '../utils/constants'

const module = 'user'

const FETCH_USER = `${module}/FETCH_USER`
const FETCH_USER_SUCCESS = `${module}/FETCH_USER_SUCCESS`
const FETCH_USER_FAIL = `${module}/FETCH_USER_FAIL`

const initialState = {
  user: null,
  isUserFetching: false,
  userError: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER:
      return {...state, isUserFetching: true, userError: null}
    case FETCH_USER_SUCCESS:
      return {...state, isUserFetching: false, user: action.user}
    case FETCH_USER_FAIL:
      return {...state, isUserFetching: false, userError: action.error}

    default:
      return state
  }
}

export const userRoleSelector = user =>
  user && user.roles.length ? user.roles.find(role => role.includes(ROLE_PREFIX)).replace(ROLE_PREFIX, '') : ''

export const fetchUser = () => dispatch => {
  dispatch({type: FETCH_USER})
  axios
    .get('/user')
    .then(({data}) => dispatch({type: FETCH_USER_SUCCESS, user: data}))
    .catch(error => dispatch({type: FETCH_USER_FAIL, error}))
}
