import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'
import {Form} from 'react-final-form'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import SettingsIcon from '@material-ui/icons/Settings'
import Tooltip from '@material-ui/core/Tooltip'

import './style.scss'
import PartnerBasic from '../../_common/PartnerBasic'
import PartnerContacts from '../../_common/PartnerContacts'
import PartnerBank from '../../_common/PartnerBank'
import PartnerWorkers from '../../_common/PartnerWorkers'
import PartnerPersons from '../../_common/PartnerPersons'
import Loader from '../../_common/Loader'
import {
  getInitialPartnerValues,
  isPartnerFederalOrRegionalDistributor,
  getPartnerFullName,
  isRPRM,
  isDPRPRM,
} from '../../../utils/helpres'
import {PARTNER_STEP_TITLES} from '../../../utils/constants'
import URLS from '../../../utils/urls'
import ROLES from '../../../utils/constants'
import {THEME} from '../../../styles/muiTheme'

const commonStyles = {
  button: {
    position: 'relative',
    zIndex: 1,
  },
}
const styles = {
  expansionPanel: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  expansionPanelSummaryStatic: {
    cursor: 'default',
  },
  buttonSettings: {
    marginRight: -(THEME.UNIT * 4),
  },
  buttonFirst: {
    ...commonStyles.button,
    marginRight: THEME.UNIT,
  },
}

class PartnerFormRead extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    roles: PropTypes.array.isRequired,
    role: PropTypes.string.isRequired,
    partner: PropTypes.object,
    isPartnerFetching: PropTypes.bool.isRequired,
    curatorsEditType: PropTypes.string,
    regionalManagers: PropTypes.array.isRequired,
    partnerTypes: PropTypes.array.isRequired,
    allCurators: PropTypes.object,
    isPartnerPanelExpand1: PropTypes.bool.isRequired,
    isPartnerPanelExpand2: PropTypes.bool.isRequired,
    isPartnerPanelExpand3: PropTypes.bool.isRequired,
    isPartnerPanelExpand4: PropTypes.bool.isRequired,
    isPartnerPanelExpand5: PropTypes.bool.isRequired,
    toggleCurrentPanelExpanded: PropTypes.func.isRequired,
    resetCurrentPanelsExpanded: PropTypes.func.isRequired,
    onEditToggle: PropTypes.func.isRequired,
  }

  handleFormSubmit = () => {}

  handleToggleExpanded = index => () => this.props.toggleCurrentPanelExpanded(index)

  handleGoToPartners = () => this.props.history.push(URLS.PARTNERS)

  getRegionalManager() {
    const {role, partner, regionalManagers, allCurators} = this.props
    const regionManagerId = partner.regionManagerId
    if (
      role !== ROLES.ADMIN &&
      (regionManagerId || regionManagerId === 0) &&
      !regionalManagers.find(coordinator => coordinator.value === regionManagerId)
    ) {
      return (allCurators && allCurators[regionManagerId]) || 'Сотрудник другого ФО'
    }
    const currentRegionalManager = regionalManagers.find(manager => manager.value === regionManagerId)
    return currentRegionalManager && currentRegionalManager.label
  }

  renderForm = ({values}) => {
    const {
      roles,
      role,
      partner,
      isPartnerFetching,
      partnerTypes,
      curatorsEditType,
      isPartnerPanelExpand1,
      isPartnerPanelExpand2,
      isPartnerPanelExpand3,
      isPartnerPanelExpand4,
      isPartnerPanelExpand5,
      resetCurrentPanelsExpanded,
      onEditToggle,
    } = this.props
    const isFederalOrRegionalDistributor = isPartnerFederalOrRegionalDistributor(values.partnerTypeId)
    const isResetAllPanels =
      isPartnerPanelExpand1 ||
      isPartnerPanelExpand2 ||
      isPartnerPanelExpand3 ||
      isPartnerPanelExpand4 ||
      isPartnerPanelExpand5
    const panels = [
      {title: ''},
      {
        title: PARTNER_STEP_TITLES[0],
        component: (
          <PartnerBasic
            isRead
            isFederalOrRegionalDistributor={isFederalOrRegionalDistributor}
            currentFederalDistrictIdValue={values.currentFederalDistrictId}
            vaillantCuratorIdValue={values.vaillantCuratorId}
            prothermCuratorIdValue={values.prothermCuratorId}
            serviceCoordinatorIdValue={values.serviceCoordinatorId}
          />
        ),
      },
      {title: PARTNER_STEP_TITLES[1], component: <PartnerContacts isRead />},
      {title: PARTNER_STEP_TITLES[2], component: <PartnerBank isRead />},
      {title: PARTNER_STEP_TITLES[3], component: <PartnerWorkers isRead />},
      {
        title: PARTNER_STEP_TITLES[4],
        component: (
          <PartnerPersons
            isRead
            isPartnerFetching={isPartnerFetching}
            isFederalOrRegionalDistributor={isFederalOrRegionalDistributor}
          />
        ),
      },
    ]
    return (
      <form noValidate className="partner-form-read" autoComplete="off">
        {panels.map((panel, index) => {
          const isFirstPanel = index === 0
          const isExpand = this.props[`isPartnerPanelExpand${index}`]
          return (
            <ExpansionPanel
              key={index}
              expanded={isFirstPanel || isExpand}
              style={styles.expansionPanel}
              onChange={!isFirstPanel ? this.handleToggleExpanded(index) : null}
            >
              <ExpansionPanelSummary
                expandIcon={!isFirstPanel ? isExpand ? <RemoveIcon /> : <AddIcon /> : null}
                style={isFirstPanel ? styles.expansionPanelSummaryStatic : null}
              >
                <div className="partner-form-read__block">
                  {isFirstPanel && (
                    <Fragment>
                      <Loader isBlock isFetch={!curatorsEditType && isPartnerFetching} />
                      {role !== ROLES.P && (
                        <div className="partner-form-read__actions f jc-sb mb-s">
                          <div>
                            <Button variant="outlined" style={styles.buttonFirst} onClick={this.handleGoToPartners}>
                              Закрыть
                            </Button>
                            {isResetAllPanels && (
                              <Button
                                className="loaded"
                                variant="outlined"
                                color="secondary"
                                style={commonStyles.button}
                                onClick={resetCurrentPanelsExpanded}
                              >
                                Свернуть все
                              </Button>
                            )}
                          </div>
                          {partner &&
                            partner.status !== 'REJECT' &&
                            (role === ROLES.ADMIN ||
                              role === ROLES.RP ||
                              isRPRM(roles) ||
                              role === ROLES.DP ||
                              isDPRPRM(roles) ||
                              role === ROLES.SC) && (
                              <Tooltip disableFocusListener title="Редактировать карточку" enterDelay={500}>
                                <Fab color="secondary" style={styles.buttonSettings} onClick={onEditToggle}>
                                  <SettingsIcon />
                                </Fab>
                              </Tooltip>
                            )}
                        </div>
                      )}
                    </Fragment>
                  )}
                  <h3 className="partner-form-read__title title title_small">
                    {isFirstPanel && partner ? (
                      <div className="f jc-sb loaded">
                        <p>{getPartnerFullName(partner, partnerTypes)}</p>
                        <p className="partner-form-read__manager">{this.getRegionalManager()}</p>
                      </div>
                    ) : (
                      panel.title
                    )}
                  </h3>
                </div>
              </ExpansionPanelSummary>
              {!isFirstPanel && (
                <ExpansionPanelDetails>
                  <div className="partner-form-read__details">
                    <Loader isBlock isFetch={index !== 4 && index !== 5 && !curatorsEditType && isPartnerFetching} />
                    {panel.component}
                  </div>
                </ExpansionPanelDetails>
              )}
            </ExpansionPanel>
          )
        })}
      </form>
    )
  }

  render() {
    return (
      <Form
        initialValues={getInitialPartnerValues(this.props.partner)}
        onSubmit={this.handleFormSubmit}
        render={this.renderForm}
      />
    )
  }
}

export default withRouter(PartnerFormRead)
