import axios from 'axios'

import {handleWithSnackbarError, paramsSerializer} from '../utils/helpres'

const module = 'agreement'

const FETCH_APPROVAL_SHEETS = `${module}/FETCH_APPROVAL_SHEETS`
const FETCH_APPROVAL_SHEETS_SUCCESS = `${module}/FETCH_APPROVAL_SHEETS_SUCCESS`
const FETCH_APPROVAL_SHEETS_FAIL = `${module}/FETCH_APPROVAL_SHEETS_FAIL`

const SET_APPROVAL_SHEETS_SIZE = `${module}/SET_APPROVAL_SHEETS_SIZE`
const SET_APPROVAL_SHEETS_PAGE = `${module}/SET_APPROVAL_SHEETS_PAGE`
const SET_APPROVAL_SHEETS_SORT = `${module}/SET_APPROVAL_SHEETS_SORT`

const FETCH_APPROVAL_SHEET = `${module}/FETCH_APPROVAL_SHEET`
const FETCH_APPROVAL_SHEET_SUCCESS = `${module}/FETCH_APPROVAL_SHEET_SUCCESS`
const FETCH_APPROVAL_SHEET_FAIL = `${module}/FETCH_APPROVAL_SHEET_FAIL`

const SELECT_APPROVAL_SHEET = `${module}/SELECT_APPROVAL_SHEET`

const RESET_APPROVAL_SHEET = `${module}/RESET_APPROVAL_SHEET`

const FETCH_HISTORIC_SHEETS = `${module}/FETCH_HISTORIC_SHEETS`
const FETCH_HISTORIC_SHEETS_SUCCESS = `${module}/FETCH_HISTORIC_SHEETS_SUCCESS`
const FETCH_HISTORIC_SHEETS_FAIL = `${module}/FETCH_HISTORIC_SHEETS_FAIL`

const SET_HISTORIC_SHEETS_SIZE = `${module}/SET_HISTORIC_SHEETS_SIZE`
const SET_HISTORIC_SHEETS_PAGE = `${module}/SET_HISTORIC_SHEETS_PAGE`
const SET_HISTORIC_SHEETS_SORT = `${module}/SET_HISTORIC_SHEETS_SORT`

const AGREE_APPROVAL_SHEET = `${module}/AGREE_APPROVAL_SHEET`
const AGREE_APPROVAL_SHEET_SUCCESS = `${module}/AGREE_APPROVAL_SHEET_SUCCESS`
const AGREE_APPROVAL_SHEET_FAIL = `${module}/AGREE_APPROVAL_SHEET_FAIL`

const SEND_MESSAGE = `${module}/SEND_MESSAGE`
const SEND_MESSAGE_SUCCESS = `${module}/SEND_MESSAGE_SUCCESS`
const SEND_MESSAGE_FAIL = `${module}/SEND_MESSAGE_FAIL`

const initialState = {
  approvalSheets: null,
  isApprovalSheetsFetching: false,
  approvalSheetsError: null,

  approvalSheetsSize: 10,
  approvalSheetsPage: 0,
  approvalSheetsSort: null,

  approvalSheet: null,
  isApprovalSheetFetching: false,
  approvalSheetError: null,

  selectedApprovalSheet: null,

  historicSheets: null,
  isHistoricSheetsFetching: false,
  historicSheetsError: null,

  historicSheetsSize: 10,
  historicSheetsPage: 0,
  historicSheetsSort: null,

  isAgreeSheetFetching: false,
  agreeSheetError: null,

  isSendMessageFetching: false,
  sendMessageError: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_APPROVAL_SHEETS:
      return {...state, isApprovalSheetsFetching: true, approvalSheetsError: null}
    case FETCH_APPROVAL_SHEETS_SUCCESS:
      return {...state, isApprovalSheetsFetching: false, approvalSheets: action.approvalSheets}
    case FETCH_APPROVAL_SHEETS_FAIL:
      return {...state, isApprovalSheetsFetching: false, approvalSheetsError: action.error}

    case SET_APPROVAL_SHEETS_SIZE:
      return {...state, approvalSheetsSize: action.size}
    case SET_APPROVAL_SHEETS_PAGE:
      return {...state, approvalSheetsPage: action.page}
    case SET_APPROVAL_SHEETS_SORT:
      return {...state, approvalSheetsSort: action.sort}

    case FETCH_APPROVAL_SHEET:
      return {...state, isApprovalSheetFetching: true, approvalSheetError: null}
    case FETCH_APPROVAL_SHEET_SUCCESS:
      return {...state, isApprovalSheetFetching: false, approvalSheet: action.approvalSheet}
    case FETCH_APPROVAL_SHEET_FAIL:
      return {...state, isApprovalSheetFetching: false, approvalSheetError: action.error}

    case SELECT_APPROVAL_SHEET:
      return {...state, selectedApprovalSheet: action.selectedApprovalSheet}

    case RESET_APPROVAL_SHEET:
      return {
        ...state,
        approvalSheet: null,
        isApprovalSheetFetching: false,
        approvalSheetError: null,
      }

    case FETCH_HISTORIC_SHEETS:
      return {...state, isHistoricSheetsFetching: true, historicSheetsError: null}
    case FETCH_HISTORIC_SHEETS_SUCCESS:
      return {...state, isHistoricSheetsFetching: false, historicSheets: action.historicSheets}
    case FETCH_HISTORIC_SHEETS_FAIL:
      return {...state, isHistoricSheetsFetching: false, historicSheetsError: action.error}

    case SET_HISTORIC_SHEETS_SIZE:
      return {...state, historicSheetsSize: action.size}
    case SET_HISTORIC_SHEETS_PAGE:
      return {...state, historicSheetsPage: action.page}
    case SET_HISTORIC_SHEETS_SORT:
      return {...state, historicSheetsSort: action.sort}

    case AGREE_APPROVAL_SHEET:
      return {...state, isAgreeSheetFetching: true, agreeSheetError: null}
    case AGREE_APPROVAL_SHEET_SUCCESS:
      return {...state, isAgreeSheetFetching: false}
    case AGREE_APPROVAL_SHEET_FAIL:
      return {...state, isAgreeSheetFetching: false, agreeSheetError: action.error}

    case SEND_MESSAGE:
      return {...state, isSendMessageFetching: true, sendMessageError: null}
    case SEND_MESSAGE_SUCCESS:
      return {...state, isSendMessageFetching: false}
    case SEND_MESSAGE_FAIL:
      return {...state, isSendMessageFetching: false, sendMessageError: action.error}

    default:
      return state
  }
}

export const fetchApprovalSheets = () => (dispatch, getState) => {
  dispatch({type: FETCH_APPROVAL_SHEETS})
  const state = getState().agreement
  const params = {
    size: state.approvalSheetsSize,
    page: state.approvalSheetsPage,
  }
  if (state.approvalSheetsSort) {
    params.sort = `${state.approvalSheetsSort[0]},${state.approvalSheetsSort[1]}`
  }
  axios
    .get('/agreement/approval_sheet', {params, paramsSerializer})
    .then(({data}) => dispatch({type: FETCH_APPROVAL_SHEETS_SUCCESS, approvalSheets: data}))
    .catch(error => handleWithSnackbarError(dispatch, FETCH_APPROVAL_SHEETS_FAIL, error))
}

export const setApprovalSheetsSize = size => ({type: SET_APPROVAL_SHEETS_SIZE, size})
export const setApprovalSheetsPage = page => ({type: SET_APPROVAL_SHEETS_PAGE, page})
export const setApprovalSheetsSort = sort => ({type: SET_APPROVAL_SHEETS_SORT, sort})

export const fetchApprovalSheet = params => dispatch => {
  dispatch({type: FETCH_APPROVAL_SHEET})
  axios
    .get('/agreement/definition', {params})
    .then(({data}) => dispatch({type: FETCH_APPROVAL_SHEET_SUCCESS, approvalSheet: data}))
    .catch(error => handleWithSnackbarError(dispatch, FETCH_APPROVAL_SHEET_FAIL, error))
}

export const selectApprovalSheet = approvalSheet => ({
  type: SELECT_APPROVAL_SHEET,
  selectedApprovalSheet: approvalSheet,
})

export const resetApprovalSheet = () => ({type: RESET_APPROVAL_SHEET})

export const fetchHistoricSheets = () => (dispatch, getState) => {
  dispatch({type: FETCH_HISTORIC_SHEETS})
  const state = getState().agreement
  const params = {
    size: state.historicSheetsSize,
    page: state.historicSheetsPage,
  }
  if (state.historicSheetsSort) {
    params.sort = `${state.historicSheetsSort[0]},${state.historicSheetsSort[1]}`
  }
  axios
    .get('/agreement/approval_sheet/history', {params, paramsSerializer})
    .then(({data}) => dispatch({type: FETCH_HISTORIC_SHEETS_SUCCESS, historicSheets: data}))
    .catch(error => handleWithSnackbarError(dispatch, FETCH_HISTORIC_SHEETS_FAIL, error))
}

export const setHistoricSheetsSize = size => ({type: SET_HISTORIC_SHEETS_SIZE, size})
export const setHistoricSheetsPage = page => ({type: SET_HISTORIC_SHEETS_PAGE, page})
export const setHistoricSheetsSort = sort => ({type: SET_HISTORIC_SHEETS_SORT, sort})

export const agreeApprovalSheet = ({
  approvalSheetId,
  curatorId,
  contractEvent,
  comment,
  documentNumber,
  closeModal,
}) => dispatch => {
  dispatch({type: AGREE_APPROVAL_SHEET})
  axios
    .post(`/agreement/${approvalSheetId}/decision/${curatorId}/${contractEvent}`, comment)
    .then(() => {
      dispatch({type: AGREE_APPROVAL_SHEET_SUCCESS})
      dispatch(fetchApprovalSheets())
      dispatch(selectApprovalSheet(null))
      closeModal()
    })
    .catch(error => handleWithSnackbarError(dispatch, AGREE_APPROVAL_SHEET_FAIL, error))
}

export const sendMessage = ({message, approvalSheet, closeModal}) => dispatch => {
  dispatch({type: SEND_MESSAGE})
  axios
    .post(`/agreement/${approvalSheet.id}/additionalComment`, message)
    .then(() => {
      dispatch({type: SEND_MESSAGE_SUCCESS})
      approvalSheet.document
        ? dispatch(fetchApprovalSheet({document_number: approvalSheet.document.docNumber}))
        : dispatch(fetchHistoricSheets())
      closeModal()
    })
    .catch(error => handleWithSnackbarError(dispatch, SEND_MESSAGE_FAIL, error))
}
