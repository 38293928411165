import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {Form, Field} from 'react-final-form'
import moment from 'moment'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'

import GridContainer from '../../../_common/GridContainer'
import InputField from '../../../_common/InputField'
import {validateReports} from '../../../../utils/validator'
import {focusOnError} from '../../../../utils/helpres'

const styles = {
  dialogContent: {
    paddingTop: 0,
  },
}

const initialValues = {
  date_from: null,
  date_to: null,
}

export default class ActsReportForm extends PureComponent {
  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    isModalOpen: PropTypes.bool.isRequired,

    downloadActsReport: PropTypes.func.isRequired,
    onModalClose: PropTypes.func.isRequired,
  }
  today = moment()

  handleFormSubmit = values => {
    const {downloadActsReport, onModalClose} = this.props
    const params = {
      date_from: moment(values.date_from).format('YYYY-MM-DD'),
      date_to: moment(values.date_to).format('YYYY-MM-DD'),
    }
    downloadActsReport(params, onModalClose)
  }

  render() {
    const {isModalOpen, isFetching, onModalClose} = this.props
    return (
      <Form
        validate={validateReports}
        initialValues={initialValues}
        decorators={[focusOnError]}
        onSubmit={this.handleFormSubmit}
        render={({handleSubmit}) => (
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <DialogContent style={styles.dialogContent}>
              <GridContainer>
                <Grid item xs={6}>
                  <Field
                    isDatePlaceholder
                    name="date_from"
                    label="Период с"
                    type="datePicker"
                    maxDate={this.today}
                    component={InputField}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    isDatePlaceholder
                    name="date_to"
                    label="Период по"
                    type="datePicker"
                    maxDate={this.today}
                    component={InputField}
                  />
                </Grid>
              </GridContainer>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={isModalOpen ? onModalClose : null}>
                Закрыть
              </Button>
              <Button type="submit" color="primary" disabled={!isModalOpen || isFetching}>
                Скачать отчет
              </Button>
            </DialogActions>
          </form>
        )}
      />
    )
  }
}
