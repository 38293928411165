import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'
import moment from 'moment'
import {withStyles} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import PersonIcon from '@material-ui/icons/Person'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import './style.scss'
import ExtendedTable from '../../_common/ExtendedTable'
import AgreementComments from '../../_common/AgreementComments'
import AgreementStages from '../../_common/AgreementStages'
import Modal from '../../_common/Modal'
import Loader from '../../_common/Loader'
import AgreementForm from './AgreementForm'
import MessageForm from '../../_common/MessageForm'
import ROLES, {AGREEMENT_EVENTS} from '../../../utils/constants'
import {THEME} from '../../../styles/muiTheme'
import {isRPRM, isDPRPRM, isAddCommentButton} from '../../../utils/helpres'
import {ROLE_PREFIX} from '../../../utils/constants'
import URLS from '../../../utils/urls'

const headerCells = [
  {title: 'Дата смены статуса', sortName: 'changeDate'},
  {title: 'Дата создания', sortName: 'createdDate'},
  {title: 'Тип документа(ов)', sortName: 'sortContractType'},
  {title: 'Номер документа', sortName: 'documentNumber'},
  {title: 'Партнер', sortName: 'orgName'},
]

const styles = {
  leftButton: {
    marginRight: THEME.UNIT,
    marginBottom: THEME.UNIT,
  },
  rightButton: {
    marginLeft: THEME.UNIT,
    marginBottom: THEME.UNIT,
  },
  icon: {
    marginRight: THEME.UNIT,
  },
}

const initialState = {
  isAgreementModalOpen: false,
  isSendModalOpen: false,
  isModifyModalOpen: false,
  isRejectModalOpen: false,
  isConclusionModalOpen: false,
  isConfirmModalOpen: false,
  isMessageModalOpen: false,
  anchorEl: null,
}

class AgreementContent extends Component {
  state = initialState

  static propTypes = {
    roles: PropTypes.array.isRequired,
    role: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    approvalSheets: PropTypes.object,
    isApprovalSheetsFetching: PropTypes.bool.isRequired,
    approvalSheet: PropTypes.object,
    isApprovalSheetFetching: PropTypes.bool.isRequired,
    approvalSheetsSize: PropTypes.number.isRequired,
    approvalSheetsPage: PropTypes.number.isRequired,
    approvalSheetsSort: PropTypes.array,
    selectedApprovalSheet: PropTypes.number,
    isAgreeSheetFetching: PropTypes.bool.isRequired,
    isSendMessageFetching: PropTypes.bool.isRequired,
    fetchApprovalSheets: PropTypes.func.isRequired,
    fetchApprovalSheet: PropTypes.func.isRequired,
    setApprovalSheetsSize: PropTypes.func.isRequired,
    setApprovalSheetsPage: PropTypes.func.isRequired,
    setApprovalSheetsSort: PropTypes.func.isRequired,
    selectApprovalSheet: PropTypes.func.isRequired,
    resetApprovalSheet: PropTypes.func.isRequired,
    agreeApprovalSheet: PropTypes.func.isRequired,
    sendMessage: PropTypes.func.isRequired,
    downloadFile: PropTypes.func.isRequired,
    fetchDocument: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.props.fetchApprovalSheets()
  }

  componentWillReceiveProps(nextProps) {
    const {
      selectedApprovalSheet,
      approvalSheets,
      approvalSheetsSize,
      approvalSheetsPage,
      approvalSheetsSort,
      fetchApprovalSheets,
      fetchApprovalSheet,
      resetApprovalSheet,
    } = this.props
    if (
      nextProps.approvalSheetsSize !== approvalSheetsSize ||
      nextProps.approvalSheetsPage !== approvalSheetsPage ||
      nextProps.approvalSheetsSort !== approvalSheetsSort
    ) {
      fetchApprovalSheets()
    }
    if (nextProps.selectedApprovalSheet !== selectedApprovalSheet) {
      if (nextProps.selectedApprovalSheet) {
        const documentNumber = approvalSheets.content.find(sheet => sheet.id === nextProps.selectedApprovalSheet)
          .documentNumber
        fetchApprovalSheet({document_number: documentNumber})
      } else {
        resetApprovalSheet()
      }
    }
  }

  handleRowSelect = selectedRow => () => {
    const {selectedApprovalSheet, selectApprovalSheet} = this.props
    selectedApprovalSheet !== selectedRow ? selectApprovalSheet(selectedRow) : selectApprovalSheet(null)
  }

  handleAgreementModalOpen = () => this.setState({isAgreementModalOpen: true})

  handleSendModalOpen = () => this.setState({isSendModalOpen: true})

  handleModifyModalOpen = () => this.setState({isModifyModalOpen: true})

  handleRejectModalOpen = () => this.setState({isRejectModalOpen: true})

  handleConclusionModalOpen = () => this.setState({isConclusionModalOpen: true})

  handleConfirmModalOpen = () => this.setState({isConfirmModalOpen: true})

  handleMessageModalOpen = () => this.setState({isMessageModalOpen: true})

  handleModalClose = () => this.setState(initialState)

  handleGoToPartner = () => {
    const {history, approvalSheet} = this.props
    history.push(`${URLS.PARTNERS}/${approvalSheet.partnerOrgNumber}`)
  }

  handleDocumentDownload = ({id, fileName}) => () => this.props.downloadFile({fileName, documentId: id})

  handleFetchDocument = () => {
    const {approvalSheets, approvalSheet, selectedApprovalSheet, fetchDocument} = this.props
    const currentSheet = approvalSheets.content.find(sheet => sheet.id === selectedApprovalSheet)
    const params = {
      document_number: currentSheet.documentNumber,
    }
    fetchDocument(params, approvalSheet.partnerOrgNumber)
  }

  handleDownloadMenuOpen = e => this.setState({anchorEl: e.currentTarget})

  handleDownloadMenuClose = () => this.setState({anchorEl: null})

  getTableRows() {
    const {approvalSheets} = this.props
    return approvalSheets && approvalSheets.content
      ? approvalSheets.content.map(sheet => ({
          id: sheet.id,
          data: [
            moment(sheet.changeDate).format('DD.MM.YYYY'),
            moment(sheet.createdDate).format('DD.MM.YYYY'),
            <Fragment>
              {sheet.contractType && <p>{Object.values(sheet.contractType)}</p>}
              {sheet.bonusType && Object.values(sheet.bonusType).map((bonus, index) => <p key={index}>{bonus}</p>)}
            </Fragment>,
            sheet.documentNumber,
            sheet.orgName,
          ],
        }))
      : []
  }

  get downloadLinks() {
    const {approvalSheet} = this.props
    return approvalSheet.document.documentFile.map(document => (
      <MenuItem key={document.id} onClick={this.handleDocumentDownload(document)}>
        {document.bonusTypeName || document.contractTypeName} {document.scan && '(сканкопия)'}
      </MenuItem>
    ))
  }

  renderButtons() {
    const {roles, role, user, classes, approvalSheet} = this.props
    const {anchorEl} = this.state
    const files = approvalSheet.document.documentFile
    let agreeButtons = <div />
    const isActiveApprovalSheet = approvalSheet.curators.every(
      curator => !(AGREEMENT_EVENTS.REJECT in curator.contractEvent)
    )
    if (isActiveApprovalSheet) {
      const currentCurator = approvalSheet.curators.find(curator => !curator.isSignature)
      const isRoleRPRM = isRPRM(roles)
      const isRoleDPRPRM = isDPRPRM(roles)
      const shortCurrentCurator = currentCurator.role.replace(ROLE_PREFIX, '')
      const isCuratorRole = currentCurator && role === shortCurrentCurator
      const isADMIN = role === ROLES.ADMIN && currentCurator && currentCurator.adminCanDecide
      const isRP =
        (role === ROLES.RP && isCuratorRole) ||
        ((isRoleRPRM || isRoleDPRPRM) && roles.includes(currentCurator.role) && shortCurrentCurator === ROLES.RP)
      const isRM =
        (role === ROLES.RM && isCuratorRole) ||
        ((isRoleRPRM || isRoleDPRPRM) && roles.includes(currentCurator.role) && shortCurrentCurator === ROLES.RM)
      const isDP =
        (role === ROLES.DP && isCuratorRole) ||
        (isRoleDPRPRM && roles.includes(currentCurator.role) && shortCurrentCurator === ROLES.DP)
      const isU = role === ROLES.U && isCuratorRole
      const isGD = role === ROLES.GD && isCuratorRole
      agreeButtons = (
        <div className="f fw-w">
          {(isRM || isDP || isADMIN) && currentCurator.eventList.includes(AGREEMENT_EVENTS.APPROVE) && (
            <Button
              variant="contained"
              color="primary"
              className={classes.leftButton}
              onClick={this.handleAgreementModalOpen}
            >
              {isRP ? 'Отправить на согласование' : 'Согласовать'}
            </Button>
          )}
          {(isGD || isADMIN) && currentCurator.eventList.includes(AGREEMENT_EVENTS.CONFIRM) && (
            <Button
              variant="contained"
              color="primary"
              className={classes.leftButton}
              onClick={this.handleConfirmModalOpen}
            >
              Согласовать
            </Button>
          )}
          {(isRP || isADMIN) && currentCurator.eventList.includes(AGREEMENT_EVENTS.SEND) && (
            <Button
              variant="contained"
              color="primary"
              className={classes.leftButton}
              onClick={this.handleSendModalOpen}
            >
              Отправить на согласование
            </Button>
          )}
          {(isRP || isADMIN) && currentCurator.eventList.includes(AGREEMENT_EVENTS.RECREATE) && (
            <Button
              variant="contained"
              color="secondary"
              className={classes.leftButton}
              onClick={this.handleFetchDocument}
            >
              Сформировать заново
            </Button>
          )}
          {(isRM || isDP || isGD || isADMIN) && currentCurator.eventList.includes(AGREEMENT_EVENTS.MODIFY) && (
            <Button
              variant="contained"
              color="secondary"
              className={classes.leftButton}
              onClick={this.handleModifyModalOpen}
            >
              Доработать
            </Button>
          )}
          {(isRM || isDP || isGD || isADMIN) && currentCurator.eventList.includes(AGREEMENT_EVENTS.REJECT) && (
            <Button
              variant="outlined"
              color="secondary"
              className={classes.leftButton}
              onClick={this.handleRejectModalOpen}
            >
              Заблокировать партнера
            </Button>
          )}
          {(isU || isADMIN) && currentCurator.eventList.includes(AGREEMENT_EVENTS.CONCLUDE) && (
            <Button
              variant="contained"
              color="primary"
              className={classes.leftButton}
              onClick={this.handleConclusionModalOpen}
            >
              Добавить заключение
            </Button>
          )}
          {isAddCommentButton(approvalSheet, user) && (
            <Button
              variant="outlined"
              color="primary"
              className={classes.leftButton}
              onClick={this.handleMessageModalOpen}
            >
              Добавить комментарий
            </Button>
          )}
        </div>
      )
    }
    return (
      <Fragment>
        {agreeButtons}
        <div className="f jc-fe fw-w">
          <Button
            variant="contained"
            color="secondary"
            className={classes.rightButton}
            onClick={this.handleGoToPartner}
          >
            <PersonIcon className={classes.icon} />
            Карточка партнера
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.rightButton}
            onClick={files.length > 1 ? this.handleDownloadMenuOpen : this.handleDocumentDownload(files[0])}
          >
            <InsertDriveFileIcon className={classes.icon} />
            Сохранить
          </Button>
          {files.length > 1 && (
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleDownloadMenuClose}>
              {this.downloadLinks}
            </Menu>
          )}
        </div>
      </Fragment>
    )
  }

  render() {
    const {
      user,
      approvalSheets,
      approvalSheet,
      isApprovalSheetFetching,
      approvalSheetsSize,
      approvalSheetsPage,
      approvalSheetsSort,
      selectedApprovalSheet,
      isApprovalSheetsFetching,
      isSendMessageFetching,
      isAgreeSheetFetching,
      agreeApprovalSheet,
      sendMessage,
      setApprovalSheetsSize,
      setApprovalSheetsPage,
      setApprovalSheetsSort,
    } = this.props
    const {
      isAgreementModalOpen,
      isSendModalOpen,
      isModifyModalOpen,
      isRejectModalOpen,
      isConclusionModalOpen,
      isConfirmModalOpen,
      isMessageModalOpen,
    } = this.state
    const total = approvalSheets ? approvalSheets.totalElements || 0 : 0
    const curators = approvalSheet ? approvalSheet.curators : null
    return (
      <div className="agreement-content loaded">
        <ExtendedTable
          isInternal
          headerCells={headerCells}
          tableRows={this.getTableRows()}
          isFetching={isApprovalSheetsFetching}
          selected={selectedApprovalSheet}
          total={total}
          rowsPerPage={approvalSheetsSize}
          page={approvalSheetsPage}
          sort={approvalSheetsSort}
          setPage={setApprovalSheetsPage}
          setSize={setApprovalSheetsSize}
          setSort={setApprovalSheetsSort}
          onRowClick={isApprovalSheetFetching ? null : this.handleRowSelect}
        />
        {selectedApprovalSheet && (
          <div className="agreement-content__block p-r">
            <Loader isBlock isFetch={isApprovalSheetFetching} />
            {approvalSheet && (
              <div className="loaded">
                {approvalSheet.approvalSheetComments && approvalSheet.approvalSheetComments.length ? (
                  <AgreementComments curators={curators} comments={approvalSheet.approvalSheetComments} />
                ) : (
                  <div className="mb" />
                )}
                <AgreementStages curators={approvalSheet.curators} />
                <div className="f ai-c jc-sb mt">{this.renderButtons()}</div>
              </div>
            )}
          </div>
        )}
        <Modal isForm open={isAgreementModalOpen} title="Согласование документа" onClose={this.handleModalClose}>
          <AgreementForm
            approvalSheet={approvalSheet}
            isAgreeSheetFetching={isAgreeSheetFetching}
            isModalOpen={isAgreementModalOpen}
            onModalClose={this.handleModalClose}
            agreeApprovalSheet={agreeApprovalSheet}
          />
        </Modal>
        <Modal isForm open={isSendModalOpen} title="Согласование документа" onClose={this.handleModalClose}>
          <AgreementForm
            isSend
            approvalSheet={approvalSheet}
            isAgreeSheetFetching={isAgreeSheetFetching}
            isModalOpen={isSendModalOpen}
            onModalClose={this.handleModalClose}
            agreeApprovalSheet={agreeApprovalSheet}
          />
        </Modal>
        <Modal isForm open={isModifyModalOpen} title="Доработка документа" onClose={this.handleModalClose}>
          <AgreementForm
            isModify
            approvalSheet={approvalSheet}
            isAgreeSheetFetching={isAgreeSheetFetching}
            isModalOpen={isModifyModalOpen}
            onModalClose={this.handleModalClose}
            agreeApprovalSheet={agreeApprovalSheet}
          />
        </Modal>
        <Modal isForm open={isRejectModalOpen} title="Отклонение документа" onClose={this.handleModalClose}>
          <AgreementForm
            isReject
            approvalSheet={approvalSheet}
            isAgreeSheetFetching={isAgreeSheetFetching}
            isModalOpen={isRejectModalOpen}
            onModalClose={this.handleModalClose}
            agreeApprovalSheet={agreeApprovalSheet}
          />
        </Modal>
        <Modal isForm open={isConclusionModalOpen} title="Заключение по документу" onClose={this.handleModalClose}>
          <AgreementForm
            isConclusion
            approvalSheet={approvalSheet}
            isAgreeSheetFetching={isAgreeSheetFetching}
            isModalOpen={isConclusionModalOpen}
            onModalClose={this.handleModalClose}
            agreeApprovalSheet={agreeApprovalSheet}
          />
        </Modal>
        <Modal isForm open={isConfirmModalOpen} title="Согласование документа" onClose={this.handleModalClose}>
          <AgreementForm
            isConfirm
            approvalSheet={approvalSheet}
            isAgreeSheetFetching={isAgreeSheetFetching}
            isModalOpen={isConfirmModalOpen}
            onModalClose={this.handleModalClose}
            agreeApprovalSheet={agreeApprovalSheet}
          />
        </Modal>
        <Modal isForm open={isMessageModalOpen} title="Дополнительный комментарий" onClose={this.handleModalClose}>
          <MessageForm
            user={user}
            approvalSheet={approvalSheet}
            isFetch={isSendMessageFetching}
            isModalOpen={isMessageModalOpen}
            onModalClose={this.handleModalClose}
            sendMessage={sendMessage}
          />
        </Modal>
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(AgreementContent))
