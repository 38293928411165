import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Form} from 'react-final-form'
import createCalculateDecorator from 'final-form-calculate'
import Paper from '@material-ui/core/Paper'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepButton from '@material-ui/core/StepButton'
import Button from '@material-ui/core/Button'

import './style.scss'
import PartnerBasic from '../../_common/PartnerBasic'
import PartnerContacts from '../../_common/PartnerContacts'
import PartnerBank from '../../_common/PartnerBank'
import PartnerWorkers from '../../_common/PartnerWorkers'
import PartnerPersons from '../../_common/PartnerPersons'
import Loader from '../../_common/Loader'
import {
  getInitialPartnerValues,
  isPartnerFederalOrRegionalDistributor,
  isPartnerEngineeringCentre,
} from '../../../utils/helpres'
import {validatePartner} from '../../../utils/validator'
import {scrollTop} from '../../../utils/helpres'
import {PARTNER_STEP_TITLES} from '../../../utils/constants'
import {THEME} from '../../../styles/muiTheme'

const steps = PARTNER_STEP_TITLES

const calculator = createCalculateDecorator(
  {
    field: /currentFederalDistrictId/,
    updates: {
      currentRegionId: () => undefined,
    },
  },
  {
    field: /partnerTypeId/,
    updates: {
      serviceCoordinatorId: (value, {serviceCoordinatorId}) =>
        value === 1 || value === 2 ? undefined : serviceCoordinatorId,
    },
  }
)

const initialState = {
  activeStep: 0,
  completed: new Set(),
  skipped: new Set(),
}

const styles = {
  stepper: {
    marginBottom: THEME.UNIT * 2,
  },
  buttonFirst: {
    marginRight: THEME.UNIT,
  },
}

export default class PartnerForm extends Component {
  state = initialState

  static propTypes = {
    partner: PropTypes.object,
    isPartnerUpdateFetching: PropTypes.bool.isRequired,
    partnerUpdateError: PropTypes.object,
    isPartnerSaveFetching: PropTypes.bool.isRequired,
    partnerSaveError: PropTypes.object,
    partnerTypes: PropTypes.array.isRequired,
    updatePartner: PropTypes.func.isRequired,
    savePartner: PropTypes.func.isRequired,
    onEditToggle: PropTypes.func.isRequired,
  }

  getNewPartner(values) {
    const {partner} = this.props
    const editValues = {
      regionManagerId: null,
      currentFederalDistrictId: null,
      currentRegionId: null,
      partnerTypeId: null,
      vaillantCuratorId: null,
      prothermCuratorId: null,
      serviceCoordinatorId: null,
      bankName: null,
      cAcc: null,
      acc: null,
    }
    const newPartner = {
      ...partner,
      ...editValues,
      ...values,
    }
    if (
      (newPartner.partnerTypeId === 1 || newPartner.partnerTypeId === 2) &&
      newPartner.signaturePartnerCurators.some(curator => curator.isSC)
    ) {
      newPartner.signaturePartnerCurators = newPartner.signaturePartnerCurators.map(curator => ({
        ...curator,
        isSC: false,
      }))
    }
    return newPartner
  }

  handleFormSubmit = values => {
    const {savePartner, onEditToggle} = this.props
    savePartner(this.getNewPartner(values), onEditToggle)
  }

  handlePartnerUpdate = values => () => this.props.updatePartner(this.getNewPartner(values))

  handleNext = () => {
    let activeStep
    if (this.isLastStep() && !this.allStepsCompleted()) {
      activeStep = steps.findIndex((step, i) => !this.state.completed.has(i))
    } else {
      activeStep = this.state.activeStep + 1
    }
    this.setState({activeStep}, scrollTop)
  }

  handleBack = () => this.setState(state => ({activeStep: state.activeStep - 1}), scrollTop)

  handleStep = step => () => this.setState({activeStep: step})

  skippedSteps() {
    return this.state.skipped.size
  }

  isStepSkipped(step) {
    return this.state.skipped.has(step)
  }

  isStepComplete(step) {
    return this.state.completed.has(step)
  }

  completedSteps() {
    return this.state.completed.size
  }

  allStepsCompleted() {
    return this.completedSteps() === steps.length - this.skippedSteps()
  }

  isLastStep() {
    return this.state.activeStep === steps.length - 1
  }

  renderSteps() {
    return steps.map((label, index) => {
      const props = {}
      const buttonProps = {}
      if (this.isStepSkipped(index)) {
        props.completed = false
      }
      return (
        <Step key={label} {...props}>
          <StepButton onClick={this.handleStep(index)} completed={this.isStepComplete(index)} {...buttonProps}>
            {label}
          </StepButton>
        </Step>
      )
    })
  }

  renderForm = ({handleSubmit, values, form}) => {
    const {partner, isPartnerUpdateFetching, isPartnerSaveFetching, partnerTypes, onEditToggle} = this.props
    const {activeStep} = this.state
    const {hasValidationErrors} = form.getState()
    const isFederalOrRegionalDistributor = isPartnerFederalOrRegionalDistributor(values.partnerTypeId)
    const isEngineeringCentre = isPartnerEngineeringCentre(values.partnerTypeId)
    const isSignaturesLimit = isFederalOrRegionalDistributor && partner.signaturePartnerCurators.length > 2
    const isApproveDisabled =
      isSignaturesLimit ||
      hasValidationErrors ||
      !partner.vgrCurator.length ||
      !partner.partnerCuratorContacts.length ||
      !partner.signaturePartnerCurators.length
    const stepContents = [
      <PartnerBasic
        status={partner.status}
        partnerTypes={partnerTypes}
        contractAnnex={values.contractAnnex}
        isEngineeringCentre={isEngineeringCentre}
        isFederalOrRegionalDistributor={isFederalOrRegionalDistributor}
        currentFederalDistrictIdValue={values.currentFederalDistrictId}
        regionManagerIdValue={values.regionManagerId}
        vaillantCuratorIdValue={values.vaillantCuratorId}
        prothermCuratorIdValue={values.prothermCuratorId}
        serviceCoordinatorIdValue={values.serviceCoordinatorId}
      />,
      <PartnerContacts />,
      <PartnerBank />,
      <PartnerWorkers />,
      <PartnerPersons
        isFederalOrRegionalDistributor={isFederalOrRegionalDistributor}
        isSignaturesLimit={isSignaturesLimit}
      />,
    ]
    return (
      <form noValidate className="partner-form" autoComplete="off" onSubmit={handleSubmit}>
        <Loader isFetch={isPartnerUpdateFetching || isPartnerSaveFetching} />
        <Stepper alternativeLabel nonLinear activeStep={activeStep} style={styles.stepper}>
          {this.renderSteps()}
        </Stepper>
        <div className="partner-form__content block">
          {stepContents[activeStep]}
          <div className="f jc-sb mt">
            <div>
              <Button
                variant="outlined"
                onClick={this.handleBack}
                style={styles.buttonFirst}
                disabled={activeStep === 0}
              >
                Назад
              </Button>
              <Button variant="outlined" onClick={this.handleNext} disabled={activeStep === steps.length - 1}>
                Вперед
              </Button>
            </div>
            <div>
              <Button
                type={isApproveDisabled ? null : 'submit'}
                variant="contained"
                color="primary"
                style={styles.buttonFirst}
                onClick={isApproveDisabled ? this.handlePartnerUpdate(values) : null}
                disabled={isSignaturesLimit || isPartnerUpdateFetching || isPartnerSaveFetching}
              >
                Сохранить
              </Button>
              <Button variant="outlined" color="secondary" onClick={onEditToggle}>
                Закрыть
              </Button>
            </div>
          </div>
        </div>
      </form>
    )
  }

  render() {
    return (
      <Paper>
        <Form
          initialValues={getInitialPartnerValues(this.props.partner)}
          validate={validatePartner}
          decorators={[calculator]}
          onSubmit={this.handleFormSubmit}
          render={this.renderForm}
        />
      </Paper>
    )
  }
}
