import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import MenuItem from '@material-ui/core/MenuItem'

import {
  fetchPartnerActs,
  setPartnerActsSize,
  setPartnerActsPage,
  setPartnerActsSort,
  resetPartnerActs,
  selectAct,
  recalculateAct,
  fetchActPrize,
  downloadAct,
  changeActStatus,
  resetAct,
} from '../../redux/acts'
import withModal from '../_decorators/withModal'
import ExtendedTable from '../_common/ExtendedTable'
import Prizes from './Prizes'
import Loader from '../_common/Loader'
import Modal from '../_common/Modal'
import PartnerActsActions from './PartnerActActions'
import {ACT_STATUSES, ACT_STATUSES_FOR_SELECT} from '../../utils/constants'
import URLS from '../../utils/urls'

const headerCells = [
  {title: 'Номер акта', sortName: 'actNumber'},
  {title: 'Статус акта', sortName: 'actStatus'},
  {title: 'Наличие Sell-Out', sortName: 'premiumStatProc'},
  {title: 'Период'},
  {title: 'Действие'},
]

const icHeaderCells = [...headerCells]
icHeaderCells.splice(2, 1)

class PartnerActs extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    partnerActs: PropTypes.object,
    isPartnerActsFetching: PropTypes.bool.isRequired,
    partnerActsSize: PropTypes.number.isRequired,
    partnerActsPage: PropTypes.number.isRequired,
    partnerActsSort: PropTypes.array,
    selected: PropTypes.object,
    isActRecalculate: PropTypes.bool.isRequired,
    actPrize: PropTypes.object,
    isActPrizeFetching: PropTypes.bool.isRequired,
    isActStatusChange: PropTypes.bool.isRequired,
    isActDownload: PropTypes.bool.isRequired,
    isModalOpen: PropTypes.bool.isRequired,

    fetchPartnerActs: PropTypes.func.isRequired,
    setPartnerActsSize: PropTypes.func.isRequired,
    setPartnerActsPage: PropTypes.func.isRequired,
    setPartnerActsSort: PropTypes.func.isRequired,
    resetPartnerActs: PropTypes.func.isRequired,
    selectAct: PropTypes.func.isRequired,
    recalculateAct: PropTypes.func.isRequired,
    fetchActPrize: PropTypes.func.isRequired,
    downloadAct: PropTypes.func.isRequired,
    changeActStatus: PropTypes.func.isRequired,
    resetAct: PropTypes.func.isRequired,
    onModalToggle: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const {
      match: {params},
      fetchPartnerActs,
    } = this.props
    fetchPartnerActs(params.id)
  }

  componentWillReceiveProps(nextProps) {
    const {
      match: {params},
      partnerActsSize,
      partnerActsPage,
      partnerActsSort,
      fetchPartnerActs,
    } = this.props
    if (
      nextProps.partnerActsSize !== partnerActsSize ||
      nextProps.partnerActsPage !== partnerActsPage ||
      nextProps.partnerActsSort !== partnerActsSort
    ) {
      fetchPartnerActs(params.id)
    }
  }

  componentWillUnmount() {
    this.props.resetPartnerActs()
  }

  handleGoToActs = () => this.props.history.push(URLS.ACTS)

  get statusOptions() {
    return Object.keys(ACT_STATUSES_FOR_SELECT).map(key => (
      <MenuItem key={key} value={key} disabled={key === 'READY_TO_PRINT'}>
        {ACT_STATUSES_FOR_SELECT[key]}
      </MenuItem>
    ))
  }

  handleStatusChange = id => e => {
    const {
      match: {params},
      changeActStatus,
    } = this.props
    changeActStatus(id, e.target.value.toLowerCase(), params.id)
  }

  get isICAct() {
    const {partnerActs} = this.props
    return partnerActs && partnerActs.content[0].actType.startsWith('IC')
  }

  getTableRows() {
    const {
      match: {params},
      partnerActs,
      recalculateAct,
      selectAct,
      downloadAct,
      onModalToggle,
    } = this.props
    return (
      partnerActs &&
      partnerActs.content.map(act => {
        const data = [
          act.actNumber,
          act.actStatus === 'READY_TO_PRINT' ||
          act.actStatus === 'ON_SIGN' ||
          act.actStatus === 'ON_PAYMENT' ||
          act.actStatus === 'PAID' ? (
            <Select
              variant="outlined"
              value={act.actStatus}
              input={<OutlinedInput fullWidth name="age" id="age-helper" margin="dense" labelWidth={0} />}
              onChange={this.handleStatusChange(act.id)}
            >
              {this.statusOptions}
            </Select>
          ) : (
            ACT_STATUSES[act.actStatus]
          ),
          act.hasSellOut ? 'Да' : 'Нет',
          act.period,
          <PartnerActsActions
            act={act}
            orgNumber={params.id}
            selectAct={selectAct}
            recalculateAct={recalculateAct}
            downloadAct={downloadAct}
            onPrizesOpen={onModalToggle}
          />,
        ]
        this.isICAct && data.splice(2, 1)
        return {
          id: act.id,
          data,
        }
      })
    )
  }

  render() {
    const {
      match: {params},
      partnerActs,
      isPartnerActsFetching,
      partnerActsPage,
      partnerActsSize,
      partnerActsSort,
      selected,
      isActRecalculate,
      actPrize,
      isActPrizeFetching,
      isActStatusChange,
      isActDownload,
      isModalOpen,

      setPartnerActsPage,
      setPartnerActsSize,
      setPartnerActsSort,
      fetchActPrize,
      changeActStatus,
      resetAct,
      onModalToggle,
    } = this.props
    const total = partnerActs ? partnerActs.total : 0
    return (
      <>
        <section className="container loaded">
          <Loader isFetch={isActRecalculate || isActDownload || isActStatusChange} />
          <Paper>
            <div className="block">
              <div className="mb">
                <Button variant="outlined" onClick={this.handleGoToActs}>
                  Назад
                </Button>
              </div>
              <ExtendedTable
                isInternal
                headerCells={this.isICAct ? icHeaderCells : headerCells}
                tableRows={this.getTableRows()}
                isFetching={isPartnerActsFetching}
                total={total}
                rowsPerPage={partnerActsSize}
                page={partnerActsPage}
                sort={partnerActsSort}
                setPage={setPartnerActsPage}
                setSize={setPartnerActsSize}
                setSort={setPartnerActsSort}
              />
            </div>
          </Paper>
        </section>
        <Modal isForm open={isModalOpen} title="Премии" maxWidth="md" onClose={onModalToggle}>
          <Prizes
            isOpen={isModalOpen}
            selected={selected}
            orgNumber={params.id}
            actPrize={actPrize}
            isActStatusChange={isActStatusChange}
            isActPrizeFetching={isActPrizeFetching}
            fetchActPrize={fetchActPrize}
            changeActStatus={changeActStatus}
            resetAct={resetAct}
            onClose={onModalToggle}
          />
        </Modal>
      </>
    )
  }
}

const mapStateToProps = ({acts}) => ({
  partnerActs: acts.partnerActs,
  isPartnerActsFetching: acts.isPartnerActsFetching,
  partnerActsSize: acts.partnerActsSize,
  partnerActsPage: acts.partnerActsPage,
  partnerActsSort: acts.partnerActsSort,
  selected: acts.selected,
  isActStatusChange: acts.isActStatusChange,
  isSellInDownload: acts.isSellInDownload,
  isActRecalculate: acts.isActRecalculate,
  actPrize: acts.actPrize,
  isActPrizeFetching: acts.isActPrizeFetching,
  isActDownload: acts.isActDownload,
})

const mapDispatchToProps = {
  fetchPartnerActs,
  setPartnerActsSize,
  setPartnerActsPage,
  setPartnerActsSort,
  resetPartnerActs,
  selectAct,
  recalculateAct,
  fetchActPrize,
  downloadAct,
  changeActStatus,
  resetAct,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withModal(PartnerActs))
