const ROLES = {
  ADMIN: 'ADMIN',
  DP: 'DIRECTOR_OF_SALES',
  P: 'PARTNER',
  RM: 'REGIONAL_MANAGER',
  RP: 'REGIONAL_REPRESENTATIVE',
  SC: 'SERVICE_COORDINATOR',
  GD: 'GENERAL_DIRECTOR',
  U: 'LAWYER',
}

export const ROLE_PREFIX = 'ROLE_'

export const PARTNER_STEP_TITLES = [
  'Основная информация',
  'Контактная информация',
  'Банковские реквизиты',
  'Контактные лица VGR',
  'Контактные лица партнера',
]

export const STATUSES = {
  DOWNLOADED: null,
  DRAFT: 'DRAFT',
  DRAFT_CONFIRMED: 'DRAFT_CONFIRMED',
  CREATED: 'CREATED_CONTRACT',
  AGREEMENT_CONTRACT: 'ON_AGREEMENT_CONTRACT',
  CONFIRMED_CONTRACT: 'CONFIRMED_CONTRACT',
  SIGNED_CONTRACT: 'SIGNED_CONTRACT',
  REJECT: 'REJECT',
}

export const AGREEMENT_EVENTS = {
  APPROVE: 'TO_APPROVE',
  SEND: 'SUBMIT_FOR_APPROVAL',
  MODIFY: 'SEND_FOR_REVISION',
  REJECT: 'REJECT',
  CONCLUDE: 'ADD_A_CONCLUSION',
  RECREATE: 'RE_CREATE',
  CONFIRM: 'CONFIRM',
}

export const ACT_STATUSES = {
  CREATED: 'Акт сформирован',
  PARTNER_CONFIRMATION: 'На подтверждении у партнера',
  READY_TO_PRINT: 'Готов к печати партнером',
  ON_SIGN: 'На подписи',
  ON_PAYMENT: 'Передан на оплату',
  PAID: 'Оплачен',
}
export const ACT_STATUSES_FOR_SELECT = {
  READY_TO_PRINT: 'Готов к печати партнером',
  ON_SIGN: 'На подписи',
  ON_PAYMENT: 'Передан на оплату',
  PAID: 'Оплачен',
}

export const SERVER_ERROR_MSG = 'Ошибка соединения с сервером'

export default ROLES
