import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom'

import {resetPartner} from '../../redux/partners'
import {fetchPartnerTypes} from '../../redux/references'
import {
  recreateDocument,
  recreateBonus,
  fetchDocumentReferences,
  fetchDocumentBonus,
  resetDocumentBonus,
  fetchDocumentNumber,
  resetDocumentNumber,
  resetDocuments,
} from '../../redux/documents'
import DocumentForm from '../_common/DocumentForm'
import URLS from '../../utils/urls'

class Document extends Component {
  static propTypes = {
    isPartner: PropTypes.bool.isRequired,
    isPartnerTypes: PropTypes.bool.isRequired,
    document: PropTypes.object,
    isDocumentRecreateFetching: PropTypes.bool.isRequired,
    documentNumber: PropTypes.string,
    isDocumentNumberFetching: PropTypes.bool.isRequired,
    documentReferences: PropTypes.object,
    isDocumentReferencesFetching: PropTypes.bool.isRequired,
    documentBonus: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isDocumentBonusFetching: PropTypes.bool.isRequired,
    fetchPartnerTypes: PropTypes.func.isRequired,
    recreateDocument: PropTypes.func.isRequired,
    recreateBonus: PropTypes.func.isRequired,
    fetchDocumentReferences: PropTypes.func.isRequired,
    fetchDocumentBonus: PropTypes.func.isRequired,
    resetDocumentBonus: PropTypes.func.isRequired,
    fetchDocumentNumber: PropTypes.func.isRequired,
    resetDocumentNumber: PropTypes.func.isRequired,
    resetPartner: PropTypes.func.isRequired,
    resetDocuments: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const {isPartnerTypes, fetchPartnerTypes} = this.props
    !isPartnerTypes && fetchPartnerTypes()
  }

  componentWillUnmount() {
    const {resetPartner, resetDocuments} = this.props
    resetPartner()
    resetDocuments()
  }

  render() {
    const {
      isPartner,
      isPartnerTypes,
      document,
      isDocumentRecreateFetching,
      documentNumber,
      isDocumentNumberFetching,
      documentReferences,
      isDocumentReferencesFetching,
      documentBonus,
      isDocumentBonusFetching,
      recreateDocument,
      recreateBonus,
      fetchDocumentReferences,
      fetchDocumentBonus,
      resetDocumentBonus,
      fetchDocumentNumber,
      resetDocumentNumber,
    } = this.props
    if (!document) return <Redirect to={URLS.AGREEMENT} />
    return (
      <section className="container loaded">
        <DocumentForm
          isRecreate
          isPartner={isPartner}
          isPartnerTypes={isPartnerTypes}
          document={document}
          documentNumber={documentNumber}
          isDocumentNumberFetching={isDocumentNumberFetching}
          documentReferences={documentReferences}
          isDocumentReferencesFetching={isDocumentReferencesFetching}
          documentBonus={documentBonus}
          isDocumentBonusFetching={isDocumentBonusFetching}
          isDocumentSaveFetching={isDocumentRecreateFetching}
          fetchDocumentReferences={fetchDocumentReferences}
          fetchDocumentBonus={fetchDocumentBonus}
          resetDocumentBonus={resetDocumentBonus}
          fetchDocumentNumber={fetchDocumentNumber}
          resetDocumentNumber={resetDocumentNumber}
          saveDocument={recreateDocument}
          saveBonus={recreateBonus}
        />
      </section>
    )
  }
}

const mapStateToProps = ({partners, references, documents}) => ({
  isPartner: Boolean(partners.partner),
  isPartnerTypes: Boolean(references.partnerTypes),
  document: documents.document,
  isDocumentRecreateFetching: documents.isDocumentRecreateFetching,
  documentNumber: documents.documentNumber,
  isDocumentNumberFetching: documents.isDocumentNumberFetching,
  documentReferences: documents.documentReferences,
  isDocumentReferencesFetching: documents.isDocumentReferencesFetching,
  documentBonus: documents.documentBonus,
  isDocumentBonusFetching: documents.isDocumentBonusFetching,
})

const mapDispatchToProps = {
  fetchPartnerTypes,
  resetPartner,
  recreateDocument,
  recreateBonus,
  fetchDocumentReferences,
  fetchDocumentBonus,
  resetDocumentBonus,
  fetchDocumentNumber,
  resetDocumentNumber,
  resetDocuments,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Document)
