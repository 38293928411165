import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {
  fetchAdminSellOut,
  setAdminSellOutSize,
  setAdminSellOutPage,
  setAdminSellOutSort,
  setAdminSellOutSearch,
} from '../../redux/reports'
import ExtendedTable from '../_common/ExtendedTable'
import AdminSellOutSearchForm from './AdminSellOutSearchForm'
import URLS from '../../utils/urls'

const headerCells = [
  {title: 'Идентификатор', sortName: 'orgNumber'},
  {title: 'Название', sortName: 'orgName'},
  {title: 'ИНН', sortName: 'inn'},
  {title: 'ФО', sortName: 'federalDistrict'},
  {title: 'Регион', sortName: 'region'},
]

class AdminSellOut extends Component {
  static propTypes = {
    adminSellOut: PropTypes.object,
    isAdminSellOutFetching: PropTypes.bool.isRequired,
    adminSellOutSize: PropTypes.number.isRequired,
    adminSellOutPage: PropTypes.number.isRequired,
    adminSellOutSort: PropTypes.array,
    adminSellOutSearch: PropTypes.string.isRequired,

    fetchAdminSellOut: PropTypes.func.isRequired,
    setAdminSellOutSize: PropTypes.func.isRequired,
    setAdminSellOutPage: PropTypes.func.isRequired,
    setAdminSellOutSort: PropTypes.func.isRequired,
    setAdminSellOutSearch: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.props.fetchAdminSellOut()
  }

  componentWillReceiveProps(nextProps) {
    const {adminSellOutSize, adminSellOutPage, adminSellOutSort, adminSellOutSearch, fetchAdminSellOut} = this.props
    if (
      nextProps.adminSellOutSize !== adminSellOutSize ||
      nextProps.adminSellOutPage !== adminSellOutPage ||
      nextProps.adminSellOutSort !== adminSellOutSort
    ) {
      fetchAdminSellOut()
    }
    if (!nextProps.adminSellOutSearch && nextProps.adminSellOutSearch !== adminSellOutSearch) {
      fetchAdminSellOut()
    }
  }

  handleGoToPartner = id => () => this.props.history.push(`${URLS.SELL_OUT}/${id}`)

  getTableRows() {
    const {adminSellOut} = this.props
    return (
      adminSellOut &&
      adminSellOut.content.map(partner => ({
        id: partner.orgNumber,
        data: [partner.orgNumber, partner.orgName, partner.inn, partner.federalDistrict, partner.region],
      }))
    )
  }

  render() {
    const {
      adminSellOut,
      isAdminSellOutFetching,
      adminSellOutSize,
      adminSellOutPage,
      adminSellOutSort,
      adminSellOutSearch,
      fetchAdminSellOut,
      setAdminSellOutSize,
      setAdminSellOutPage,
      setAdminSellOutSort,
      setAdminSellOutSearch,
    } = this.props
    const total = adminSellOut ? adminSellOut.totalElements : 0
    return (
      <section className="container loaded">
        <AdminSellOutSearchForm
          placeholder="Введите номер или название партнера"
          search={adminSellOutSearch}
          isFetching={isAdminSellOutFetching}
          setSearch={setAdminSellOutSearch}
          fetchData={fetchAdminSellOut}
        />
        <ExtendedTable
          headerCells={headerCells}
          tableRows={this.getTableRows()}
          isFetching={isAdminSellOutFetching}
          total={total}
          rowsPerPage={adminSellOutSize}
          page={adminSellOutPage}
          sort={adminSellOutSort}
          setPage={setAdminSellOutPage}
          setSize={setAdminSellOutSize}
          setSort={setAdminSellOutSort}
          onRowClick={this.handleGoToPartner}
        />
      </section>
    )
  }
}

const mapStateToProps = ({reports}) => ({
  adminSellOut: reports.adminSellOut,
  isAdminSellOutFetching: reports.isAdminSellOutFetching,
  adminSellOutSize: reports.adminSellOutSize,
  adminSellOutPage: reports.adminSellOutPage,
  adminSellOutSort: reports.adminSellOutSort,
  adminSellOutSearch: reports.adminSellOutSearch,
})

const mapDispatchToProps = {
  fetchAdminSellOut,
  setAdminSellOutSize,
  setAdminSellOutPage,
  setAdminSellOutSort,
  setAdminSellOutSearch,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminSellOut)
