import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import moment from 'moment'

import {fetchHistory, setHistorySize, setHistoryPage, setHistorySort} from '../../redux/history'
import ExtendedTable from '../_common/ExtendedTable'

const headerCells = [
  {title: 'Пользователь', sortName: 'uploadedUser'},
  {title: 'Дата', sortName: 'lastModifiedDate'},
  {title: 'Название файла', sortName: 'fileName'},
  {title: 'Новых записей', sortName: 'persisted'},
  {title: 'Обновленных записей', sortName: 'updated'},
  {title: 'Записей с ошибкой', sortName: 'hasError'},
]

class History extends Component {
  static propTypes = {
    history: PropTypes.object,
    isHistoryFetching: PropTypes.bool.isRequired,
    historyError: PropTypes.object,
    historySize: PropTypes.number.isRequired,
    historyPage: PropTypes.number.isRequired,
    historySort: PropTypes.array,
    fetchHistory: PropTypes.func.isRequired,
    setHistorySize: PropTypes.func.isRequired,
    setHistoryPage: PropTypes.func.isRequired,
    setHistorySort: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.props.fetchHistory()
  }

  componentWillReceiveProps(nextProps) {
    const {historySize, historyPage, historySort, fetchHistory} = this.props
    if (
      nextProps.historySize !== historySize ||
      nextProps.historyPage !== historyPage ||
      nextProps.historySort !== historySort
    ) {
      fetchHistory()
    }
  }

  getTableRows() {
    const {history} = this.props
    return (
      history &&
      history.content.map(item => ({
        id: item.guid,
        data: [
          item.uploadedUser,
          <span className="ws-nw">{moment(item.lastModifiedDate).format('DD.MM.YYYY HH:mm')}</span>,
          item.fileName,
          item.persisted,
          item.updated,
          item.hasError,
        ],
      }))
    )
  }

  render() {
    const {
      history,
      isHistoryFetching,
      historySize,
      historyPage,
      historySort,
      setHistorySize,
      setHistoryPage,
      setHistorySort,
    } = this.props
    const total = history ? history.totalElements : 0
    return (
      <section className="container loaded">
        <ExtendedTable
          headerCells={headerCells}
          tableRows={this.getTableRows()}
          isFetching={isHistoryFetching}
          total={total}
          rowsPerPage={historySize}
          page={historyPage}
          sort={historySort}
          setSize={setHistorySize}
          setPage={setHistoryPage}
          setSort={setHistorySort}
        />
      </section>
    )
  }
}

const mapStateToProps = ({history}) => ({
  history: history.history,
  isHistoryFetching: history.isHistoryFetching,
  historyError: history.historyError,
  historySize: history.historySize,
  historyPage: history.historyPage,
  historySort: history.historySort,
})

const mapDispatchToProps = {
  fetchHistory,
  setHistorySize,
  setHistoryPage,
  setHistorySort,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(History)
