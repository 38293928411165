import axios from 'axios'

import {openSuccessSnackbar} from './ui'
import {handleWithSnackbarError} from '../utils/helpres'

const module = 'catalogue'

const FETCH_CATALOGUE = `${module}/FETCH_CATALOGUE`
const FETCH_CATALOGUE_SUCCESS = `${module}/FETCH_CATALOGUE_SUCCESS`
const FETCH_CATALOGUE_FAIL = `${module}/FETCH_CATALOGUE_FAIL`

const DOWNLOAD_BRANCH = `${module}/DOWNLOAD_BRANCH`
const DOWNLOAD_BRANCH_SUCCESS = `${module}/DOWNLOAD_BRANCH_SUCCESS`
const DOWNLOAD_BRANCH_FAIL = `${module}/DOWNLOAD_BRANCH_FAIL`

const DOWNLOAD_VENDOR_EXCLUSION = `${module}/DOWNLOAD_VENDOR_EXCLUSION`
const DOWNLOAD_VENDOR_EXCLUSION_SUCCESS = `${module}/DOWNLOAD_VENDOR_EXCLUSION_SUCCESS`
const DOWNLOAD_VENDOR_EXCLUSION_FAIL = `${module}/DOWNLOAD_VENDOR_EXCLUSION_FAIL`

const DOWNLOAD_VENDOR = `${module}/DOWNLOAD_VENDOR`
const DOWNLOAD_VENDOR_SUCCESS = `${module}/DOWNLOAD_VENDOR_SUCCESS`
const DOWNLOAD_VENDOR_FAIL = `${module}/DOWNLOAD_VENDOR_FAIL`

const DOWNLOAD_SELL_IN = `${module}/DOWNLOAD_SELL_IN`
const DOWNLOAD_SELL_IN_SUCCESS = `${module}/DOWNLOAD_SELL_IN_SUCCESS`
const DOWNLOAD_SELL_IN_FAIL = `${module}/DOWNLOAD_SELL_IN_FAIL`

const initialState = {
  catalogue: null,
  isCatalogueFetching: false,

  isBranchDownload: false,

  isVendorExclusionDownload: false,

  isVendorDownload: false,

  isSellInDownload: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CATALOGUE:
      return {...state, isCatalogueFetching: true}
    case FETCH_CATALOGUE_SUCCESS:
      return {...state, isCatalogueFetching: false, catalogue: action.catalogue.catalogueMetadata}
    case FETCH_CATALOGUE_FAIL:
      return {...state, isCatalogueFetching: false}

    case DOWNLOAD_BRANCH:
      return {...state, isBranchDownload: true}
    case DOWNLOAD_BRANCH_SUCCESS:
    case DOWNLOAD_BRANCH_FAIL:
      return {...state, isBranchDownload: false}

    case DOWNLOAD_VENDOR_EXCLUSION:
      return {...state, isVendorExclusionDownload: true}
    case DOWNLOAD_VENDOR_EXCLUSION_SUCCESS:
    case DOWNLOAD_VENDOR_EXCLUSION_FAIL:
      return {...state, isVendorExclusionDownload: false}

    case DOWNLOAD_VENDOR:
      return {...state, isVendorDownload: true}
    case DOWNLOAD_VENDOR_SUCCESS:
    case DOWNLOAD_VENDOR_FAIL:
      return {...state, isVendorDownload: false}

    case DOWNLOAD_SELL_IN:
      return {...state, isSellInDownload: true}
    case DOWNLOAD_SELL_IN_SUCCESS:
    case DOWNLOAD_SELL_IN_FAIL:
      return {...state, isSellInDownload: false}

    default:
      return state
  }
}

export const fetchCatalogue = () => dispatch => {
  dispatch({type: FETCH_CATALOGUE})
  axios
    .get('/catalogue/metadata')
    .then(({data}) => dispatch({type: FETCH_CATALOGUE_SUCCESS, catalogue: data}))
    .catch(error => handleWithSnackbarError(dispatch, FETCH_CATALOGUE_FAIL, error))
}

export const downloadBranch = files => dispatch => {
  if (!files.length) return
  const fd = new FormData()
  fd.append('file', files[0])
  dispatch({type: DOWNLOAD_BRANCH})
  axios
    .post('/catalogue/branch', fd, {
      contentType: 'multipart/form-data',
    })
    .then(() => {
      dispatch({type: DOWNLOAD_BRANCH_SUCCESS})
      dispatch(openSuccessSnackbar(`Справочник "${files[0].name}" загружен`))
      dispatch(fetchCatalogue())
    })
    .catch(error => handleWithSnackbarError(dispatch, DOWNLOAD_BRANCH_FAIL, error))
}

export const downloadVendorExclusion = (catalogueType, files) => dispatch => {
  if (!files.length) return
  const fd = new FormData()
  fd.append('file', files[0])
  dispatch({type: DOWNLOAD_VENDOR_EXCLUSION})
  axios
    .post(`/catalogue/vendorCodeExclusion/${catalogueType}`, fd, {
      contentType: 'multipart/form-data',
    })
    .then(() => {
      dispatch(openSuccessSnackbar(`Справочник "${files[0].name}" загружен`))
      dispatch({type: DOWNLOAD_VENDOR_EXCLUSION_SUCCESS})
      dispatch(fetchCatalogue())
    })
    .catch(error => handleWithSnackbarError(dispatch, DOWNLOAD_VENDOR_EXCLUSION_FAIL, error))
}

export const downloadVendor = (catalogueType, files) => dispatch => {
  if (!files.length) return
  const fd = new FormData()
  fd.append('file', files[0])
  dispatch({type: DOWNLOAD_VENDOR})
  axios
    .post(`/catalogue/vendorCode/${catalogueType}`, fd, {
      contentType: 'multipart/form-data',
    })
    .then(() => {
      dispatch({type: DOWNLOAD_VENDOR_SUCCESS})
      dispatch(openSuccessSnackbar(`Справочник "${files[0].name}" загружен`))
      dispatch(fetchCatalogue())
    })
    .catch(error => handleWithSnackbarError(dispatch, DOWNLOAD_VENDOR_FAIL, error))
}

export const downloadSellIn = files => dispatch => {
  if (!files.length) return
  const fd = new FormData()
  fd.append('file', files[0])
  dispatch({type: DOWNLOAD_SELL_IN})
  axios
    .post('/catalogue/sellInExclusion', fd, {
      contentType: 'multipart/form-data',
    })
    .then(() => {
      dispatch({type: DOWNLOAD_SELL_IN_SUCCESS})
      dispatch(openSuccessSnackbar(`Справочник "${files[0].name}" загружен`))
      dispatch(fetchCatalogue())
    })
    .catch(error => handleWithSnackbarError(dispatch, DOWNLOAD_SELL_IN_FAIL, error))
}
