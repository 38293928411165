import React, {Component} from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'

import Loader from '../../_common/Loader'

const prepaymentProc = 2

const StyledDialogContent = withStyles({
  root: {
    '&:first-child': {
      paddingTop: 0,
    },
  },
})(DialogContent)

export default class Prizes extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    selected: PropTypes.object,
    orgNumber: PropTypes.string.isRequired,
    actPrize: PropTypes.object,
    isActStatusChange: PropTypes.bool.isRequired,
    isActPrizeFetching: PropTypes.bool.isRequired,

    fetchActPrize: PropTypes.func.isRequired,
    changeActStatus: PropTypes.func.isRequired,
    resetAct: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const {fetchActPrize} = this.props
    fetchActPrize()
  }

  componentWillUnmount() {
    this.props.resetAct()
  }

  handleActApprove = () => {
    const {selected, orgNumber, changeActStatus, onClose} = this.props
    changeActStatus(selected.id, 'approveA', orgNumber, onClose)
  }

  getTotalRDPrize() {
    const {actPrize} = this.props
    return (+actPrize.premiumRub + +actPrize.premiumStatRub).toFixed(2)
  }

  getTotalICPrize(prepaymentRub) {
    const {actPrize} = this.props
    return (
      +actPrize.premiumRub +
      +actPrize.innovationRub +
      +actPrize.condensationRub +
      +(actPrize.goldRub || 0) +
      prepaymentRub
    ).toFixed(2)
  }

  render() {
    const {isOpen, selected, actPrize, isActPrizeFetching, isActStatusChange, onClose} = this.props
    const isCreated = selected.actStatus === 'CREATED'
    const prepaymentRub = actPrize ? (actPrize.prepayment * prepaymentProc) / 100 : null
    return (
      <>
        <StyledDialogContent>
          <div className="internal-table">
            <Loader isBlock isFetch={isActPrizeFetching} />
            <Table>
              <TableBody>
                {selected.actType === 'RD_QUARTER' ? (
                  <>
                    <TableRow>
                      <TableCell>
                        <strong>Бонусируемый оборот, руб без НДС</strong>
                      </TableCell>
                      <TableCell colSpan={2}>{actPrize && actPrize.bonus}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>Позиция начисления</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Размер премии, %</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Сумма премии, руб без НДС</strong>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Оборотная премия</TableCell>
                      <TableCell>{actPrize && actPrize.premiumProc}</TableCell>
                      <TableCell>{actPrize && actPrize.premiumRub}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Дополнительная премия</TableCell>
                      <TableCell>{actPrize && actPrize.premiumStatProc}</TableCell>
                      <TableCell>{actPrize && actPrize.premiumStatRub}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <strong>Итого:</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{actPrize && this.getTotalRDPrize()}</strong>
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  <>
                    {selected.actType === 'IC_ANNUAL' && (
                      <TableRow>
                        <TableCell>
                          <strong>Бонусируемый оборот за {selected.period.split(' ')[2]} год, руб без НДС</strong>
                        </TableCell>
                        <TableCell colSpan={2}>{actPrize && actPrize.bonus_annual}</TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell>
                        <strong>Бонусируемый оборот за отчетный период, руб без НДС</strong>
                      </TableCell>
                      <TableCell colSpan={2}>{actPrize && actPrize.bonus}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>Оборот (Инновац. оборудования), руб без НДС</strong>
                      </TableCell>
                      <TableCell colSpan={2}>{actPrize && actPrize.innovation}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>Оборот (Конденс. оборудования), руб без НДС</strong>
                      </TableCell>
                      <TableCell colSpan={2}>{actPrize && actPrize.condensation}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>Сумма предоплачен. и отгружен. товара в указанный период, руб без НДС</strong>
                      </TableCell>
                      <TableCell colSpan={2}>{actPrize && actPrize.prepayment}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong>Позиция начисления</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Размер премии, %</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Сумма премии, руб без НДС</strong>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Оборотная премия</TableCell>
                      <TableCell>{actPrize && (+actPrize.premiumProc).toFixed(0)}</TableCell>
                      <TableCell>{actPrize && actPrize.premiumRub}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Премия за продажу инновац. оборудования</TableCell>
                      <TableCell>{actPrize && (+actPrize.innovationProc).toFixed(0)}</TableCell>
                      <TableCell>{actPrize && actPrize.innovationRub}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Премия за продажу конденс. оборудования</TableCell>
                      <TableCell>{actPrize && (+actPrize.condensationProc).toFixed(0)}</TableCell>
                      <TableCell>{actPrize && actPrize.condensationRub}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Премия за предоплату отгруженного товара в указанный период</TableCell>
                      <TableCell>{actPrize && prepaymentProc}</TableCell>
                      <TableCell>{actPrize && prepaymentRub.toFixed(2)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Дополнительная премия</TableCell>
                      <TableCell>{actPrize && (+actPrize.goldProc).toFixed(0)}</TableCell>
                      <TableCell>{actPrize && actPrize.goldRub}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <strong>Итого:</strong>
                      </TableCell>
                      <TableCell>
                        <strong>{actPrize && this.getTotalICPrize(prepaymentRub)}</strong>
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </div>
        </StyledDialogContent>
        <DialogActions>
          <Button color="secondary" onClick={isOpen ? onClose : null}>
            {isCreated ? 'Отмена' : 'Закрыть'}
          </Button>
          {isCreated && (
            <Button
              color="primary"
              disabled={!isOpen || isActStatusChange || isActPrizeFetching}
              onClick={this.handleActApprove}
            >
              Подтвердить
            </Button>
          )}
        </DialogActions>
      </>
    )
  }
}
