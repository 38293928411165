import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFileTwoTone'
import ImageIcon from '@material-ui/icons/ImageTwoTone'
import CheckCircleIcon from '@material-ui/icons/CheckCircleTwoTone'
import CancelIcon from '@material-ui/icons/CancelTwoTone'

import ROLES from '../../../utils/constants'

export default class ActDocumentActions extends Component {
  static propTypes = {
    role: PropTypes.string.isRequired,
    isPartnerConfirmation: PropTypes.bool.isRequired,
    id: PropTypes.number.isRequired,
    scanId: PropTypes.number,
    scanFileName: PropTypes.string,

    changeActStatus: PropTypes.func.isRequired,
    downloadAct: PropTypes.func.isRequired,
    downloadFile: PropTypes.func.isRequired,
    onAlertOpen: PropTypes.func.isRequired,
  }

  handleActStatusChange = () => {
    const {id, changeActStatus} = this.props
    changeActStatus(id, 'approveP')
  }

  handleScanDownload = e => {
    e.stopPropagation()
    const {scanId, scanFileName, downloadFile} = this.props
    downloadFile({scanId, scanFileName})
  }

  handleActDownload = () => {
    const {id, downloadAct} = this.props
    downloadAct(id)
  }

  render() {
    const {role, isPartnerConfirmation, scanId, onAlertOpen} = this.props
    return (
      <div className="table-actions f ws-nw">
        <Tooltip disableFocusListener title="Сохранить сканкопию" enterDelay={500}>
          <div>
            <IconButton onClick={this.handleScanDownload} disabled={!scanId}>
              <ImageIcon />
            </IconButton>
          </div>
        </Tooltip>
        <Tooltip disableFocusListener title="Скачать" enterDelay={500}>
          <IconButton onClick={this.handleActDownload}>
            <InsertDriveFileIcon />
          </IconButton>
        </Tooltip>
        {role === ROLES.P && (
          <>
            <Tooltip disableFocusListener title="Подтвердить" enterDelay={500}>
              <div>
                <IconButton onClick={this.handleActStatusChange} disabled={!isPartnerConfirmation}>
                  <CheckCircleIcon />
                </IconButton>
              </div>
            </Tooltip>
            <Tooltip disableFocusListener title="Отклонить" enterDelay={500}>
              <div>
                <IconButton onClick={onAlertOpen} disabled={!isPartnerConfirmation}>
                  <CancelIcon />
                </IconButton>
              </div>
            </Tooltip>
          </>
        )}
      </div>
    )
  }
}
