import React from 'react'
import PropTypes from 'prop-types'
import ReactFileReader from 'react-file-reader'
import moment from 'moment'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'

import './style.scss'
import Loader from '../../_common/Loader'

const fileTypes = ['.xls', '.xlsx']

ReferenceBlock.propTypes = {
  isFetch: PropTypes.bool.isRequired,
  title: PropTypes.string,
  blocks: PropTypes.array.isRequired,
}

const renderBlock = (block, isFetch) => {
  const button = (
    <Button variant={block.data ? 'outlined' : 'contained'} color="primary">
      {block.data ? 'Обновить справочник' : 'Загрузить справочник'}
    </Button>
  )
  return block.data ? (
    <div className="f ai-c jc-sb">
      <p className="reference-block__text">
        <span className="grey">Дата последней загрузки:</span>{' '}
        {moment(block.data.lastModifiedDate).format('DD.MM.YYYY HH:mm')}
      </p>
      <p className="reference-block__text">
        <span className="grey">Выполнено:</span> {block.data.uploadedUser}
      </p>
      {!isFetch ? (
        <ReactFileReader fileTypes={fileTypes} handleFiles={block.handler}>
          {button}
        </ReactFileReader>
      ) : (
        button
      )}
    </div>
  ) : !isFetch ? (
    <ReactFileReader fileTypes={fileTypes} handleFiles={block.handler}>
      {button}
    </ReactFileReader>
  ) : (
    button
  )
}

export default function ReferenceBlock({isFetch, title, blocks}) {
  return (
    <Paper className="reference-block">
      <Loader isBlock isFetch={isFetch} />
      <div className="block">
        <h3 className="title title_small mb">{title}</h3>
        <ul>
          {blocks.map(block => (
            <li key={block.id} className="reference-block__wrapper">
              <fieldset>
                {block.title && <legend>{block.title}</legend>}
                {renderBlock(block, isFetch)}
              </fieldset>
            </li>
          ))}
        </ul>
      </div>
    </Paper>
  )
}
