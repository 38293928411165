import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

import {THEME} from '../../../styles/muiTheme'

const spacing = THEME.UNIT * 3

GridContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default function GridContainer({children, ...props}) {
  return (
    <Grid {...props} container spacing={spacing}>
      {children}
    </Grid>
  )
}
