import React, {Component} from 'react'

export default ComposedComponent =>
  class extends Component {
    state = {
      openedModal: '',
    }

    handleModalOpen = type => () => this.setState({openedModal: type})

    handleModalClose = () => this.setState({openedModal: ''})

    render() {
      return (
        <ComposedComponent
          {...this.state}
          {...this.props}
          onModalOpen={this.handleModalOpen}
          onModalClose={this.handleModalClose}
        />
      )
    }
  }
