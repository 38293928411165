import React from 'react'
import PropTypes from 'prop-types'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import DeleteIcon from '@material-ui/icons/Delete'

import {THEME} from '../../../styles/muiTheme'

const styles = {
  fabFirst: {
    marginBottom: THEME.UNIT,
  },
}

TableActions.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
}

export default function TableActions({isFetching, onAdd = null, onDelete = null}) {
  return (
    <div className="f fd-c ai-fe">
      <Tooltip disableFocusListener title="Добавить контакт" enterDelay={500}>
        <div>
          <Fab size="small" color="primary" onClick={onAdd} style={styles.fabFirst} disabled={!onAdd || isFetching}>
            <PersonAddIcon />
          </Fab>
        </div>
      </Tooltip>
      <Tooltip disableFocusListener title="Удалить контакт" enterDelay={500}>
        <div>
          <Fab size="small" color="secondary" onClick={onDelete} disabled={!onDelete}>
            <DeleteIcon />
          </Fab>
        </div>
      </Tooltip>
    </div>
  )
}
