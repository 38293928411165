import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'
import moment from 'moment'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'

import withModal from '../../_decorators/withModal'
import Loader from '../../_common/Loader'
import ExtendedTable from '../../_common/ExtendedTable'
import PartnerDocumentActions from '../../_common/PartnerDocumentActions'
import Alert from '../../_common/Alert'
import ActDocumentActions from '../ActDocumentActions'
import {cloneObject, isRPRM, isDPRPRM} from '../../../utils/helpres'
import {THEME} from '../../../styles/muiTheme'
import ROLES, {ACT_STATUSES} from '../../../utils/constants'
import URLS from '../../../utils/urls'

const headerCells = [
  {title: 'Тип документа'},
  {title: 'Дата формирования'},
  {title: 'Период'},
  {title: 'Номер'},
  {title: 'Действует с .. по ..'},
  {title: 'Статус'},
  {title: 'Действие'},
]

const styles = {
  paper: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  button: {
    marginRight: THEME.UNIT,
  },
}

class PartnerDocuments extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    roles: PropTypes.array.isRequired,
    role: PropTypes.string.isRequired,
    isActivePartner: PropTypes.bool.isRequired,
    documents: PropTypes.array,
    isDocumentsFetching: PropTypes.bool.isRequired,
    contractBonusTypes: PropTypes.array,
    isContractBonusTypesFetching: PropTypes.bool.isRequired,
    isFileDownloadFetching: PropTypes.bool.isRequired,
    isDocumentDelete: PropTypes.bool.isRequired,
    isActStatusChange: PropTypes.bool.isRequired,
    isActDownload: PropTypes.bool.isRequired,
    isModalOpen: PropTypes.bool.isRequired,

    fetchDocuments: PropTypes.func.isRequired,
    downloadFile: PropTypes.func.isRequired,
    onNewContractToggle: PropTypes.func.isRequired,
    deleteDocument: PropTypes.func.isRequired,
    changeActStatus: PropTypes.func.isRequired,
    downloadAct: PropTypes.func.isRequired,
    onModalToggle: PropTypes.func.isRequired,
  }

  handleGoToPartners = () => this.props.history.push(URLS.PARTNERS)

  componentDidMount() {
    const {fetchDocuments} = this.props
    fetchDocuments()
  }

  get isDocumentEditable() {
    const {roles, role, isActivePartner} = this.props
    return (
      isActivePartner &&
      (role === ROLES.ADMIN || role === ROLES.RP || role === ROLES.SC || isRPRM(roles) || isDPRPRM(roles))
    )
  }

  getTableCellData(document) {
    const {
      role,
      isDocumentDelete,
      downloadFile,
      deleteDocument,
      changeActStatus,
      downloadAct,
      onModalToggle,
    } = this.props
    return [
      document.act ? 'Акт' : document.annex ? document.bonusTypeName : document.contractTypeName,
      <span className="ws-nw">{moment(document.createdDate).format('DD.MM.YYYY HH:mm')}</span>,
      document.act ? <p className="ws-nw">{document.actPeriod}</p> : null,
      document.act ? document.actNumber : document.documentNumber,
      document.act
        ? null
        : `${moment(document.validDateFrom).format('DD.MM.YYYY')} - ${moment(document.validDateTo).format(
            'DD.MM.YYYY'
          )}`,
      document.act ? document.actStatus : Object.values(document.contractState),
      document.act ? (
        <ActDocumentActions
          role={role}
          isPartnerConfirmation={role === ROLES.P && document.actStatus === ACT_STATUSES['PARTNER_CONFIRMATION']}
          id={document.actId}
          scanId={document.scanId}
          scanFileName={document.scanFileName}
          changeActStatus={changeActStatus}
          downloadAct={downloadAct}
          downloadFile={downloadFile}
          onAlertOpen={onModalToggle}
        />
      ) : (
        <PartnerDocumentActions
          document={document}
          documentId={document.id}
          fileName={document.fileName}
          scanId={document.scanId}
          scanFileName={document.scanFileName}
          documentDeleteId={document.document_id}
          documentDeleteBonusId={document.annex ? document.bonusId : null}
          isDocumentDelete={isDocumentDelete}
          downloadFile={downloadFile}
          deleteDocument={this.isDocumentEditable ? deleteDocument : null}
        />
      ),
    ]
  }

  getTableRows() {
    const {documents} = this.props
    if (!documents) return
    const extendedDocuments = cloneObject(documents)
    extendedDocuments.forEach(document => {
      if (document.scan && !document.annex && !document.act) {
        const parentDocument = extendedDocuments.find(
          item => !item.act && !item.annex && !item.scan && item.document_id === document.document_id
        )
        parentDocument.scanId = document.id
        parentDocument.scanFileName = document.fileName
      } else if (document.scan && document.annex) {
        const parentDocument = extendedDocuments.find(
          item => !item.act && !item.scan && item.bonusId === document.bonusId
        )
        parentDocument.scanId = document.id
        parentDocument.scanFileName = document.fileName
      } else if (document.scan && document.act) {
        const parentDocument = extendedDocuments.find(
          item => !item.annex && !item.scan && item.actId === document.actId
        )
        parentDocument.scanId = document.id
        parentDocument.scanFileName = document.fileName
      }
    })
    const filteredExtendedDocuments = extendedDocuments.filter(document => !document.scan)
    filteredExtendedDocuments.sort((a, b) => {
      return (!a.annex && !a.act && (b.annex || b.act)) || (a.annex && b.act) ? -1 : 1
    })
    const documentsTree = []
    filteredExtendedDocuments.forEach(item => {
      if (!item.annex && !item.act) {
        documentsTree.push(item)
      } else if (item.annex) {
        const currentDoc = filteredExtendedDocuments.find(doc => doc.document_id === item.document_id)
        if (currentDoc.subItems) {
          currentDoc.subItems.push(item)
        } else {
          currentDoc.subItems = [item]
        }
      } else if (item.act) {
        const currentBonus = filteredExtendedDocuments.find(doc => doc.annex && doc.bonusId === item.bonusId)
        if (currentBonus.subItems) {
          currentBonus.subItems.push(item)
        } else {
          currentBonus.subItems = [item]
        }
      }
    })
    return documentsTree.map(document => ({
      id: document.id,
      data: this.getTableCellData(document),
      subItems: document.subItems
        ? document.subItems.map(annex => ({
            id: annex.id,
            isFirstSub: true,
            data: this.getTableCellData(annex),
            subItems: annex.subItems
              ? annex.subItems.map(act => ({
                  id: act.actId,
                  isSecondSub: true,
                  data: this.getTableCellData(act),
                }))
              : null,
          }))
        : null,
    }))
  }

  render() {
    const {
      role,
      isModalOpen,
      isDocumentsFetching,
      isFileDownloadFetching,
      isDocumentDelete,
      isActStatusChange,
      isActDownload,
      onNewContractToggle,
      onModalToggle,
    } = this.props
    return (
      <>
        <Paper style={styles.paper}>
          <Loader isFetch={isFileDownloadFetching || isDocumentDelete || isActStatusChange || isActDownload} />
          <div className="block loaded">
            <ExtendedTable
              isInternal
              headerCells={headerCells}
              tableRows={this.getTableRows()}
              isFetching={isDocumentsFetching}
            />
            <div className="f jc-fe mt">
              {this.isDocumentEditable && (
                <Button variant="contained" color="primary" style={styles.button} onClick={onNewContractToggle}>
                  Сформировать новый документ
                </Button>
              )}
              {role !== ROLES.P && (
                <Button variant="outlined" onClick={this.handleGoToPartners}>
                  Закрыть
                </Button>
              )}
            </div>
          </div>
        </Paper>
        <Alert
          isOpen={isModalOpen}
          title="Отклонение акта"
          message="В случае вопросов свяжитесь с вашим региональным представителем."
          onClose={onModalToggle}
        />
      </>
    )
  }
}

export default withRouter(withModal(PartnerDocuments))
