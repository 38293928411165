import React, {Component} from 'react'
import {Provider} from 'react-redux'
import {createStore, applyMiddleware} from 'redux'
import {composeWithDevTools} from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import moment from 'moment'
import 'moment/locale/ru'
import 'typeface-roboto'

import rootReducer from '../redux'
import App from './App'
import '../styles/main.scss'

moment.updateLocale('ru', {
  week: {
    dow: 1,
  },
})

const store = createStore(
  rootReducer,
  process.env.NODE_ENV === 'development' && window.navigator.userAgent.includes('Chrome')
    ? composeWithDevTools(applyMiddleware(thunk))
    : applyMiddleware(thunk)
)

export default class Root extends Component {
  render() {
    return (
      <Provider store={store}>
        <App />
      </Provider>
    )
  }
}
