import React from 'react'
import {BarChart, Bar, XAxis, LabelList, Legend} from 'recharts'
import PropTypes from 'prop-types'

const renderCustomizedLabel = props => {
  const {x, y, width, height, value} = props
  return (
    <g>
      <text
        x={x + width / 2}
        y={y + height / 2}
        fill="#000000"
        fontSize="18px"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {value}
      </text>
    </g>
  )
}

SimpleBarChart.propTypes = {
  data: PropTypes.array,
}

SimpleBarChart.defaultProps = {
  data: [],
}

function findRequiredData(data, value) {
  const barData = data.find(item => item.name === value)
  return [barData['2018'], barData['2019']]
}

function SimpleBarChart({data}) {
  const renderDifferenceBar = props => {
    const {x, y, width, height, value} = props
    if (data && data.length) {
      const [a, b] = findRequiredData(data, value)
      const difference = Math.round(((b - a) * 100) / a)
      const fillColor = difference < 0 ? 'red' : difference > 0 ? 'green' : 'orange'
      const fontSize = data.length > 4 ? 8 : 12
      //Coordinates
      const heightCorrection = difference > 0 ? (difference * height) / 100 : 0
      const xCoord = x + width / 2
      const yCoord = y - width / 2 - 10 - heightCorrection

      //Rect size
      const rectWidth = data.length > 4 ? '25' : '45'
      const rectHeight = data.length > 4 ? '15' : '25'

      return (
        <g>
          <rect x={xCoord} y={yCoord} width={rectWidth} height={rectHeight} rx="15" fill={fillColor} />
          <text
            x={xCoord + rectWidth / 2}
            y={yCoord + rectHeight / 2}
            fill="#fff"
            textAnchor="middle"
            dominantBaseline="middle"
            fontSize={fontSize}
          >
            {`${difference}%`}
          </text>
        </g>
      )
    }
    return null
  }

  return (
    <BarChart width={450} height={270} data={data} margin={{top: 40, right: 30, left: 20, bottom: 5}}>
      <XAxis dataKey="name" interval={0} fontSize={data.length > 4 ? 8 : 14} />
      <Legend />
      <Bar dataKey="2018" fill="#5B9BD5">
        <LabelList dataKey="2018" content={renderCustomizedLabel} />
        <LabelList dataKey="name" content={renderDifferenceBar} />
      </Bar>
      <Bar dataKey="2019" fill="#ED7D31">
        <LabelList dataKey="2019" content={renderCustomizedLabel} />
      </Bar>
    </BarChart>
  )
}

export default SimpleBarChart
