import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

Modal.propTypes = {
  isForm: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func,
  onExited: PropTypes.func,
}

export default function Modal({isForm, open, title, children, onClose, onAccept, ...props}) {
  const onModalClose = open ? onClose : null
  return (
    <Dialog {...props} open={open} scroll="body" onClose={onModalClose}>
      <DialogTitle>{title}</DialogTitle>
      {isForm ? (
        children
      ) : (
        <Fragment>
          <DialogContent>{children}</DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={onModalClose}>
              Закрыть
            </Button>
            {onAccept && (
              <Button color="primary" onClick={open ? onAccept : null}>
                Добавить
              </Button>
            )}
          </DialogActions>
        </Fragment>
      )}
    </Dialog>
  )
}
