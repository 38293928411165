import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import {withStyles} from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {THEME} from '../../../styles/muiTheme'

const styles = {
  expandMoreIcon: {
    transition: `transform ${THEME.SHORTER}ms ${THEME.EASE_IN_OUT}`,
  },
  expandMoreIconActive: {
    transform: 'rotate(-180deg)',
  },
}

class MenuDropdown extends Component {
  state = {
    anchorEl: null,
  }

  static propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.object.isRequired,
    links: PropTypes.array.isRequired,
  }

  handleClick = e => this.setState({anchorEl: e.currentTarget})

  handleClose = () => this.setState({anchorEl: null})

  handleItemClick = handler => () => {
    handler()
    this.handleClose()
  }

  renderLinks() {
    return this.props.links.map(link => (
      <MenuItem key={link.id} onClick={this.handleItemClick(link.handler)}>
        {link.text}
      </MenuItem>
    ))
  }

  render() {
    const {children, classes} = this.props
    const {anchorEl} = this.state
    const isOpen = Boolean(anchorEl)
    return (
      <Fragment>
        <button className={cn('link link_large', {link_active: isOpen}, 'f ai-c')} onClick={this.handleClick}>
          {children}
          <ExpandMoreIcon className={cn(classes.expandMoreIcon, {[classes.expandMoreIconActive]: isOpen})} />
        </button>
        <Menu anchorEl={anchorEl} open={isOpen} onClose={this.handleClose}>
          {this.renderLinks()}
        </Menu>
      </Fragment>
    )
  }
}

export default withStyles(styles)(MenuDropdown)
