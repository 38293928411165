import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWalletTwoTone'
import RestorePageIcon from '@material-ui/icons/RestorePageTwoTone'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFileTwoTone'

export default class PartnerActActions extends PureComponent {
  static propTypes = {
    act: PropTypes.object.isRequired,
    orgNumber: PropTypes.string.isRequired,

    selectAct: PropTypes.func.isRequired,
    recalculateAct: PropTypes.func.isRequired,
    downloadAct: PropTypes.func.isRequired,
    onPrizesOpen: PropTypes.func.isRequired,
  }

  handleActPrizes = () => {
    const {act, selectAct, onPrizesOpen} = this.props
    selectAct(act)
    onPrizesOpen()
  }

  handleActRecalculate = () => {
    const {act, orgNumber, recalculateAct} = this.props
    recalculateAct(act.id, orgNumber)
  }

  handleActDownload = () => {
    const {act, downloadAct} = this.props
    downloadAct(act.id)
  }

  render() {
    return (
      <div className="table-actions f ws-nw">
        <Tooltip disableFocusListener title="Премии" enterDelay={500}>
          <IconButton onClick={this.handleActPrizes}>
            <AccountBalanceWalletIcon />
          </IconButton>
        </Tooltip>
        <Tooltip disableFocusListener title="Переформировать акт" enterDelay={500}>
          <IconButton onClick={this.handleActRecalculate}>
            <RestorePageIcon />
          </IconButton>
        </Tooltip>
        <Tooltip disableFocusListener title="Скачать" enterDelay={500}>
          <IconButton onClick={this.handleActDownload}>
            <InsertDriveFileIcon />
          </IconButton>
        </Tooltip>
      </div>
    )
  }
}
