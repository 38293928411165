import React, {Component} from 'react'
import PropTypes from 'prop-types'
import AgreementStage from './AgreementStage'

class AgreementStages extends Component {
  static propTypes = {
    curators: PropTypes.array.isRequired,
  }

  render() {
    const {curators} = this.props
    const sortedCurators = [...curators].sort((a, b) => a.order - b.order)
    return (
      <div className="wrapper">
        <div className="f jc-sb">
          {sortedCurators.map((curator, index) => (
            <AgreementStage key={curator.id} curator={curator} isFinalStage={curators.length - 1 === index} />
          ))}
        </div>
      </div>
    )
  }
}

export default AgreementStages
