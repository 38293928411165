import React, {Component} from 'react'

export default ComposedComponent =>
  class extends Component {
    state = {
      isModalOpen: false,
    }

    handleModalToggle = () =>
      this.setState(state => ({
        isModalOpen: !state.isModalOpen,
      }))

    render() {
      return <ComposedComponent {...this.state} {...this.props} onModalToggle={this.handleModalToggle} />
    }
  }
