//import axios from 'axios'

const module = 'dashboard'

const FETCH_DASHBOARD_DATA = `${module}/FETCH_DASHBOARD_DATA`
const FETCH_DASHBOARD_DATA_SUCCESS = `${module}/FETCH_DASHBOARD_DATA_SUCCESS`
const FETCH_DASHBOARD_DATA_FAIL = `${module}/FETCH_DASHBOARD_DATA_FAIL`

const initialState = {
  simpleStatistics: [
    {label: 'Fact 2019 YTD', value: '22222333'},
    {label: 'Plan 2019 YTD', value: '23222333'},
    {label: 'Fact 2019 YTD', value: '21222333'},
    {label: 'Plan 2019 YTD', value: '99222333'},
  ],
  ratesByYear: {label: '2018 vs 2019', data: [{name: '2018', pv: '10.6'}, {name: '2019', pv: '15.8'}]},
  theoryPlan: {label: '2019 PLAN vs FACT', data: [{name: 'Plan', pv: '12.8'}, {name: 'Fact', pv: '15.8'}]},
  salesByRegions: {
    label: 'Sales by regions',
    data: [
      {name: 'Тюменская', '2018': 5, '2019': 6},
      {name: 'Свердловская', '2018': 5, '2019': 7},
      {name: 'Челябинская', '2018': 7, '2019': 5},
      {name: 'Курганская', '2018': 5, '2019': 4},
      {name: 'Челябинская', '2018': 7, '2019': 5},
      {name: 'Смоленская', '2018': 7, '2019': 5},
      {name: 'Челябинская', '2018': 7, '2019': 5},
    ],
  },
  brand: {label: 'Brand', data: [{name: 'Vaillant', value: 8000}, {name: 'Protherm', value: 6000}]},
  salesByChannels: {
    label: 'Sales by Channels',
    data: [{name: 'ФД', value: 50}, {name: 'РД', value: 20}, {name: 'ИЦ', value: 20}, {name: 'Direct', value: 10}],
  },
  partners: {
    label: 'Partners',
    data: [
      {cardName: 'ФД', value: '2'},
      {cardName: 'РД', value: '14'},
      {cardName: 'ИЦ', value: '25'},
      {cardName: 'Direct', value: '46'},
      {cardName: 'СЦ', value: '150'},
      {cardName: 'Рысь', value: '6'},
    ],
  },
  products: {
    label: 'Products',
    data: [
      {name: 'WHB', '2018': 3, '2019': 5},
      {name: 'WHBc', '2018': 4, '2019': 3},
      {name: 'EWHB', '2018': 6, '2019': 9},
      {name: 'FSB', '2018': 7, '2019': 5},
    ],
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD_DATA:
      return {...state, isUserFetching: true, userError: null}
    case FETCH_DASHBOARD_DATA_SUCCESS:
      return {...state, isUserFetching: false, user: action.user}
    case FETCH_DASHBOARD_DATA_FAIL:
      return {...state, isUserFetching: false, userError: action.error}

    default:
      return state
  }
}
