import axios from 'axios'
import {combineReducers} from 'redux'

import auth from './auth'
import user from './user'
import partners from './partners'
import references from './references'
import curators from './curators'
import history from './history'
import documents from './documents'
import agreement from './agreement'
import reports from './reports'
import acts from './acts'
import catalogue from './catalogue'
import ui from './ui'
import dashboard from './dashboard'
import {API_URL} from '../utils/urls'

axios.defaults.baseURL = API_URL

export default combineReducers({
  auth,
  user,
  partners,
  references,
  curators,
  history,
  documents,
  agreement,
  reports,
  acts,
  catalogue,
  ui,
  dashboard,
})
