import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Form, Field} from 'react-final-form'
import Grid from '@material-ui/core/Grid'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

import GridContainer from '../GridContainer'
import Loader from '../Loader'
import InputField from '../InputField'
import {focusOnError} from '../../../utils/helpres'
import {validateWorkerAdd} from '../../../utils/validator'

const styles = {
  dialogContent: {
    paddingTop: 0,
  },
}

export default class WorkerAddForm extends Component {
  static propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    createCurator: PropTypes.func.isRequired,
    onModalToggle: PropTypes.func.isRequired,
  }

  handleFormSubmit = values => {
    const {createCurator, onModalToggle} = this.props
    const worker = {
      ...values,
      middleName: values.middleName || '',
      phoneNumber: values.phoneNumber.replace(/\+|( \()|(\) )/g, ''),
    }
    createCurator(worker, onModalToggle)
  }

  render() {
    const {isModalOpen, isFetching, onModalToggle} = this.props
    return (
      <Form
        validate={validateWorkerAdd}
        decorators={[focusOnError]}
        onSubmit={this.handleFormSubmit}
        render={({handleSubmit}) => (
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Loader isFetch={isFetching} />
            <DialogContent style={styles.dialogContent}>
              <GridContainer>
                <Grid item xs={12}>
                  <Field autoFocus name="firstName" label="Имя" component={InputField} />
                </Grid>
              </GridContainer>
              <GridContainer>
                <Grid item xs={12}>
                  <Field name="lastName" label="Фамилия" component={InputField} />
                </Grid>
              </GridContainer>
              <GridContainer>
                <Grid item xs={12}>
                  <Field name="middleName" label="Отчество" component={InputField} />
                </Grid>
              </GridContainer>
              <GridContainer>
                <Grid item xs={12}>
                  <Field isMask name="phoneNumber" label="Телефон" component={InputField} />
                </Grid>
              </GridContainer>
              <GridContainer>
                <Grid item xs={12}>
                  <Field name="email" label="E-mail" component={InputField} />
                </Grid>
              </GridContainer>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={isModalOpen ? onModalToggle : null}>
                Закрыть
              </Button>
              <Button type="submit" color="primary" disabled={!isModalOpen || isFetching}>
                Добавить
              </Button>
            </DialogActions>
          </form>
        )}
      />
    )
  }
}
