import React from 'react'
import ReactDOM from 'react-dom'
import Keycloak from 'keycloak-js'
import axios from 'axios'
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Root from './components'
import * as serviceWorker from './utils/serviceWorker'

const render = Component => ReactDOM.render(<Component />, document.getElementById('root'))

export const kc = new Keycloak('/keycloak.json')

kc.init({onLoad: 'login-required', checkLoginIframe: false}).success(authenticated => {
  if (authenticated) {
    axios.defaults.headers.common.Authorization = `Bearer ${kc.token}`
    render(Root)
    if (module.hot) {
      module.hot.accept('./components', () => {
        const NextApp = require('./components').default
        render(NextApp)
      })
    }
  }
})

serviceWorker.unregister()
