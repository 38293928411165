import React, {Component} from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import {withStyles} from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TablePagination from '@material-ui/core/TablePagination'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRightTwoTone'

import './style.scss'
import Loader from '../Loader'
import {COLORS, THEME} from '../../../styles/muiTheme'

const rowsPerPageOptions = [5, 10, 25, 50]
const getLabelDisplayedRows = ({from, to, count}) => `${from}-${to} из ${count}`

const styles = {
  paper: {
    position: 'relative',
  },
  paperInternal: {
    boxShadow: 'none',
    border: `1px solid ${COLORS.inputBorder}`,
    overflow: 'hidden',
  },
  paperError: {
    borderColor: COLORS.error,
  },
  arrowDownwardIcon: {
    marginLeft: THEME.UNIT / 2,
    transition: `transform ${THEME.SHORTER}ms ${THEME.EASE_IN_OUT}`,
    opacity: 0.2,
    fontSize: 18,
  },
  arrowDownwardIconRotated: {
    transform: 'rotate(-180deg)',
  },
  arrowDownwardIconActive: {
    opacity: 1,
  },
  tableRowHeadTop: {
    backgroundColor: COLORS.defaultBackground,
  },
  tableCellCenter: {
    textAlign: 'center',
  },
  tableCellEmpty: {
    color: COLORS.textSecondary,
    textAlign: 'center',
  },
  tableCellFont: {
    fontSize: '0.8125rem',
  },
  tableCellIndent: {
    paddingLeft: 54,
  },
  tableCellNoWrap: {
    whiteSpace: 'nowrap',
  },
  subdirectoryIcon: {
    color: COLORS.textSecondary,
    marginRight: 8,
    flexShrink: 0,
    position: 'relative',
    top: 5,
  },
}

class ExtendedTable extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    isInternal: PropTypes.bool,
    isError: PropTypes.bool,
    headerTopCells: PropTypes.array,
    headerCells: PropTypes.array.isRequired,
    tableRows: PropTypes.array,
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isFetching: PropTypes.bool,
    total: PropTypes.number,
    rowsPerPage: PropTypes.number,
    page: PropTypes.number,
    sort: PropTypes.array,
    style: PropTypes.object,
    setPage: PropTypes.func,
    setSize: PropTypes.func,
    setSort: PropTypes.func,
    onRowClick: PropTypes.func,
  }

  handleRowsPerPageChange = ({target}) => this.props.setSize(target.value)

  handlePageChange = (e, page) => this.props.setPage(page)

  handleSortChange = sortName => () => {
    const {sort, setSort} = this.props
    if (sort && sort[0] !== sortName) {
      setSort([sortName, 'asc'])
    } else if (sort && sort[1] === 'desc') {
      setSort(null)
    } else {
      setSort([sortName, sort && sort[1] === 'asc' ? 'desc' : 'asc'])
    }
  }

  renderHeaderCells(headerCells) {
    const {classes, sort, isFetching, setSort} = this.props
    return headerCells.map((cell, index) => (
      <TableCell
        key={index}
        className={cn(classes.tableCellFont, {[classes.tableCellCenter]: cell.colSpan})}
        colSpan={cell.colSpan}
      >
        {cell.sortName ? (
          <button
            className={cn('link link_cell', {link_active: sort && sort[0] === cell.sortName}, 'f ai-c')}
            onClick={this.handleSortChange(cell.sortName, sort, setSort)}
            disabled={isFetching}
          >
            {cell.title}
            <ArrowDownwardIcon
              className={cn(classes.arrowDownwardIcon, {
                [classes.arrowDownwardIconRotated]: sort && (sort[0] === cell.sortName && sort[1] === 'asc'),
                [classes.arrowDownwardIconActive]: sort && (sort[0] === cell.sortName && sort[1]),
              })}
            />
          </button>
        ) : (
          cell.title
        )}
      </TableCell>
    ))
  }

  renderTableRow(row) {
    const {classes, selected, onRowClick} = this.props
    return (
      <TableRow
        key={row.id}
        hover={Boolean(onRowClick)}
        selected={selected === row.id}
        onClick={onRowClick ? onRowClick(row.id) : null}
      >
        {row.data.map((cell, index) => (
          <TableCell
            key={index}
            className={cn({
              [classes.tableCellIndent]: index === 0 && row.isSecondSub,
              [classes.tableCellNoWrap]: index === 0 && (row.isFirstSub || row.isSecondSub),
            })}
          >
            {index === 0 && (row.isFirstSub || row.isSecondSub) && (
              <SubdirectoryArrowRightIcon className={classes.subdirectoryIcon} />
            )}
            {cell !== null ? cell : '-'}
          </TableCell>
        ))}
      </TableRow>
    )
  }

  get tableRows() {
    const {classes, headerCells, tableRows} = this.props
    if (tableRows && tableRows.length) {
      const collectedTableRows = []
      tableRows.forEach(row => {
        collectedTableRows.push(this.renderTableRow(row))
        if (row.subItems) {
          row.subItems.forEach(row => {
            collectedTableRows.push(this.renderTableRow(row))
            if (row.subItems) {
              row.subItems.forEach(row => {
                collectedTableRows.push(this.renderTableRow(row))
              })
            }
          })
        }
      })
      return collectedTableRows
    }
    return (
      <TableRow key="empty">
        <TableCell className={classes.tableCellEmpty} colSpan={headerCells.length}>
          Нет данных
        </TableCell>
      </TableRow>
    )
  }

  render() {
    const {
      classes,
      isInternal,
      isError,
      headerCells,
      headerTopCells,
      isFetching,
      total,
      rowsPerPage,
      page,
      style,
    } = this.props
    return (
      <Paper
        className={cn(`extended-table ${classes.paper}`, {
          [classes.paperInternal]: isInternal,
          [classes.paperError]: isError,
        })}
        style={style}
      >
        <Loader isFetch={isFetching} isBlock />
        <div className="extended-table__wrapper">
          <Table>
            <TableHead>
              {headerTopCells && (
                <TableRow className={classes.tableRowHeadTop}>{this.renderHeaderCells(headerTopCells)}</TableRow>
              )}
              <TableRow>{this.renderHeaderCells(headerCells)}</TableRow>
            </TableHead>
            <TableBody>{this.tableRows}</TableBody>
          </Table>
        </div>
        {rowsPerPage && (
          <TablePagination
            component="div"
            rowsPerPageOptions={rowsPerPageOptions}
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage="Количество строк:"
            labelDisplayedRows={getLabelDisplayedRows}
            onChangeRowsPerPage={this.handleRowsPerPageChange}
            onChangePage={this.handlePageChange}
          />
        )}
      </Paper>
    )
  }
}

export default withStyles(styles)(ExtendedTable)
