import React, {Component} from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import {Redirect, Switch, Route} from 'react-router-dom'
import withWidth from '@material-ui/core/withWidth'

import './style.scss'
import Sidebar from '../Sidebar'
import Header from '../Header'
import Dashboard from '../Dashboard'
import Partners from '../Partners'
import Partner from '../Partner'
import PartnerSellOut from '../PartnerSellOut'
import Agreement from '../Agreement'
import Document from '../Document'
import History from '../History'
import Scans from '../Scans'
import SellIn from '../SellIn'
import SellOut from '../SellOut'
import AdminSellOut from '../AdminSellOut'
import Acts from '../Acts'
import PartnerActs from '../PartnerActs'
import Reports from '../Reports'
import References from '../References'
import {isRPRM, isDPRPRM} from '../../utils/helpres'
import URLS from '../../utils/urls'
import ROLES from '../../utils/constants'

const reportsRoute = {
  path: URLS.REPORTS,
  exact: true,
  component: Reports,
}

class AppContent extends Component {
  static propTypes = {
    user: PropTypes.object,
    roles: PropTypes.array.isRequired,
    role: PropTypes.string.isRequired,
    backInfo: PropTypes.object,
    width: PropTypes.string.isRequired,
    logOut: PropTypes.func.isRequired,
  }

  get routes() {
    const {roles, role} = this.props
    const routes = [
      {
        path: `${URLS.PARTNERS}/:id`,
        component: Partner,
      },
    ]
    if (role !== ROLES.P && role !== ROLES.GD && role !== ROLES.U) {
      routes.push({
        path: URLS.DASHBOARD,
        exact: true,
        component: Dashboard,
      })
    }
    if (role !== ROLES.U) {
      routes.push({
        path: URLS.PARTNERS,
        exact: true,
        component: Partners,
      })
    }
    if (role !== ROLES.P) {
      routes.push({
        path: URLS.AGREEMENT,
        exact: true,
        component: Agreement,
      })
    }
    if (role === ROLES.ADMIN) {
      routes.push(
        {
          path: URLS.SCANS,
          exact: true,
          component: Scans,
        },
        {
          path: URLS.ACTS,
          exact: true,
          component: Acts,
        },
        {
          path: `${URLS.ACTS}/:id`,
          component: PartnerActs,
        },
        {
          path: URLS.SELL_IN,
          exact: true,
          component: SellIn,
        },
        {
          path: URLS.SELL_OUT,
          exact: true,
          component: AdminSellOut,
        },
        {
          path: `${URLS.SELL_OUT}/:id`,
          component: PartnerSellOut,
        },
        reportsRoute,
        {
          path: URLS.REFERENCES,
          exact: true,
          component: References,
        },
        {
          path: URLS.HISTORY,
          exact: true,
          component: History,
        }
      )
    }
    if (role === ROLES.P) {
      routes.push({
        path: URLS.SELL_OUT,
        exact: true,
        component: SellOut,
      })
    }
    if (role === ROLES.ADMIN || role === ROLES.RP || isRPRM(roles) || isDPRPRM(roles)) {
      routes.push({
        path: URLS.AGREEMENT_RECREATE,
        exact: true,
        component: Document,
      })
    }
    if (role === ROLES.RP || role === ROLES.RM || role === ROLES.DP || role === ROLES.GD) {
      routes.push(reportsRoute)
    }
    return routes.map(route => (
      <Route key={route.path} exact={route.exact} path={route.path} component={route.component} />
    ))
  }

  render() {
    const {user, role, backInfo, width, logOut} = this.props
    const isCompact = width !== 'lg' && width !== 'xl'
    return (
      <div className={cn('app-content', {'app-content_compact': isCompact})}>
        {!isCompact && <Sidebar role={role} backInfo={backInfo} />}
        <Header isCompact={isCompact} user={user} role={role} backInfo={backInfo} logOut={logOut} />
        <main>
          <Switch>
            {this.routes}
            <Redirect to={role === ROLES.U || role === ROLES.GD ? URLS.AGREEMENT : URLS.PARTNERS} />
          </Switch>
        </main>
      </div>
    )
  }
}

export default withWidth()(AppContent)
