import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {Form, FormSpy, Field} from 'react-final-form'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFileRounded'

import InputField from '../../_common/InputField'
import {focusOnError} from '../../../utils/helpres'
import {validateReports} from '../../../utils/validator'
import {THEME} from '../../../styles/muiTheme'

const FirstButton = withStyles({
  root: {
    marginRight: THEME.UNIT,
  },
})(Button)

export default class ReportsForm extends PureComponent {
  static propTypes = {
    reports: PropTypes.object,
    reportsFormValues: PropTypes.object.isRequired,
    isReportsFetching: PropTypes.bool.isRequired,
    isSellInFileDownload: PropTypes.bool.isRequired,
    updateReportsFormValues: PropTypes.func.isRequired,
    fetchReports: PropTypes.func.isRequired,
    downloadSellInFile: PropTypes.func.isRequired,
  }

  handleFormSubmit = () => {
    this.props.fetchReports()
  }

  handleUpdateFormValues = state => {
    this.props.updateReportsFormValues(state.values)
  }

  render() {
    const {reports, reportsFormValues, isReportsFetching, isSellInFileDownload, downloadSellInFile} = this.props
    return (
      <Form
        initialValues={reportsFormValues}
        validate={validateReports}
        decorators={[focusOnError]}
        onSubmit={this.handleFormSubmit}
        render={({handleSubmit}) => (
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <FormSpy onChange={this.handleUpdateFormValues} />
            <div className="f">
              <div className="w-300">
                <Field name="date_from" label="Период с" type="datePicker" component={InputField} />
              </div>
              <div className="w-300 ml">
                <Field name="date_to" label="Период по" type="datePicker" component={InputField} />
              </div>
            </div>
            <div className="mt-xs f ai-c">
              <div className="radio-title radio-title_no-padding">Бренд:</div>
              <Field name="is_vaillant" type="checkbox" label="Vaillant" color="primary" component={InputField} />
              <Field name="is_proterm" type="checkbox" label="Protherm" color="primary" component={InputField} />
              <Field name="is_zch" type="checkbox" label="с з/ч" color="primary" component={InputField} />
            </div>
            <div className="f mt">
              <FirstButton type="submit" color="primary" variant="contained" disabled={isReportsFetching}>
                Сформировать отчет
              </FirstButton>
              {reports && Boolean(reports.totalElements) && (
                <div className="loaded">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={downloadSellInFile}
                    disabled={isSellInFileDownload || isReportsFetching}
                  >
                    <InsertDriveFileIcon />
                    <span className="ml-xs">Выгрузить Excel</span>
                  </Button>
                </div>
              )}
            </div>
          </form>
        )}
      />
    )
  }
}
