import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Field} from 'react-final-form'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'

import {userRoleSelector} from '../../../redux/user'
import {
  regionalManagersSelector,
  regionalRepresentativesSelector,
  serviceCoordinatorsSelector,
} from '../../../redux/references'
import InputField from '../../_common/InputField'
import GridContainer from '../../_common/GridContainer'
import {sortOptions, isRPRM, isDPRPRM} from '../../../utils/helpres'
import ROLES from '../../../utils/constants'
import {STATUSES} from '../../../utils/constants'

class PartnerBasic extends PureComponent {
  isRPRM = isRPRM(this.props.roles)
  isDPRPRM = isDPRPRM(this.props.roles)

  static propTypes = {
    isRead: PropTypes.bool,
    roles: PropTypes.array.isRequired,
    role: PropTypes.string.isRequired,
    regionalManagers: PropTypes.array.isRequired,
    regionalRepresentatives: PropTypes.array.isRequired,
    serviceCoordinators: PropTypes.array.isRequired,
    federalDistricts: PropTypes.array,
    status: PropTypes.string,
    partnerTypes: PropTypes.array,
    allCurators: PropTypes.object,
    contractAnnex: PropTypes.string,
    isEngineeringCentre: PropTypes.bool,
    isFederalOrRegionalDistributor: PropTypes.bool.isRequired,
    currentFederalDistrictIdValue: PropTypes.number,
    regionManagerIdValue: PropTypes.number,
    vaillantCuratorIdValue: PropTypes.number,
    prothermCuratorIdValue: PropTypes.number,
    serviceCoordinatorIdValue: PropTypes.number,
  }

  getCurrentRegionsData() {
    const {federalDistricts, currentFederalDistrictIdValue} = this.props
    const selectedCurrentDistrict =
      federalDistricts && federalDistricts.find(district => district.id === currentFederalDistrictIdValue)
    return selectedCurrentDistrict
      ? sortOptions(
          selectedCurrentDistrict.regions.map(region => ({
            value: region.id,
            label: region.region,
          }))
        )
      : []
  }

  getFederalDistrictsOptions() {
    const {federalDistricts} = this.props
    return federalDistricts
      ? federalDistricts.map(district => ({
          value: district.id,
          label: district.federalDistrict,
          regions: district.regions,
        }))
      : []
  }

  getEmptyCuratorLabel(id) {
    const {allCurators} = this.props
    return (allCurators && allCurators[id]) || 'Сотрудник другого ФО'
  }

  renderFieldWithPossibleTooltip(field) {
    const {role, status} = this.props
    return role === ROLES.ADMIN && status === STATUSES.AGREEMENT_CONTRACT ? (
      <Tooltip
        disableFocusListener
        title="Поле будет доступно для редактирования после согласования документа."
        placement="bottom-start"
      >
        {field}
      </Tooltip>
    ) : (
      field
    )
  }

  render() {
    const {
      isRead,
      role,
      regionalManagers,
      regionalRepresentatives,
      serviceCoordinators,
      status,
      partnerTypes,
      contractAnnex,
      isEngineeringCentre,
      isFederalOrRegionalDistributor,
      currentFederalDistrictIdValue,
      regionManagerIdValue,
      vaillantCuratorIdValue,
      prothermCuratorIdValue,
      serviceCoordinatorIdValue,
    } = this.props
    const isAdmin = role === ROLES.ADMIN
    const isCuratorDisabled =
      isRead ||
      status === STATUSES.AGREEMENT_CONTRACT ||
      (!isAdmin && role !== ROLES.RP && !this.isRPRM && !this.isDPRPRM) ||
      ((role === ROLES.RP || this.isRPRM || this.isDPRPRM) &&
        status !== STATUSES.DOWNLOADED &&
        status !== STATUSES.DRAFT)
    const regionalManagersFinal = regionalManagers
    const regionalRepresentativesFinal = regionalRepresentatives
    const serviceCoordinatorsFinal = serviceCoordinators
    const currentRegionField = (
      <Field
        name="currentRegionId"
        label="Регион фактического местоположения"
        options={this.getCurrentRegionsData()}
        component={InputField}
        disabled={isRead || !currentFederalDistrictIdValue}
      />
    )
    let orgWorkersField
    let orgProthermWorkersField
    let serviceCoordinatorField
    if (role !== ROLES.P) {
      orgWorkersField = this.renderFieldWithPossibleTooltip(
        <Field
          name="vaillantCuratorId"
          label="Ответственный сотрудник Vaillant"
          options={
            !isAdmin &&
            (vaillantCuratorIdValue || vaillantCuratorIdValue === 0) &&
            !regionalRepresentativesFinal.find(curator => curator.value === vaillantCuratorIdValue)
              ? [
                  ...regionalRepresentativesFinal,
                  {value: vaillantCuratorIdValue, label: this.getEmptyCuratorLabel(vaillantCuratorIdValue)},
                ]
              : regionalRepresentativesFinal
          }
          component={InputField}
          disabled={isCuratorDisabled}
        />
      )
      orgProthermWorkersField = this.renderFieldWithPossibleTooltip(
        <Field
          name="prothermCuratorId"
          label="Ответственный сотрудник Protherm"
          options={
            !isAdmin &&
            (prothermCuratorIdValue || prothermCuratorIdValue === 0) &&
            !regionalRepresentativesFinal.find(curator => curator.value === prothermCuratorIdValue)
              ? [
                  ...regionalRepresentativesFinal,
                  {value: prothermCuratorIdValue, label: this.getEmptyCuratorLabel(prothermCuratorIdValue)},
                ]
              : regionalRepresentativesFinal
          }
          component={InputField}
          disabled={isCuratorDisabled}
        />
      )
      serviceCoordinatorField = !isFederalOrRegionalDistributor && (
        <Field
          name="serviceCoordinatorId"
          label="Сервисный координатор"
          options={
            !isAdmin &&
            (serviceCoordinatorIdValue || serviceCoordinatorIdValue === 0) &&
            !serviceCoordinatorsFinal.find(coordinator => coordinator.value === serviceCoordinatorIdValue)
              ? [
                  ...serviceCoordinatorsFinal,
                  {value: serviceCoordinatorIdValue, label: this.getEmptyCuratorLabel(serviceCoordinatorIdValue)},
                ]
              : serviceCoordinatorsFinal
          }
          component={InputField}
          disabled={isRead}
        />
      )
    }
    return (
      <div className={isRead ? null : 'loaded'}>
        {role !== ROLES.P && (
          <GridContainer>
            <Grid item xs={6}>
              <Field disabled name="orgNumber" label="Номер" component={InputField} />
            </Grid>
            <Grid item xs={6}>
              {isRead && orgWorkersField}
            </Grid>
          </GridContainer>
        )}
        {!isRead && (
          <GridContainer>
            <Grid item xs={6}>
              <Field disabled name="orgName" label="Название организации" component={InputField} />
            </Grid>
            <Grid item xs={6}>
              {this.renderFieldWithPossibleTooltip(
                <Field
                  name="regionManagerId"
                  label="Региональный менеджер"
                  options={
                    !isAdmin &&
                    (regionManagerIdValue || regionManagerIdValue === 0) &&
                    !regionalManagersFinal.find(manager => manager.value === regionManagerIdValue)
                      ? [
                          ...regionalManagersFinal,
                          {value: regionManagerIdValue, label: this.getEmptyCuratorLabel(regionManagerIdValue)},
                        ]
                      : regionalManagersFinal
                  }
                  component={InputField}
                  disabled={isCuratorDisabled}
                />
              )}
            </Grid>
          </GridContainer>
        )}
        <GridContainer>
          <Grid item xs={6}>
            <Field
              name="currentFederalDistrictId"
              label="ФО фактического местоположения"
              options={this.getFederalDistrictsOptions()}
              component={InputField}
              disabled={isRead}
            />
          </Grid>
          <Grid item xs={6}>
            {role === ROLES.P ? currentRegionField : isRead ? orgProthermWorkersField : orgWorkersField}
          </Grid>
        </GridContainer>
        {role !== ROLES.P && (
          <GridContainer>
            <Grid item xs={6}>
              {currentRegionField}
            </Grid>
            <Grid item xs={6}>
              {isRead ? serviceCoordinatorField : orgProthermWorkersField}
            </Grid>
          </GridContainer>
        )}
        {!isRead && (
          <GridContainer>
            <Grid item xs={isEngineeringCentre && contractAnnex ? 3 : 6}>
              <Field name="partnerTypeId" label="Тип партнера" options={partnerTypes} component={InputField} />
            </Grid>
            {isEngineeringCentre && contractAnnex && (
              <Grid item xs={3}>
                <Field disabled name="contractAnnex" label="Тип ИЦ" component={InputField} />
              </Grid>
            )}
            <Grid item xs={6}>
              {serviceCoordinatorField}
            </Grid>
          </GridContainer>
        )}
      </div>
    )
  }
}

export default connect(({user, partners, references}) => ({
  roles: user.user.roles,
  role: userRoleSelector(user.user),
  regionalManagers: regionalManagersSelector(references.regionalManagers),
  regionalRepresentatives: regionalRepresentativesSelector(references.regionalRepresentatives),
  serviceCoordinators: serviceCoordinatorsSelector(references.serviceCoordinators),
  federalDistricts: references.federalDistricts,
  allCurators: references.allCurators,
}))(PartnerBasic)
