import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {
  fetchCatalogue,
  downloadBranch,
  downloadVendorExclusion,
  downloadVendor,
  downloadSellIn,
} from '../../redux/catalogue'
import Loader from '../_common/Loader'
import ReferenceBlock from './ReferenceBlock'

const IC_BONUS_VENDOR_CODE_EXCLUSION = 'IC_BONUS_VENDOR_CODE_EXCLUSION'
const RD_BONUS_VENDOR_CODE_EXCLUSION = 'RD_BONUS_VENDOR_CODE_EXCLUSION'
const FD_BONUS_VENDOR_CODE_EXCLUSION = 'FD_BONUS_VENDOR_CODE_EXCLUSION'
const CONDENSING_EQUIPMENT_VENDOR_CODE = 'CONDENSING_EQUIPMENT_VENDOR_CODE'
const INNOVATIVE_EQUIPMENT_VENDOR_CODE = 'INNOVATIVE_EQUIPMENT_VENDOR_CODE'

class References extends Component {
  static propTypes = {
    catalogue: PropTypes.array,
    isCatalogueFetching: PropTypes.bool.isRequired,
    isBranchDownload: PropTypes.bool.isRequired,
    isVendorExclusionDownload: PropTypes.bool.isRequired,
    isVendorDownload: PropTypes.bool.isRequired,
    isSellInDownload: PropTypes.bool.isRequired,

    fetchCatalogue: PropTypes.func.isRequired,
    downloadBranch: PropTypes.func.isRequired,
    downloadVendorExclusion: PropTypes.func.isRequired,
    downloadVendor: PropTypes.func.isRequired,
    downloadSellIn: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.props.fetchCatalogue()
  }

  getCatalogueData = type => {
    const {catalogue} = this.props
    return catalogue ? catalogue.find(item => item.catalogueType === type) : null
  }

  handleVendorExclusionDownload = type => files => this.props.downloadVendorExclusion(type, files)

  handleVendorDownload = type => files => this.props.downloadVendor(type, files)

  render() {
    const {
      isCatalogueFetching,
      isBranchDownload,
      isVendorExclusionDownload,
      isVendorDownload,
      isSellInDownload,
      downloadBranch,
      downloadSellIn,
    } = this.props
    const branch = [{id: 1, title: null, data: this.getCatalogueData('BRANCH'), handler: downloadBranch}]
    const exceptions = [
      {
        id: 1,
        title: 'Для ИЦ',
        data: this.getCatalogueData(IC_BONUS_VENDOR_CODE_EXCLUSION),
        handler: this.handleVendorExclusionDownload(IC_BONUS_VENDOR_CODE_EXCLUSION),
      },
      {
        id: 2,
        title: 'Для РД',
        data: this.getCatalogueData(RD_BONUS_VENDOR_CODE_EXCLUSION),
        handler: this.handleVendorExclusionDownload(RD_BONUS_VENDOR_CODE_EXCLUSION),
      },
      {
        id: 3,
        title: 'Для ФД',
        data: this.getCatalogueData(FD_BONUS_VENDOR_CODE_EXCLUSION),
        handler: this.handleVendorExclusionDownload(FD_BONUS_VENDOR_CODE_EXCLUSION),
      },
    ]
    const goods = [
      {
        id: 1,
        title: 'Для ИЦ - Конденсационное оборудование',
        data: this.getCatalogueData(CONDENSING_EQUIPMENT_VENDOR_CODE),
        handler: this.handleVendorDownload(CONDENSING_EQUIPMENT_VENDOR_CODE),
      },
      {
        id: 2,
        title: 'Для ИЦ - Инновационное оборудование',
        data: this.getCatalogueData(INNOVATIVE_EQUIPMENT_VENDOR_CODE),
        handler: this.handleVendorDownload(INNOVATIVE_EQUIPMENT_VENDOR_CODE),
      },
    ]
    const sellIn = [{id: 1, title: null, data: this.getCatalogueData('SELL_IN_EXCLUSION'), handler: downloadSellIn}]
    return (
      <section className="references container loaded">
        <Loader isFetch={isBranchDownload || isVendorExclusionDownload || isVendorDownload || isSellInDownload} />
        <ReferenceBlock isFetch={isCatalogueFetching} title="Филиалы ФД" blocks={branch} />
        <ReferenceBlock isFetch={isCatalogueFetching} title="Исключения в бонусных соглашениях" blocks={exceptions} />
        <ReferenceBlock isFetch={isCatalogueFetching} title="Группы товаров для бонусных соглащений" blocks={goods} />
        <ReferenceBlock isFetch={isCatalogueFetching} title="Исключения для отчета Sell-in" blocks={sellIn} />
      </section>
    )
  }
}

const mapStateToProps = ({catalogue}) => ({
  catalogue: catalogue.catalogue,
  isCatalogueFetching: catalogue.isCatalogueFetching,
  isVendorExclusionDownload: catalogue.isVendorExclusionDownload,
  isVendorDownload: catalogue.isVendorDownload,
  isBranchDownload: catalogue.isBranchDownload,
  isSellInDownload: catalogue.isSellInDownload,
})

const mapDispatchToProps = {
  fetchCatalogue,
  downloadBranch,
  downloadVendorExclusion,
  downloadVendor,
  downloadSellIn,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(References)
