import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {Field} from 'react-final-form'
import Grid from '@material-ui/core/Grid'

import GridContainer from '../../_common/GridContainer'
import InputField from '../../_common/InputField'

export default class PartnerBank extends PureComponent {
  static propTypes = {
    isRead: PropTypes.bool,
  }

  render() {
    const {isRead} = this.props
    return (
      <div className={isRead ? null : 'loaded'}>
        <GridContainer>
          <Grid item xs={6}>
            <Field disabled name="BIK" label="БИК" component={InputField} />
          </Grid>
          <Grid item xs={6}>
            <Field name="bankName" label="Название банка" component={InputField} disabled={isRead} />
          </Grid>
        </GridContainer>
        <GridContainer>
          <Grid item xs={6}>
            <Field disabled name="INN" label="ИНН" component={InputField} />
          </Grid>
          <Grid item xs={6}>
            <Field disabled name="KPP" label="КПП" component={InputField} />
          </Grid>
        </GridContainer>
        <GridContainer>
          <Grid item xs={6}>
            <Field name="cAcc" label="Корреспондентский счет" component={InputField} disabled={isRead} />
          </Grid>
          <Grid item xs={6}>
            <Field name="acc" label="Расчетный счет" component={InputField} disabled={isRead} />
          </Grid>
        </GridContainer>
      </div>
    )
  }
}
