import axios from 'axios'

import {fetchPartner} from './partners'
import {handleWithSnackbarError} from '../utils/helpres'

const module = 'curators'

const CREATE_PARTNER_CURATOR = `${module}/CREATE_PARTNER_CURATOR`
const CREATE_PARTNER_CURATOR_SUCCESS = `${module}/CREATE_PARTNER_CURATOR_SUCCESS`
const CREATE_PARTNER_CURATOR_FAIL = `${module}/CREATE_PARTNER_CURATOR_FAIL`

const DELETE_PARTNER_CURATOR = `${module}/DELETE_PARTNER_CURATOR`
const DELETE_PARTNER_CURATOR_SUCCESS = `${module}/DELETE_PARTNER_CURATOR_SUCCESS`
const DELETE_PARTNER_CURATOR_FAIL = `${module}/DELETE_PARTNER_CURATOR_FAIL`

const CREATE_VAILLANT_CURATOR = `${module}/CREATE_VAILLANT_CURATOR`
const CREATE_VAILLANT_CURATOR_SUCCESS = `${module}/CREATE_VAILLANT_CURATOR_SUCCESS`
const CREATE_VAILLANT_CURATOR_FAIL = `${module}/CREATE_VAILLANT_CURATOR_FAIL`

const DELETE_VAILLANT_CURATOR = `${module}/DELETE_VAILLANT_CURATOR`
const DELETE_VAILLANT_CURATOR_SUCCESS = `${module}/DELETE_VAILLANT_CURATOR_SUCCESS`
const DELETE_VAILLANT_CURATOR_FAIL = `${module}/DELETE_VAILLANT_CURATOR_FAIL`

const CREATE_PARTNER_SIGNATURE = `${module}/CREATE_PARTNER_SIGNATURE`
const CREATE_PARTNER_SIGNATURE_SUCCESS = `${module}/CREATE_PARTNER_SIGNATURE_SUCCESS`
const CREATE_PARTNER_SIGNATURE_FAIL = `${module}/CREATE_PARTNER_SIGNATURE_FAIL`

const DELETE_PARTNER_SIGNATURE = `${module}/DELETE_PARTNER_SIGNATURE`
const DELETE_PARTNER_SIGNATURE_SUCCESS = `${module}/DELETE_PARTNER_SIGNATURE_SUCCESS`
const DELETE_PARTNER_SIGNATURE_FAIL = `${module}/DELETE_PARTNER_SIGNATURE_FAIL`

const RESET_CURATORS_EDIT_TYPE = `${module}/RESET_CURATORS_EDIT_TYPE`

const initialState = {
  curatorsEditType: '',

  isPartnerCuratorCreateFetching: false,
  partnerCuratorCreateError: null,

  isPartnerCuratorDeleteFetching: false,
  partnerCuratorDeleteError: null,

  isVaillantCuratorCreateFetching: false,
  vaillantCuratorCreateError: null,

  isVaillantCuratorDeleteFetching: false,
  vaillantCuratorDeleteError: null,

  isPartnerSignatureCreateFetching: false,
  partnerSignatureCreateError: null,

  isPartnerSignatureDeleteFetching: false,
  partnerSignatureDeleteError: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_PARTNER_CURATOR:
      return {...state, isPartnerCuratorCreateFetching: true, partnerCuratorCreateError: null}
    case CREATE_PARTNER_CURATOR_SUCCESS:
      return {...state, isPartnerCuratorCreateFetching: false, curatorsEditType: 'partner'}
    case CREATE_PARTNER_CURATOR_FAIL:
      return {...state, isPartnerCuratorCreateFetching: false, partnerCuratorCreateError: action.error}

    case DELETE_PARTNER_CURATOR:
      return {...state, isPartnerCuratorDeleteFetching: true, partnerCuratorDeleteError: null}
    case DELETE_PARTNER_CURATOR_SUCCESS:
      return {...state, isPartnerCuratorDeleteFetching: false, curatorsEditType: 'partner'}
    case DELETE_PARTNER_CURATOR_FAIL:
      return {...state, isPartnerCuratorDeleteFetching: false, partnerCuratorDeleteError: action.error}

    case CREATE_VAILLANT_CURATOR:
      return {...state, isVaillantCuratorCreateFetching: true, vaillantCuratorCreateError: null}
    case CREATE_VAILLANT_CURATOR_SUCCESS:
      return {...state, isVaillantCuratorCreateFetching: false, curatorsEditType: 'vgr'}
    case CREATE_VAILLANT_CURATOR_FAIL:
      return {...state, isVaillantCuratorCreateFetching: false, vaillantCuratorCreateError: action.error}

    case DELETE_VAILLANT_CURATOR:
      return {...state, isVaillantCuratorDeleteFetching: true, vaillantCuratorDeleteError: null}
    case DELETE_VAILLANT_CURATOR_SUCCESS:
      return {...state, isVaillantCuratorDeleteFetching: false, curatorsEditType: 'vgr'}
    case DELETE_VAILLANT_CURATOR_FAIL:
      return {...state, isVaillantCuratorDeleteFetching: false, vaillantCuratorDeleteError: action.error}

    case CREATE_PARTNER_SIGNATURE:
      return {...state, isPartnerSignatureCreateFetching: true, partnerSignatureCreateError: null}
    case CREATE_PARTNER_SIGNATURE_SUCCESS:
      return {...state, isPartnerSignatureCreateFetching: false, curatorsEditType: 'signature'}
    case CREATE_PARTNER_SIGNATURE_FAIL:
      return {...state, isPartnerSignatureCreateFetching: false, partnerSignatureCreateError: action.error}

    case DELETE_PARTNER_SIGNATURE:
      return {...state, isPartnerSignatureDeleteFetching: true, partnerSignatureDeleteError: null}
    case DELETE_PARTNER_SIGNATURE_SUCCESS:
      return {...state, isPartnerSignatureDeleteFetching: false, curatorsEditType: 'signature'}
    case DELETE_PARTNER_SIGNATURE_FAIL:
      return {...state, isPartnerSignatureDeleteFetching: false, partnerSignatureDeleteError: action.error}

    case RESET_CURATORS_EDIT_TYPE:
      return {...state, curatorsEditType: ''}

    default:
      return state
  }
}

export const createPartnerCurator = (partnerCurator, toggleModal) => (dispatch, getState) => {
  dispatch({type: CREATE_PARTNER_CURATOR})
  const partnerState = getState().partners.partner
  const orgNumber = partnerState && partnerState.orgNumber
  axios
    .post(`/partner/${orgNumber}/partner-curator`, partnerCurator)
    .then(() => {
      dispatch({type: CREATE_PARTNER_CURATOR_SUCCESS})
      dispatch(fetchPartner(orgNumber))
      toggleModal()
    })
    .catch(error => handleWithSnackbarError(dispatch, CREATE_PARTNER_CURATOR_FAIL, error))
}

export const deletePartnerCurator = (id, resetSelectedRow) => (dispatch, getState) => {
  dispatch({type: DELETE_PARTNER_CURATOR})
  const partnerState = getState().partners.partner
  const orgNumber = partnerState && partnerState.orgNumber
  axios
    .delete(`/partner/${id}/partner-curator`)
    .then(() => {
      dispatch({type: DELETE_PARTNER_CURATOR_SUCCESS})
      dispatch(fetchPartner(orgNumber))
      resetSelectedRow()
    })
    .catch(error => handleWithSnackbarError(dispatch, DELETE_PARTNER_CURATOR_FAIL, error))
}

export const createVaillantCurator = (vaillantCurator, toggleModal) => (dispatch, getState) => {
  dispatch({type: CREATE_VAILLANT_CURATOR})
  const partnerState = getState().partners.partner
  const orgNumber = partnerState && partnerState.orgNumber
  axios
    .post(`/partner/${orgNumber}/vgr-curator`, vaillantCurator)
    .then(() => {
      dispatch({type: CREATE_VAILLANT_CURATOR_SUCCESS})
      dispatch(fetchPartner(orgNumber))
      toggleModal()
    })
    .catch(error => handleWithSnackbarError(dispatch, CREATE_VAILLANT_CURATOR_FAIL, error))
}

export const deleteVaillantCurator = (id, resetSelectedRow) => (dispatch, getState) => {
  dispatch({type: DELETE_VAILLANT_CURATOR})
  const partnerState = getState().partners.partner
  const orgNumber = partnerState && partnerState.orgNumber
  axios
    .delete(`/partner/${id}/vgr-curator`)
    .then(() => {
      dispatch({type: DELETE_VAILLANT_CURATOR_SUCCESS})
      dispatch(fetchPartner(orgNumber))
      resetSelectedRow()
    })
    .catch(error => handleWithSnackbarError(dispatch, DELETE_VAILLANT_CURATOR_FAIL, error))
}

export const createPartnerSignature = (partnerSignature, resetForm) => (dispatch, getState) => {
  dispatch({type: CREATE_PARTNER_SIGNATURE})
  const partnerState = getState().partners.partner
  const orgNumber = partnerState && partnerState.orgNumber
  axios
    .post(`/partner/${orgNumber}/partner-signature`, partnerSignature)
    .then(() => {
      dispatch({type: CREATE_PARTNER_SIGNATURE_SUCCESS})
      dispatch(fetchPartner(orgNumber))
      resetForm()
    })
    .catch(error => handleWithSnackbarError(dispatch, CREATE_PARTNER_SIGNATURE_FAIL, error))
}

export const deletePartnerSignature = (id, resetSelectedRow) => (dispatch, getState) => {
  dispatch({type: DELETE_PARTNER_SIGNATURE})
  const partnerState = getState().partners.partner
  const orgNumber = partnerState && partnerState.orgNumber
  axios
    .delete(`/partner/${id}/partner-signature`)
    .then(() => {
      dispatch({type: DELETE_PARTNER_SIGNATURE_SUCCESS})
      dispatch(fetchPartner(orgNumber))
      resetSelectedRow()
    })
    .catch(error => handleWithSnackbarError(dispatch, DELETE_PARTNER_SIGNATURE_FAIL, error))
}

export const resetCuratorsEditType = () => ({type: RESET_CURATORS_EDIT_TYPE})
