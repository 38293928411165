import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import CheckCircleIcon from '@material-ui/icons/CheckCircleTwoTone'
import HelpIcon from '@material-ui/icons/HelpTwoTone'
import CancelIcon from '@material-ui/icons/CancelTwoTone'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import {ROLE_PREFIX} from '../../../../utils/constants'
import './style.scss'

AgreementStage.propTypes = {
  curator: PropTypes.object.isRequired,
  isFinalStage: PropTypes.bool.isRequired,
}

const getStageIcon = contractEvent => {
  const eventName = Object.keys(contractEvent)[0]
  return eventName === 'INITIALIZE_CONTRACT' ? (
    <span className="secondary-light f ai-c">
      <HelpIcon />
    </span>
  ) : eventName === 'REJECT' ? (
    <Tooltip disableFocusListener title="Отклонено">
      <span className="error  f ai-c">
        <CancelIcon />
      </span>
    </Tooltip>
  ) : (
    <Tooltip disableFocusListener title="Согласовано">
      <span className="success  f ai-c">
        <CheckCircleIcon />
      </span>
    </Tooltip>
  )
}

const getRoleName = role => {
  switch (role) {
    case 'REGIONAL_REPRESENTATIVE':
      return 'РП'
    case 'REGIONAL_MANAGER':
      return 'РМ'
    case 'DIRECTOR_OF_SALES':
      return 'РБА'
    case 'LAWYER':
      return 'Юрист'
    default:
      return 'ГД'
  }
}

export default function AgreementStage({curator, isFinalStage}) {
  return (
    <Fragment>
      <div className="agreement-stage f ai-c">
        <div className="agreement-stage__icon">{getStageIcon(curator.contractEvent)}</div>
        <div className="agreement-stage__participant">
          <div>{getRoleName(curator.role.replace(ROLE_PREFIX, ''))}</div>
          {curator.curator && (
            <div>
              {curator.curator.lastName} {`${curator.curator.firstName.slice(0, 1)}.`}
              {curator.curator.middleName && curator.curator.middleName !== 'null'
                ? ` ${curator.curator.middleName.slice(0, 1)}.`
                : ''}
            </div>
          )}
        </div>
      </div>
      {!isFinalStage && (
        <span className="grey f ai-c">
          <ArrowForwardIcon />
        </span>
      )}
    </Fragment>
  )
}
