import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {Form, Field} from 'react-final-form'
import moment from 'moment'
import {withStyles} from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import Grid from '@material-ui/core/Grid'
import Fab from '@material-ui/core/Fab'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'

import withMultipleModals from '../../_decorators/withMultipleModals'
import GridContainer from '../../_common/GridContainer'
import InputField from '../../_common/InputField'
import Confirmation from '../../_common/Confirmation'
import Modal from '../../_common/Modal'
import ActsReportForm from './ActsReportForm'
import {THEME} from '../../../styles/muiTheme'

const StyledFab = withStyles({
  root: {
    marginRight: THEME.UNIT,
    flexShrink: 0,
  },
})(Fab)

const StyledButton = withStyles({
  root: {
    marginRight: THEME.UNIT,
    flexShrink: 0,
  },
})(Button)

class ActsSearchForm extends PureComponent {
  static propTypes = {
    isSellOut: PropTypes.bool,
    placeholder: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    isFetching: PropTypes.bool.isRequired,
    isActsInit: PropTypes.bool.isRequired,
    isActsReportDownload: PropTypes.bool.isRequired,
    openedModal: PropTypes.string.isRequired,

    setSearch: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,
    initActs: PropTypes.func.isRequired,
    downloadActsReport: PropTypes.func.isRequired,
    onModalOpen: PropTypes.func.isRequired,
    onModalClose: PropTypes.func.isRequired,
  }

  handleFormSubmit = ({search}) => {
    const {setSearch, fetchData} = this.props
    setSearch(search)
    fetchData()
  }

  handleChange = value => !value && this.props.setSearch('')

  handleActsInit = () => {
    const {initActs, onModalClose} = this.props
    const previousReportDate = moment().subtract(1, 'Q')
    const params = {
      quarter: previousReportDate.quarter(),
      year: previousReportDate.year(),
    }
    initActs(params)
    onModalClose()
  }

  render() {
    const {
      openedModal,
      placeholder,
      search,
      isFetching,
      isActsInit,
      isActsReportDownload,
      downloadActsReport,
      onModalOpen,
      onModalClose,
    } = this.props
    const initialValues = {
      search,
    }
    return (
      <>
        <Form
          initialValues={initialValues}
          onSubmit={this.handleFormSubmit}
          render={({handleSubmit, values}) => (
            <form noValidate className="mb" autoComplete="off" onSubmit={handleSubmit}>
              <GridContainer>
                <Grid item xs={5}>
                  <div className="f ai-c">
                    <Field
                      name="search"
                      variant="standard"
                      placeholder={placeholder}
                      component={InputField}
                      onChange={this.handleChange}
                    />
                    <Tooltip disableFocusListener title="Найти" enterDelay={500}>
                      <div>
                        <StyledFab type="submit" size="small" color="secondary" disabled={!values.search || isFetching}>
                          <SearchIcon />
                        </StyledFab>
                      </div>
                    </Tooltip>
                  </div>
                </Grid>
                <Grid item xs={7}>
                  <div className="f jc-fe ai-c">
                    <StyledButton variant="contained" color="primary" onClick={onModalOpen('regenerate')}>
                      Переформировать акты
                    </StyledButton>
                    <Button variant="contained" color="primary" onClick={onModalOpen('report')}>
                      Сформировать отчет
                    </Button>
                  </div>
                </Grid>
              </GridContainer>
            </form>
          )}
        />
        <Confirmation
          title="Переформировать акты"
          isOpen={openedModal === 'regenerate'}
          isFetching={isActsInit}
          confirmAction={this.handleActsInit}
          onClose={onModalClose}
        />
        <Modal isForm open={openedModal === 'report'} title="Сформировать отчет" onClose={onModalClose}>
          <ActsReportForm
            isFetching={isActsReportDownload}
            isModalOpen={openedModal === 'report'}
            downloadActsReport={downloadActsReport}
            onModalClose={onModalClose}
          />
        </Modal>
      </>
    )
  }
}

export default withMultipleModals(ActsSearchForm)
