import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import {
  fetchActs,
  setActsSize,
  setActsPage,
  setActsSort,
  setActsSearch,
  initActs,
  downloadActsReport,
} from '../../redux/acts'
import ExtendedTable from '../_common/ExtendedTable'
import Loader from '../_common/Loader'
import ActsSearchForm from './ActsSearchForm'
import URLS from '../../utils/urls'

const headerCells = [
  {title: 'Идентификатор', sortName: 'orgNumber'},
  {title: 'Период', sortName: 'period'},
  {title: 'Название', sortName: 'orgName'},
  {title: 'Тип партнера', sortName: 'orgType'},
  {title: 'Статус акта', sortName: 'actStatus'},
]

class Acts extends Component {
  static propTypes = {
    acts: PropTypes.object,
    isActsFetching: PropTypes.bool.isRequired,
    actsSize: PropTypes.number.isRequired,
    actsPage: PropTypes.number.isRequired,
    actsSort: PropTypes.array,
    actsSearch: PropTypes.string.isRequired,
    isActsInit: PropTypes.bool.isRequired,
    isActsReportDownload: PropTypes.bool.isRequired,

    fetchActs: PropTypes.func.isRequired,
    setActsSize: PropTypes.func.isRequired,
    setActsPage: PropTypes.func.isRequired,
    setActsSort: PropTypes.func.isRequired,
    setActsSearch: PropTypes.func.isRequired,
    initActs: PropTypes.func.isRequired,
    downloadActsReport: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.props.fetchActs()
  }

  componentWillReceiveProps(nextProps) {
    const {actsSize, actsPage, actsSort, actsSearch, fetchActs} = this.props
    if (nextProps.actsSize !== actsSize || nextProps.actsPage !== actsPage || nextProps.actsSort !== actsSort) {
      fetchActs()
    }
    if (!nextProps.actsSearch && nextProps.actsSearch !== actsSearch) {
      fetchActs()
    }
  }

  handleGoToPartner = id => () => this.props.history.push(`${URLS.ACTS}/${id}`)

  getTableRows() {
    const {acts} = this.props
    return (
      acts &&
      acts.content.map(item => ({
        id: item.orgNumber,
        data: [item.orgNumber, item.period, item.orgName, item.orgType, item.actStatus],
      }))
    )
  }

  render() {
    const {
      acts,
      isActsFetching,
      actsSize,
      actsPage,
      actsSort,
      actsSearch,
      isActsInit,
      isActsReportDownload,

      fetchActs,
      setActsSize,
      setActsPage,
      setActsSort,
      setActsSearch,
      initActs,
      downloadActsReport,
    } = this.props
    const total = acts ? acts.totalElements : 0
    return (
      <>
        <Loader isFetch={isActsInit || isActsReportDownload} />
        <section className="container loaded">
          <ActsSearchForm
            placeholder="Введите номер или название партнера"
            search={actsSearch}
            isFetching={isActsFetching}
            setSearch={setActsSearch}
            isActsInit={isActsInit}
            isActsReportDownload={isActsReportDownload}
            fetchData={fetchActs}
            initActs={initActs}
            downloadActsReport={downloadActsReport}
          />
          <ExtendedTable
            headerCells={headerCells}
            tableRows={this.getTableRows()}
            isFetching={isActsFetching}
            total={total}
            rowsPerPage={actsSize}
            page={actsPage}
            sort={actsSort}
            setPage={setActsPage}
            setSize={setActsSize}
            setSort={setActsSort}
            onRowClick={this.handleGoToPartner}
          />
        </section>
      </>
    )
  }
}

const mapStateToProps = ({acts}) => ({
  acts: acts.acts,
  isActsFetching: acts.isActsFetching,
  actsSize: acts.actsSize,
  actsPage: acts.actsPage,
  actsSort: acts.actsSort,
  actsSearch: acts.actsSearch,
  isActsInit: acts.isActsInit,
  isActsReportDownload: acts.isActsReportDownload,
})

const mapDispatchToProps = {
  fetchActs,
  setActsSize,
  setActsPage,
  setActsSort,
  setActsSearch,
  initActs,
  downloadActsReport,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Acts)
