import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'

import {
  fetchConfirmedDocuments,
  setConfirmedDocumentsSize,
  setConfirmedDocumentsPage,
  setConfirmedDocumentsSort,
  setConfirmedDocumentsSearch,
  selectConfirmedDocument,
  downloadFile,
  downloadScanFile,
} from '../../redux/documents'
import withModal from '../_decorators/withModal'
import Loader from '../_common/Loader'
import Modal from '../_common/Modal'
import SearchConfirmedDocumentForm from './SearchConfirmedDocumentForm'
import ConfirmedDocumentsTable from './ConfirmedDocumentsTable'
import ConfirmedPartnerTable from './ConfirmedPartnerTable'
import ScanForm from './ScanForm'
import {isDocumentHasScan, getDocumentFile} from '../../utils/helpres'

class Scans extends Component {
  static propTypes = {
    confirmedDocuments: PropTypes.object,
    isConfirmedDocumentsFetching: PropTypes.bool.isRequired,
    confirmedDocumentsSize: PropTypes.number.isRequired,
    confirmedDocumentsPage: PropTypes.number.isRequired,
    confirmedDocumentsSort: PropTypes.array,
    confirmedDocumentsSearch: PropTypes.string.isRequired,
    selectedConfirmedDocument: PropTypes.string,
    isFileDownloadFetching: PropTypes.bool.isRequired,
    isScanFileDownloadFetching: PropTypes.bool.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    fetchConfirmedDocuments: PropTypes.func.isRequired,
    setConfirmedDocumentsSize: PropTypes.func.isRequired,
    setConfirmedDocumentsPage: PropTypes.func.isRequired,
    setConfirmedDocumentsSort: PropTypes.func.isRequired,
    setConfirmedDocumentsSearch: PropTypes.func.isRequired,
    selectConfirmedDocument: PropTypes.func.isRequired,
    downloadFile: PropTypes.func.isRequired,
    downloadScanFile: PropTypes.func.isRequired,
    onModalToggle: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.props.fetchConfirmedDocuments()
  }

  componentWillReceiveProps(nextProps) {
    const {
      confirmedDocumentsSize,
      confirmedDocumentsPage,
      confirmedDocumentsSort,
      confirmedDocumentsSearch,
      fetchConfirmedDocuments,
    } = this.props
    if (
      nextProps.confirmedDocumentsSize !== confirmedDocumentsSize ||
      nextProps.confirmedDocumentsPage !== confirmedDocumentsPage ||
      nextProps.confirmedDocumentsSort !== confirmedDocumentsSort
    ) {
      fetchConfirmedDocuments()
    }
    if (!nextProps.confirmedDocumentsSearch && nextProps.confirmedDocumentsSearch !== confirmedDocumentsSearch) {
      fetchConfirmedDocuments(true)
    }
  }

  render() {
    const {
      confirmedDocuments,
      isConfirmedDocumentsFetching,
      confirmedDocumentsSize,
      confirmedDocumentsPage,
      confirmedDocumentsSort,
      confirmedDocumentsSearch,
      selectedConfirmedDocument,
      isFileDownloadFetching,
      isScanFileDownloadFetching,
      isModalOpen,
      fetchConfirmedDocuments,
      setConfirmedDocumentsSize,
      setConfirmedDocumentsPage,
      setConfirmedDocumentsSort,
      setConfirmedDocumentsSearch,
      selectConfirmedDocument,
      downloadFile,
      downloadScanFile,
      onModalToggle,
    } = this.props
    const selectedParts = selectedConfirmedDocument && selectedConfirmedDocument.split('-')
    const selectedDocumentData = selectedConfirmedDocument
      ? confirmedDocuments.content.find(document =>
          selectedParts[2]
            ? document.actId === +selectedParts[2]
            : selectedParts[1]
            ? document.bonusId === +selectedParts[1]
            : !document.bonusId && !document.actId && document.id === +selectedParts[0]
        )
      : null
    const selectedDocumentFile = selectedDocumentData && getDocumentFile(selectedDocumentData)
    let isSelectedDocumentHasScan = false
    selectedDocumentData &&
      selectedDocumentData.documentFile.forEach(item => {
        if (isDocumentHasScan(selectedDocumentData, item)) {
          isSelectedDocumentHasScan = true
        }
      })
    return (
      <>
        <Loader isFetch={isFileDownloadFetching || isScanFileDownloadFetching} />
        <section className="container loaded">
          <SearchConfirmedDocumentForm
            confirmedDocumentsSearch={confirmedDocumentsSearch}
            isConfirmedDocumentsFetching={isConfirmedDocumentsFetching}
            setConfirmedDocumentsSearch={setConfirmedDocumentsSearch}
            fetchConfirmedDocuments={fetchConfirmedDocuments}
          />
          <Paper>
            <div className="block">
              <div className="mb">
                <ConfirmedDocumentsTable
                  confirmedDocuments={confirmedDocuments}
                  isConfirmedDocumentsFetching={isConfirmedDocumentsFetching}
                  confirmedDocumentsSize={confirmedDocumentsSize}
                  confirmedDocumentsPage={confirmedDocumentsPage}
                  confirmedDocumentsSort={confirmedDocumentsSort}
                  selectedConfirmedDocument={selectedConfirmedDocument}
                  setConfirmedDocumentsSize={setConfirmedDocumentsSize}
                  setConfirmedDocumentsPage={setConfirmedDocumentsPage}
                  setConfirmedDocumentsSort={setConfirmedDocumentsSort}
                  selectConfirmedDocument={selectConfirmedDocument}
                  downloadFile={downloadFile}
                  downloadScanFile={downloadScanFile}
                />
              </div>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={onModalToggle}
                disabled={!selectedDocumentData || isSelectedDocumentHasScan}
              >
                Загрузить документ
              </Button>
              {selectedDocumentData && (
                <div key={selectedDocumentData.id} className="mt loaded">
                  <ConfirmedPartnerTable partner={selectedDocumentData.partnerCard} />
                </div>
              )}
            </div>
          </Paper>
          <Modal isForm open={isModalOpen} maxWidth="md" title="Загрузка скана" onClose={onModalToggle}>
            <ScanForm
              isModalOpen={isModalOpen}
              isScanFileDownloadFetching={isScanFileDownloadFetching}
              documentFile={selectedDocumentFile}
              downloadScanFile={downloadScanFile}
              onModalClose={onModalToggle}
            />
          </Modal>
        </section>
      </>
    )
  }
}

const mapStateToProps = ({documents}) => ({
  confirmedDocuments: documents.confirmedDocuments,
  isConfirmedDocumentsFetching: documents.isConfirmedDocumentsFetching,
  confirmedDocumentsSize: documents.confirmedDocumentsSize,
  confirmedDocumentsPage: documents.confirmedDocumentsPage,
  confirmedDocumentsSort: documents.confirmedDocumentsSort,
  confirmedDocumentsSearch: documents.confirmedDocumentsSearch,
  selectedConfirmedDocument: documents.selectedConfirmedDocument,
  isFileDownloadFetching: documents.isFileDownloadFetching,
  isScanFileDownloadFetching: documents.isScanFileDownloadFetching,
})

const mapDispatchToProps = {
  fetchConfirmedDocuments,
  setConfirmedDocumentsSize,
  setConfirmedDocumentsPage,
  setConfirmedDocumentsSort,
  setConfirmedDocumentsSearch,
  selectConfirmedDocument,
  downloadFile,
  downloadScanFile,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withModal(Scans))
