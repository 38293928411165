const module = 'ui'

const OPEN_SUCCESS_SNACKBAR = `${module}/OPEN_SUCCESS_SNACKBAR`
const OPEN_ERROR_SNACKBAR = `${module}/OPEN_ERROR_SNACKBAR`
const CLOSE_SNACKBAR = `${module}/CLOSE_SNACKBAR`

const TOGGLE_PARTNER_PANEL_EXPANDED = `${module}/TOGGLE_PARTNER_PANEL_EXPANDED`
const RESET_PARTNER_PANELS_EXPANDED = `${module}/RESET_PARTNER_PANELS_EXPANDED`

const panelsExpandedInitialState = {
  isPartnerPanelExpand1: false,
  isPartnerPanelExpand2: false,
  isPartnerPanelExpand3: false,
  isPartnerPanelExpand4: false,
  isPartnerPanelExpand5: false,
}

const initialState = {
  ...panelsExpandedInitialState,
  isSuccessSnackbarOpen: false,
  isErrorSnackbarOpen: false,
  snackbarMessage: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_SUCCESS_SNACKBAR:
      return {
        ...state,
        isSuccessSnackbarOpen: true,
        isErrorSnackbarOpen: false,
        snackbarMessage: action.snackbarMessage,
      }
    case OPEN_ERROR_SNACKBAR:
      return {
        ...state,
        isErrorSnackbarOpen: true,
        isSuccessSnackbarOpen: false,
        snackbarMessage: action.snackbarMessage,
      }
    case CLOSE_SNACKBAR:
      return {...state, isSuccessSnackbarOpen: false, isErrorSnackbarOpen: false}

    case TOGGLE_PARTNER_PANEL_EXPANDED:
      const currentPanel = `isPartnerPanelExpand${action.index}`
      return {...state, [currentPanel]: !state[currentPanel]}

    case RESET_PARTNER_PANELS_EXPANDED:
      return {
        ...state,
        ...panelsExpandedInitialState,
      }

    default:
      return state
  }
}

export const openSuccessSnackbar = snackbarMessage => ({type: OPEN_SUCCESS_SNACKBAR, snackbarMessage})
export const openErrorSnackbar = snackbarMessage => ({type: OPEN_ERROR_SNACKBAR, snackbarMessage})
export const closeSnackbar = () => ({type: CLOSE_SNACKBAR})

export const toggleCurrentPanelExpanded = index => ({type: TOGGLE_PARTNER_PANEL_EXPANDED, index})
export const resetCurrentPanelsExpanded = () => ({type: RESET_PARTNER_PANELS_EXPANDED})
