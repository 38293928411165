import React from 'react'
import PropTypes from 'prop-types'

import Card from '../Card'
import SimpleStatistics from '../SimpleStatistics'
import './style.css'

PartnersView.propTypes = {
  data: PropTypes.array,
}

PartnersView.defaultProps = {
  data: [],
}

function PartnersView({data}) {
  return (
    <div className="partners-container-wrapper">
      <div className="partners-container">
        {data.map(item => (
          <Card cardName={item.cardName} smallCard={true} key={item.cardName}>
            <SimpleStatistics value={item.value} small={true} />
          </Card>
        ))}
      </div>
    </div>
  )
}

export default PartnersView
