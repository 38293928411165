import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import Portal from '@material-ui/core/Portal'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import AccountCircleIcon from '@material-ui/icons/AccountCircleTwoTone'
import MenuIcon from '@material-ui/icons/MenuTwoTone'

import './style.scss'
import {closeSnackbar} from '../../redux/ui'
import MenuDropdown from '../_common/MenuDropdown'
import Notification from '../_common/Notification'
import Sidebar from '../Sidebar'
import {getTitle} from '../../utils/helpres'
import {COLORS} from '../../styles/muiTheme'

const StyledIconButton = withStyles({
  root: {
    position: 'relative',
    left: -14,
    color: COLORS.textPrimary,
  },
})(IconButton)

class Header extends Component {
  state = {
    isOpen: false,
  }

  static propTypes = {
    location: PropTypes.object.isRequired,
    isCompact: PropTypes.bool.isRequired,
    user: PropTypes.object,
    role: PropTypes.string.isRequired,
    backInfo: PropTypes.object,
    partner: PropTypes.object,
    partnerTypes: PropTypes.array,
    isSuccessSnackbarOpen: PropTypes.bool.isRequired,
    isErrorSnackbarOpen: PropTypes.bool.isRequired,
    snackbarMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    closeSnackbar: PropTypes.func.isRequired,
    logOut: PropTypes.func.isRequired,
  }

  handleDrawerToggle = () => this.setState(state => ({isOpen: !state.isOpen}))

  render() {
    const {
      location,
      isCompact,
      user,
      role,
      backInfo,
      partner,
      partnerTypes,
      isSuccessSnackbarOpen,
      isErrorSnackbarOpen,
      snackbarMessage,
      closeSnackbar,
      logOut,
    } = this.props
    const {isOpen} = this.state
    const title = getTitle(location.pathname, role, partner, partnerTypes)
    const userLinks = [
      {
        id: 1,
        text: 'Выход',
        handler: logOut,
      },
    ]
    const userContent = user
      ? `${user.lastName} ${user.firstName}${
          user.middleName && user.middleName !== 'null' ? ` ${user.middleName}` : ''
        }`
      : 'Пользователь'
    return (
      <Fragment>
        <header className="header container f ai-c jc-sb">
          <div className="f ai-c">
            {isCompact && (
              <StyledIconButton onClick={this.handleDrawerToggle}>
                <MenuIcon />
              </StyledIconButton>
            )}
            <h2 className="title">{title}</h2>
          </div>
          <div className="header__user ml">
            <MenuDropdown links={userLinks}>
              <AccountCircleIcon />
              <span className="header__user-name ws-nw">{userContent}</span>
            </MenuDropdown>
          </div>
          <Portal container={document.body}>
            <Notification
              open={isSuccessSnackbarOpen}
              variant="success"
              message={snackbarMessage}
              onClose={closeSnackbar}
            />
            <Notification
              open={isErrorSnackbarOpen}
              variant="error"
              message={snackbarMessage}
              onClose={closeSnackbar}
            />
          </Portal>
        </header>
        {isCompact && (
          <Drawer open={isOpen} onClose={this.handleDrawerToggle}>
            <Sidebar role={role} backInfo={backInfo} onClose={this.handleDrawerToggle} />
          </Drawer>
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = ({partners, references, ui}) => ({
  partner: partners.partner,
  partnerTypes: references.partnerTypes,
  isSuccessSnackbarOpen: ui.isSuccessSnackbarOpen,
  isErrorSnackbarOpen: ui.isErrorSnackbarOpen,
  snackbarMessage: ui.snackbarMessage,
})

const mapDispatchToProps = {
  closeSnackbar,
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Header)
)
