import ReactDOM from 'react-dom'
import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import {CSSTransition} from 'react-transition-group'
import CircularProgress from '@material-ui/core/CircularProgress'

import './style.scss'
import {COLORS, THEME} from '../../../styles/muiTheme'

const styles = {
  circularProgress: {
    color: COLORS.primaryLight,
  },
}

Loader.propTypes = {
  isFetch: PropTypes.bool,
  isBlock: PropTypes.bool,
  isApp: PropTypes.bool,
}

export default function Loader({isFetch, isBlock, isApp}) {
  const loader = (
    <CSSTransition in={isFetch} classNames="loader" timeout={THEME.SHORTER} unmountOnExit>
      <div className={cn('loader', {loader_block: isBlock, loader_app: isApp}, 'f ai-c jc-c')}>
        <CircularProgress size={50} style={styles.circularProgress} />
      </div>
    </CSSTransition>
  )
  return isBlock || isApp ? loader : ReactDOM.createPortal(loader, document.body)
}
