import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import AgreementComment from './AgreementComment'

import './style.scss'

AgreementComments.propTypes = {
  curators: PropTypes.array.isRequired,
  comments: PropTypes.array.isRequired,
}

export default function AgreementComments({curators, comments}) {
  const sortedComments = [...comments].sort((a, b) => moment(b.createdDate) - moment(a.createdDate))
  return (
    <div className="agreement-comments wrapper wrapper_scroll mt mb">
      <ul>
        {sortedComments.map(comment => (
          <AgreementComment key={comment.id} curators={curators} comment={comment} />
        ))}
      </ul>
    </div>
  )
}
