import React from 'react'
import PropTypes from 'prop-types'

import './style.css'

PercentageDifferenceBar.propTypes = {
  value: PropTypes.number.isRequired,
}

function PercentageDifferenceBar({value}) {
  return (
    <div className={`bar ${value < 0 ? 'bar_decrease' : ''}`}>
      <span>{`${value}%`}</span>
    </div>
  )
}

export default PercentageDifferenceBar
