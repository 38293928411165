export const BASE_URL =
  process.env.NODE_ENV === 'development' ? 'http://vaillant-keycloak.int.tsftd.ru' : window.location.origin
export const API_URL = `${BASE_URL}/api/v1`

const URLS = {
  DASHBOARD: '/',
  PARTNERS: '/partners',
  AGREEMENT: '/agreement',
  AGREEMENT_RECREATE: '/agreement/recreate',
  HISTORY: '/history',
  SCANS: '/scans',
  SELL_IN: '/sell-in',
  SELL_OUT: '/sell-out',
  REPORTS: '/reports',
  ACTS: '/acts',
  REFERENCES: '/references',
}

export default URLS
