import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {Form, Field} from 'react-final-form'
import {withStyles} from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'

import GridContainer from '../../_common/GridContainer'
import {THEME} from '../../../styles/muiTheme'

const styles = {
  fabSearch: {
    marginLeft: THEME.UNIT,
    flexShrink: 0,
  },
}

class SearchConfirmedDocumentForm extends PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    confirmedDocumentsSearch: PropTypes.string,
    isConfirmedDocumentsFetching: PropTypes.bool.isRequired,
    setConfirmedDocumentsSearch: PropTypes.func.isRequired,
    fetchConfirmedDocuments: PropTypes.func.isRequired,
  }

  handleFormSubmit = ({search}) => {
    const {setConfirmedDocumentsSearch, fetchConfirmedDocuments} = this.props
    setConfirmedDocumentsSearch(search)
    fetchConfirmedDocuments(true)
  }

  handleInputChange = onChange => ({target: {value}}) => {
    if (!value) this.props.setConfirmedDocumentsSearch('')
    onChange(value)
  }

  renderInput = ({input: {value, onChange}}) => {
    return (
      <Input
        fullWidth
        value={value}
        type="search"
        placeholder="Введите номер, название, ИНН партнера или номер документа"
        onChange={this.handleInputChange(onChange)}
      />
    )
  }

  render() {
    const {classes, confirmedDocumentsSearch, isConfirmedDocumentsFetching} = this.props
    const initialValues = {
      search: confirmedDocumentsSearch,
    }
    return (
      <Form
        initialValues={initialValues}
        onSubmit={this.handleFormSubmit}
        render={({handleSubmit, values}) => (
          <form noValidate className="mb" autoComplete="off" onSubmit={handleSubmit}>
            <GridContainer>
              <Grid item xs={8}>
                <div className="f ai-c">
                  <Field name="search" component={this.renderInput} />
                  <Tooltip disableFocusListener title="Найти" enterDelay={500}>
                    <div>
                      <Fab
                        className={classes.fabSearch}
                        type="submit"
                        size="small"
                        color="secondary"
                        disabled={!values.search || isConfirmedDocumentsFetching}
                      >
                        <SearchIcon />
                      </Fab>
                    </div>
                  </Tooltip>
                </div>
              </Grid>
            </GridContainer>
          </form>
        )}
      />
    )
  }
}

export default withStyles(styles)(SearchConfirmedDocumentForm)
