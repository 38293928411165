import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import ReactFileReader from 'react-file-reader'
import {Form, Field} from 'react-final-form'
import moment from 'moment'
import Button from '@material-ui/core/Button'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate'

import GridContainer from '../../_common/GridContainer'
import InputField from '../../_common/InputField'

const styles = {
  dialogContent: {
    paddingTop: 0,
  },
}

export default class ScanForm extends PureComponent {
  state = {
    file: null,
  }

  static propTypes = {
    documentFile: PropTypes.object,
    isScanFileDownloadFetching: PropTypes.bool.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    downloadScanFile: PropTypes.func.isRequired,
    onModalClose: PropTypes.func.isRequired,
  }

  handleFormSubmit = () => {
    const {documentFile, downloadScanFile, onModalClose} = this.props
    const params = {
      document_number: documentFile.documentNumber,
    }
    if (!documentFile.annex && documentFile.act) params.act_id = documentFile.actId
    if (!documentFile.act && documentFile.annex) params.bonus_id = documentFile.bonusTypeId
    downloadScanFile(this.state.file, params, onModalClose)
  }

  handleFiles = files => this.setState({file: files[0]})

  render() {
    const {documentFile, isModalOpen, isScanFileDownloadFetching, onModalClose} = this.props
    const {file} = this.state
    const isAct = documentFile.act
    const initialValues = {
      contractType: isAct
        ? `Акт ${documentFile.actPeriod}`
        : documentFile.bonusTypeName || documentFile.contractTypeName,
      document_number: isAct ? documentFile.actNumber : documentFile.documentNumber,
      createdDate: moment(documentFile.createdDate).format('YYYY-MM-DD'),
      fileName: file && file.name,
      file: file,
    }
    return (
      <Form
        initialValues={initialValues}
        onSubmit={this.handleFormSubmit}
        render={({handleSubmit}) => (
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <DialogContent style={styles.dialogContent}>
              <GridContainer>
                <Grid item xs={4}>
                  <Field disabled autoFocus name="contractType" label="Тип документа" component={InputField} />
                </Grid>
                <Grid item xs={4}>
                  <Field disabled name="document_number" label="Номер документа" component={InputField} />
                </Grid>
                <Grid item xs={4}>
                  <Field disabled name="createdDate" label="Дата создания" type="date" component={InputField} />
                </Grid>
              </GridContainer>
              <GridContainer>
                <Grid item xs={12}>
                  <div className="f ai-c">
                    <Field disabled name="fileName" label="Сканкопия" component={InputField} />
                    <div className="ml">
                      <ReactFileReader handleFiles={this.handleFiles}>
                        <Tooltip disableFocusListener title="Выбрать файл" enterDelay={500}>
                          <Fab size="medium" color="primary">
                            <AddPhotoAlternateIcon />
                          </Fab>
                        </Tooltip>
                      </ReactFileReader>
                    </div>
                  </div>
                </Grid>
              </GridContainer>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={isModalOpen ? onModalClose : null}>
                Закрыть
              </Button>
              <Button type="submit" color="primary" disabled={!isModalOpen || !file || isScanFileDownloadFetching}>
                Загрузить
              </Button>
            </DialogActions>
          </form>
        )}
      />
    )
  }
}
