import React, {Component, Fragment} from 'react'
import {findDOMNode} from 'react-dom'
import PropTypes from 'prop-types'
import {Form, Field} from 'react-final-form'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import GridContainer from '../../../GridContainer'
import Loader from '../../../Loader'
import InputField from '../../../InputField'
import {focusOnError} from '../../../../../utils/helpres'
import {validateContactSubscriber} from '../../../../../utils/validator'

export default class SignatureForm extends Component {
  static propTypes = {
    selectedSignature: PropTypes.object,
    partnerOriginalSignatures: PropTypes.array,
    isGetFetching: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    isFederalOrRegionalDistributor: PropTypes.bool.isRequired,
    createPartnerSignature: PropTypes.func.isRequired,
    onSelectedSignatureRowReset: PropTypes.func.isRequired,
  }

  handleFormSubmit = (values, handlers) => {
    this.props.createPartnerSignature(values, handlers.reset)
  }

  handleNewSubscriberStart = () => {
    const {selectedSignature, onSelectedSignatureRowReset} = this.props
    if (selectedSignature) onSelectedSignatureRowReset()
    findDOMNode(this.firstField)
      .querySelector('input')
      .focus()
  }

  handleInputBlur = (value, onChange) => {
    const trimmedValue = value.trim()
    if (trimmedValue && trimmedValue[0] !== trimmedValue[0].toUpperCase()) {
      onChange(trimmedValue[0].toUpperCase() + trimmedValue.slice(1))
    }
  }

  getFirstFieldRef = field => (this.firstField = field)

  getInitialValues() {
    if (!this.props.selectedSignature)
      return {
        sex: 'MALE',
        isSC: false,
      }
    const {
      id,
      surnameAndInitials,
      fullNameInGenitiveCase,
      position,
      positionInGenitiveCase,
      signatureBaseInGenitiveCase,
      sex,
      isSC,
    } = this.props.selectedSignature
    return {
      id,
      surnameAndInitials,
      fullNameInGenitiveCase,
      position,
      positionInGenitiveCase,
      signatureBaseInGenitiveCase,
      sex,
      isSC,
    }
  }

  isSubmitDisabled(isSC) {
    const {selectedSignature, partnerOriginalSignatures, isFederalOrRegionalDistributor} = this.props
    return (
      !selectedSignature &&
      Boolean(
        partnerOriginalSignatures &&
          (isFederalOrRegionalDistributor
            ? partnerOriginalSignatures.length >= 2
            : partnerOriginalSignatures.filter(signature => signature.isSC === isSC).length >= 2)
      )
    )
  }

  render() {
    const {selectedSignature, isGetFetching, isFetching, isFederalOrRegionalDistributor} = this.props
    return (
      <Form
        initialValues={this.getInitialValues()}
        validate={validateContactSubscriber}
        decorators={[focusOnError]}
        onSubmit={this.handleFormSubmit}
        render={({handleSubmit, values}) => (
          <Fragment>
            <Loader isFetch={isFetching} />
            <GridContainer>
              <Grid item xs={12}>
                <Button variant="outlined" color="primary" onClick={this.handleNewSubscriberStart}>
                  Новая
                </Button>
              </Grid>
            </GridContainer>
            <GridContainer>
              <Grid item xs={6}>
                <Field
                  name="surnameAndInitials"
                  label="Фамилия, инициалы"
                  ref={this.getFirstFieldRef}
                  component={InputField}
                />
              </Grid>
              <Grid item xs={6}>
                <Field name="fullNameInGenitiveCase" label="ФИО (род. пад.)" component={InputField} />
              </Grid>
            </GridContainer>
            <GridContainer>
              <Grid item xs={6}>
                <Field
                  name="position"
                  label="Должность (имен. пад.)"
                  component={InputField}
                  onBlur={this.handleInputBlur}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="positionInGenitiveCase"
                  label="Должность (род. пад.)"
                  component={InputField}
                  onBlur={this.handleInputBlur}
                />
              </Grid>
            </GridContainer>
            <GridContainer>
              <Grid item xs={12}>
                <Field
                  name="signatureBaseInGenitiveCase"
                  label="Основание (род. пад.)"
                  placeholder="Устава / доверенности / свидетельства"
                  component={InputField}
                  onBlur={this.handleInputBlur}
                />
              </Grid>
            </GridContainer>
            <GridContainer>
              <Grid item xs={7}>
                <div className="f ai-c">
                  <div className="radio-title radio-title_no-padding">Пол:</div>
                  <Field name="sex" type="radio" value="MALE" label="Мужчина" component={InputField} />
                  <Field name="sex" type="radio" value="FEMALE" label="Женщина" component={InputField} />
                </div>
              </Grid>
              <Grid item xs={5}>
                {!isFederalOrRegionalDistributor && (
                  <div className="f ai-c jc-fe">
                    <Field name="isSC" type="checkbox" label="Для СЦ" component={InputField} />
                  </div>
                )}
              </Grid>
            </GridContainer>
            <GridContainer>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={this.isSubmitDisabled(values.isSC) || isFetching || isGetFetching}
                >
                  {selectedSignature ? 'Изменить' : 'Добавить'}
                </Button>
              </Grid>
            </GridContainer>
          </Fragment>
        )}
      />
    )
  }
}
