import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Fab from '@material-ui/core/Fab'
import FormHelperText from '@material-ui/core/FormHelperText'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete'

import {createPartnerSignature, deletePartnerSignature} from '../../../../redux/curators'
import withRowSelect from '../../../_decorators/withRowSelect'
import GridContainer from '../../../_common/GridContainer'
import ExtendedTable from '../../../_common/ExtendedTable'
import Loader from '../../../_common/Loader'
import SignatureForm from './SignatureForm'
import {isCuratorsTableFetching, getSexValue, getCheckValue} from '../../../../utils/helpres'

class PartnerSignatures extends Component {
  static propTypes = {
    isRead: PropTypes.bool,
    partner: PropTypes.object,
    curatorsEditType: PropTypes.string,
    isPartnerFetching: PropTypes.bool.isRequired,
    isPartnerSignatureCreateFetching: PropTypes.bool.isRequired,
    isPartnerSignatureDeleteFetching: PropTypes.bool.isRequired,
    selectedRow: PropTypes.number,
    isFederalOrRegionalDistributor: PropTypes.bool.isRequired,
    isSignaturesLimit: PropTypes.bool,
    createPartnerSignature: PropTypes.func.isRequired,
    deletePartnerSignature: PropTypes.func.isRequired,
    onRowSelect: PropTypes.func.isRequired,
    onSelectedRowReset: PropTypes.func.isRequired,
  }

  handlePartnerSignatureDelete = () => {
    const {selectedRow, deletePartnerSignature, onSelectedRowReset} = this.props
    deletePartnerSignature(selectedRow, onSelectedRowReset)
  }

  getPartnerSignatureRows() {
    const {partner} = this.props
    return partner
      ? partner.signaturePartnerCurators.map(curator => ({
          id: curator.id,
          data: [curator.surnameAndInitials, getSexValue(curator.sex), getCheckValue(curator.isSC)],
        }))
      : []
  }

  getPartnerSignatureDetailRows() {
    const {partner} = this.props
    return partner
      ? partner.signaturePartnerCurators.map(curator => ({
          id: curator.id,
          data: [
            curator.surnameAndInitials,
            curator.fullNameInGenitiveCase,
            curator.position,
            curator.positionInGenitiveCase,
            curator.signatureBaseInGenitiveCase,
            getSexValue(curator.sex),
            getCheckValue(curator.isSC),
          ],
        }))
      : []
  }

  getWithoutSCPartnerSignatures = signatures => {
    const {isFederalOrRegionalDistributor} = this.props
    return isFederalOrRegionalDistributor
      ? signatures.map(signature => ({
          id: signature.id,
          data: [...signature.data.slice(0, signature.data.length - 1)],
        }))
      : signatures
  }

  render() {
    const {
      isRead,
      partner,
      curatorsEditType,
      isPartnerFetching,
      isPartnerSignatureCreateFetching,
      isPartnerSignatureDeleteFetching,
      selectedRow,
      isFederalOrRegionalDistributor,
      isSignaturesLimit,
      createPartnerSignature,
      onRowSelect,
      onSelectedRowReset,
    } = this.props
    const headerCells = [{title: 'ФИО'}, {title: 'Пол'}, {title: 'СЦ'}]
    const headerDetailsCells = [
      {title: 'Фамилия, инициалы'},
      {title: 'ФИО род. пад.'},
      {title: 'Должность имен. пад.'},
      {title: 'Должность род. пад.'},
      {title: 'Основание род. пад.'},
      {title: 'Пол'},
      {title: 'СЦ'},
    ]
    if (isFederalOrRegionalDistributor) {
      headerCells.pop()
      headerDetailsCells.pop()
    }
    const selectedSignature =
      partner && partner.signaturePartnerCurators.find(signature => signature.id === selectedRow)
    const isTableFetching = isCuratorsTableFetching('signature', isRead, curatorsEditType, isPartnerFetching)
    return (
      <fieldset className="mt">
        <legend>Подписанты</legend>
        <Loader isFetch={isPartnerSignatureDeleteFetching} />
        {isRead ? (
          <ExtendedTable
            isInternal
            headerCells={headerDetailsCells}
            tableRows={this.getWithoutSCPartnerSignatures(this.getPartnerSignatureDetailRows())}
            isFetching={isTableFetching}
          />
        ) : (
          <GridContainer>
            <Grid item xs={5}>
              <ExtendedTable
                isInternal
                isError={isSignaturesLimit}
                headerCells={headerCells}
                tableRows={this.getWithoutSCPartnerSignatures(this.getPartnerSignatureRows())}
                selected={selectedRow}
                isFetching={isTableFetching}
                onRowClick={!isRead ? onRowSelect : null}
              />
              {isSignaturesLimit && (
                <FormHelperText error>
                  Для типа партнера "Федеральный дистрибьютор" или "Региональный дистрибьютор" количество подписантов
                  ограничено двумя персонами
                </FormHelperText>
              )}
              <div className="f mt-s">
                <Tooltip disableFocusListener title="Удалить подписанта" enterDelay={500}>
                  <div>
                    <Fab
                      size="small"
                      color="secondary"
                      onClick={selectedRow ? this.handlePartnerSignatureDelete : null}
                      disabled={!selectedRow}
                    >
                      <DeleteIcon />
                    </Fab>
                  </div>
                </Tooltip>
              </div>
            </Grid>
            <Grid item xs={7}>
              <SignatureForm
                selectedSignature={selectedSignature}
                partnerOriginalSignatures={partner.signaturePartnerCurators}
                isGetFetching={isPartnerFetching}
                isFetching={isPartnerSignatureCreateFetching}
                isFederalOrRegionalDistributor={isFederalOrRegionalDistributor}
                createPartnerSignature={createPartnerSignature}
                onSelectedSignatureRowReset={onSelectedRowReset}
              />
            </Grid>
          </GridContainer>
        )}
      </fieldset>
    )
  }
}

const mapStateToProps = ({partners, curators}) => ({
  partner: partners.partner,
  curatorsEditType: curators.curatorsEditType,
  isPartnerFetching: partners.isPartnerFetching,
  isPartnerSignatureCreateFetching: curators.isPartnerSignatureCreateFetching,
  isPartnerSignatureDeleteFetching: curators.isPartnerSignatureDeleteFetching,
})

const mapDispatchToProps = {
  createPartnerSignature,
  deletePartnerSignature,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRowSelect(PartnerSignatures))
