import axios from 'axios'
import fileDownload from 'js-file-download'

import {fetchPartner} from './partners'
import {selectApprovalSheet, resetApprovalSheet} from './agreement'
import {handleWithSnackbarError, getPartnerTypeMnemonic} from '../utils/helpres'
import URLS from '../utils/urls'

const module = 'document'

const FETCH_DOCUMENTS = `${module}/FETCH_DOCUMENTS`
const FETCH_DOCUMENTS_SUCCESS = `${module}/FETCH_DOCUMENTS_SUCCESS`
const FETCH_DOCUMENTS_FAIL = `${module}/FETCH_DOCUMENTS_FAIL`

const FETCH_CONFIRMED_DOCUMENTS = `${module}/FETCH_CONFIRMED_DOCUMENTS`
const FETCH_CONFIRMED_DOCUMENTS_SUCCESS = `${module}/FETCH_CONFIRMED_DOCUMENTS_SUCCESS`
const FETCH_CONFIRMED_DOCUMENTS_FAIL = `${module}/FETCH_CONFIRMED_DOCUMENTS_FAIL`

const SET_CONFIRMED_DOCUMENTS_SIZE = `${module}/SET_CONFIRMED_DOCUMENTS_SIZE`
const SET_CONFIRMED_DOCUMENTS_PAGE = `${module}/SET_CONFIRMED_DOCUMENTS_PAGE`
const SET_CONFIRMED_DOCUMENTS_SORT = `${module}/SET_CONFIRMED_DOCUMENTS_SORT`
const SET_CONFIRMED_DOCUMENTS_SEARCH = `${module}/SET_CONFIRMED_DOCUMENTS_SEARCH`

const SELECT_CONFIRMED_DOCUMENT = `${module}/SELECT_CONFIRMED_DOCUMENT`

const FETCH_DOCUMENT = `${module}/FETCH_DOCUMENT`
const FETCH_DOCUMENT_SUCCESS = `${module}/FETCH_DOCUMENT_SUCCESS`
const FETCH_DOCUMENT_FAIL = `${module}/FETCH_DOCUMENT_FAIL`

const FETCH_DOCUMENT_REFERENCES = `${module}/FETCH_DOCUMENT_REFERENCES`
const FETCH_DOCUMENT_REFERENCES_SUCCESS = `${module}/FETCH_DOCUMENT_REFERENCES_SUCCESS`
const FETCH_DOCUMENT_REFERENCES_FAIL = `${module}/FETCH_DOCUMENT_REFERENCES_FAIL`

const FETCH_DOCUMENT_NUMBER = `${module}/FETCH_DOCUMENT_NUMBER`
const FETCH_DOCUMENT_NUMBER_SUCCESS = `${module}/FETCH_DOCUMENT_NUMBER_SUCCESS`
const FETCH_DOCUMENT_NUMBER_FAIL = `${module}/FETCH_DOCUMENT_NUMBER_FAIL`

const RESET_DOCUMENT_NUMBER = `${module}/RESET_DOCUMENT_NUMBER`

const FETCH_DOCUMENT_BONUS = `${module}/FETCH_DOCUMENT_BONUS`
const FETCH_DOCUMENT_BONUS_SUCCESS = `${module}/FETCH_DOCUMENT_BONUS_SUCCESS`
const FETCH_DOCUMENT_BONUS_FAIL = `${module}/FETCH_DOCUMENT_BONUS_FAIL`

const RESET_DOCUMENT_BONUS = `${module}/RESET_DOCUMENT_BONUS`

const SAVE_DOCUMENT = `${module}/SAVE_DOCUMENT`
const SAVE_DOCUMENT_SUCCESS = `${module}/SAVE_DOCUMENT_SUCCESS`
const SAVE_DOCUMENT_FAIL = `${module}/SAVE_DOCUMENT_FAIL`

const RECREATE_DOCUMENT = `${module}/RECREATE_DOCUMENT`
const RECREATE_DOCUMENT_SUCCESS = `${module}/RECREATE_DOCUMENT_SUCCESS`
const RECREATE_DOCUMENT_FAIL = `${module}/RECREATE_DOCUMENT_FAIL`

const RECREATE_BONUS = `${module}/RECREATE_BONUS`
const RECREATE_BONUS_SUCCESS = `${module}/RECREATE_BONUS_SUCCESS`
const RECREATE_BONUS_FAIL = `${module}/RECREATE_BONUS_FAIL`

const FETCH_FILE_DOWNLOAD = `${module}/FETCH_FILE_DOWNLOAD`
const FETCH_FILE_DOWNLOAD_SUCCESS = `${module}/FETCH_FILE_DOWNLOAD_SUCCESS`
const FETCH_FILE_DOWNLOAD_FAIL = `${module}/FETCH_FILE_DOWNLOAD_FAIL`

const DOWNLOAD_SCAN_FILE = `${module}/DOWNLOAD_SCAN_FILE`
const DOWNLOAD_SCAN_FILE_SUCCESS = `${module}/DOWNLOAD_SCAN_FILE_SUCCESS`
const DOWNLOAD_SCAN_FILE_FAIL = `${module}/DOWNLOAD_SCAN_FILE_FAIL`

const DELETE_DOCUMENT = `${module}/DELETE_DOCUMENT`
const DELETE_DOCUMENT_SUCCESS = `${module}/DELETE_DOCUMENT_SUCCESS`
const DELETE_DOCUMENT_FAIL = `${module}/DELETE_DOCUMENT_FAIL`

const RESET_DOCUMENTS = `${module}/RESET_DOCUMENTS`

const initialState = {
  documents: null,
  isDocumentsFetching: false,
  documentsError: null,

  confirmedDocuments: null,
  isConfirmedDocumentsFetching: false,
  confirmedDocumentsError: null,

  confirmedDocumentsSize: 10,
  confirmedDocumentsPage: 0,
  confirmedDocumentsSort: null,
  confirmedDocumentsSearch: '',

  selectedConfirmedDocument: '',

  document: null,
  isDocumentFetching: false,
  documentError: null,

  documentReferences: null,
  isDocumentReferencesFetching: false,
  documentReferencesError: null,

  documentNumber: '',
  isDocumentNumberFetching: false,
  documentNumberError: null,

  documentBonus: null,
  isDocumentBonusFetching: false,
  documentBonusError: null,

  isDocumentSaveFetching: false,
  documentSaveError: null,

  isDocumentRecreateFetching: false,
  documentRecreateError: null,

  isFileDownloadFetching: false,
  fileDownloadError: null,

  isScanFileDownloadFetching: false,
  scanFileDownloadError: null,

  isDocumentDelete: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DOCUMENTS:
      return {...state, isDocumentsFetching: true, documentsError: null}
    case FETCH_DOCUMENTS_SUCCESS:
      return {...state, isDocumentsFetching: false, documents: action.documents}
    case FETCH_DOCUMENTS_FAIL:
      return {...state, isDocumentsFetching: false, documentsError: action.error}

    case FETCH_CONFIRMED_DOCUMENTS:
      return {...state, isConfirmedDocumentsFetching: true, confirmedDocumentsError: null}
    case FETCH_CONFIRMED_DOCUMENTS_SUCCESS:
      return {...state, isConfirmedDocumentsFetching: false, confirmedDocuments: action.confirmedDocuments}
    case FETCH_CONFIRMED_DOCUMENTS_FAIL:
      return {...state, isConfirmedDocumentsFetching: false, confirmedDocumentsError: action.error}

    case SET_CONFIRMED_DOCUMENTS_SIZE:
      return {...state, confirmedDocumentsSize: action.size}
    case SET_CONFIRMED_DOCUMENTS_PAGE:
      return {...state, confirmedDocumentsPage: action.page}
    case SET_CONFIRMED_DOCUMENTS_SORT:
      return {...state, confirmedDocumentsSort: action.sort}
    case SET_CONFIRMED_DOCUMENTS_SEARCH:
      return {...state, confirmedDocumentsSearch: action.search}

    case SELECT_CONFIRMED_DOCUMENT:
      return {...state, selectedConfirmedDocument: action.selectedConfirmedDocument}

    case FETCH_DOCUMENT:
      return {...state, isDocumentFetching: true, documentError: null}
    case FETCH_DOCUMENT_SUCCESS:
      return {...state, isDocumentFetching: false, document: action.document}
    case FETCH_DOCUMENT_FAIL:
      return {...state, isDocumentFetching: false, documentError: action.error}

    case FETCH_DOCUMENT_REFERENCES:
      return {...state, isDocumentReferencesFetching: true, documentReferencesError: null}
    case FETCH_DOCUMENT_REFERENCES_SUCCESS:
      return {...state, isDocumentReferencesFetching: false, documentReferences: action.documentReferences}
    case FETCH_DOCUMENT_REFERENCES_FAIL:
      return {...state, isDocumentReferencesFetching: false, documentReferencesError: action.error}

    case FETCH_DOCUMENT_NUMBER:
      return {...state, isDocumentNumberFetching: true, documentNumberError: null}
    case FETCH_DOCUMENT_NUMBER_SUCCESS:
      return {...state, isDocumentNumberFetching: false, documentNumber: action.documentNumber}
    case FETCH_DOCUMENT_NUMBER_FAIL:
      return {...state, isDocumentNumberFetching: false, documentNumberError: action.error}

    case RESET_DOCUMENT_NUMBER:
      return {
        ...state,
        documentNumber: '',
        isDocumentNumberFetching: false,
        documentNumberError: null,
      }

    case FETCH_DOCUMENT_BONUS:
      return {...state, isDocumentBonusFetching: true, documentBonusError: null}
    case FETCH_DOCUMENT_BONUS_SUCCESS:
      return {...state, isDocumentBonusFetching: false, documentBonus: action.documentBonus}
    case FETCH_DOCUMENT_BONUS_FAIL:
      return {...state, isDocumentBonusFetching: false, documentBonusError: action.error}

    case RESET_DOCUMENT_BONUS:
      return {
        ...state,
        documentBonus: null,
        isDocumentBonusFetching: false,
        documentBonusError: null,
      }

    case SAVE_DOCUMENT:
      return {...state, isDocumentSaveFetching: true, documentSaveError: null}
    case SAVE_DOCUMENT_SUCCESS:
      return {...state, isDocumentSaveFetching: false}
    case SAVE_DOCUMENT_FAIL:
      return {...state, isDocumentSaveFetching: false, documentSaveError: action.error}

    case RECREATE_DOCUMENT:
      return {...state, isDocumentRecreateFetching: true, documentRecreateError: null}
    case RECREATE_DOCUMENT_SUCCESS:
      return {...state, isDocumentRecreateFetching: false}
    case RECREATE_DOCUMENT_FAIL:
      return {...state, isDocumentRecreateFetching: false, documentRecreateError: action.error}

    case RECREATE_BONUS:
      return {...state, isDocumentRecreateFetching: true, documentRecreateError: null}
    case RECREATE_BONUS_SUCCESS:
      return {...state, isDocumentRecreateFetching: false}
    case RECREATE_BONUS_FAIL:
      return {...state, isDocumentRecreateFetching: false, documentRecreateError: action.error}

    case FETCH_FILE_DOWNLOAD:
      return {...state, isFileDownloadFetching: true, fileDownloadError: null}
    case FETCH_FILE_DOWNLOAD_SUCCESS:
      return {...state, isFileDownloadFetching: false}
    case FETCH_FILE_DOWNLOAD_FAIL:
      return {...state, isFileDownloadFetching: false, fileDownloadError: action.fileDownloadError}

    case DOWNLOAD_SCAN_FILE:
      return {...state, isScanFileDownloadFetching: true, scanFileDownloadError: null}
    case DOWNLOAD_SCAN_FILE_SUCCESS:
      return {...state, isScanFileDownloadFetching: false, scanDocument: action.scanDocument}
    case DOWNLOAD_SCAN_FILE_FAIL:
      return {...state, isScanFileDownloadFetching: false, scanFileDownloadError: action.error}

    case DELETE_DOCUMENT:
      return {...state, isDocumentDelete: true}
    case DELETE_DOCUMENT_SUCCESS:
    case DELETE_DOCUMENT_FAIL:
      return {...state, isDocumentDelete: false}

    case RESET_DOCUMENTS:
      return {
        ...initialState,
        confirmedDocuments: state.confirmedDocuments,
        isConfirmedDocumentsFetching: state.isConfirmedDocumentsFetching,
        confirmedDocumentsError: state.confirmedDocumentsError,
        confirmedDocumentsSize: state.confirmedDocumentsSize,
        confirmedDocumentsPage: state.confirmedDocumentsPage,
        confirmedDocumentsSort: state.confirmedDocumentsSort,
        confirmedDocumentsSearch: state.confirmedDocumentsSearch,
        selectedConfirmedDocument: state.selectedConfirmedDocument,
      }

    default:
      return state
  }
}

export const fetchDocuments = partnerId => (dispatch, getState) => {
  dispatch({type: FETCH_DOCUMENTS})
  let orgNumber = partnerId
  if (!orgNumber) {
    orgNumber = getState().partners.partner.orgNumber
    if (!orgNumber) orgNumber = getState().user.user.orgNumber
  }
  axios
    .get(`/document/${orgNumber}`)
    .then(({data}) => {
      dispatch({type: FETCH_DOCUMENTS_SUCCESS, documents: data})
    })
    .catch(error => handleWithSnackbarError(dispatch, FETCH_DOCUMENTS_FAIL, error))
}

export const fetchConfirmedDocuments = isPropsChange => (dispatch, getState) => {
  dispatch({type: FETCH_CONFIRMED_DOCUMENTS})
  const state = getState().documents
  const params = {
    search: state.confirmedDocumentsSearch,
    size: state.confirmedDocumentsSize,
    page: state.confirmedDocumentsPage,
  }
  if (state.confirmedDocumentsSort) {
    params.sort = `${state.confirmedDocumentsSort[0]},${state.confirmedDocumentsSort[1]}`
  }
  axios
    .get(`/document/conform_search`, {params})
    .then(({data}) => {
      isPropsChange && dispatch(selectConfirmedDocument(''))
      dispatch({type: FETCH_CONFIRMED_DOCUMENTS_SUCCESS, confirmedDocuments: data})
    })
    .catch(error => handleWithSnackbarError(dispatch, FETCH_CONFIRMED_DOCUMENTS_FAIL, error))
}

export const setConfirmedDocumentsSize = size => ({type: SET_CONFIRMED_DOCUMENTS_SIZE, size})
export const setConfirmedDocumentsPage = page => ({type: SET_CONFIRMED_DOCUMENTS_PAGE, page})
export const setConfirmedDocumentsSort = sort => ({type: SET_CONFIRMED_DOCUMENTS_SORT, sort})
export const setConfirmedDocumentsSearch = search => ({type: SET_CONFIRMED_DOCUMENTS_SEARCH, search})

export const selectConfirmedDocument = document => ({
  type: SELECT_CONFIRMED_DOCUMENT,
  selectedConfirmedDocument: document,
})

export const fetchDocument = (params, orgNumber) => dispatch => {
  dispatch({type: FETCH_DOCUMENT})
  axios
    .get('/contract/recreate', {params})
    .then(({data}) => {
      dispatch({type: FETCH_DOCUMENT_SUCCESS, document: {...data, orgNumber}})
      dispatch(fetchPartner(orgNumber))
      dispatch(fetchDocumentBonus(data.contractFlowDTO.contract[0].contractScope))
      window.routerHistory.push(URLS.AGREEMENT_RECREATE)
    })
    .catch(error => handleWithSnackbarError(dispatch, FETCH_DOCUMENT_FAIL, error))
}

export const fetchDocumentReferences = () => (dispatch, getState) => {
  dispatch({type: FETCH_DOCUMENT_REFERENCES})
  const documentState = getState().documents.document
  const orgNumber = documentState ? documentState.orgNumber : getState().partners.partner.orgNumber
  axios
    .get(`/contract/${orgNumber}/definition`)
    .then(({data}) => {
      dispatch({type: FETCH_DOCUMENT_REFERENCES_SUCCESS, documentReferences: data})
    })
    .catch(error => handleWithSnackbarError(dispatch, FETCH_DOCUMENT_REFERENCES_FAIL, error))
}

export const fetchDocumentNumber = year => (dispatch, getState) => {
  dispatch({type: FETCH_DOCUMENT_NUMBER})
  const {currentFederalDistrictId, partnerTypeId} = getState().partners.partner
  const {partnerTypes} = getState().references
  const partnerType = getPartnerTypeMnemonic(partnerTypes, partnerTypeId)
  const params = {
    year,
    currentFederalDistrictId,
    partnerType,
  }
  if (year) params.year = year
  axios
    .get('/document/nextDocumentNumber', {params})
    .then(({data}) => {
      dispatch({type: FETCH_DOCUMENT_NUMBER_SUCCESS, documentNumber: data.documentNumber})
    })
    .catch(error => handleWithSnackbarError(dispatch, FETCH_DOCUMENT_NUMBER_FAIL, error))
}

export const resetDocumentNumber = () => ({type: RESET_DOCUMENT_NUMBER})

export const fetchDocumentBonus = contractScope => dispatch => {
  dispatch({type: FETCH_DOCUMENT_BONUS})
  axios
    .get(`/contract/${contractScope}/bonus`)
    .then(({data}) => dispatch({type: FETCH_DOCUMENT_BONUS_SUCCESS, documentBonus: data}))
    .catch(error => handleWithSnackbarError(dispatch, FETCH_DOCUMENT_BONUS_FAIL, error))
}

export const resetDocumentBonus = () => ({type: RESET_DOCUMENT_BONUS})

export const saveDocument = (document, contractTypeId, params, toggleNewContract) => (dispatch, getState) => {
  dispatch({type: SAVE_DOCUMENT})
  const {orgNumber} = getState().partners.partner
  axios
    .post(`/contract/${orgNumber}/${contractTypeId}/generate`, document, {
      params,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(() => {
      dispatch({type: SAVE_DOCUMENT_SUCCESS})
      dispatch(fetchPartner(orgNumber))
      toggleNewContract()
    })
    .catch(error => handleWithSnackbarError(dispatch, SAVE_DOCUMENT_FAIL, error))
}

export const recreateDocument = (document, contractTypeId, params) => dispatch => {
  dispatch({type: RECREATE_DOCUMENT})
  axios
    .post(`/contract/${contractTypeId}/recreate`, document, {
      params,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(() => {
      dispatch({type: RECREATE_DOCUMENT_SUCCESS})
      dispatch(resetApprovalSheet())
      dispatch(selectApprovalSheet(null))
      window.routerHistory.push(URLS.AGREEMENT)
    })
    .catch(error => handleWithSnackbarError(dispatch, RECREATE_DOCUMENT_FAIL, error))
}

export const recreateBonus = (document, contractTypeId, params) => dispatch => {
  dispatch({type: RECREATE_BONUS})
  axios
    .post(`/contract/${contractTypeId}/recreateBonus`, document, {
      params,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(() => {
      dispatch({type: RECREATE_DOCUMENT_SUCCESS})
      dispatch(resetApprovalSheet())
      dispatch(selectApprovalSheet(null))
      window.routerHistory.push(URLS.AGREEMENT)
    })
    .catch(error => handleWithSnackbarError(dispatch, RECREATE_BONUS_FAIL, error))
}

export const downloadFile = document => dispatch => {
  dispatch({type: FETCH_FILE_DOWNLOAD})
  const fileName = document.scanFileName || document.fileName
  axios
    .get(`/document/${document.scanId || document.documentId}/file`, {
      params: {
        file_name: fileName,
      },
      responseType: 'blob',
    })
    .then(({data}) => {
      dispatch({type: FETCH_FILE_DOWNLOAD_SUCCESS})
      fileDownload(data, fileName)
    })
    .catch(error => handleWithSnackbarError(dispatch, FETCH_FILE_DOWNLOAD_FAIL, error))
}

export const downloadScanFile = (file, params, closeModal) => dispatch => {
  dispatch({type: DOWNLOAD_SCAN_FILE})
  const formData = new FormData()
  formData.append('file', file)
  axios
    .post('/document/file', formData, {params})
    .then(() => {
      dispatch({type: DOWNLOAD_SCAN_FILE_SUCCESS})
      dispatch(fetchConfirmedDocuments())
      closeModal()
    })
    .catch(error => handleWithSnackbarError(dispatch, DOWNLOAD_SCAN_FILE_FAIL, error))
}

export const deleteDocument = (id, bonusId) => (dispatch, getState) => {
  dispatch({type: DELETE_DOCUMENT})
  const {orgNumber} = getState().partners.partner
  axios
    .delete(`/contract/${id}${bonusId ? `/bonus/${bonusId}` : ''}`)
    .then(() => {
      dispatch({type: DELETE_DOCUMENT_SUCCESS})
      dispatch(fetchPartner(orgNumber))
      dispatch(fetchDocuments(orgNumber))
    })
    .catch(error => handleWithSnackbarError(dispatch, DELETE_DOCUMENT_FAIL, error))
}

export const resetDocuments = () => ({type: RESET_DOCUMENTS})
