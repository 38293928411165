import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {Field} from 'react-final-form'
import Grid from '@material-ui/core/Grid'

import GridContainer from '../../_common/GridContainer'
import InputField from '../../_common/InputField'

export default class PartnerContacts extends PureComponent {
  static propTypes = {
    isRead: PropTypes.bool,
  }

  render() {
    const {isRead} = this.props
    return (
      <div className={isRead ? null : 'loaded'}>
        <GridContainer>
          <Grid item xs={6}>
            <Field disabled name="postcode" label="Индекс" component={InputField} />
          </Grid>
          <Grid item xs={6}>
            <Field disabled name="city" label="Город" component={InputField} />
          </Grid>
        </GridContainer>
        <GridContainer>
          <Grid item xs={6}>
            <Field disabled name="street" label="Улица" component={InputField} />
          </Grid>
          <Grid item xs={3}>
            <Field disabled name="building" label="Дом" component={InputField} />
          </Grid>
          <Grid item xs={3}>
            <Field disabled name="apartment" label="Квартира" component={InputField} />
          </Grid>
        </GridContainer>
        <GridContainer>
          <Grid item xs={6}>
            <Field disabled name="phoneNumber" label="Телефон" component={InputField} />
          </Grid>
          <Grid item xs={6}>
            <Field disabled name="email" label="E-mail" component={InputField} />
          </Grid>
        </GridContainer>
        <GridContainer>
          <Grid item xs={6}>
            <Field disabled name="federalDistrict" label="Федеральный округ" component={InputField} />
          </Grid>
          <Grid item xs={6}>
            <Field disabled name="region" label="Регион" component={InputField} />
          </Grid>
        </GridContainer>
      </div>
    )
  }
}
