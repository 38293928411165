import axios from 'axios'

import {handleWithSnackbarError} from '../utils/helpres'
import {BASE_URL} from '../utils/urls'

const module = 'auth'

const FETCH_BACK_INFO = `${module}/FETCH_BACK_INFO`
const FETCH_BACK_INFO_SUCCESS = `${module}/FETCH_BACK_INFO_SUCCESS`
const FETCH_BACK_INFO_FAIL = `${module}/FETCH_BACK_INFO_FAIL`

const FETCH_CHECK = `${module}/FETCH_CHECK`
const FETCH_CHECK_SUCCESS = `${module}/FETCH_CHECK_SUCCESS`
const FETCH_CHECK_FAIL = `${module}/FETCH_CHECK_FAIL`

const initialState = {
  backInfo: null,
  isBackInfoFetching: false,
  backInfoError: null,

  isCheckFetching: false,
  checkError: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BACK_INFO:
      return {...state, isBackInfoFetching: true, backInfoError: null}
    case FETCH_BACK_INFO_SUCCESS:
      return {...state, isBackInfoFetching: false, backInfo: action.backInfo}
    case FETCH_BACK_INFO_FAIL:
      return {...state, isBackInfoFetching: false, backInfoError: action.error}

    case FETCH_CHECK:
      return {...state, isCheckFetching: true, checkError: null}
    case FETCH_CHECK_SUCCESS:
      return {...state, isCheckFetching: false}
    case FETCH_CHECK_FAIL:
      return {...state, isCheckFetching: false, checkError: action.error}

    default:
      return state
  }
}

export const fetchBackInfo = () => dispatch => {
  dispatch({type: FETCH_BACK_INFO})
  axios
    .get(`${BASE_URL}/actuator/info`)
    .then(({data}) => dispatch({type: FETCH_BACK_INFO_SUCCESS, backInfo: data}))
    .catch(error => handleWithSnackbarError(dispatch, FETCH_BACK_INFO_FAIL, error))
}

export const fetchCheck = () => dispatch => {
  dispatch({type: FETCH_CHECK})
  axios
    .get(`${BASE_URL}/api/v1/error`)
    .then(() => dispatch({type: FETCH_CHECK_SUCCESS}))
    .catch(error => dispatch({type: FETCH_CHECK_FAIL, error}))
}
