import React, {Component} from 'react'

export default ComposedComponent =>
  class extends Component {
    state = {
      selectedRow: null,
    }

    handleRowSelect = selectedRow => () =>
      this.state.selectedRow !== selectedRow ? this.setState({selectedRow}) : this.handleSelectedRowReset()

    handleSelectedRowReset = () => this.setState({selectedRow: null})

    render() {
      return (
        <ComposedComponent
          {...this.state}
          {...this.props}
          onRowSelect={this.handleRowSelect}
          onSelectedRowReset={this.handleSelectedRowReset}
        />
      )
    }
  }
