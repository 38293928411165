import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import Grid from '@material-ui/core/Grid'

import {createPartnerCurator, deletePartnerCurator} from '../../../../redux/curators'
import withModal from '../../../_decorators/withModal'
import withRowSelect from '../../../_decorators/withRowSelect'
import GridContainer from '../../../_common/GridContainer'
import ExtendedTable from '../../../_common/ExtendedTable'
import TableActions from '../../../_common/TableActions'
import Modal from '../../../_common/Modal'
import Loader from '../../../_common/Loader'
import WorkerAddForm from '../../WorkerAddForm'
import {isCuratorsTableFetching, getCuratorsRows} from '../../../../utils/helpres'

const headerCells = [{title: 'ФИО'}, {title: 'Телефон'}, {title: 'E-mail'}]

class PartnerCurators extends Component {
  static propTypes = {
    isRead: PropTypes.bool,
    partner: PropTypes.object,
    isPartnerFetching: PropTypes.bool.isRequired,
    curatorsEditType: PropTypes.string,
    isPartnerCuratorCreateFetching: PropTypes.bool.isRequired,
    isPartnerCuratorDeleteFetching: PropTypes.bool.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    selectedRow: PropTypes.number,
    createPartnerCurator: PropTypes.func.isRequired,
    deletePartnerCurator: PropTypes.func.isRequired,
    onModalToggle: PropTypes.func.isRequired,
    onRowSelect: PropTypes.func.isRequired,
    onSelectedRowReset: PropTypes.func.isRequired,
  }

  handlePartnerCuratorDelete = () => {
    const {selectedRow, deletePartnerCurator, onSelectedRowReset} = this.props
    deletePartnerCurator(selectedRow, onSelectedRowReset)
  }

  render() {
    const {
      isRead,
      partner,
      curatorsEditType,
      isPartnerFetching,
      isPartnerCuratorCreateFetching,
      isPartnerCuratorDeleteFetching,
      isModalOpen,
      selectedRow,
      createPartnerCurator,
      onModalToggle,
      onRowSelect,
    } = this.props
    const partnerCuratorsRows = getCuratorsRows(partner, 'partnerCuratorContacts')
    return (
      <Fragment>
        <Loader isFetch={isPartnerCuratorDeleteFetching} />
        <GridContainer>
          <Grid item xs={isRead ? 12 : 11}>
            <ExtendedTable
              isInternal
              headerCells={headerCells}
              tableRows={partnerCuratorsRows}
              selected={selectedRow}
              isFetching={isCuratorsTableFetching('partner', isRead, curatorsEditType, isPartnerFetching)}
              onRowClick={!isRead ? onRowSelect : null}
            />
          </Grid>
          {!isRead && (
            <Grid item xs={1}>
              <TableActions
                isFetching={isPartnerFetching}
                onAdd={partnerCuratorsRows.length >= 2 ? null : onModalToggle}
                onDelete={selectedRow ? this.handlePartnerCuratorDelete : null}
              />
              <Modal isForm open={isModalOpen} title="Добавление контактного лица партнера" onClose={onModalToggle}>
                <WorkerAddForm
                  isModalOpen={isModalOpen}
                  isFetching={isPartnerCuratorCreateFetching}
                  createCurator={createPartnerCurator}
                  onModalToggle={onModalToggle}
                />
              </Modal>
            </Grid>
          )}
        </GridContainer>
      </Fragment>
    )
  }
}

const mapStateToProps = ({partners, curators}) => ({
  partner: partners.partner,
  isPartnerFetching: partners.isPartnerFetching,
  curatorsEditType: curators.curatorsEditType,
  isPartnerCuratorCreateFetching: curators.isPartnerCuratorCreateFetching,
  isPartnerCuratorDeleteFetching: curators.isPartnerCuratorDeleteFetching,
})

const mapDispatchToProps = {
  createPartnerCurator,
  deletePartnerCurator,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withModal(withRowSelect(PartnerCurators)))
