import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ReactFileReader from 'react-file-reader'
import {Form, Field} from 'react-final-form'
import {withStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import Fab from '@material-ui/core/Fab'
import Tooltip from '@material-ui/core/Tooltip'
import SearchIcon from '@material-ui/icons/Search'
import AddIcon from '@material-ui/icons/Add'

import GridContainer from '../../_common/GridContainer'
import Loader from '../../_common/Loader'
import {isRPRM, isDPRPRM} from '../../../utils/helpres'
import ROLES from '../../../utils/constants'
import {THEME} from '../../../styles/muiTheme'

const fileTypes = []

const styles = {
  fabSearch: {
    marginLeft: THEME.UNIT,
    flexShrink: 0,
  },
}

class SearchForm extends Component {
  isRPRM = isRPRM(this.props.roles)
  isDPRPRM = isDPRPRM(this.props.roles)

  static propTypes = {
    classes: PropTypes.object.isRequired,
    roles: PropTypes.array.isRequired,
    role: PropTypes.string.isRequired,
    partnersSearch: PropTypes.string,
    isPartnersFetching: PropTypes.bool.isRequired,
    isPartnerFetching: PropTypes.bool.isRequired,
    isExelFetching: PropTypes.bool.isRequired,
    setPartnersSearch: PropTypes.func.isRequired,
    fetchPartners: PropTypes.func.isRequired,
    fetchPartner: PropTypes.func.isRequired,
    fetchDownloadExel: PropTypes.func.isRequired,
  }

  handleFormSubmit = ({search}) => {
    const {role, setPartnersSearch, fetchPartners, fetchPartner} = this.props
    setPartnersSearch(search)
    role !== ROLES.RP && !this.isRPRM && role !== ROLES.DP && !this.isDPRPRM && role !== ROLES.SC
      ? fetchPartners()
      : fetchPartner(search, true)
  }

  handleInputChange = onChange => ({target: {value}}) => {
    !value && this.props.setPartnersSearch('')
    onChange(value)
  }

  renderInput = ({input: {value, onChange}}) => {
    return (
      <Input
        fullWidth
        value={value}
        placeholder="Поиск партнера"
        type="search"
        onChange={this.handleInputChange(onChange)}
      />
    )
  }

  get downloadExelButton() {
    const {classes, role, isExelFetching, fetchDownloadExel} = this.props
    if (role !== ROLES.ADMIN) return null
    const button = (
      <Tooltip disableFocusListener title="Загрузить партнеров" enterDelay={500}>
        <Fab className={classes.fabSearch} size="small" color="primary">
          <AddIcon />
        </Fab>
      </Tooltip>
    )
    return !isExelFetching ? (
      <ReactFileReader fileTypes={fileTypes} handleFiles={fetchDownloadExel}>
        {button}
      </ReactFileReader>
    ) : (
      button
    )
  }

  render() {
    const {classes, role, partnersSearch, isPartnersFetching, isPartnerFetching} = this.props
    const initialValues = {
      search: partnersSearch,
    }
    return (
      <Form
        initialValues={initialValues}
        onSubmit={this.handleFormSubmit}
        render={({values, handleSubmit}) => (
          <form noValidate className="mb" autoComplete="off" onSubmit={handleSubmit}>
            <Loader
              isFetch={
                ((role !== ROLES.ADMIN && role !== ROLES.RM) || this.isRPRM || this.isDPRPRM) && isPartnerFetching
              }
            />
            <GridContainer>
              <Grid item xs={5}>
                <div className="f ai-c">
                  <Field name="search" component={this.renderInput} />
                  <Tooltip disableFocusListener title="Найти" enterDelay={500}>
                    <div>
                      <Fab
                        className={classes.fabSearch}
                        type="submit"
                        size="small"
                        color="secondary"
                        disabled={!values.search || isPartnerFetching || isPartnersFetching}
                      >
                        <SearchIcon />
                      </Fab>
                    </div>
                  </Tooltip>
                  {this.downloadExelButton}
                </div>
              </Grid>
            </GridContainer>
          </form>
        )}
      />
    )
  }
}

export default withStyles(styles)(SearchForm)
