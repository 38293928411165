import React, {PureComponent, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Field} from 'react-final-form'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import GridContainer from '../GridContainer'
import ExtendedTable from '../ExtendedTable'
import InputField from '../InputField'
import {COLORS} from '../../../styles/muiTheme'

const fieldProps = {
  placeholder: '0',
  type: 'number',
  inputProps: {
    step: '0.1',
    min: '0',
  },
  component: InputField,
}
const InputLabelProps = {
  shrink: true,
}

const getQuarterTableRows = (quarterNumber, isSecond, isConfirmed) => {
  const second = isSecond ? 'Second' : ''
  return {
    id: quarterNumber,
    data: [
      <span className="ws-nw">{quarterNumber} квартал</span>,
      <Field name={`quarterlyPurchaseNoVAT${second}${quarterNumber}1`} {...fieldProps} disabled={isConfirmed} />,
      <Field name={`prize${second}${quarterNumber}1`} {...fieldProps} disabled={isConfirmed} />,
      <Field disabled name={`quarterlyPurchaseNoVAT${second}${quarterNumber}2`} {...fieldProps} />,
      <Field name={`prize${second}${quarterNumber}2`} {...fieldProps} disabled={isConfirmed} />,
    ],
  }
}

const headerQuarterTopCells = [
  {title: ''},
  {title: 'Сценарий №1 (100%)', colSpan: 2},
  {title: 'Сценарий №2 (80%)', colSpan: 2},
]
const headerQuarterCells = [
  {title: ''},
  {
    title: (
      <span>
        Закупка в квартал,
        <br />
        евро без НДС
      </span>
    ),
  },
  {title: 'Премия, %'},
  {
    title: (
      <span>
        Закупка в квартал,
        <br />
        евро без НДС
      </span>
    ),
  },
  {title: 'Премия, %'},
]

const quarterTableRows = isConfirmed => [
  getQuarterTableRows(1, false, isConfirmed),
  getQuarterTableRows(2, false, isConfirmed),
  getQuarterTableRows(3, false, isConfirmed),
  getQuarterTableRows(4, false, isConfirmed),
]
const quarterSecondTableRows = isConfirmed => [
  getQuarterTableRows(1, true, isConfirmed),
  getQuarterTableRows(2, true, isConfirmed),
  getQuarterTableRows(3, true, isConfirmed),
  getQuarterTableRows(4, true, isConfirmed),
]

const quarterICTableRows = [
  getQuarterTableRows(1),
  getQuarterTableRows(2),
  getQuarterTableRows(3),
  getQuarterTableRows(4),
]

const headerAnnualCells = [
  {title: ''},
  {
    title: (
      <span>
        Закупка в год,
        <br />
        евро без НДС
      </span>
    ),
  },
  {title: 'Премия, %'},
]
const annualTableRows = [
  {
    id: 1,
    data: [
      <Field name="year" placeholder="Год" type="number" component={InputField} />,
      <Field name="quarterlyPurchaseNoVAT" {...fieldProps} />,
      <Field name="prize" {...fieldProps} />,
    ],
  },
]

const headerInpTopCells = [
  {title: 'Сценарий №1', colSpan: 2},
  {title: 'Сценарий №2', colSpan: 2},
  {title: 'Сценарий №3', colSpan: 2},
]
const headerInpCells = [
  {
    title: (
      <span>
        Тыс. евро, без
        <br />
        НДС в год
      </span>
    ),
  },
  {title: 'Премия, %'},
  {
    title: (
      <span>
        Тыс. евро, без
        <br />
        НДС в год
      </span>
    ),
  },
  {title: 'Премия, %'},
  {
    title: (
      <span>
        Тыс. евро, без
        <br />
        НДС в год
      </span>
    ),
  },
  {title: 'Премия, %'},
]
const inpTableRows = [
  {
    id: 1,
    data: [
      <Field name="quarterlyPurchaseNoVAT1" {...fieldProps} />,
      <Field name="prize1" {...fieldProps} />,
      <Field name="quarterlyPurchaseNoVAT2" {...fieldProps} />,
      <Field name="prize2" {...fieldProps} />,
      <Field name="quarterlyPurchaseNoVAT3" {...fieldProps} />,
      <Field name="prize3" {...fieldProps} />,
    ],
  },
]

const headerRszchTopCells = [{title: ''}, {title: 'Сценарий №1', colSpan: 2}, {title: 'Сценарий №2', colSpan: 2}]
const headerRszchCells = [
  {
    title: '',
  },
  {
    title: (
      <span>
        Закупка в год,
        <br />
        рублей без НДС
      </span>
    ),
  },
  {title: 'Премия, %'},
  {
    title: (
      <span>
        Закупка в год,
        <br />
        рублей без НДС
      </span>
    ),
  },
  {title: 'Премия, %'},
]
const rszchTableRows = [
  {
    id: 1,
    data: [
      'ЗЧ Vaillant',
      <Field name="quarterlyPurchaseNoVATVaillant1" {...fieldProps} />,
      <Field name="prizeVaillant1" {...fieldProps} />,
      <Field name="quarterlyPurchaseNoVATVaillant2" {...fieldProps} />,
      <Field name="prizeVaillant2" {...fieldProps} />,
    ],
  },
  {
    id: 2,
    data: [
      'ЗЧ Protherm',
      <Field name="quarterlyPurchaseNoVATProtherm1" {...fieldProps} />,
      <Field name="prizeProtherm1" {...fieldProps} />,
      <Field name="quarterlyPurchaseNoVATProtherm2" {...fieldProps} />,
      <Field name="prizeProtherm2" {...fieldProps} />,
    ],
  },
]

const vaillantProthermFields = [[{legend: 'Продажи в ГРО', name: 'groBonus', label: 'Размер премии, %'}]]
const vaillantProthermSecondFields = [[{legend: 'Продажи в ГРО', name: 'groBonusSecond', label: 'Размер премии, %'}]]

const engineerCenterSilverFields = [
  [
    {
      legend: (
        <span>
          Оборотная за закупку
          <br />
          инновационного оборудования
        </span>
      ),
      name: 'negotiableBonus',
      label: 'Размер премии, %',
    },
    {
      legend: (
        <span>
          Оборотная за закупку
          <br />
          конденсационных котлов, дымоходов
        </span>
      ),
      name: 'boilersNegotiableBonus',
      label: 'Размер премии, %',
    },
  ],
  [{legend: 'Премия за предоплату', name: 'prepaymentBonus', label: 'Размер премии, %'}],
]

const engineerCenterGoldFields = [
  [
    {
      legend: (
        <span>
          Оборотная за закупку
          <br />
          инновационного оборудования
        </span>
      ),
      name: 'negotiableBonus',
      label: 'Размер премии, %',
    },
    {
      legend: (
        <span>
          Оборотная за закупку
          <br />
          конденсационных котлов, дымоходов
        </span>
      ),
      name: 'boilersNegotiableBonus',
      label: 'Размер премии, %',
    },
  ],
  [
    {legend: 'Премия за предоплату', name: 'prepaymentBonus', label: 'Размер премии, %'},
    {legend: 'Дополнительная', name: 'additionalBonus', label: 'Размер премии, %'},
  ],
]

const StyledExpansionPanel = withStyles({
  root: {
    boxShadow: 'none',
    border: `1px solid ${COLORS.inputBorder}`,
    borderRadius: `4px !important`,
    '&::before': {
      display: 'none',
    },
  },
})(ExpansionPanel)

const getTableRowsWithTotal = (rows, values, isSecond) => {
  const second = isSecond ? 'Second' : ''
  const firstSum =
    (+values[`quarterlyPurchaseNoVAT${second}11`] || 0) +
    (+values[`quarterlyPurchaseNoVAT${second}21`] || 0) +
    (+values[`quarterlyPurchaseNoVAT${second}31`] || 0) +
    (+values[`quarterlyPurchaseNoVAT${second}41`] || 0)
  const secondSum =
    (+values[`quarterlyPurchaseNoVAT${second}12`] || 0) +
    (+values[`quarterlyPurchaseNoVAT${second}22`] || 0) +
    (+values[`quarterlyPurchaseNoVAT${second}32`] || 0) +
    (+values[`quarterlyPurchaseNoVAT${second}42`] || 0)
  return [
    ...rows,
    {
      id: 5,
      data: [
        'Итого',
        <span className="input-text">{firstSum.toFixed(2)}</span>,
        '',
        <span className="input-text">{secondSum.toFixed(2)}</span>,
        '',
      ],
    },
  ]
}

export default class BonusesContent extends PureComponent {
  static propTypes = {
    isSecond: PropTypes.bool,
    isConfirmed: PropTypes.bool,
    type: PropTypes.string.isRequired,
    values: PropTypes.object.isRequired,
    currentReference: PropTypes.object,
  }

  get fields() {
    const {type, isConfirmed, isSecond} = this.props
    const fields =
      type === '4' || type === '5'
        ? engineerCenterGoldFields
        : type === '6' || type === '7'
        ? engineerCenterSilverFields
        : isSecond
        ? vaillantProthermSecondFields
        : vaillantProthermFields
    return fields.map((container, index) => (
      <GridContainer key={index}>
        {container.map(field => (
          <Grid item xs={6} key={field.name}>
            <fieldset>
              <legend>{field.legend}</legend>
              <Field
                name={field.name}
                label={field.label}
                InputLabelProps={InputLabelProps}
                disabled={isConfirmed}
                {...fieldProps}
              />
            </fieldset>
          </Grid>
        ))}
      </GridContainer>
    ))
  }

  render() {
    const {isSecond, isConfirmed, type, values, currentReference} = this.props
    let title = ''
    if (currentReference) {
      title = currentReference.bonusType[type]
      if (type === '3') {
        title = isSecond ? currentReference.bonusType['2'] : currentReference.bonusType['1']
      }
    }
    const isIC = type === '4' || type === '5' || type === '6' || type === '7'
    if (type === '8')
      return (
        <div className="wrapper">
          <h3 className="title title_small grey mb-s">Оборотная премия</h3>
          <ExtendedTable
            isInternal
            headerTopCells={headerInpTopCells}
            headerCells={headerInpCells}
            tableRows={inpTableRows}
          />
        </div>
      )
    if (type === '9')
      return (
        <div className="wrapper">
          <h3 className="title title_small grey mb-s">Квартальная премия</h3>
          <ExtendedTable
            isInternal
            headerTopCells={headerRszchTopCells}
            headerCells={headerRszchCells}
            tableRows={rszchTableRows}
          />
        </div>
      )
    return (
      <StyledExpansionPanel defaultExpanded={!isConfirmed} className={isSecond ? 'mt-s' : null}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <h3 className="title title_small mr-xs">{title}</h3>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className="max-width">
            {isIC ? (
              <Fragment>
                {type === '5' || type === '6' ? (
                  <div className="mb">
                    <h3 className="title title_small grey mb-s">Квартальная премия</h3>
                    <ExtendedTable
                      isInternal
                      headerTopCells={headerQuarterTopCells}
                      headerCells={headerQuarterCells}
                      tableRows={getTableRowsWithTotal(quarterICTableRows, values)}
                    />
                  </div>
                ) : (
                  <div className="mb">
                    <h3 className="title title_small grey mb-s">Годовая пермия</h3>
                    <ExtendedTable isInternal headerCells={headerAnnualCells} tableRows={annualTableRows} />
                  </div>
                )}
              </Fragment>
            ) : (
              <Fragment>
                <div className="mb">
                  <h3 className="title title_small grey mb-s">Квартальная премия</h3>
                  <ExtendedTable
                    isInternal
                    headerTopCells={headerQuarterTopCells}
                    headerCells={headerQuarterCells}
                    tableRows={
                      isSecond
                        ? getTableRowsWithTotal(quarterSecondTableRows(isConfirmed), values, true)
                        : getTableRowsWithTotal(quarterTableRows(isConfirmed), values)
                    }
                  />
                </div>
              </Fragment>
            )}
            {this.fields}
          </div>
        </ExpansionPanelDetails>
      </StyledExpansionPanel>
    )
  }
}
