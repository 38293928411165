import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import moment from 'moment'
import Paper from '@material-ui/core/Paper'

import {
  updateReportsFormValues,
  fetchReports,
  setReportsSize,
  setReportsPage,
  setReportsSort,
  downloadSellInFile,
} from '../../redux/reports'
import ExtendedTable from '../_common/ExtendedTable'
import Loader from '../_common/Loader'
import ReportsForm from './ReportsForm'

const headerCells = [
  {title: 'Sales Organization', sortName: 'salesOrganization'},
  {title: 'Sold-to party', sortName: 'soldToParty'},
  {title: 'Customer', sortName: 'customer'},
  {title: 'Billing Doc Date', sortName: 'billingDocDate'},
  {title: 'Billing document', sortName: 'billingDocument'},
  {title: 'Material'},
  {title: 'Description of material'},
  {title: 'Division'},
  {title: 'Description of division'},
  {title: 'Product technology'},
  {title: 'Description of product technology'},
  {title: 'Ship-to party', sortName: 'shipToParty'},
  {title: 'Description of ship-to party', sortName: 'descriptionOfShipToParty'},
  {title: 'Quantity'},
  {title: 'Net value'},
  {title: 'Tax amount'},
  {title: 'Gross Value'},
  {title: 'Net Value (EUR)'},
  {title: 'Tax Amount (EUR)'},
  {title: 'Gross Value (EUR)'},
  {title: 'XRate (EUR -> RUB)'},
]

class Reports extends Component {
  static propTypes = {
    reportsFormValues: PropTypes.object.isRequired,
    reports: PropTypes.object,
    isReportsFetching: PropTypes.bool.isRequired,
    reportsSize: PropTypes.number.isRequired,
    reportsPage: PropTypes.number.isRequired,
    reportsSort: PropTypes.array,
    isSellInFileDownload: PropTypes.bool.isRequired,

    updateReportsFormValues: PropTypes.func.isRequired,
    fetchReports: PropTypes.func.isRequired,
    setReportsSize: PropTypes.func.isRequired,
    setReportsPage: PropTypes.func.isRequired,
    setReportsSort: PropTypes.func.isRequired,
    downloadSellInFile: PropTypes.func.isRequired,
  }

  componentWillReceiveProps(nextProps) {
    const {reportsSize, reportsPage, reportsSort, fetchReports} = this.props
    if (
      nextProps.reportsSize !== reportsSize ||
      nextProps.reportsPage !== reportsPage ||
      nextProps.reportsSort !== reportsSort
    ) {
      fetchReports()
    }
  }

  get tableRows() {
    const {reports} = this.props
    return (
      reports &&
      reports.content.map(item => ({
        id: item.id,
        data: [
          item.salesOrganization,
          item.soldToParty,
          item.customer,
          moment(item.billingDocDate).format('DD.MM.YYYY'),
          item.billingDocument,
          item.material,
          item.descriptionOfMaterial,
          item.division,
          item.descriptionOfDivision,
          item.productTechnology,
          item.descriptionOfProductTechnology,
          item.shipToParty,
          item.descriptionOfShipToParty,
          item.quantity,
          item.netValue,
          item.taxAmount,
          item.grossValue,
          item.netValueEur,
          item.taxAmountEur,
          item.grossValueEur,
          item.rate,
        ],
      }))
    )
  }

  render() {
    const {
      reportsFormValues,
      reports,
      isReportsFetching,
      reportsSize,
      reportsPage,
      reportsSort,
      isSellInFileDownload,
      updateReportsFormValues,
      fetchReports,
      setReportsSize,
      setReportsPage,
      setReportsSort,
      downloadSellInFile,
    } = this.props
    const total = reports ? reports.totalElements : 0
    return (
      <section className="container loaded">
        <Loader isFetch={isSellInFileDownload} />
        <Paper>
          <div className="block">
            <ReportsForm
              reports={reports}
              reportsFormValues={reportsFormValues}
              isReportsFetching={isReportsFetching}
              isSellInFileDownload={isSellInFileDownload}
              updateReportsFormValues={updateReportsFormValues}
              fetchReports={fetchReports}
              downloadSellInFile={downloadSellInFile}
            />
            {(isReportsFetching || reports) && (
              <div className="mt loaded">
                <ExtendedTable
                  isInternal
                  headerCells={headerCells}
                  tableRows={this.tableRows}
                  isFetching={isReportsFetching}
                  total={total}
                  rowsPerPage={reportsSize}
                  page={reportsPage}
                  sort={reportsSort}
                  setSize={setReportsSize}
                  setPage={setReportsPage}
                  setSort={setReportsSort}
                />
              </div>
            )}
          </div>
        </Paper>
      </section>
    )
  }
}

const mapStateToProps = ({reports}) => ({
  reportsFormValues: reports.reportsFormValues,
  reports: reports.reports,
  isReportsFetching: reports.isReportsFetching,
  reportsSize: reports.reportsSize,
  reportsPage: reports.reportsPage,
  reportsSort: reports.reportsSort,
  isSellInFileDownload: reports.isSellInFileDownload,
})

const mapDispatchToProps = {
  updateReportsFormValues,
  fetchReports,
  setReportsSize,
  setReportsPage,
  setReportsSort,
  downloadSellInFile,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Reports)
