import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import MaskedInput from 'react-text-mask'
import {InlineDatePicker} from 'material-ui-pickers'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import Checkbox from '@material-ui/core/Checkbox'
import FormHelperText from '@material-ui/core/FormHelperText'

const placeholderChar = '_'
const phoneMask = ['+', '7', ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
const dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
const monthMask = [/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]

const monthViews = ['year', 'month']
const dateViews = ['day']

const setSelectionRange = (input, selectionStart, selectionEnd) => {
  if (input.setSelectionRange) {
    input.setSelectionRange(selectionStart, selectionEnd)
  } else if (input.createTextRange) {
    const range = input.createTextRange()
    range.collapse(true)
    range.moveEnd('character', selectionEnd)
    range.moveStart('character', selectionStart)
    range.select()
  }
}
const setCaretToPos = e => {
  const pos = e.target.value.indexOf(placeholderChar)
  if (!~pos) return
  setSelectionRange(e.target, pos, pos)
}
const renderMaskedInput = ({inputRef, ...props}) => (
  <MaskedInput
    {...props}
    showMask
    ref={ref => {
      inputRef(ref ? ref.inputElement : null)
    }}
    mask={phoneMask}
    placeholderChar={placeholderChar}
    onFocus={setCaretToPos}
    onClick={setCaretToPos}
  />
)
const shrinkLabelProps = {shrink: true}
const maskedInputProps = {
  inputComponent: renderMaskedInput,
}

export default class InputField extends Component {
  static propTypes = {
    isMask: PropTypes.bool,
    isDatePlaceholder: PropTypes.bool,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    type: PropTypes.string,
    label: PropTypes.string,
    options: PropTypes.array,
    inputProps: PropTypes.object,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }

  handleInputChange = e => {
    const {input, onChange} = this.props
    onChange(e.target.value)
    input.onChange(e.target.value)
  }

  handleDatePickerChange = value => {
    const {input, onChange} = this.props
    onChange(value)
    input.onChange(value)
  }

  handleInputBlur = e => {
    const {input, onBlur} = this.props
    input.onBlur()
    onBlur(e.target.value, input.onChange)
  }

  renderOptions() {
    return this.props.options.map((option, index) => (
      <MenuItem key={index} value={option.value}>
        {option.label}
      </MenuItem>
    ))
  }

  render() {
    const {
      isMask,
      isDatePlaceholder,
      input,
      meta,
      type,
      label,
      options,
      inputProps,
      onChange,
      onBlur,
      ...props
    } = this.props
    if (type === 'radio')
      return (
        <FormControlLabel
          control={<Radio {...input} onChange={onChange ? this.handleInputChange : input.onChange} />}
          label={label}
        />
      )
    if (type === 'checkbox') return <FormControlLabel control={<Checkbox {...input} {...props} />} label={label} />
    const isError = meta.error && meta.touched
    if (type === 'datePicker' || type === 'monthPicker') {
      const isMonth = type === 'monthPicker'
      return (
        <InlineDatePicker
          {...input}
          fullWidth
          keyboard
          clearable
          label={label}
          openTo={isMonth ? 'year' : 'day'}
          views={isMonth ? monthViews : dateViews}
          margin="dense"
          InputLabelProps={isDatePlaceholder ? shrinkLabelProps : null}
          placeholder={isDatePlaceholder ? 'ДД/ММ/ГГГГ' : null}
          format={isMonth ? 'MM/YYYY' : 'DD/MM/YYYY'}
          variant="outlined"
          error={isError}
          InputProps={
            !moment(input.value, 'DD-MM-YYYY', true).isValid()
              ? {
                  value: input.value._i,
                }
              : null
          }
          helperText={isError && <span className="loaded">Некорркетная дата</span>}
          mask={isMonth ? monthMask : dateMask}
          onError={value => input.onChange(value)}
          onChange={onChange ? this.handleDatePickerChange : input.onChange}
          {...props}
        />
      )
    }
    return (
      <Fragment>
        <TextField
          {...input}
          fullWidth
          label={label}
          type={type}
          select={Boolean(options)}
          error={isError}
          margin="dense"
          variant="outlined"
          InputLabelProps={type === 'date' || isMask ? shrinkLabelProps : null}
          InputProps={isMask ? {...maskedInputProps, ...inputProps} : inputProps}
          onChange={onChange ? this.handleInputChange : input.onChange}
          onBlur={onBlur ? this.handleInputBlur : input.onBlur}
          {...props}
        >
          {options && this.renderOptions()}
        </TextField>
        {isError && meta.error !== 'required' && (
          <FormHelperText error className="loaded">
            {meta.error}
          </FormHelperText>
        )}
      </Fragment>
    )
  }
}
