import axios from 'axios'

import {handleWithSnackbarError} from '../utils/helpres'

const module = 'history'

const FETCH_HISTORY = `${module}/FETCH_HISTORY`
const FETCH_HISTORY_SUCCESS = `${module}/FETCH_HISTORY_SUCCESS`
const FETCH_HISTORY_FAIL = `${module}/FETCH_HISTORY_FAIL`

const SET_HISTORY_SIZE = `${module}/SET_HISTORY_SIZE`
const SET_HISTORY_PAGE = `${module}/SET_HISTORY_PAGE`
const SET_HISTORY_SORT = `${module}/SET_HISTORY_SORT`

const initialState = {
  history: null,
  isHistoryFetching: false,
  historyError: null,

  historySize: 10,
  historyPage: 0,
  historySort: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_HISTORY:
      return {...state, isHistoryFetching: true, historyError: null}
    case FETCH_HISTORY_SUCCESS:
      return {...state, isHistoryFetching: false, history: action.history}
    case FETCH_HISTORY_FAIL:
      return {...state, isHistoryFetching: false, historyError: action.error}

    case SET_HISTORY_SIZE:
      return {...state, historySize: action.size}
    case SET_HISTORY_PAGE:
      return {...state, historyPage: action.page}
    case SET_HISTORY_SORT:
      return {...state, historySort: action.sort}

    default:
      return state
  }
}

export const fetchHistory = () => (dispatch, getState) => {
  dispatch({type: FETCH_HISTORY})
  const state = getState().history
  const params = {
    size: state.historySize,
    page: state.historyPage,
  }
  if (state.historySort) {
    params.sort = `${state.historySort[0]},${state.historySort[1]}`
  }
  axios
    .get('/file/history', {params})
    .then(({data}) => dispatch({type: FETCH_HISTORY_SUCCESS, history: data}))
    .catch(error => handleWithSnackbarError(dispatch, FETCH_HISTORY_FAIL, error))
}

export const setHistorySize = size => ({type: SET_HISTORY_SIZE, size})
export const setHistoryPage = page => ({type: SET_HISTORY_PAGE, page})
export const setHistorySort = sort => ({type: SET_HISTORY_SORT, sort})
