import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import {withStyles} from '@material-ui/core/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'

import {COLORS, THEME} from '../../../styles/muiTheme'

const anchorOrigin = {
  vertical: 'top',
  horizontal: 'right',
}

const styles = {
  success: {
    backgroundColor: COLORS.success,
  },
  error: {
    backgroundColor: COLORS.errorDark,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: THEME.UNIT,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}

Notification.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.string.isRequired,
}

function Notification({classes, message, onClose, variant, ...props}) {
  const Icon = variant === 'success' ? CheckCircleIcon : ErrorIcon
  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      autoHideDuration={6000}
      ContentProps={{
        className: cn(classes[variant]),
      }}
      message={
        <span className={classes.message}>
          <Icon className={cn(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" color="inherit" className={classes.close} onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      onClose={onClose}
      {...props}
    />
  )
}

export default withStyles(styles)(Notification)
