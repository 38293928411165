import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import ImageIcon from '@material-ui/icons/ImageTwoTone'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFileTwoTone'
import DeleteIcon from '@material-ui/icons/DeleteTwoTone'

export default class PartnerDocumentActions extends Component {
  static propTypes = {
    documentId: PropTypes.number.isRequired,
    fileName: PropTypes.string,
    scanId: PropTypes.number,
    scanFileName: PropTypes.string,
    documentDeleteId: PropTypes.number,
    documentDeleteBonusId: PropTypes.number,
    isDocumentDelete: PropTypes.bool,
    downloadFile: PropTypes.func.isRequired,
    deleteDocument: PropTypes.func,
  }

  handleScanDownload = e => {
    e.stopPropagation()
    const {scanId, scanFileName, downloadFile} = this.props
    downloadFile({scanId, scanFileName})
  }

  handleFileDownload = e => {
    e.stopPropagation()
    const {documentId, fileName, downloadFile} = this.props
    downloadFile({documentId, fileName})
  }

  handleDocumentDelete = () => {
    const {documentDeleteId, documentDeleteBonusId, deleteDocument} = this.props
    deleteDocument(documentDeleteId, documentDeleteBonusId)
  }

  render() {
    const {document, scanId, isDocumentDelete, deleteDocument} = this.props
    return (
      <div className="table-actions f ws-nw">
        <Tooltip disableFocusListener title="Сохранить сканкопию" enterDelay={500}>
          <div>
            <IconButton onClick={this.handleScanDownload} disabled={!scanId}>
              <ImageIcon />
            </IconButton>
          </div>
        </Tooltip>
        <Tooltip disableFocusListener title="Сохранить документ" enterDelay={500}>
          <IconButton onClick={this.handleFileDownload}>
            <InsertDriveFileIcon />
          </IconButton>
        </Tooltip>
        {deleteDocument && (
          <Tooltip disableFocusListener title="Удалить документ" enterDelay={500}>
            <div>
              <IconButton
                onClick={this.handleDocumentDelete}
                disabled={Boolean(isDocumentDelete || document.subItems || !document.contractState.INITIALIZE_CONTRACT)}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </Tooltip>
        )}
      </div>
    )
  }
}
