import axios from 'axios'
import {createSelector} from 'reselect'

import {handleWithSnackbarError} from '../utils/helpres'
import {sortOptions, flatArray} from '../utils/helpres'
import ROLES, {ROLE_PREFIX} from '../utils/constants'

const module = 'references'

const FETCH_REGIONAL_MANAGERS = `${module}/FETCH_REGIONAL_MANAGERS`
const FETCH_REGIONAL_MANAGERS_SUCCESS = `${module}/FETCH_REGIONAL_MANAGERS_SUCCESS`
const FETCH_REGIONAL_MANAGERS_FAIL = `${module}/FETCH_REGIONAL_MANAGERS_FAIL`

const FETCH_REGIONAL_REPRESENTATIVES = `${module}/FETCH_REGIONAL_REPRESENTATIVES`
const FETCH_REGIONAL_REPRESENTATIVES_SUCCESS = `${module}/FETCH_REGIONAL_REPRESENTATIVES_SUCCESS`
const FETCH_REGIONAL_REPRESENTATIVES_FAIL = `${module}/FETCH_REGIONAL_REPRESENTATIVES_FAIL`

const FETCH_SERVICE_COORDINATORS = `${module}/FETCH_SERVICE_COORDINATORS`
const FETCH_SERVICE_COORDINATORS_SUCCESS = `${module}/FETCH_SERVICE_COORDINATORS_SUCCESS`
const FETCH_SERVICE_COORDINATORS_FAIL = `${module}/FETCH_SERVICE_COORDINATORS_FAIL`

const FETCH_FEDERAL_DISTRICTS = `${module}/FETCH_FEDERAL_DISTRICTS`
const FETCH_FEDERAL_DISTRICTS_SUCCESS = `${module}/FETCH_FEDERAL_DISTRICTS_SUCCESS`
const FETCH_FEDERAL_DISTRICTS_FAIL = `${module}/FETCH_FEDERAL_DISTRICTS_FAIL`

const FETCH_PARTNER_TYPES = `${module}/FETCH_PARTNER_TYPES`
const FETCH_PARTNER_TYPES_SUCCESS = `${module}/FETCH_PARTNER_TYPES_SUCCESS`
const FETCH_PARTNER_TYPES_FAIL = `${module}/FETCH_PARTNER_TYPES_FAIL`

const FETCH_CONTRACT_BONUS_TYPES = `${module}/FETCH_CONTRACT_BONUS_TYPES`
const FETCH_CONTRACT_BONUS_TYPES_SUCCESS = `${module}/FETCH_CONTRACT_BONUS_TYPES_SUCCESS`
const FETCH_CONTRACT_BONUS_TYPES_FAIL = `${module}/FETCH_CONTRACT_BONUS_TYPES_FAIL`

const FETCH_ALL_CURATORS = `${module}/FETCH_ALL_CURATORS`
const FETCH_ALL_CURATORS_SUCCESS = `${module}/FETCH_ALL_CURATORS_SUCCESS`
const FETCH_ALL_CURATORS_FAIL = `${module}/FETCH_ALL_CURATORS_FAIL`

const initialState = {
  regionalManagers: null,
  isRegionalManagersFetching: false,
  regionalManagersError: null,

  regionalManagerById: null,
  isRegionalManagerByIdFetching: false,
  regionalManagerByIdError: null,

  regionalRepresentatives: null,
  isRegionalRepresentativesFetching: false,
  regionalRepresentativesError: null,

  serviceCoordinators: null,
  isServiceCoordinatorsFetching: false,
  serviceCoordinatorsError: null,

  federalDistricts: null,
  isFederalDistrictsFetching: false,
  federalDistrictsError: null,

  partnerTypes: null,
  isPartnerTypesFetching: false,
  partnerTypesError: null,

  contractBonusTypes: null,
  isContractBonusTypesFetching: false,
  contractBonusTypesError: null,

  allCurators: null,
  isAllCuratorsFetching: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REGIONAL_MANAGERS:
      return {...state, isRegionalManagersFetching: true, regionalManagersError: null}
    case FETCH_REGIONAL_MANAGERS_SUCCESS:
      return {...state, isRegionalManagersFetching: false, regionalManagers: action.regionalManagers}
    case FETCH_REGIONAL_MANAGERS_FAIL:
      return {...state, isRegionalManagersFetching: false, regionalManagersError: action.error}

    case FETCH_REGIONAL_REPRESENTATIVES:
      return {...state, isRegionalRepresentativesFetching: true, regionalRepresentativesError: null}
    case FETCH_REGIONAL_REPRESENTATIVES_SUCCESS:
      return {
        ...state,
        isRegionalRepresentativesFetching: false,
        regionalRepresentatives: action.regionalRepresentatives,
      }
    case FETCH_REGIONAL_REPRESENTATIVES_FAIL:
      return {...state, isRegionalRepresentativesFetching: false, regionalRepresentativesError: action.error}

    case FETCH_SERVICE_COORDINATORS:
      return {...state, isServiceCoordinatorsFetching: true, serviceCoordinatorsError: null}
    case FETCH_SERVICE_COORDINATORS_SUCCESS:
      return {...state, isServiceCoordinatorsFetching: false, serviceCoordinators: action.serviceCoordinators}
    case FETCH_SERVICE_COORDINATORS_FAIL:
      return {...state, isServiceCoordinatorsFetching: false, serviceCoordinatorsError: action.error}

    case FETCH_FEDERAL_DISTRICTS:
      return {...state, isFederalDistrictsFetching: true, federalDistrictsError: null}
    case FETCH_FEDERAL_DISTRICTS_SUCCESS:
      return {...state, isFederalDistrictsFetching: false, federalDistricts: action.federalDistricts}
    case FETCH_FEDERAL_DISTRICTS_FAIL:
      return {...state, isFederalDistrictsFetching: false, federalDistrictsError: action.error}

    case FETCH_PARTNER_TYPES:
      return {...state, isPartnerTypesFetching: true, partnerTypesError: null}
    case FETCH_PARTNER_TYPES_SUCCESS:
      return {...state, isPartnerTypesFetching: false, partnerTypes: action.partnerTypes}
    case FETCH_PARTNER_TYPES_FAIL:
      return {...state, isPartnerTypesFetching: false, partnerTypesError: action.error}

    case FETCH_CONTRACT_BONUS_TYPES:
      return {...state, isContractBonusTypesFetching: true, contractBonusTypesError: null}
    case FETCH_CONTRACT_BONUS_TYPES_SUCCESS:
      return {...state, isContractBonusTypesFetching: false, contractBonusTypes: action.contractBonusTypes}
    case FETCH_CONTRACT_BONUS_TYPES_FAIL:
      return {...state, isContractBonusTypesFetching: false, contractBonusTypesError: action.error}

    case FETCH_ALL_CURATORS:
      return {...state, isAllCuratorsFetching: true}
    case FETCH_ALL_CURATORS_SUCCESS:
      return {...state, isAllCuratorsFetching: false, allCurators: action.allCurators}
    case FETCH_ALL_CURATORS_FAIL:
      return {...state, isAllCuratorsFetching: false}

    default:
      return state
  }
}

const getPeople = people =>
  people
    ? sortOptions(
        flatArray(flatArray(Object.values(people).map(item => Object.values(item)))).map(person => ({
          value: person.id,
          label: person.name,
        }))
      )
    : []

const getRegionalManagers = regionalManagers => regionalManagers
export const regionalManagersSelector = createSelector(
  getRegionalManagers,
  getPeople
)

const getRegionalRepresentatives = regionalRepresentatives => regionalRepresentatives
export const regionalRepresentativesSelector = createSelector(
  getRegionalRepresentatives,
  getPeople
)

const getServiceCoordinators = serviceCoordinators => serviceCoordinators
export const serviceCoordinatorsSelector = createSelector(
  getServiceCoordinators,
  getPeople
)

export const fetchRegionalManagers = () => dispatch => {
  dispatch({type: FETCH_REGIONAL_MANAGERS})
  axios
    .get(`/partner/vgr-region-curator/${ROLE_PREFIX}${ROLES.RM}`)
    .then(({data}) => dispatch({type: FETCH_REGIONAL_MANAGERS_SUCCESS, regionalManagers: data}))
    .catch(error => handleWithSnackbarError(dispatch, FETCH_REGIONAL_MANAGERS_FAIL, error))
}

export const fetchRegionalRepresentatives = () => dispatch => {
  dispatch({type: FETCH_REGIONAL_REPRESENTATIVES})
  axios
    .get(`/partner/vgr-region-curator/${ROLE_PREFIX}${ROLES.RP}`)
    .then(({data}) => dispatch({type: FETCH_REGIONAL_REPRESENTATIVES_SUCCESS, regionalRepresentatives: data}))
    .catch(error => handleWithSnackbarError(dispatch, FETCH_REGIONAL_REPRESENTATIVES_FAIL, error))
}

export const fetchServiceCoordinators = () => dispatch => {
  dispatch({type: FETCH_SERVICE_COORDINATORS})
  axios
    .get(`/partner/vgr-region-curator/${ROLE_PREFIX}${ROLES.SC}`)
    .then(({data}) => dispatch({type: FETCH_SERVICE_COORDINATORS_SUCCESS, serviceCoordinators: data}))
    .catch(error => handleWithSnackbarError(dispatch, FETCH_SERVICE_COORDINATORS_FAIL, error))
}

export const fetchFederalDistricts = () => dispatch => {
  dispatch({type: FETCH_FEDERAL_DISTRICTS})
  axios
    .get('/nsi/federal-district')
    .then(({data}) => dispatch({type: FETCH_FEDERAL_DISTRICTS_SUCCESS, federalDistricts: data}))
    .catch(error => handleWithSnackbarError(dispatch, FETCH_FEDERAL_DISTRICTS_FAIL, error))
}

export const fetchPartnerTypes = () => dispatch => {
  dispatch({type: FETCH_PARTNER_TYPES})
  axios
    .get('/nsi/partner-type')
    .then(({data}) => dispatch({type: FETCH_PARTNER_TYPES_SUCCESS, partnerTypes: data}))
    .catch(error => handleWithSnackbarError(dispatch, FETCH_PARTNER_TYPES_FAIL, error))
}

export const fetchContractBonusTypes = () => dispatch => {
  dispatch({type: FETCH_CONTRACT_BONUS_TYPES})
  axios
    .get('/nsi/contract-type')
    .then(({data}) => dispatch({type: FETCH_CONTRACT_BONUS_TYPES_SUCCESS, contractBonusTypes: data}))
    .catch(error => handleWithSnackbarError(dispatch, FETCH_CONTRACT_BONUS_TYPES_FAIL, error))
}

export const fetchAllCurators = () => dispatch => {
  dispatch({type: FETCH_ALL_CURATORS})
  axios
    .get('/partner/vgr-region-curator')
    .then(({data}) => dispatch({type: FETCH_ALL_CURATORS_SUCCESS, allCurators: data}))
    .catch(error => handleWithSnackbarError(dispatch, FETCH_ALL_CURATORS_FAIL, error))
}
