import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Button from '@material-ui/core/Button'

import withRowSelect from '../../_decorators/withRowSelect'
import withModal from '../../_decorators/withModal'
import ExtendedTable from '../../_common/ExtendedTable'
import AgreementComments from '../../_common/AgreementComments'
import AgreementStages from '../../_common/AgreementStages'
import Modal from '../../_common/Modal'
import MessageForm from '../../_common/MessageForm'
import {isAddCommentButton} from '../../../utils/helpres'

const headerCells = [
  {title: 'Дата смены статуса', sortName: 'changeDate'},
  {title: 'Дата создания', sortName: 'createdDate'},
  {title: 'Тип документа(ов)', sortName: 'sortContractType'},
  {title: 'Номер документа', sortName: 'documentNumber'},
  {title: 'Партнер', sortName: 'orgName'},
  {title: 'Статус', sortName: 'sortPackageState'},
]

class AgreementHistory extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    historicSheets: PropTypes.object,
    isHistoricSheetsFetching: PropTypes.bool.isRequired,
    historicSheetsSize: PropTypes.number.isRequired,
    historicSheetsPage: PropTypes.number.isRequired,
    historicSheetsSort: PropTypes.array,
    isSendMessageFetching: PropTypes.bool.isRequired,
    selectedRow: PropTypes.number,
    isModalOpen: PropTypes.bool.isRequired,
    fetchHistoricSheets: PropTypes.func.isRequired,
    setHistoricSheetsSize: PropTypes.func.isRequired,
    setHistoricSheetsPage: PropTypes.func.isRequired,
    setHistoricSheetsSort: PropTypes.func.isRequired,
    sendMessage: PropTypes.func.isRequired,
    onRowSelect: PropTypes.func.isRequired,
    onModalToggle: PropTypes.func.isRequired,
  }

  componentDidMount() {
    this.props.fetchHistoricSheets()
  }

  componentWillReceiveProps(nextProps) {
    const {historicSheetsSize, historicSheetsPage, historicSheetsSort, fetchHistoricSheets} = this.props
    if (
      nextProps.historicSheetsSize !== historicSheetsSize ||
      nextProps.historicSheetsPage !== historicSheetsPage ||
      nextProps.historicSheetsSort !== historicSheetsSort
    ) {
      fetchHistoricSheets()
    }
  }

  getTableRows() {
    const {historicSheets} = this.props
    return historicSheets
      ? historicSheets.content.map(sheet => ({
          id: sheet.id,
          data: [
            moment(sheet.changeDate).format('DD.MM.YYYY'),
            moment(sheet.createdDate).format('DD.MM.YYYY'),
            <Fragment>
              {sheet.contractType && <p>{Object.values(sheet.contractType)}</p>}
              {sheet.bonusType && Object.values(sheet.bonusType).map((bonus, index) => <p key={index}>{bonus}</p>)}
            </Fragment>,
            sheet.documentNumber,
            sheet.orgName,
            Object.values(sheet.packageState),
          ],
        }))
      : []
  }

  render() {
    const {
      user,
      historicSheets,
      isHistoricSheetsFetching,
      historicSheetsSize,
      historicSheetsPage,
      historicSheetsSort,
      isSendMessageFetching,
      selectedRow,
      isModalOpen,
      setHistoricSheetsSize,
      setHistoricSheetsPage,
      setHistoricSheetsSort,
      sendMessage,
      onRowSelect,
      onModalToggle,
    } = this.props
    const total = historicSheets ? historicSheets.totalElements : 0
    const historicalSheet = selectedRow && historicSheets.content.find(sheet => sheet.id === selectedRow)
    const curators = historicalSheet ? historicalSheet.approvalSheetCurators : null
    return (
      <div className="agreement-history loaded">
        <ExtendedTable
          isInternal
          headerCells={headerCells}
          tableRows={this.getTableRows()}
          isFetching={isHistoricSheetsFetching}
          selected={selectedRow}
          total={total}
          rowsPerPage={historicSheetsSize}
          page={historicSheetsPage}
          sort={historicSheetsSort}
          setPage={setHistoricSheetsPage}
          setSize={setHistoricSheetsSize}
          setSort={setHistoricSheetsSort}
          onRowClick={onRowSelect}
        />
        {selectedRow &&
          historicalSheet &&
          (historicalSheet.approvalSheetComments || historicalSheet.approvalSheetCurators) && (
            <div key={selectedRow} className="loaded p-r">
              {historicalSheet.approvalSheetComments ? (
                <AgreementComments curators={curators} comments={historicalSheet.approvalSheetComments} />
              ) : (
                <div className="mb" />
              )}
              {historicalSheet.approvalSheetCurators && (
                <>
                  <AgreementStages curators={historicalSheet.approvalSheetCurators} />
                  {historicalSheet && isAddCommentButton(historicalSheet, user) && (
                    <div className="f ai-c jc-sb mt">
                      <Button variant="outlined" color="primary" onClick={onModalToggle}>
                        Добавить комментарий
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        <Modal isForm open={isModalOpen} title="Дополнительный комментарий" onClose={onModalToggle}>
          <MessageForm
            user={user}
            approvalSheet={historicalSheet}
            isFetch={isSendMessageFetching}
            isModalOpen={isModalOpen}
            onModalClose={onModalToggle}
            sendMessage={sendMessage}
          />
        </Modal>
      </div>
    )
  }
}

export default withRowSelect(withModal(AgreementHistory))
